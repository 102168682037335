import type { FC } from 'react';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { extendSession } from '~/Modules/user';
import LogoutWarning from '~/Shared/Components/LogoutWarning/LogoutWarning';
import useIdleTimer from '~/hooks/useIdleTimer';
import MainHeader from '~/Shared/Components/MainHeader/MainHeader';
import LiveChat from '~/Shared/Components/LiveChat/LiveChat';
import { isHealthPlanRole } from '~/utilities/helperFunctions';
import type { AppStore } from '~/types';

/** Controls how long a user can be inactive before it triggers
 * the auto-logout modal. Milliseconds */
const IDLE_TIMEOUT = 60 * 15 * 1000;

interface PageFrameProps {
  children: React.ReactNode;
}

const env = process.env.REACT_APP_ENVIRONMENT;

const PageFrame: FC<PageFrameProps> = ({ children }) => {
  const user = useSelector((state: AppStore) => state.user);
  const [isIdle, setIsIdle] = useState(false);
  const [showLogoutWarning, setShowLogoutWarning] = useState(false);
  const [idleTimerMount, idleTimerDismount] = useIdleTimer(IDLE_TIMEOUT, setIsIdle);

  useEffect(() => {
    idleTimerMount();

    return () => idleTimerDismount();
  }, []);

  // Mount and dismount idleTimer eventListeners
  useEffect(() => {
    if (isIdle) {
      idleTimerDismount();
    } else {
      idleTimerMount();
    }
  }, [isIdle, idleTimerMount, idleTimerDismount]);

  // passing a callback that generates a call back to the logout warning component
  const handleExtendSessionRequest = () => {
    if (showLogoutWarning) {
      extendSession(() => {
        setShowLogoutWarning(false);
      });
    } else if (isIdle) {
      setIsIdle(false);
    }
  };

  return (
    <main className="careAppContainer">
      {isIdle && env !== 'local' ? (
        <LogoutWarning ttl={60} extendSession={handleExtendSessionRequest} />
      ) : null}

      <MainHeader />

      <section id="careAppContentContainer">
        {!isHealthPlanRole(user) ? <LiveChat /> : null}
        <section className="careAppBody">{children}</section>
      </section>
    </main>
  );
};

export default PageFrame;
