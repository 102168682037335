import React from 'react';
import { Card, Alert } from '@SRHealth/frontend-lib';
import { format, parseISO, isToday } from 'date-fns';
import DuplicateRideDetails from './DuplicateRideDetails';
import { NOOP } from '~/utilities/helperFunctions';
import type { DuplicateRide } from '~/Modules/rideBooking';

interface DateSummaryProps {
  duplicateRides: DuplicateRide[];
  selectedDate: DateString;
  onEdit: () => void;
}

const DateSummary = ({ duplicateRides, selectedDate, onEdit }: DateSummaryProps) => {
  const hasDuplicateRides = duplicateRides.length > 0;
  const selectedDateObj = parseISO(selectedDate);
  const selectedDateIsToday = isToday(selectedDateObj);
  const selectedDateFormatted = selectedDateIsToday
    ? `Today, ${format(selectedDateObj, 'MMMM d, yyyy')}`
    : format(selectedDateObj, 'EEEE, MMMM d, yyyy');

  return (
    <Card label="Trip Date" editable={false} onEdit={onEdit} onCancel={NOOP}>
      <Card.Content>
        <div className="reset-div text-xl">{selectedDateFormatted}</div>

        {hasDuplicateRides && (
          <div style={{ paddingTop: '20px' }}>
            <Alert
              type="warning"
              label="This member has one or more rides booked on this date. Please confirm they need an additional ride."
            />
          </div>
        )}
      </Card.Content>
      {hasDuplicateRides && (
        <Card.Content
          label={`Additional rides ${selectedDateIsToday ? '' : 'on'} ${selectedDateFormatted}`}
        >
          {duplicateRides.map(duplicateRide => (
            <DuplicateRideDetails
              key={duplicateRide.id}
              duplicateRide={duplicateRide}
              selectedDate={selectedDate}
            />
          ))}
        </Card.Content>
      )}
    </Card>
  );
};

export default DateSummary;
