import React from 'react';
import type { MemberProfileStore } from '~/Modules/memberProfile';

export type MemberPortalStatusProps = {
  personalInfo: MemberProfileStore['formData']['personalInfo'];
  memberPortalInfo: MemberProfileStore['memberPortalInfo'];
};

export default function MemberPortalStatus({
  personalInfo,
  memberPortalInfo
}: MemberPortalStatusProps) {
  const status: DotAndStatusProps['status'] =
    memberPortalInfo.isActive && memberPortalInfo.userLogin ? 'Active' : 'Inactive';

  return !personalInfo.displayMemberPortal ? null : <DotAndStatus status={status} />;
}

type DotAndStatusProps = {
  status: 'Active' | 'Inactive';
};

function DotAndStatus({ status }: DotAndStatusProps) {
  return (
    <>
      <span className={`dot ${status !== 'Active' && 'is' + status}`}></span>
      <span className="eligibleStatus">{`Member Portal: ${status}`}</span>
    </>
  );
}
