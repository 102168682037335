//actions file
import _ from 'lodash-es';
import axios from './safeAxios';
import { baseRequestConfig } from '~/utilities/auth.helper';

export const GET_ADDRESSES = 'mapbox/GET_ADDRESSES';
export const GET_ADDRESS_TIMEZONE = 'mapbox/GET_ADDRESS_TIMEZONE';
export const GET_ADDRESS_TIMEZONE_ERROR = 'mapbox/GET_ADDRESS_TIMEZONE_ERROR';
export const GET_DIRECTIONS = 'mapbox/GET_DIRECTIONS';
export const AUTOCOMPLETE_ERROR = 'mapbox/AUTOCOMPLETE_ERROR';
export const DIRECTIONS_ERROR = 'mapbox/DIRECTIONS_ERROR';
export const CLEAR_ALL = 'mapbox/CLEAR_ALL';
export const CHECK_LOCATION = 'locationCheck/CHECK_LOCATION';
export const CHECK_LOCATION_ERROR = 'locationCheck/CHECK_LOCATION_ERROR';

const accessToken = process.env.REACT_APP_MAPBOX_TOKEN;
const url = 'https://api.mapbox.com/geocoding/v5/mapbox.places/';
const directionsUrl = 'https://api.mapbox.com/directions/v5/mapbox/driving/';
const tileeUrl = 'https://api.mapbox.com/v4/whit-schrader.927rombv/tilequery/';

export const clearAll = () => {
  return dispatch => {
    dispatch({
      type: CLEAR_ALL
    });
  };
};
/**
 * action for checking the location against the back end for certain addresses
 * for health plans some health plans require addresses to be preapproved
 * @param {object} params - object with address fields and health plan information
 * @return {dispatch} - returns dispatch object
 */
export const checkLocation = params => {
  const url = `${process.env.REACT_APP_PROTOCOL}://${process.env.REACT_APP_ANALYTICS_API_HOST}/api/v1/rides/check-location`;
  return dispatch => {
    return axios
      .post(url, params, baseRequestConfig())
      .then(response => {
        dispatch({
          type: CHECK_LOCATION,
          data: response.data,
          params
        });
      })
      .catch(error => {
        dispatch({
          type: CHECK_LOCATION_ERROR,
          error
        });
      });
  };
};

/**
 * autocomplete for ride booking and ride editing
 * @param {string} address - address
 * @param {object} proximityCoords - coordinates for proximity
 * @return {dispatch} - returns dispatch
 */
export const autoComplete = (address, proximityCoords) => {
  const lng = _.get(proximityCoords, 'lng', null);
  const lat = _.get(proximityCoords, 'lat', null);
  let proximity = '';

  if (!_.isNil(lng) && !_.isNil(lat)) {
    proximity = `${lng},${lat}`;
  }

  const encodedAddress = encodeURIComponent(address);

  return dispatch => {
    const mapboxUrl = `${url}${encodedAddress}.json?access_token=${accessToken}&proximity=${proximity}&autocomplete=true&country=us&fuzzyMatch=true&routing=true`;
    return axios
      .get(mapboxUrl)
      .then(response => {
        dispatch({
          type: GET_ADDRESSES,
          features: response.data.features,
          query: response.data.features,
          status: 200
        });
      })
      .catch(error => {
        dispatch({
          type: AUTOCOMPLETE_ERROR,
          error
        });
      });
  };
};

/**
 * get route for ride booking, edit ride view, and ride report
 * @param {double} latitude - pickup longitude
 * @param {double} longitude - pickup longitude
 * @param {boolean} dropoff - set to true if using dropoff coordinates
 * @return {void}
 */

export const getTimezoneFromLatLong = (latitude, longitude, dropoff) => {
  return dispatch => {
    const requestUrl = `${tileeUrl}${longitude},${latitude}.json?limit=1&dedupe=true&access_token=${accessToken}`;
    return axios
      .get(requestUrl)
      .then(response => {
        response.data.dropoff = dropoff;
        dispatch({
          type: GET_ADDRESS_TIMEZONE,
          data: response.data
        });
      })
      .catch(error => {
        dispatch({
          type: GET_ADDRESS_TIMEZONE_ERROR,
          data: { dropoff },
          error: error?.message ?? error
        });
      });
  };
};

/**
 * get route for ride booking, edit ride view, and ride report
 * @param {double} pickupLongitude - pickup longitude
 * @param {double} pickupLatitude - pickup longitude
 * @param {double} dropoffLongitude - pickup longitude
 * @param {double} dropoffLatitude - pickup longitude
 * @return {dispatch} - return dispatch
 */
export const getDirections = (
  pickupLongitude,
  pickupLatitude,
  dropoffLongitude,
  dropoffLatitude
) => {
  return dispatch => {
    const directionsApiUrl = `${directionsUrl}${pickupLongitude},${pickupLatitude};${dropoffLongitude},${dropoffLatitude}?access_token=${accessToken}&geometries=geojson&alternatives=true`;
    return axios
      .get(directionsApiUrl)
      .then(response => {
        dispatch({
          type: GET_DIRECTIONS,
          routes: response.data.routes,
          waypoints: response.data.waypoints,
          status: 200,
          code: response.data.code
        });
      })
      .catch(error => {
        dispatch({
          type: DIRECTIONS_ERROR,
          error
        });
      });
  };
};

export default (state = {}, action) => {
  const newState = _.cloneDeep(state);
  switch (action.type) {
    case GET_ADDRESSES: {
      const relevance = 0.75;
      newState.autoComplete = {
        features: action.features,
        query: action.query,
        status: action.status
      };
      newState.autoComplete.features = newState.autoComplete.features.filter(
        address => address.relevance > relevance
      );
      return newState;
    }
    case GET_DIRECTIONS: {
      newState.directions = {
        routes: action.routes,
        waypoints: action.waypoints,
        status: action.status,
        timestamp: Math.floor(Date.now() / 1000)
      };
      return newState;
    }
    case GET_ADDRESS_TIMEZONE: {
      if (action?.data?.features[0] && action?.data?.features[0]?.properties?.TZID) {
        if (action.data.dropoff) {
          newState.dropoffAddressTimezone = action.data.features[0].properties.TZID;
        } else {
          newState.pickupAddressTimezone = action.data.features[0].properties.TZID;
        }
      }

      return newState;
    }
    case GET_ADDRESS_TIMEZONE_ERROR: {
      const errorMessage =
        action.error || 'Failed to fetch mapbox address timezone information';

      if (action.data.dropoff) {
        newState.dropoffAddressTimezoneError = errorMessage;
      } else {
        newState.pickupAddressTimezoneError = errorMessage;
      }
      return newState;
    }
    case AUTOCOMPLETE_ERROR: {
      const status = _.get(action, 'error.response.status', 500);
      const message = _.get(action, 'error.response.data.message', 'Error');
      if (!_.has(newState, 'autoComplete')) {
        newState.autoComplete = {};
      }

      newState.autoComplete.error = {
        status,
        message
      };
      return newState;
    }
    case DIRECTIONS_ERROR: {
      const status = action?.error?.response?.status ?? 500;
      const message = action?.error?.reponse?.data?.message ?? 'Error';

      if (!_.has(newState, 'autoComplete') || !newState.directions) {
        newState.directions = {};
      }

      newState.directions.error = {
        status,
        message
      };
      return newState;
    }
    case CLEAR_ALL: {
      return {};
    }
    default: {
      return newState;
    }
  }
};

export const locationCheck = (state = {}, action) => {
  const newState = _.cloneDeep(state);
  switch (action.type) {
    case CHECK_LOCATION: {
      const status = _.get(action.data, 'status', false);
      const httpCode = _.get(action.data, 'httpCode', 500);
      const message = _.get(
        action.data,
        'message',
        'There was an error with the address validation. Please try again or contact support.'
      );
      newState.checkLocation = {
        status,
        httpCode,
        message,
        timestamp: Math.floor(Date.now() / 1000)
      };
      return newState;
    }
    case CHECK_LOCATION_ERROR: {
      const errorData = _.get(action.error, 'response.data', {});
      const status = _.get(errorData, 'status', false);
      const httpCode = _.get(errorData, 'httpCode', 500);
      const message = _.get(
        errorData,
        'message',
        'There was an error with the address validation. Please try again or contact support.'
      );
      newState.checkLocation = {
        status,
        httpCode,
        message,
        timestamp: Math.floor(Date.now() / 1000)
      };
      return newState;
    }
    default: {
      return newState;
    }
  }
};
