import type { AddressModel } from '~/models';
import React from 'react';
import { capitalize } from '@SRHealth/frontend-lib';
import { formatPhoneNumber } from '~/utilities/phone';
import { standardizeAddress } from './Address.utils';

export type RidesSummaryAddressProps = {
  address: AddressModel;
};

export default function RidesSummaryAddress({ address }: RidesSummaryAddressProps) {
  const [street, city, state, zip] = standardizeAddress(address.value);

  switch (address.type) {
    case 'mapbox':
      return <div className="text-base">{address.value}</div>;

    case 'approved-provider':
      return (
        <div className="reset-div flex flex-col">
          <div className="py-[4px]">
            <div className="heading-sm">Care Provider</div>
            <div className="text-md">
              <span className="heading-sm">NPI:</span> {address.npi}
              <div className="text-md">{formatPhoneNumber(address.phone)}</div>
            </div>
          </div>
          <div className="flex flex-col gap-[4px] py-[4px]">
            <div className="heading-sm">{address.name}</div>
            <div className="text-md">{street}</div>
            <div className="text-md">
              {city ? `${city}, ` : ''}
              {state} {zip}
            </div>
          </div>
        </div>
      );

    case 'provider-not-found':
      return (
        <div className="reset-div flex flex-col">
          <div className="py-[4px]">
            <div className="text-md">
              <span className="heading-sm">Care Provider:</span> Unapproved
            </div>
            <div className="text-md">
              <span className="heading-sm">Physician Name:</span> {address.name}
            </div>
            <div className="text-md">
              <span className="heading-sm">Facility Name:</span> {address.facility}
            </div>
            <div className="text-md">
              <span className="heading-sm">Phone Number:</span>{' '}
              {formatPhoneNumber(address.phone)}
            </div>
            <div className="text-md">
              <span className="heading-sm">NPI:</span> Unknown
            </div>
            <div className="text-md">
              <span className="heading-sm">Notes:</span> {address.notes}
            </div>
          </div>
          <div className="flex flex-col gap-[4px] py-[4px]">
            <div className="text-md">{street}</div>
            <div className="text-md">
              {city ? `${city}, ` : ''}
              {state} {zip}
            </div>
          </div>
        </div>
      );

    case 'saved':
      return (
        <div className="text-base">
          <div className="heading-sm">{address.name}</div>
          {address.value}
        </div>
      );
    case 'venue':
      return (
        <div className="reset-div flex flex-col">
          <div className="flex flex-col gap-[4px] py-[4px]">
            <div className="heading-sm">{capitalize(address.type)}</div>
            <div className="heading-sm">{address.venue}</div>
            <div className="heading-sm">Entrance: {address.entrance}</div>
            <div className="text-md">{street}</div>
            <div className="text-md">
              {city ? `${city}, ` : ''}
              {state} {zip}
            </div>
          </div>
        </div>
      );
  }
}
