import { createTypeGuard } from '@SRHealth/frontend-lib';

/**
 * Cleans the phone number by removing all parentheses, dashes, and spaces but leaving spaces
 * in the phone number office extension intact
 */
export function cleanPhoneNumber(phoneNumber: string) {
  // Remove all parentheses and dashes but leave spaces intact
  // e.g. (123)  456-7890 ext 12--> 123  4567890 ext 12
  let cleanedPhoneNumber = phoneNumber.replace(/[()-]/g, '');

  // Remove only the spaces in the first part of the phone number, such as the space between the area code and
  // the first 3 digits but not the space between the phone number and extension e.g. 123  4567890 ext 12 --> 1234567890 ext 12
  cleanedPhoneNumber = cleanedPhoneNumber.replace(/^(.{0,8})/, match =>
    match.replace(/\s/g, '')
  );

  return cleanedPhoneNumber;
}

/** Converts a raw phone number string into a hyphen delimited format. */
export function formatPhoneNumber(phoneNumber: string) {
  const cleanedPhoneNumber = cleanPhoneNumber(phoneNumber);
  // Break up and format the phone number into something like (123) 456-7890 ext 12
  return `(${cleanedPhoneNumber.slice(0, 3)}) ${cleanedPhoneNumber.slice(3, 6)}-${cleanedPhoneNumber.slice(
    6
  )}`;
}

/** Validates if the phone number is a string and at least 10 digits
 * in length.
 *
 * This can be extended if we need to.
 */
export const isPhoneNumberValid = createTypeGuard((t: unknown) =>
  typeof t === 'string' && /\d{10,}/.test(t) ? t : null
);

/** Validates if the phone number is a string and at least 10 digits
 * in length. After the 10 digits, it can have any characters (to account for any
 * weird office extension number formats they may use e.g. 1234567890 x1234).
 */
export const isExtendedPhoneNumberValid = createTypeGuard((t: unknown) =>
  typeof t === 'string' && /\d{10,}.*/.test(t) ? t : null
);
