import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';

/**
 * Get suggested time
 * @param  {String} [suggestedAppointmentTime=''] Suggested appt time (by NEMT)
 * @param  {String} [suggestedPickupTime='']      Suggested pickup time (by NEMT)
 * @return {Object}                               Display fields
 */
function getSuggestedTime(suggestedAppointmentTime = '', suggestedPickupTime = '') {
  const suggestedAppt = moment(suggestedAppointmentTime, 'hh:mm');
  const suggestedPickup = moment(suggestedPickupTime, 'hh:mm');
  let suggestedTime = '';

  if (suggestedAppointmentTime) {
    if (suggestedPickupTime) {
      suggestedTime = `- Appointment: ${suggestedAppt.format('h:mm a')}\n`;
      suggestedTime += `- Pickup: ${suggestedPickup.format('h:mm a')}\n`;
    } else {
      suggestedTime = `- Appointment: ${suggestedAppt.format('h:mm a')}\n`;
    }
  } else if (suggestedPickupTime) {
    suggestedTime = `- Pickup: ${suggestedPickup.format('h:mm a')}\n`;
  }

  return suggestedTime;
}

const LiveChatSuggestion = props => {
  let confirmButton = null;
  let rejectButton = null;

  /**
   * Handle confirm button click
   * @return {void}
   */
  const handleConfirmRideClick = () => {
    // disable buttons
    if (confirmButton) {
      confirmButton.disabled = true;
    }
    if (rejectButton) {
      rejectButton.disabled = true;
    }

    props.handleConfirmRide(props.selectedChat.requestId);
  };

  /**
   * Handle reject button click
   * @return {void}
   */
  const handleRejectRideClick = () => {
    // disable buttons
    if (confirmButton) {
      confirmButton.disabled = true;
    }
    if (rejectButton) {
      rejectButton.disabled = true;
    }

    props.handleRejectRide(props.selectedChat.requestId);
  };

  const suggestedAppointmentTime =
    props?.selectedChat?.rideDetails?.suggestedAppointmentTime ?? '';
  const suggestedPickupTime = props?.selectedChat?.rideDetails?.suggestedPickupTime ?? '';
  const suggestedTime = getSuggestedTime(suggestedAppointmentTime, suggestedPickupTime);
  const sender = props?.selectedChat?.users?.[props.message.senderId] ?? {};
  const sentAt = moment(props.message.sentAt).format('h:mm A');

  return (
    <div className="liveChatMessage received suggestion">
      <div className="liveChatMessageText">
        New ride times suggested:
        <br />
        {suggestedTime}
        <button
          ref={el => (confirmButton = el)}
          onClick={handleConfirmRideClick}
          disabled={props.selectedChat.confirmed}
        >
          Confirm
        </button>
        <button
          ref={el => (rejectButton = el)}
          onClick={handleRejectRideClick}
          disabled={props.selectedChat.requestStatus !== 'active'}
        >
          Reject
        </button>
      </div>
      <sub>
        <span>
          {sender.firstName} {sender.lastName} - {sentAt}
        </span>
      </sub>
    </div>
  );
};

LiveChatSuggestion.propTypes = {
  selectedChat: PropTypes.object,
  message: PropTypes.object,
  handleConfirmRide: PropTypes.func,
  handleRejectRide: PropTypes.func
};

LiveChatSuggestion.defaultProps = {
  selectedChat: {},
  message: {},
  handleConfirmRide: () => {},
  handleRejectRide: () => {}
};

export default LiveChatSuggestion;
