import { createAppAsyncThunk } from '~/Modules';
import { addressFactory, rideFactory } from '~/models';
import { removeRecurringRides } from '../RideBooking.slice';
import { processNewPrimaryRide } from '../RideBooking.utils';
import { selectTreatAllBlocksAsSoft } from '~/Modules/rideBooking/selectors/selectTreatAllBlocksAsSoft';

/** A thunk used to add a return ride leg on the current ride
 * in the RBF. This is necessary because we need to access the
 * memberProfile store to get the member's notes and don't have
 * access to that inside of the rideBooking slice. */
export const addReturnRideThunk = createAppAsyncThunk(
  'rideBooking/addReturnRide',
  async (_, { getState, dispatch, rejectWithValue }) => {
    const state = getState();
    const rideOne = state.rideBooking.rides[0];
    const memberNotes = state.memberProfile.formData?.personalInfo?.otherDetails;
    const shouldClearRecurringRides = rideOne.isDirty;

    try {
      await processNewPrimaryRide({
        getState,
        rideIndex: 0,
        treatAllBlocksAsSoftBlocks: selectTreatAllBlocksAsSoft(state)
      });
    } catch (error) {
      return rejectWithValue(error);
    }

    // Clear recurring rides if needed.
    if (shouldClearRecurringRides) {
      dispatch(removeRecurringRides());
    }

    // Create and return the new return ride.
    const returnRide = rideFactory({
      ...rideOne.toJSON(),
      departAddress: addressFactory(rideOne.arriveAddress.toJSON()),
      arriveAddress: addressFactory(rideOne.departAddress.toJSON()),
      notes: memberNotes || '',
      type: 'departAt',
      time: undefined,
      distance: undefined
    });

    return returnRide;
  }
);
