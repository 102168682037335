import type { UserStore, DependentField as DependentFieldRecord } from '~/Modules/user';
import type { TreatmentRecord } from '~/Modules/patients';
import { Is } from '@SRHealth/frontend-lib';
import type { MemberProfileStore } from '~/Modules/memberProfile';
import type { FundingSourceProps } from '~/models';
import { convertISOToDisplayDate } from '~/utilities/timesAndDates';
import { FUNDING_SOURCE_TXT } from './FundingSource.constants';
import type { BlockType } from '~/Modules/rideBooking';

/** The BE sends a list of custom fields in different formats based on
 * user role. Unfortunately we cannot fix that without breaking legacy RBF
 * and other parts of the app. This function standardizes the format so that
 * the data structure is consistent. */
function standardizeCustomFieldFormat(
  fields?: Record<number, BE.HospitalCustomField[]> | BE.HospitalCustomField[]
): Record<number, BE.HospitalCustomField[]> {
  if (!fields) return {};

  if (Is.JsonObject(fields)) return fields;

  return Object.values(fields).reduce((acc, field) => {
    acc[field.hospitalId] ??= [];
    acc[field.hospitalId].push(field);
    return acc;
  }, {});
}

/** Retrieves the custom fields for the user's selected hospital. This is dependent
 * on the facilityId set in the FundingSource model. */
export function getHospitalCustomFields(user: UserStore, facilityId: number | undefined) {
  if (Is.Undefined(facilityId)) return [];

  const patientCustomFields = standardizeCustomFieldFormat(user?.patientCustomFields);
  const rideCustomFields = standardizeCustomFieldFormat(user?.rideCustomFields);

  let customFields: BE.HospitalCustomField[] = [];

  if (!Array.isArray(patientCustomFields) && facilityId in patientCustomFields) {
    customFields = customFields.concat(patientCustomFields[facilityId]);
  }

  if (!Array.isArray(rideCustomFields) && facilityId in rideCustomFields) {
    customFields = customFields.concat(rideCustomFields[facilityId]);
  }

  return customFields;
}

/** Automatically scroll to the bottom of the footer so that the TripQuestions
 * component is in view for the user. */
export function scrollToFooter(ref: React.RefObject<HTMLDivElement>) {
  if (!ref.current) return;

  ref.current.scrollIntoView({ behavior: 'smooth', block: 'end', inline: 'center' });
}

/** Crawls the dependent fields and extracts the field label and value.
 * Used primarily in the Summary component. This is what happens when we
 * let Hayk rewrite data for FE consumption. */
export function crawlDependentFields(
  field: DependentFieldRecord,
  modelData: Readonly<FundingSourceProps>
) {
  const collection: { label: string; value: string }[] = [];

  let _field = field;
  for (const fieldId in modelData.dependentFields) {
    const record = _field.compliance_options.find(
      ({ id }) => id === modelData.dependentFields[fieldId]
    );

    const label = _field.input_name;
    const value = record?.option_value ?? '';

    _field = _field.dependent_field!;

    collection.push({ label, value });
  }

  return collection;
}

/** Retrieves the eligibility dates from the member profile. */
export function getEligibilityDates(memberProfile: MemberProfileStore) {
  const { eligibleStartDate, eligibleEndDate } = memberProfile.formData.benefits;

  const startDate = eligibleStartDate?.split(' ')?.[0] as DateString | undefined;
  const endDate = eligibleEndDate?.split(' ')?.[0] as DateString | undefined;

  return {
    startDate: startDate ? convertISOToDisplayDate(startDate) : '',
    endDate: endDate ? convertISOToDisplayDate(endDate) : ''
  };
}

/**
 * Filters the list of treatments based on the current search query and formats them
 * for consumption by the InputSearchDropdown component.
 */
export function filterTreatments(
  treatments: TreatmentRecord[] | undefined,
  query: string
) {
  if (!treatments) return [];

  return treatments
    .filter(t => t.treatment_name.toLowerCase().includes(query.toLowerCase()))
    .map(t => ({
      label: t.treatment_name,
      value: t.id,
      isDeducting: t.benefit_deduction === 1
    }));
}

/** Matches a block type to an alert class type. */
export function getAlertType(blockType: BlockType) {
  switch (blockType) {
    case 'Soft':
    case 'Treatments-Soft':
    case 'Past':
    case 'LimitedYears':
    case 'Treatments-LimitedYears':
      return 'warning';
    case 'Hard':
    case 'Treatments-Hard':
      return 'error';
    default:
      return 'info';
  }
}

/** Matches a block type to its display text */
export function getAlertText(blockType: BlockType, bookableYears?: string[] | undefined) {
  switch (blockType) {
    case 'Soft':
      return `${FUNDING_SOURCE_TXT.BENEFIT_SOFT_BLOCK_WARNING_BASE}.`;
    case 'Treatments-Soft':
      return `${FUNDING_SOURCE_TXT.BENEFIT_SOFT_BLOCK_WARNING_BASE} ${FUNDING_SOURCE_TXT.BENEFIT_SOFT_BLOCK_WARNING_TREATMENT}`;
    case 'Past':
      return FUNDING_SOURCE_TXT.BENEFIT_PAST_BLOCK_WARNING;
    case 'Hard':
      return `${FUNDING_SOURCE_TXT.BENEFIT_HARD_BLOCK_WARNING_BASE}.`;
    case 'Treatments-Hard':
      return `${FUNDING_SOURCE_TXT.BENEFIT_HARD_BLOCK_WARNING_BASE} ${FUNDING_SOURCE_TXT.BENEFIT_HARD_BLOCK_WARNING_TREATMENT}`;
    case 'LimitedYears':
      return `${FUNDING_SOURCE_TXT.BENEFIT_LIMITED_YEARS_WARNING_BASE} ${getBookableYearsText(bookableYears)}.`;
    case 'Treatments-LimitedYears':
      return `${FUNDING_SOURCE_TXT.BENEFIT_LIMITED_YEARS_WARNING_BASE} ${getBookableYearsText(bookableYears)} ${FUNDING_SOURCE_TXT.BENEFIT_SOFT_BLOCK_WARNING_TREATMENT}`;
    default:
      return '';
  }
}

/**
 * Returns a formatted string for display of the bookable years.
 * @param bookableYears - An array of bookable years.
 * @returns A formatted string of the bookable years ready for display.
 */
const getBookableYearsText = (bookableYears: string[] | undefined) => {
  if (!bookableYears) return '';

  return bookableYears.length === 1
    ? bookableYears[0]
    : `${bookableYears.slice(0, -1).join(', ')} and ${bookableYears[bookableYears.length - 1]}`;
};
