import { Icon } from '@SRHealth/frontend-lib';
import React from 'react';
import type { RideTime, RideType } from '~/models';
import { formatRideTimeForDisplay } from '~/utilities/timesAndDates';

type TripCardSummaryProps = {
  date: DateString;
  timeOne: RideTime;
  timeTwo: RideTime | undefined;
  timezone: string;
  initialRideType: RideType;
};

export default function TripCardSummary({
  date,
  timeOne,
  timeTwo,
  initialRideType,
  timezone
}: TripCardSummaryProps) {
  // Need to format with the date as well to get the correct timezone per daylight savings time (e.g. March 8th CST vs March 10th CDT)
  const formattedTimeOne = formatRideTimeForDisplay(timeOne, date, timezone);
  const formattedTimeTwo = timeTwo
    ? formatRideTimeForDisplay(timeTwo, date, timezone)
    : '';

  return (
    <div
      className="flex flex-col"
      style={{ marginTop: '8px', padding: '8px 0', gap: '24px' }}
    >
      <div
        className="text-sm items-center"
        style={{
          display: 'inline-grid',
          gridTemplateColumns: '1fr 1fr 24px 1fr',
          columnGap: '4px',
          rowGap: '12px'
        }}
      >
        <span>Initial Ride</span>
        <span style={{ textAlign: 'right' }}>
          {initialRideType === 'arriveBy' ? 'Appointment' : 'Depart'}
        </span>
        <Icon type="Time" style={{ width: '24px', height: '24px' }} />
        <span>{formattedTimeOne}</span>

        {formattedTimeTwo && (
          <>
            <span>Return Ride</span>
            <span style={{ textAlign: 'right' }}>Depart</span>
            <Icon type="Time" style={{ width: '24px', height: '24px' }} />
            <span>{formattedTimeTwo}</span>
          </>
        )}
      </div>
    </div>
  );
}
