import type { App } from 'contracts/mod';
import type { NemtProvider } from 'contracts/src/app-layer/rbf/v1';
import { createSlice, current, type PayloadAction } from '@reduxjs/toolkit';
import { NOOP } from '~/utilities/helperFunctions';
import {
  dateFactory,
  fundingSourceFactory,
  providerNemtFactory,
  passengerInfoFactory,
  rideFactory,
  type DateModel,
  type FundingSourceModel,
  type ProviderNemtModel,
  type ProviderPublicModel,
  type PassengerInfoModel,
  type RideModel,
  type ProviderRideshareModel,
  providerRideshareFactory,
  providerPublicFactory,
  recurringRidePatternFactory,
  type RecurringRidePatternProps
} from '~/models';
import {
  initializeRbfNewThunk,
  initializeDateThunk,
  getProviderSupplyThunk,
  getTimeRestrictionsThunk,
  completeFundingSourceThunk,
  completePassengerInfoThunk,
  completeDateThunk,
  completeRidesThunk,
  addReturnRideThunk
} from './thunks';
import { isProviderModel } from './RideBooking.types';
import type { PassengerInfoSection, RideBookingStore } from './RideBooking.types';
import { RIDE_SECTION_ORDER } from './RideBooking.constants';
import {
  completeSection as _completeSection,
  toNextSection as _toNextSection,
  toSection
} from './RideBooking.utils';
import { completeRecurringRidesThunk } from './thunks/completeRecurringRides.thunk';
import { Datetime, DAYS_OF_WEEK } from '@SRHealth/frontend-lib';

const INITIAL_STATE: () => RideBookingStore = () => ({
  // Meta data will not be passed into the ride booking request. It's
  // used to track the state of the ride booking form.
  meta: {
    isInitialized: false,
    activeSection: RIDE_SECTION_ORDER['funding-source'],
    previousActiveSection: RIDE_SECTION_ORDER['passenger-info'],
    dateRestrictions: undefined,
    timeRestrictions: undefined,
    rideRequestId: undefined,
    transportProviders: [],
    noSupplies: {},
    recurringRidesCache: undefined
  },
  passengerInfo: passengerInfoFactory(),
  fundingSource: fundingSourceFactory(),
  date: dateFactory(),
  rides: [rideFactory()],
  recurringRides: undefined,
  transportProvider: providerNemtFactory()
});

const rideBookingSlice = createSlice({
  name: 'rideBooking',
  initialState: INITIAL_STATE(),
  reducers: {
    reset: () => INITIAL_STATE(),
    /** Mark the store as initialized. */
    initialized: state => {
      state.meta.isInitialized = true;
    },
    /** Move to the next section in the RBF. */
    toNextSection: state => _toNextSection(state.meta),
    /** Set the passenger info model for the ride booking. */
    setPassengerInfo: (state, action: PayloadAction<PassengerInfoModel>) => {
      state.passengerInfo = action.payload;
    },
    /** Set the funding source model for the ride booking. */
    setFundingSource: (state, action: PayloadAction<FundingSourceModel>) => {
      state.fundingSource = action.payload;
    },
    /** Set the date model for the ride booking. */
    setDate: (state, action: PayloadAction<DateModel>) => {
      state.date = action.payload;
    },
    /** Set the rides model(s) for the ride booking. Resets the recurring
     * rides data. */
    setRides: (state, action: PayloadAction<RideModel[]>) => {
      state.rides = action.payload;
      state.recurringRides = recurringRidePatternFactory();
    },
    /** Set the recurring ride models for the ride booking. */
    setRecurringRides: (
      state,
      action: PayloadAction<RideBookingStore['recurringRides']>
    ) => {
      state.recurringRides = action.payload;
    },
    /** Set the new transport provider for the ride. */
    setTransportProvider: (
      state,
      action: PayloadAction<
        ProviderNemtModel | ProviderRideshareModel | ProviderPublicModel
      >
    ) => {
      if (!isProviderModel(action.payload)) {
        throw Error('Invalid value passed as transport provider model.');
      }

      state.transportProvider = action.payload;
    },
    /** Return to the complete Passenger Info section and begin editing it. */
    editPassengerInfo: (state, _: PayloadAction<PassengerInfoSection>) => {
      toSection(state.meta, 'passenger-info');
    },
    /** Return to the completed Funding Source section and begin editing it. */
    editFundingSource: state => {
      toSection(state.meta, 'funding-source');
    },
    /** Return to the completed Date section and begin editing it. */
    editDate: state => {
      toSection(state.meta, 'date');

      // Wipe out return leg if it exists.
      if (state.rides.length > 1) {
        state.rides = [state.rides[0]];
      }
    },
    editRides: state => {
      toSection(state.meta, 'rides');
    },
    /** Before editing recurring rides, restore them from cache if present. */
    editRecurringRides: state => {
      if (state.meta.recurringRidesCache) {
        state.recurringRides = recurringRidePatternFactory(
          current(state.meta.recurringRidesCache)
        );
      }
      toSection(state.meta, 'recurring-rides');
    },
    /** Adds and prepopulates a return ride. If the return ride seems
     * like it's missing data from the initial, you may have not
     * committed some changes to the ride model. */
    addReturnRide: (state, action: PayloadAction<RideModel>) => {
      state.rides[1] = action.payload;
    },
    /** Removes the return ride from the ride booking. */
    removeReturnRide: state => {
      state.rides = [state.rides[0]];
    },
    /** Add multiple rides to the ride booking. */
    addRides: (state, action: PayloadAction<RideModel[]>) => {
      state.rides.push(...action.payload);
    },
    /** Remove a ride, by index, from the rides array. */
    removeRide: (state, action: PayloadAction<number>) => {
      state.rides.splice(action.payload, 1);
    },
    /** Removes all recurring rides and marks the section as completed. */
    removeRecurringRides: state => {
      state.recurringRides = undefined;
    },
    /** Resets the recurring rides. */
    resetRecurringRides: state => {
      const props: Partial<RecurringRidePatternProps> = {};

      if (state.date && !state.date.isDirty) {
        props.daysOfWeek = [DAYS_OF_WEEK[new Datetime(state.date.date).getUTCDay()]];
      }

      state.recurringRides = recurringRidePatternFactory(props);
    },
    /** Roll back the active session to the previous session. */
    rollbackActiveSection: state => {
      state.meta.activeSection = state.meta.previousActiveSection;
    },
    /** Update all recurring rides in a series which share a weekday with the
     * provided recurring ride.
     *
     * @note This action has been disabled for now since we no longer support modifying
     * recurring rides in the UI. The feature will be rolled out after the initial RBF 4.0
     * in a subsequent release.
     */
    // updateRecurringSeries: (state, action: PayloadAction<RecurringRideModel>) => {
    //   const ride = action.payload;
    //   const recurringRides = state.recurringRides;

    //   const weekDay = new Datetime(ride.date).getDay();

    //   const newRideTimeOne = ride.propertyIsDirty('legOneTime')
    //     ? ride.legOneTime
    //     : undefined;

    //   const newRideTimeTwo = ride.propertyIsDirty('legTwoTime')
    //     ? ride.legTwoTime
    //     : undefined;

    //   recurringRides.forEach(recurringRide => {
    //     const rideWeekDay = new Datetime(recurringRide.date).getDay();

    //     if (rideWeekDay !== weekDay) return;

    //     if (newRideTimeOne) {
    //       recurringRide.legOneTime = newRideTimeOne;
    //     }

    //     if (newRideTimeTwo) {
    //       recurringRide.legTwoTime = newRideTimeTwo;
    //     }
    //   });
    // },
    /** Caches the recurring rides in meta so that we can restore state if the user
     * toggles the recurring rides on/off. Only commit models will be cached.
     * will be cached. */
    cacheRecurringRides: state => {
      if (!state.recurringRides) return;

      // Revert any uncommitted changes before caching.
      if (state.recurringRides.isDirty) state.recurringRides.revert();

      // Cache only if an end date exists.
      if (state.recurringRides.endDate) {
        state.meta.recurringRidesCache = state.recurringRides.toJSON();
      }
    }
  },
  selectors: {
    selectSupplyRequestData: (state: RideBookingStore) =>
      ({
        passengerInfo: state.passengerInfo.toJSON(),
        fundingSource: state.fundingSource.toJSON(),
        date: state.date.toJSON(),
        rides: state.rides.map(r => r.toJSON()),
        recurringRides: state.recurringRides?.endDate
          ? state.recurringRides.toJSON()
          : undefined
      }) as App.RBF.V1.GetSupplyRequest
  },
  extraReducers: builder => {
    // Add extra reducers here
    builder
      .addCase(initializeRbfNewThunk.fulfilled, NOOP)
      .addCase(initializeDateThunk.pending, state => void (state.date = dateFactory()))
      .addCase(initializeDateThunk.fulfilled, (state, action) => {
        state.meta.dateRestrictions = {
          earliestDate: action.payload.earliestDate,
          latestDate: action.payload.latestDate,
          restrictedDates: action.payload.restrictedDates
        };
      })
      // Clear any existing time restrictions and ride times when
      // fetching new time restrictions.
      .addCase(getTimeRestrictionsThunk.pending, state => {
        state.meta.timeRestrictions = undefined;
        state.rides.forEach(ride => (ride.time = undefined));
      })
      .addCase(getTimeRestrictionsThunk.fulfilled, (state, action) => {
        state.meta.timeRestrictions = action.payload;
      })
      // Assign the new return ride to the rides array.
      .addCase(addReturnRideThunk.fulfilled, (state, action) => {
        state.rides[1] = action.payload;
      })
      // Complete the section and move to the next section.
      .addCase(completePassengerInfoThunk.fulfilled, state => {
        const tripType = state.passengerInfo.tripType;

        state.transportProvider =
          tripType === 'public' ? providerPublicFactory() : providerNemtFactory();

        _completeSection('passenger-info')(state);
      })
      .addCase(completeFundingSourceThunk.fulfilled, _completeSection('funding-source'))
      .addCase(completeDateThunk.fulfilled, _completeSection('date'))
      .addCase(completeRidesThunk.fulfilled, _completeSection('rides', 1))
      .addCase(completeRecurringRidesThunk.fulfilled, _completeSection('recurring-rides'))
      .addCase(getProviderSupplyThunk.fulfilled, (state, action) => {
        state.meta.rideRequestId = action.payload.requestId;
        state.meta.transportProviders = action.payload.providers;
        state.meta.noSupplies = action.payload.noSupplies;

        if (state.meta.transportProviders.length > 1) {
          const transportProviders = state.meta.transportProviders as NemtProvider[];
          state.meta.transportProviders = transportProviders.sort((a, b) => {
            if (a.isPrimary) return -1;
            if (b.isPrimary) return 1;

            if (a.isSecondary && !b.isPrimary) return -1;
            if (b.isSecondary) return 1;

            return a.rideCosts[0] + (a.rideCosts?.[1] ?? 0) >
              b.rideCosts[0] + (b.rideCosts?.[1] ?? 0)
              ? 1
              : -1;
          });
        }

        if (state.passengerInfo.tripType === 'public') {
          state.transportProvider = providerPublicFactory();
        } else if (action.payload.providers.length === 1) {
          state.transportProvider =
            'isPrimary' in action.payload.providers[0]
              ? providerNemtFactory(action.payload.providers[0])
              : providerRideshareFactory(action.payload.providers[0]);
        }
      });
  }
});

export const rideBookingReducer = rideBookingSlice.reducer;
export const {
  reset,
  initialized,
  toNextSection,
  setPassengerInfo,
  setFundingSource,
  setDate,
  setRides,
  setRecurringRides,
  setTransportProvider,
  editPassengerInfo,
  editFundingSource,
  editDate,
  editRides,
  editRecurringRides,
  addReturnRide,
  removeReturnRide,
  removeRecurringRides,
  resetRecurringRides,
  rollbackActiveSection,
  // updateRecurringSeries,
  cacheRecurringRides
} = rideBookingSlice.actions;
export const { selectSupplyRequestData } = rideBookingSlice.selectors;
