import React from 'react';
import { Alert, isString } from '@SRHealth/frontend-lib';
import type { RideModel } from '~/models';
import { useAppSelector } from '~/Modules';
import { checkMileageLimit } from '~/Modules/rideBooking/RideBooking.utils';
import { selectTreatAllBlocksAsSoft } from '~/Modules/rideBooking/selectors/selectTreatAllBlocksAsSoft';

interface RideMileageWarningProps {
  isHardBlock?: boolean;
  rideModel: RideModel;
}

/** Display when the ride mileage has exceeded either the mileage hard block or soft block */
export default function RideMileageWarning({ rideModel }: RideMileageWarningProps) {
  const perRideBenefit = useAppSelector(
    state => state.memberProfile.formData?.benefits?.perRideBenefits
  );
  // Check if the ride has a mileage rule error that was generated on commit (i.e. trying to add a return ride or review ride)
  const mileageHardBlockRuleError =
    rideModel.ruleErrors?.distance?.['mileage-hard-block'];

  // Check if the ride has exceeded the mileage limit
  const { isBlocked, isHardBlocked, distance, limit } = checkMileageLimit(
    rideModel,
    perRideBenefit
  );

  // See if we should treat all blocks as soft blocks.
  const treatAllBlocksAsSoftBlocks = useAppSelector(selectTreatAllBlocksAsSoft);

  const isSoftBlocked =
    ((mileageHardBlockRuleError || isBlocked) && treatAllBlocksAsSoftBlocks) ||
    (isBlocked && !isHardBlocked);

  if (isSoftBlocked) {
    const message = `This ride exceeds the plan mileage limit. ${distance} 
          miles of ${limit} mileage limit reached.`;
    return <Alert type="warning" label={message} />;
  }

  if (mileageHardBlockRuleError && isString(mileageHardBlockRuleError)) {
    return <Alert type="error" label={mileageHardBlockRuleError} />;
  }

  return null;
}
