/**
 * Initializes the Google Maps service and appends the
 * library to the browser window
 */
export function googleMapsInit() {
  const googleMapsScript = document.createElement('script');

  if (typeof google !== 'object' || typeof google.maps !== 'object') {
    googleMapsScript.setAttribute(
      'src',
      `https://maps.googleapis.com/maps/api/js?key=${process.env.REACT_APP_GMAP_KEY}&v=weekly&libraries=geometry,places`
    );
    window.document.head.appendChild(googleMapsScript);
  }
}

/** Singleton instance of the autocomplete service */
let _autoCompleteService:
  | InstanceType<typeof google.maps.places.AutocompleteService>
  | undefined;

/**
 * Retrieves an instance of the AutocompleteService
 * @returns {google.maps.places.AutocompleteService}
 */
function autoCompleteService() {
  if (!_autoCompleteService) {
    _autoCompleteService = new google.maps.places.AutocompleteService();
  }

  return _autoCompleteService;
}

/** Singleton instance of the Geocoder service */
let _geocoder: InstanceType<typeof google.maps.Geocoder> | undefined;

/**
 * Retrieves an instance of the google maps Geocoder
 * @returns {google.maps.Geocoder}
 */
function geocoder() {
  if (!_geocoder) {
    _geocoder = new google.maps.Geocoder();
  }

  return _geocoder;
}

/**
 * Queries Google Map Places library for autocomplete
 * predictions
 * @param input
 * @returns
 */
export function fetchAutoComplete(input: string, location?: google.maps.LatLngLiteral) {
  const request: google.maps.places.AutocompletionRequest = {
    region: 'us',
    componentRestrictions: { country: ['US', 'PR', 'VI', 'GU'] },
    language: 'en',
    input
  };

  if (location) {
    request.location = new google.maps.LatLng(location.lat, location.lng);
    request.radius = 10;
  }

  return autoCompleteService().getPlacePredictions(request);
}

/**
 * Asynchronously retrieve geocode data for either an
 * address or a placeId
 * @param param0
 * @returns {google.maps.GeocoderResponse}
 */
export function fetchGeocodeData({
  placeId,
  address
}: {
  placeId?: string;
  address?: string;
}) {
  const request: google.maps.GeocoderRequest = {
    region: 'us',
    language: 'en'
  };

  if (placeId) {
    request.placeId = placeId;
  } else if (address) {
    request.address = address;
    request.componentRestrictions = { country: 'us' };
  } else {
    throw Error('Either an address or a placeId must be provided to fetch Geocode data');
  }

  return geocoder().geocode(request);
}

/**
 * Retrieve address data from Google Maps using
 * the address string
 * @param address
 * @returns {google.maps.GeocoderResponse}
 */
export function fetchAddressData(address: string) {
  return fetchGeocodeData({ address });
}

/**
 * Retrieve address data from Google Maps using
 * the placeId
 * @param placeId
 * @returns {google.maps.GeocoderResponse}
 */
export function fetchPlaceIdData(placeId: string) {
  return fetchGeocodeData({ placeId });
}
