import { createAppAsyncThunk } from '~/Modules';
import { cacheRecurringRides } from '../RideBooking.slice';
import { getDuplicateRides } from '~/services/rideBooking.service';
import { getProviderSupplyThunk } from '..';

export const completeRecurringRidesThunk = createAppAsyncThunk(
  'rideBooking/completeRecurringThunk',
  async (_, { getState, dispatch }) => {
    const state = getState();
    const { recurringRides, passengerInfo, date, rides } = state.rideBooking;

    if (recurringRides) {
      await recurringRides.commit();
      dispatch(cacheRecurringRides());

      // Run both API calls in parallel
      const [duplicateRidesResponse] = await Promise.all([
        getDuplicateRides(passengerInfo, date, rides, recurringRides),
        dispatch(getProviderSupplyThunk()).unwrap()
      ]);

      return duplicateRidesResponse.data;
    }
  }
);
