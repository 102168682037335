import React, { useEffect } from 'react';
import { useMemberPortalStyles, memberPortalDescriptionStyles } from './styles';
import { Icon, TOKENS } from '@SRHealth/frontend-lib';
import MemberPortalManage from './subcomponents/MemberPortalManage';
import MemberPortalAccount from './subcomponents/MemberPortalAccount';
import { useAppDispatch, useAppSelector } from '~/Modules';
import LoadingModal from '~/Shared/Components/LoadingModal/LoadingModal';
import type { MemberPortalInfo } from '~/Modules/memberProfile';
import { getMemberPortalInfoFromApi } from '~/Modules/memberProfile/actions';

type MemberPortalDescriptionProps = {
  description?: MemberPortalInfo['portalDescription'];
};

function MemberPortalSupportDescription({ description }: MemberPortalDescriptionProps) {
  return description ? (
    <div style={memberPortalDescriptionStyles.container}>
      <Icon style={memberPortalDescriptionStyles.icon} type="Info" />
      <p className="text-sm">{description}</p>
    </div>
  ) : null;
}

type MemberPortalHeaderProps = {
  ssoEnabled: MemberPortalInfo['ssoEnabled'];
};

function MemberPortalSupportHeader({ ssoEnabled }: MemberPortalHeaderProps) {
  const headerText =
    ssoEnabled && ssoEnabled === 1
      ? `Member Portal access using health plan login credentials (SSO)`
      : `Member Portal access using Saferide login credentials`;
  return (
    <div className={`heading-lg font-bold text-${TOKENS.FONT.PRIMARY}`}>{headerText}</div>
  );
}

type MemberPortalSupportProps = {
  setIsChildDataLoading: (isLoading: boolean) => void;
};

function MemberPortalSupport({ setIsChildDataLoading }: MemberPortalSupportProps) {
  const classes = useMemberPortalStyles();
  const memberPortalInfo = useAppSelector(state => state.memberProfile.memberPortalInfo);
  const id = useAppSelector(state => state.memberProfile.formData.personalInfo.id);
  const dispatch = useAppDispatch();

  const memberPortalSlug = useAppSelector(
    state => state.memberProfile.formData.benefits.memberPortalSlug
  );
  const isLoading = useAppSelector(
    state => state.memberProfile.isLoadingMemberPortalInfo
  );

  useEffect(() => {
    if (id !== 0 && Object.keys(memberPortalInfo).length === 0) {
      dispatch(getMemberPortalInfoFromApi(id));
    }
  }, [id]);

  useEffect(() => {
    setIsChildDataLoading(isLoading);
  }, [isLoading]);

  if (isLoading || !memberPortalInfo) {
    return <LoadingModal label="Loading..." isOpen={isLoading} />;
  }

  return (
    <div className="parentTab">
      <div className={classes.container}>
        <MemberPortalSupportHeader ssoEnabled={memberPortalInfo?.ssoEnabled} />
        <MemberPortalSupportDescription
          description={memberPortalInfo?.portalDescription}
        />
        <div className={classes.accountContainer}>
          <MemberPortalAccount memberPortalInfo={memberPortalInfo} />
          <MemberPortalManage
            memberPortalInfo={memberPortalInfo}
            portalSlug={memberPortalSlug}
          />
        </div>
      </div>
    </div>
  );
}

export default MemberPortalSupport;
