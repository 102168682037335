import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import withRouter from '~/hooks/withRouter';
import Cookies from 'js-cookie';
import { parse } from 'querystring';
import ReactLoading from 'react-loading';
import {
  getPlans,
  createPlan,
  updatePlan,
  getBenefits,
  createBenefit,
  updateBenefit,
  deleteBenefit,
  getTreatments,
  getLocations,
  getBenefitCategoryList,
  getBenefitCategories,
  createBenefitCategory,
  updateBenefitCategory,
  deleteBenefitCategory
} from '~/Modules/plans';
import { isEmpty } from '@SRHealth/frontend-lib';
import PageFrame from '~/Shared/Components/PageFrame/PageFrame';
import PlansList from './Components/PlansList/PlansList';
import PlanDetail from './Components/PlanDetail/PlanDetail';
import PlanCreate from './Components/PlanCreate/PlanCreate';

class Plans extends Component {
  constructor(props) {
    super(props);

    this.isSuperAdmin = Cookies.get('super_admin_logged_in') === 'yes';
    this.state = {
      selectedId: 0,
      showAddPlan: false
    };
  }

  componentDidMount() {
    const selectedId = parseInt(this.props?.match?.params?.id ?? 0, 10);
    const queryParams = parse(window.location.search.substr(1));
    const showAddPlan = queryParams.action === 'add';
    this.setState({ selectedId, showAddPlan }, () => {
      // get data if not already in redux store
      if (!this.props.plans.data) {
        this.props.getPlans({ id: selectedId });
      }
    });
  }

  componentDidUpdate() {
    const selectedId = parseInt(this.props?.match?.params?.id ?? 0, 10);

    if (this.state.selectedId !== selectedId) {
      this.setState({ selectedId });
    }
  }

  /**
   * Handle plan selection
   * @param   {Number}    selectedId Selected plan ID
   * @returns {undefined}
   */
  handleSelection = selectedId => this.props.history.push(`/plans/${selectedId}`);

  /**
   * Handle list view selection
   * @returns {undefined}
   */
  handleListView = () => this.props.history.push(`/plans`);

  /**
   * Add new subplan
   * @returns {undefined}
   */
  addNewPlan = () => this.setState({ showAddPlan: true });

  render() {
    const {
      user,
      history,
      plans,
      createPlan,
      updatePlan,
      getBenefits,
      createBenefit,
      updateBenefit,
      deleteBenefit,
      getTreatments,
      getLocations,
      getRestrictionsByPage,
      getBenefitCategoryList,
      getBenefitCategories,
      createBenefitCategory,
      updateBenefitCategory,
      deleteBenefitCategory
    } = this.props;
    const { selectedId, showAddPlan } = this.state;
    const planData = plans.data || {};
    const selectedPlan = planData[selectedId];

    return (
      <main className="PlansContainer">
        <PageFrame>
          {isEmpty(plans) ? (
            <section className="Plans">
              <ReactLoading type="spin" className="pageLoading" />
            </section>
          ) : (
            <section className="Plans">
              {showAddPlan ? (
                <PlanCreate
                  handleCancel={() => this.setState({ showAddPlan: false })}
                  createPlan={params => createPlan(params)}
                />
              ) : this.state.selectedId > 0 ? (
                <PlanDetail
                  user={user}
                  history={history}
                  selectedPlan={selectedPlan}
                  availableBenefitCategories={plans.availableBenefitCategories}
                  showList={() => this.handleListView()}
                  isSuperAdmin={this.isSuperAdmin}
                  updatePlan={updatePlan}
                  getBenefits={getBenefits}
                  createBenefit={createBenefit}
                  updateBenefit={updateBenefit}
                  deleteBenefit={deleteBenefit}
                  getBenefitCategoryList={getBenefitCategoryList}
                  getBenefitCategories={getBenefitCategories}
                  createBenefitCategory={createBenefitCategory}
                  updateBenefitCategory={updateBenefitCategory}
                  deleteBenefitCategory={deleteBenefitCategory}
                  getTreatmentsByPage={getTreatments}
                  getLocationsByPage={getLocations}
                  getRestrictionsByPage={getRestrictionsByPage}
                />
              ) : (
                <PlansList
                  plans={planData}
                  selectedId={selectedId}
                  setSelectedId={this.handleSelection}
                  isSuperAdmin={this.isSuperAdmin}
                  addNewPlan={() => this.addNewPlan()}
                  totalPlanCount={plans.totalPlanCount}
                  getMorePlans={this.props.getPlans}
                />
              )}
            </section>
          )}
        </PageFrame>
      </main>
    );
  }
}

Plans.propTypes = {
  user: PropTypes.object,
  history: PropTypes.object,
  match: PropTypes.object,
  plans: PropTypes.object,
  getPlans: PropTypes.func,
  createPlan: PropTypes.func,
  updatePlan: PropTypes.func,
  getBenefits: PropTypes.func,
  createBenefit: PropTypes.func,
  updateBenefit: PropTypes.func,
  deleteBenefit: PropTypes.func,
  getBenefitCategoryList: PropTypes.func,
  getBenefitCategories: PropTypes.func,
  createBenefitCategory: PropTypes.func,
  updateBenefitCategory: PropTypes.func,
  deleteBenefitCategory: PropTypes.func,
  getTreatments: PropTypes.func,
  getLocations: PropTypes.func,
  getRestrictionsByPage: PropTypes.func
};

Plans.defaultProps = {
  user: {},
  history: {},
  match: {},
  plans: {},
  getPlans: () => {},
  createPlan: () => {},
  updatePlan: () => {},
  getBenefits: () => {},
  createBenefit: () => {},
  updateBenefit: () => {},
  deleteBenefit: () => {},
  getTreatments: () => {},
  getLocations: () => {},
  getRestrictionsByPage: () => {},
  getBenefitCategoryList: () => {},
  getBenefitCategories: () => {},
  createBenefitCategory: () => {},
  updateBenefitCategory: () => {},
  deleteBenefitCategory: () => {}
};

const mapStateToProps = state => ({
  user: state.user,
  plans: state.plans
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      getPlans: data => getPlans(data),
      createPlan: data => createPlan(data),
      updatePlan: data => updatePlan(data),
      getBenefits: data => getBenefits(data),
      createBenefit: data => createBenefit(data),
      updateBenefit: data => updateBenefit(data),
      deleteBenefit: data => deleteBenefit(data),
      getTreatments: data => getTreatments(data),
      getLocations: data => getLocations(data),
      getBenefitCategoryList: data => getBenefitCategoryList(data),
      getBenefitCategories: data => getBenefitCategories(data),
      createBenefitCategory: data => createBenefitCategory(data),
      updateBenefitCategory: data => updateBenefitCategory(data),
      deleteBenefitCategory: data => deleteBenefitCategory(data)
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Plans));
