import { Is } from '@SRHealth/frontend-lib';
import type { PassengerInfoModel } from '~/models';
import type { MemberProfileStore } from '~/Modules/memberProfile';
import { TRIP_TYPE_OPTIONS } from './PassengerInfo.constants';
import type { PassengerInfoSection } from '~/Modules/rideBooking';

/** Logic to determine if the SMS Opt Out modal should trigger
 * after passenger info updates have been committed. */
export function shouldTriggerOptOutModal(
  section: PassengerInfoSection,
  model: PassengerInfoModel
) {
  return (
    section === 'TripPhoneNumber' &&
    model.phoneNumber &&
    model.phoneNumberType === 'mobile' &&
    (Is.Null(model.phoneNumberOptOutStatus) || model.phoneNumberOptOutStatus === 0) &&
    (Is.Null(model.phoneNumberOptOutPromptStatus) ||
      model.phoneNumberOptOutPromptStatus === 0)
  );
}

/** Matches the 4 potential trip types against what the member's profile indicates
 * as allowed. Used in TripType component. */
export function getAllowedTripTypes(
  transportTypes: MemberProfileStore['transportTypes']
) {
  return TRIP_TYPE_OPTIONS.filter(({ value }) => value in transportTypes);
}
