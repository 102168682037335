import React from 'react';
import { Card, Icon, TOKENS } from '@SRHealth/frontend-lib';
import type { RideModel } from '~/models';
import { NOOP } from '~/utilities/helperFunctions';
import RidesSummarySectionHeader from './RidesSummarySectionHeader';
import RidesSummaryAddress from './RidesSummaryAddress';
import { bufferPickupTime } from '../Rides.utils';
import RideMileageWarning from './RideMileageWarning';
import { formatRideTimeForDisplay } from '~/utilities/timesAndDates';
import { useAppSelector } from '~/Modules';
import moment from 'moment-timezone';

interface RidesSummaryProps {
  rides: RideModel[];
  onEditRide: (rideIndex: number) => Promise<void>;
}

export default function RidesSummary({ rides, onEditRide }: RidesSummaryProps) {
  const _rides = rides.map(r => r.toJSON());
  const rideDate = useAppSelector(s => s.rideBooking.date.date);

  return (
    <div className="flex flex-col" style={{ paddingTop: '16px', gap: '24px' }}>
      {_rides.map((ride, rideIndex) => {
        const hours = Math.floor(ride.duration! / 60) || 0;
        const minutes = Math.floor(ride.duration! % 60)
          .toString()
          .padStart(2, '0');

        const duration = `${hours}:${minutes} ${hours ? 'hours' : 'minutes'}`;

        const isReturnRide = rideIndex === 1;
        const isFlexRide = ride.time === 'Flex Time';
        const estimatedPickupTime =
          ride.type === 'departAt'
            ? ride.time
            : bufferPickupTime(ride.time, ride.duration!);

        const rideLabel = (
          <div className="flex flex-row items-center gap-[8px]">
            <Icon type="TransportCar" className="w-[32px] h-[32px] fill-current" />
            <div className="flex-1">{rideIndex ? 'Return Ride' : 'Initial Ride'}</div>
            <div className={`text-sm-italic text-${TOKENS.FONT.NEUTRAL}`}>
              {rideIndex === 0 && rides.length > 1
                ? 'Editing the initial ride will remove the return ride'
                : ''}
            </div>
          </div>
        );
        return (
          <Card
            label={rideLabel}
            editable={false}
            key={rideIndex}
            onEdit={() => onEditRide(rideIndex)}
            onSave={NOOP}
          >
            <div className="flex flex-col gap-[16px]">
              <div
                data-testid={`row-1-ride-summary-${rideIndex}`}
                className="flex flex-col gap-[8px]"
              >
                <RidesSummarySectionHeader label="Locations" icon="Location" />
                <div className="reset-div grid gap-x-[32px] gap-y-[16px] overflow-x-clip grid-cols-3">
                  <Card.Content label="Pick Up">
                    <RidesSummaryAddress address={ride.departAddress} />
                  </Card.Content>
                  <Card.Content label="Drop Off">
                    <RidesSummaryAddress address={ride.arriveAddress} />
                  </Card.Content>
                  <Card.Content label="Distance">
                    <div className="text-base mb-[8px]">{ride.distance} Miles</div>
                    <RideMileageWarning rideModel={rides.at(rideIndex) as RideModel} />
                  </Card.Content>
                </div>
              </div>

              <div
                data-testid={`row-2-ride-summary-${rideIndex}`}
                className="flex flex-col gap-[8px]"
              >
                <RidesSummarySectionHeader label="Times" icon="Time" />
                <div className="reset-div grid gap-x-[32px] gap-y-[16px] overflow-x-clip grid-cols-3">
                  <Card.Content label={isReturnRide ? 'Depart At' : 'Pick Up Time'}>
                    <div className="text-base">{`${formatRideTimeForDisplay(estimatedPickupTime, rideDate!, ride.timezone!)}`}</div>
                  </Card.Content>

                  {!rideIndex ? (
                    <Card.Content label="Appointment Time">
                      <div className="text-base">
                        {ride.type === 'arriveBy' &&
                          moment
                            .tz(
                              `${rideDate} ${ride.time}`,
                              'YYYY-MM-DD h:mm a',
                              ride.timezone!
                            )
                            .format('h:mm a, z')}
                      </div>
                    </Card.Content>
                  ) : (
                    <Card.Content />
                  )}

                  {!isFlexRide ? (
                    <Card.Content label="Travel Time">
                      <div className="text-base">{duration}</div>
                    </Card.Content>
                  ) : (
                    <Card.Content />
                  )}
                </div>
              </div>

              <div
                data-testid={`row-3-ride-summary-${rideIndex}`}
                className="flex flex-col"
                style={{ gap: '10px' }}
              >
                <RidesSummarySectionHeader label="Notes" icon="EditText" />
                <div className="reset-div grid gap-x-[32px] gap-y-[16px] overflow-x-clip grid-cols-1">
                  <Card.Content label="Ride Note">
                    <div
                      className="text-base"
                      style={{ fontStyle: ride.notes ? 'normal' : 'italic' }}
                    >
                      {ride.notes || 'None'}
                    </div>
                  </Card.Content>
                </div>
              </div>
            </div>
          </Card>
        );
      })}
    </div>
  );
}
