import React from 'react';
import { Icon, THEME, TOKENS } from '@SRHealth/frontend-lib';
import type { DuplicateRide } from '~/Modules/rideBooking';
import { getFormattedDuplicateRideTime } from '~/Pages/RideBooking/RideBooking.utils';
import { getFormattedDuplicateRideDate } from '../RecurringRides.utils';

type DuplicateRecurringRideDetailsProps = {
  ride: DuplicateRide;
};

export default function DuplicateRecurringRideDetails({
  ride
}: DuplicateRecurringRideDetailsProps) {
  const formattedDate = getFormattedDuplicateRideDate(ride);
  const formattedTime = getFormattedDuplicateRideTime(ride, true);
  const location = ride?.appointmentTime ? ride.dropoffAddress : ride.pickupAddress;

  return (
    <div
      className="flex flex-col gap-[8px]"
      style={{
        borderBottom: `1px solid ${THEME.colors[TOKENS.BORDER.NEUTRAL_XLT]}`,
        padding: '16px 0 8px'
      }}
    >
      <p className="heading-sm">{formattedDate}</p>
      <div className="flex flex-col gap-[4px] text-sm">
        <div className="flex items-center gap-[8px]">
          <div className="flex items-center justify-between w-full">
            <p>{ride?.appointmentTime ? 'Appointment:' : 'Depart:'}</p>
            <div className="flex items-center gap-[4px]">
              <Icon type="Time" className="w-[24px] h-[24px]" />
              <p>{formattedTime}</p>
            </div>
          </div>
        </div>
        <p>{`Ride ID: ${ride.id}`}</p>
        <p>{`Location: ${location}`}</p>
      </div>
    </div>
  );
}
