import { createModelFactory, Is, type ModelType } from '@SRHealth/frontend-lib';
import type { PropertyRuleSignature } from '.';

export type PublicCardModelProps = {
  typeId: number;
  name: string;
  cost: string;
  quantity: number;
};

export type PublicCardModel = ModelType<PublicCardModelProps>;
export type PublicCardRule = PropertyRuleSignature<PublicCardModelProps>;

const PUBLIC_CARD_DEFAULT = (): PublicCardModelProps => ({
  typeId: 0,
  name: '',
  cost: '0.00',
  quantity: 1
});

export const publicCardFactory = createModelFactory<PublicCardModel>(
  PUBLIC_CARD_DEFAULT(),
  {
    properties: [
      ['is-string', 'name', Is.String.strict],
      [
        'valid-cost',
        'cost',
        (v: string, _, isCommit) => {
          if (isCommit && parseFloat(v) && parseFloat(v) < 0) {
            throw Error('Public card cost must be a valid number greater than zero');
          }

          return true;
        }
      ],
      [
        'is-not-empty',
        'name',
        (v, _, isCommit) => {
          if (isCommit && Is.Empty(v)) {
            throw new Error('Card name field is empty');
          }
          return true;
        }
      ],
      ['is-number', 'quantity', Is.Number.strict]
    ],
    model: []
  }
);
