import type { DuplicateRide } from '~/Modules/rideBooking';
import type { RideBookingMode } from './RideBooking';
import moment from 'moment-timezone';

/** A generic utility to cancel ride booking from anywhere from
 * inside of the RBF. Resets RBF redux store and redirects
 * the user to passenger's member profile. */
export const genericCancelBookingHandler = () =>
  document.getElementById('to-member-profile')!.click();

/**
 *  Determine what label to display in the subheader based on the page mode
 * and the passenger name. If a first and last name are provided, an empty
 * string is returned.
 */
export function getLabel(mode: RideBookingMode, firstName: string, lastName: string) {
  if (!firstName && !lastName) return '';

  const prefix = mode === 'new' ? 'New Transport Trip For' : 'Edit Transport Trip For';

  return `${prefix} ${firstName} ${lastName}`;
}

/**
 * Determine what label and href to display in the back link based on the page mode
 * and id.
 */
export function getBackLink(mode: RideBookingMode, id: string) {
  const label = mode === 'new' ? 'Member Profile' : 'Ride Details';
  const href = mode === 'new' ? `/member-profiles/${id}/general/personal-info` : `#`;

  return [label, href];
}

/** Gets the formatted time to display for a duplicate ride */
export const getFormattedDuplicateRideTime = (
  ride: DuplicateRide,
  checkAppointmentTime = false
): string => {
  if (ride.pickupTime.includes('Now')) return ride.pickupTime;

  // Flex Time should always be displayed as Flexible
  if (ride.pickupTime.includes('Flex Time')) return 'Flexible';

  let isoDateTime = ride.pickupTime;
  // see if we should display the appointment time instead of the pickup time
  if (checkAppointmentTime && ride?.appointmentTime) {
    isoDateTime = ride.appointmentTime;
  }

  return moment(isoDateTime).tz(ride.timezone).format('h:mm a, z');
};
