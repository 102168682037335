import { type InputOption } from '@SRHealth/frontend-lib';
import { useMemo } from 'react';
import type { TimeRestrictions } from '~/Modules/rideBooking';
import type { RideType } from '~/models';
import {
  minutesToMidnight,
  minutesUntilDate,
  timezoneOffsetFromUser
} from '~/utilities/timesAndDates';
import { useAppSelector } from '~/Modules';
import moment from 'moment-timezone';

/** Calculate the offset from the start of the day, in minutes. Accounts
 * for differences between the target address time zone and the user's
 * time zone.
 * @param timezone This should be returned from a lookup in mapbox
 * @param restrictionOffset This should be the offset value returned
 * from the app-layer-rbf getAvailableTimes endpoint.
 */
function getOffset(timezone: string, date: DateString, restrictionOffset = 0): number {
  const now = new Date();
  const currentTime = now.getMinutes() + now.getHours() * 60;

  const minutesToBookingDate = minutesUntilDate(date, true);
  const diffBetweenTargetAndUser = timezoneOffsetFromUser(timezone);

  // Take the offset and adjust it by the different between target timezone
  // and user timezone.
  let offset = restrictionOffset + diffBetweenTargetAndUser;

  // If the booking date is further out than the offset, return 0.
  if (minutesToBookingDate > Math.abs(offset)) return 0;

  // If the booking date is for more than 24 hours in the past, return 0.
  if (minutesToBookingDate < -1 * 60 * 24) return 0;

  // If the offset is less than a day, we need to add the current time
  if (offset < minutesToMidnight()) offset += currentTime;

  return offset - (minutesToBookingDate < 0 ? 0 : minutesToBookingDate);
}

/** Create an array of available booking times based on the
 * step size (defaults to 10 minutes), target timezone and buffer offset.
 * Supports daylight savings time transitions and gaps (e.g. skipping from 2AM CST to 3AM CDT).
 * */
export function getTimes(
  offsetMinutes: number,
  date: DateString,
  timezone: string,
  intervalMinutes = 10
): InputOption[] {
  // Get the start of the day in the target timezone.
  const startOfDay = moment.tz(date, timezone).startOf('day');

  // Round to the next interval time boundary (so we don't end up with "6:37PM" or another weird uneven time)
  const numIntervalsInOffset = Math.ceil(offsetMinutes / intervalMinutes);
  const finalOffsetMinutes = numIntervalsInOffset * intervalMinutes;

  let current = startOfDay.clone().add(finalOffsetMinutes, 'minutes');
  const endOfDay = startOfDay.clone().endOf('day');
  const times: InputOption[] = [];

  // Loop and add the specified time until we pass the end of day.
  while (current.isBefore(endOfDay)) {
    // Format the time slot (e.g., "3:00 am")
    const timeInterval = current.format('h:mm a');
    // Get the timezone abbreviation for this moment (e.g., CDT or CST)
    const abbreviation = current.format('z');

    times.push({
      label: `${timeInterval}, ${abbreviation}`,
      value: timeInterval
    });

    // Adding the time: moment handles DST transitions and will jump over gaps (e.g. skipping from 2AM CST to 3AM CDT, going from 2AM CDT to 1AM CST).
    current = current.clone().add(intervalMinutes, 'minutes');
  }

  return times;
}

export const useTimeOptions = (
  type: RideType,
  timezone: string | undefined,
  date: DateString,
  isReturnRide = false
) => {
  const timeRestrictions = useAppSelector(s => s.rideBooking.meta.timeRestrictions);

  const options = useMemo(() => {
    const options: InputOption[] = [];

    const restrictions: Partial<TimeRestrictions> =
      timeRestrictions && type ? timeRestrictions[type] : {};

    if (restrictions?.flex) {
      options.push({ label: 'Flexible Pick Up', value: 'Flex Time' });
    }

    // Only include the "Now" option for the initial ride (since the initial ride time must be earlier than the return ride time and given there is nothing earlier than "now", doesn't make sense to allow that for the return ride).
    if (restrictions?.now && !isReturnRide) {
      options.push({ label: 'Now', value: 'Now' });
    }

    if (!timezone) return options;

    // The offset is the number of minutes from the start of the booking day.
    const offset = getOffset(timezone, date!, restrictions?.offset);
    const times = getTimes(offset, date, timezone, restrictions?.interval);

    return options.concat(times);
  }, [timeRestrictions, date, type, timezone, isReturnRide]);

  return options;
};
