import { Container } from '@material-ui/core';
import _ from 'lodash-es';
import { AutoComplete } from 'mapbox-components';
import moment from 'moment';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { REGEX_DATE, REGEX_PHONE_NUMBER } from '~/constants';
import { ELIGIBILITY_API } from '~/constants/features';
import { clearData, putMemberProfile } from '~/Modules/memberProfile/actions';
import { PERMISSIONS } from '~/Pages/MemberProfiles/MemberProfiles.constants';
import DropDown from '~/Shared/Components/DropDown';
import InputText from '~/Shared/Components/InputText';
import LoadingModal from '~/Shared/Components/LoadingModal';
import TextAreaField from '~/Shared/Components/TextAreaField';
import {
  formatPhoneNumberToE164Standard,
  getHospitalCoords,
  isHealthPlanRole
} from '~/utilities/helperFunctions';
import { mapMemberPhoneNumberUpdateRequest } from '~/utilities/memberPhoneNumber.helper';
import { initState } from './PersonalInfo.helper';

const ERROR_MSG = 'There are items that require your attention*';

const primaryPhoneIds = [
  'member_primary_phone_type',
  'member_primary_phone_preference',
  'member_primary_phone_opt_out'
];
const secondaryPhoneIds = [
  'member_secondary_phone_type',
  'member_secondary_phone_preference',
  'member_secondary_phone_opt_out'
];

class PersonalInfo extends Component {
  constructor(props) {
    super(props);
    this.state = initState;
  }

  componentDidMount() {
    const state = this.initialize();

    this.setState(state, () => {
      this.props.setSelectionCallback(this.handleChildSelection);
      this.props.setEditButton(
        <PersonalInfoEditButton
          permission={this.props.permission}
          editable={this.props.editable}
          handleEdit={this.handleEdit}
          handleCancel={this.handleCancel}
          handleSave={this.handleSave}
        />
      );
    });
  }

  componentWillUnmount() {
    this.props.setErrorMsg('');
    this.props.cancel();
  }

  componentDidUpdate(prevProps) {
    const state = {};

    if (!_.isEqual(prevProps.personalInfo, this.props.personalInfo)) {
      _.assign(state, initState, this.initialize());
    }

    if (!_.isEmpty(this.props.success) && _.isEmpty(prevProps.success)) {
      state.success =
        this.props.success.formType === 'personalInfo' ? this.props.success.message : '';
      state.isDirty = false;
      this.props.cancel();
    }

    if (!_.isEmpty(this.props.error) && _.isEmpty(prevProps.error)) {
      // if it's an array then there are field validation
      if (Array.isArray(this.props.error)) {
        state.error = '';
        for (let i = 0; i < this.props.error.length; i++) {
          const errorObj = this.props.error[i];
          const key = Object.keys(errorObj)[0];

          state[`${key}Error`] = true;
          state[`${key}ErrorText`] = errorObj[key];
          if (i === 0) {
            state.error = `${errorObj[key]}`;
          } else {
            state.error += ` ${errorObj[key]}`;
          }
        }
      } else {
        state.error = this.props.error;
      }
    }

    if (this.props.editable !== prevProps.editable) {
      this.props.setEditButton(
        <PersonalInfoEditButton
          permission={this.props.permission}
          editable={this.props.editable}
          handleEdit={this.handleEdit}
          handleCancel={this.handleCancel}
          handleSave={this.handleSave}
        />
      );
    }

    if (Object.keys(state).length) {
      state.showLoader = false;

      this.setState(state, () => {
        if (this.state.error !== state.error) {
          this.props.setIsChildDataLoading(false);
          this.props.setErrorMsg(state.error);
        }
      });
    }
  }

  /**
   * general drop down select handler
   * @param {event} e - event
   * @param {any} item - value of drop down
   * @param {string} key - key associated with value
   * @return {undefined}
   */
  handleDDSelect = (e, item, key) => {
    this.setState({
      [key]: item,
      [`${key}Id`]: parseInt(e.target.id, 10),
      isDirty: true
    });
  };

  /**
   * eligibility toggle
   * @return {undefined}
   */
  handleEligible = () => {
    const { editable, user } = this.props;

    const isEligibilityApi = user.features?.eligibility_api;
    const internalEligibility = user.internalEligibility;

    if (!editable || (isEligibilityApi && !internalEligibility)) {
      return false;
    }

    this.setState(prevState => ({
      isEligible: !prevState.isEligible,
      isDirty: true
    }));
  };

  /**
   * general textbox handler
   * @param {event} e - event
   * @param {string} id - string for id value which will be used for modfying state
   * @return {undefined}
   */
  handleText = (e, id) => {
    const errorProp = `${id}Error`;
    const val = e.target.value;

    const state = { isDirty: true };

    if (this.state[errorProp] && val.length > 0) {
      state[errorProp] = false;
    }

    if (id === 'email') {
      state[id] = val.trim();
    } else {
      state[id] = val;
    }

    if (id === 'mobileNo' || id === 'phone2') {
      state[errorProp] = val && !REGEX_PHONE_NUMBER.test(val);
      if (id === 'mobileNo') {
        primaryPhoneIds.map(id => {
          this.setState({
            [id]: null,
            [`${id}Id`]: null
          });
        });
      }
      if (id === 'phone2') {
        secondaryPhoneIds.forEach(id => {
          this.setState({
            [id]: null,
            [`${id}Id`]: null
          });
        });
      }
    }

    this.setState(state);
  };

  /**
   * date of birth handler and validator
   * @param {event} e - event
   * @return {undefined}
   */
  handleDateOfBirth = e => {
    let dateOfBirthError = false;
    let { dateOfBirthEntered } = this.state;
    const dateOfBirth = this.state.dateOfBirth;

    if (e.target.value === '') {
      dateOfBirthEntered = false;
    } else if (e.target.value.indexOf('_') === -1 || dateOfBirth.length === 10) {
      dateOfBirthEntered = true;
    }

    if (
      !REGEX_DATE.test(e.target.value) ||
      moment(e.target.value, 'MM/DD/YYYY').isAfter(moment()) ||
      moment(e.target.value, 'MM/DD/YYYY').isBefore(moment('01/01/1910'))
    ) {
      if (dateOfBirthEntered) {
        dateOfBirthError = true;
      }
    }

    this.setState({
      dateOfBirth: e.target.value,
      dateOfBirthError,
      dateOfBirthEntered,
      isDirty: true
    });
  };

  /**
   * autocomplete handler
   * @param {string} whereTo - 'home' or 'billing'
   * @return {function} - returns function which sets state for address properties
   */
  handleSuggestionSelect(whereTo) {
    return (suggestion, lat, lng, text, addressObj) => {
      const state = _.cloneDeep(this.state);
      state[`${whereTo}Address`] = suggestion;
      state[`${whereTo}Latitude`] = lat;
      state[`${whereTo}Longitude`] = lng;
      state[`${whereTo}Zip`] = _.get(addressObj, 'zipcode', null);
      state[`${whereTo}AddressError`] = false;
      state.isDirty = true;
      this.setState(state);
    };
  }

  /**
   * save handler, shapes data for dispatch then calls dispatch
   * @return {undefined}
   */
  handleSave = () => {
    const validate = this.validate();

    if (validate) {
      const { selectedId, personalInfo } = this.props;
      const memberProfile = {};
      const memberPhoneNumbersRequestSchema = mapMemberPhoneNumberUpdateRequest({
        personalInfo: {
          ...personalInfo,
          phone2: formatPhoneNumberToE164Standard(_.clone(this.state.phone2)),
          mobileNo: formatPhoneNumberToE164Standard(_.clone(this.state.mobileNo))
        },
        state: {
          ...this.state,
          phone2: formatPhoneNumberToE164Standard(_.clone(this.state.phone2)),
          mobileNo: formatPhoneNumberToE164Standard(_.clone(this.state.mobileNo))
        }
      });

      memberProfile.member_phone_numbers = memberPhoneNumbersRequestSchema;
      // input fields
      memberProfile.firstName = this.state.firstName?.trim();
      memberProfile.lastName = this.state.lastName?.trim();
      memberProfile.mobileNo = this.state.mobileNo?.trim();
      memberProfile.email = this.state.email?.trim();
      memberProfile.medicalId = this.state.medicalId?.trim();
      memberProfile.dateOfBirth =
        this.state.dateOfBirth !== ''
          ? moment(this.state.dateOfBirth, 'MM/DD/YYYY').format('YYYY-MM-DD')
          : null;
      memberProfile.isEligible = this.state.isEligible;
      memberProfile.member_portal_active = this.state.memberPortal;
      memberProfile.internalNotes = this.state.internalNotes?.trim();
      memberProfile.otherDetails = this.state.otherDetails?.trim();
      memberProfile.homeAddress = this.state.homeAddress?.trim();
      memberProfile.street2 = this.state.street2?.trim();
      memberProfile.billingAddress = this.state.billingAddress?.trim();
      memberProfile.billingStreet2 = this.state.billingStreet2?.trim();
      memberProfile.phone2 = this.state.phone2?.trim();

      // check id values for drop downs
      if (this.state.member_genderId !== null) {
        memberProfile.member_gender = this.state.member_genderId;
      }
      if (this.state.member_spoken_languageId !== null) {
        memberProfile.member_spoken_language = this.state.member_spoken_languageId;
      }
      if (this.state.member_written_languageId !== null) {
        memberProfile.member_written_language = this.state.member_written_languageId;
      }
      if (this.state.member_primary_phone_typeId !== null) {
        memberProfile.member_primary_phone_type = this.state.member_primary_phone_typeId;
      }
      if (this.state.member_primary_phone_preferenceId !== null) {
        memberProfile.member_primary_phone_preference =
          this.state.member_primary_phone_preferenceId;
      }
      memberProfile.member_primary_phone_opt_out =
        this.state.member_primary_phone_opt_outId;
      if (this.state.member_secondary_phone_typeId !== null) {
        memberProfile.member_secondary_phone_type =
          this.state.member_secondary_phone_typeId;
      }
      if (this.state.member_secondary_phone_preferenceId !== null) {
        memberProfile.member_secondary_phone_preference =
          this.state.member_secondary_phone_preferenceId;
      }

      memberProfile.member_secondary_phone_opt_out =
        this.state.member_secondary_phone_opt_outId;

      if (this.state.member_ethnicityId !== null) {
        memberProfile.member_ethnicity = this.state.member_ethnicityId;
      }

      this.props.clearData();
      this.setState(
        {
          success: '',
          error: '',
          showLoader: true
        },
        () => {
          this.props.setErrorMsg('');
          this.props.setIsChildDataLoading(true);
          this.props.putMemberProfile({
            memberProfile,
            passengerId: selectedId,
            formType: 'personalInfo'
          });
        }
      );
    }
  };

  /**
   * cancel an update, resets
   * @return {undefined}
   */
  handleCancel = () => {
    const state = initState;
    const newState = this.assignPersonalInfo();
    _.assign(state, newState);
    this.setState(state, () => {
      this.props.cancel();
    });
  };

  /**
   * edit member profile
   * @return {undefined}
   */
  handleEdit = () => {
    this.setState({ success: '' }, this.props.edit);
  };

  /**
   * validate form data b4 submission
   * @return {boolean} - returns true if validated, false if not
   */
  validate() {
    let validate = true;
    const state = {};

    state.firstNameError = false;
    state.lastNameError = false;
    state.medicalIdError = false;
    state.emailError = false;
    state.dateOfBirthError = false;
    state.member_genderError = false;
    state.mobileNoError = false;
    state.member_primary_phone_typeError = false;
    state.member_primary_phone_preferenceError = false;
    state.member_primary_phone_opt_outError = false;
    state.member_secondary_phone_typeError = false;
    state.member_secondary_phone_preferenceError = false;
    state.member_secondary_phone_opt_outError = false;
    state.member_spoken_languageError = false;
    state.member_written_languageError = false;
    state.member_ethnicityError = false;
    state.error = '';

    if (!this.state.firstName) {
      state.firstNameError = true;
      validate = false;
    }
    if (!this.state.lastName) {
      state.lastNameError = true;
      validate = false;
    }
    if (!this.state.medicalId) {
      state.medicalIdError = true;
      validate = false;
    }

    // hack to work around regex alternating bug
    REGEX_DATE.lastIndex = 0;

    if (
      (!REGEX_DATE.test(this.state.dateOfBirth) ||
        moment(this.state.dateOfBirth, 'MM/DD/YYYY').isAfter(moment()) ||
        moment(this.state.dateOfBirth, 'MM/DD/YYYY').isBefore(moment('01/01/1910'))) &&
      this.state.dateOfBirthEntered
    ) {
      state.dateOfBirthError = true;
      validate = false;
    }

    if (
      !this.state.mobileNo ||
      (this.state.mobileNo && !REGEX_PHONE_NUMBER.test(this.state.mobileNo))
    ) {
      state.mobileNoError = true;
      validate = false;
    }

    if (this.state.phone2 && !REGEX_PHONE_NUMBER.test(this.state.phone2)) {
      state.phone2Error = true;
      validate = false;
    }

    if (!validate) {
      state.error = ERROR_MSG;
    }

    this.setState(state);
    return validate;
  }

  /**
   * format drop down values
   * @param {object} values - personalInfo object
   * @param {string} key - namespace for form element
   * @param {string} sortKey - "id" || "name"
   * @return {array} - formatted data for drop down
   */
  formatValues(values, key, sortKey = '') {
    const formattedValues = _.get(values, `${key}.values`, []);

    if (formattedValues.length === 0) return [];

    const useSortKey = sortKey.toLowerCase() === 'id' || sortKey.toLowerCase() === 'name';

    const ret = formattedValues.map(val => {
      return {
        id: val.id,
        name: val.value
      };
    });

    return useSortKey
      ? ret.sort((a, b) => {
        if (a[sortKey] === b[sortKey]) {
          return 0;
        }

        return a[sortKey] > b[sortKey] ? 1 : -1;
      })
      : ret;
  }

  /**
   * assign personalInfo data to state since we have to modify values prior to submission
   * @return {object} - returns updated object which will later be setStated
   */
  assignPersonalInfo() {
    const personalInfo = _.cloneDeep(this.props.personalInfo);

    if (_.isEmpty(personalInfo)) return {};

    return {
      dateOfBirth: personalInfo.dateOfBirth,
      firstName: personalInfo.firstName,
      lastName: personalInfo.lastName,
      email: personalInfo.email,
      homeAddress: personalInfo.homeAddress,
      isEligible: personalInfo.isEligible,
      memberPortal: personalInfo.memberPortalActive,
      medicalId: personalInfo.medicalId,
      internalNotes: personalInfo.internalNotes,
      otherDetails: personalInfo.otherDetails,
      mobileNo: personalInfo.mobileNo,
      phone2: personalInfo.phone2,
      member_ethnicityId: personalInfo.member_ethnicityId,
      member_genderId: personalInfo.member_genderId,
      member_primary_phone_typeId: personalInfo.member_primary_phone_typeId,
      member_secondary_phone_typeId: personalInfo.member_secondary_phone_typeId,
      member_primary_phone_opt_outId: personalInfo.member_primary_phone_opt_outId,
      member_primary_phone_preferenceId: personalInfo.member_primary_phone_preferenceId,
      member_secondary_phone_opt_outId: personalInfo.member_secondary_phone_opt_outId,
      member_secondary_phone_preferenceId:
        personalInfo.member_secondary_phone_preferenceId,
      member_spoken_languageId: personalInfo.member_spoken_languageId,
      member_written_languageId: personalInfo.member_written_languageId,
      street2: personalInfo.street2,
      billingAddress: personalInfo.billingAddress,
      billingStreet2: personalInfo.billingStreet2
    };
  }

  initializeError = () => {
    const { error } = this.props;
    if (error && error.length && error[0] && error[0][0]) {
      return error[0][0].member_portal_active || null;
    }
  };

  /**
   * create object that will be initialized into component state
   * @return {object} - initialized state
   */
  initialize() {
    const hospitalData = !_.isEmpty(this.props.hospitalData)
      ? this.props.hospitalData
      : this.props.hospitals;
    const isNetwork = isHealthPlanRole(this.props.user);
    const hospitalId = this.props.personalInfo.hospitalId;
    const coords = getHospitalCoords(hospitalId, hospitalData, isNetwork);

    const state = this.assignPersonalInfo();
    const error = this.initializeError();

    if (error) {
      state.error = error;
    }

    if (!_.isEmpty(coords)) {
      state.coords = coords;
    }
    return state;
  }

  handleChildSelection = () => {
    return (
      !this.state.isDirty || confirm(`Are you sure you want to leave without saving?`)
    );
  };

  /**
   * render modal and calendar
   * @return {jsx} returns jsx.
   */
  render() {
    const { isEligible } = this.state;
    const { editable, personalInfo, user } = this.props;
    const externalEligibilityApi =
      user.features[ELIGIBILITY_API] && !user.internalEligibility;

    const buttonClass =
      editable && !externalEligibilityApi ? 'toggleBtn' : 'toggleBtn disabled';
    const circleClass = isEligible ? 'toggleCircle on' : 'toggleCircle';
    const eligibleButtonClass = isEligible ? `${buttonClass} on` : buttonClass;

    return (
      <Container className="parentTab">
        <div className="childTab personalInfo">
          <div className="memberRow">
            <InputText
              customClassName="oneColumn"
              placeholder="First Name"
              fieldName="firstName"
              label="First Name"
              showLabel={true}
              error={this.state.firstNameError}
              errorText="Please enter a first name."
              onChange={e => this.handleText(e, 'firstName')}
              value={this.state.firstName}
              disabled={!editable || externalEligibilityApi}
              required={true}
            />
            <InputText
              customClassName="oneColumn"
              placeholder="Last Name"
              fieldName="lastName"
              label="Last Name"
              showLabel={true}
              onChange={e => this.handleText(e, 'lastName')}
              value={this.state.lastName}
              error={this.state.lastNameError}
              errorText="Please enter a last name."
              disabled={!editable || externalEligibilityApi}
              required={true}
            />
            <InputText
              customClassName="oneColumn"
              placeholder="ID"
              fieldName="medicalId"
              label="ID"
              showLabel={true}
              onChange={e => this.handleText(e, 'medicalId')}
              value={this.state.medicalId}
              error={this.state.medicalIdError}
              errorText="Please enter an ID."
              disabled={!editable || user.features[ELIGIBILITY_API]}
              required={true}
            />
          </div>
          <div className="memberRow">
            {personalInfo.displayMemberPortal ? (
              <>
                <div className="CustomInputText halfColumn">
                  <label className="inputLabel">Eligible</label>
                  <div className={eligibleButtonClass} onClick={this.handleEligible}>
                    <span className={circleClass}></span>
                  </div>
                </div>
              </>
            ) : (
              <>
                <div className="CustomInputText oneColumn">
                  <label className="inputLabel">Eligible</label>
                  <div className={eligibleButtonClass} onClick={this.handleEligible}>
                    <span className={circleClass}></span>
                  </div>
                </div>
              </>
            )}
            <InputText
              customClassName="oneColumn"
              placeholder="##/##/####"
              fieldName="dateOfBirth"
              label="Date of Birth"
              showLabel={true}
              format="##/##/####"
              mask="_"
              onChange={this.handleDateOfBirth}
              value={this.state.dateOfBirth}
              error={this.state.dateOfBirthError}
              errorText="Please enter a valid date of birth."
              disabled={!editable || user.features[ELIGIBILITY_API]}
            />
            <DropDown
              customClassName="oneColumn"
              items={this.formatValues(personalInfo, 'member_gender')}
              placeholder={this.state.member_gender}
              label="Gender"
              showLabel={true}
              dropDownCallback={(e, item) =>
                this.handleDDSelect(e, item, 'member_gender')
              }
              disabled={!editable || externalEligibilityApi}
              id={this.state.member_genderId}
              error={this.state.member_genderError}
            />
          </div>
          <div className="memberRow">
            <div
              className={`CustomInputText twoColumn${
                this.state.homeAddressError ? ' error' : ''
              }${this.props.editable ? '' : ' disabled'}`}
            >
              <label htmlFor="homeAddress" className="inputLabel">
                Home Address
              </label>
              <AutoComplete
                accessToken={process.env.REACT_APP_MAPBOX_TOKEN}
                query={this.state.homeAddress}
                onSuggestionSelect={this.handleSuggestionSelect('home').bind(this)}
                proximity={{
                  lat: this.state.coords.lat,
                  lng: this.state.coords.lng
                }}
                placeholder="Enter Home Address"
                inputId="homeAddress"
                disabled={!editable || externalEligibilityApi}
              />
            </div>
            <InputText
              customClassName="oneColumn"
              placeholder=""
              fieldName="street2"
              label="Apt/Unit/Suite"
              showLabel={true}
              value={this.state.street2}
              inputId="street2"
              onChange={e => this.handleText(e, 'street2')}
              disabled={!editable || externalEligibilityApi}
            />
          </div>
          <div className="memberRow">
            <div
              className={`CustomInputText twoColumn${
                this.state.billingAddressError ? ' error' : ''
              }${this.props.editable ? '' : ' disabled'}`}
            >
              <label htmlFor="billingAddress" className="inputLabel">
                Billing Address
              </label>
              <AutoComplete
                accessToken={process.env.REACT_APP_MAPBOX_TOKEN}
                query={this.state.billingAddress}
                onSuggestionSelect={this.handleSuggestionSelect('billing').bind(this)}
                proximity={{
                  lat: this.state.coords.lat,
                  lng: this.state.coords.lng
                }}
                placeholder="Enter Billing Address"
                inputId="billingAddress"
                disabled={!this.props.editable}
              />
            </div>
            <InputText
              customClassName="oneColumn"
              placeholder=""
              fieldName="billingStreet2"
              label="Apt/Unit/Suite"
              showLabel={true}
              disabled={!this.props.editable}
              onChange={e => this.handleText(e, 'billingStreet2')}
              value={this.state.billingStreet2}
            />
          </div>
          <div className="memberRow">
            <InputText
              customClassName="oneColumn"
              placeholder="(  )   -   "
              fieldName="mobileNo"
              label="Primary Phone"
              showLabel={true}
              format="(###) ###-####"
              mask="_"
              onChange={e => this.handleText(e, 'mobileNo')}
              error={this.state.mobileNoError}
              errorText={'Please enter a valid phone number.'}
              value={this.state.mobileNo}
              disabled={!editable || externalEligibilityApi}
            />
            <DropDown
              customClassName="halfColumn"
              items={this.formatValues(personalInfo, 'member_primary_phone_type')}
              placeholder={this.state.member_primary_phone_type}
              label="Type"
              showLabel={true}
              dropDownCallback={(e, item) =>
                this.handleDDSelect(e, item, 'member_primary_phone_type')
              }
              disabled={!this.props.editable}
              id={this.state.member_primary_phone_typeId}
              error={this.state.member_primary_phone_typeError}
              errorText={this.state.member_primary_phone_typeErrorText}
            />
            <DropDown
              customClassName="halfColumn"
              items={this.formatValues(personalInfo, 'member_primary_phone_preference')}
              placeholder={this.state.member_primary_phone_preference}
              label="Preference"
              showLabel={true}
              dropDownCallback={(e, item) =>
                this.handleDDSelect(e, item, 'member_primary_phone_preference')
              }
              disabled={!this.props.editable}
              id={this.state.member_primary_phone_preferenceId}
              error={this.state.member_primary_phone_preferenceError}
              errorText={this.state.member_primary_phone_preferenceErrorText}
            />
            <DropDown
              customClassName="oneColumn"
              items={this.formatValues(personalInfo, 'member_primary_phone_opt_out')}
              placeholder={this.state.member_primary_phone_opt_out}
              label="Opt Out"
              showLabel={true}
              dropDownCallback={(e, item) =>
                this.handleDDSelect(e, item, 'member_primary_phone_opt_out')
              }
              disabled={!this.props.editable}
              id={this.state.member_primary_phone_opt_outId}
              error={this.state.member_primary_phone_opt_outError}
              errorText={this.state.member_primary_phone_opt_outErrorText}
            />
          </div>
          <div className="memberRow">
            <InputText
              customClassName="oneColumn"
              placeholder="(  )   -   "
              fieldName="phone2"
              label="Secondary Phone"
              showLabel={true}
              format="(###) ###-####"
              mask="_"
              onChange={e => this.handleText(e, 'phone2')}
              error={this.state.phone2Error}
              errorText={'Please enter a valid phone number.'}
              value={this.state.phone2}
              disabled={!editable || externalEligibilityApi}
            />
            <DropDown
              customClassName="halfColumn"
              items={this.formatValues(personalInfo, 'member_secondary_phone_type')}
              placeholder={this.state.member_secondary_phone_type}
              label="Type"
              showLabel={true}
              dropDownCallback={(e, item) =>
                this.handleDDSelect(e, item, 'member_secondary_phone_type')
              }
              disabled={!this.props.editable}
              id={this.state.member_secondary_phone_typeId}
              error={this.state.member_secondary_phone_typeError}
              errorText={this.state.member_secondary_phone_typeErrorText}
            />
            <DropDown
              customClassName="halfColumn"
              items={this.formatValues(personalInfo, 'member_secondary_phone_preference')}
              placeholder={this.state.member_secondary_phone_preference}
              label="Preference"
              showLabel={true}
              dropDownCallback={(e, item) =>
                this.handleDDSelect(e, item, 'member_secondary_phone_preference')
              }
              disabled={!this.props.editable}
              id={this.state.member_secondary_phone_preferenceId}
              error={this.state.member_secondary_phone_preferenceError}
              errorText={this.state.member_secondary_phone_preferenceErrorText}
            />
            <DropDown
              customClassName="oneColumn"
              items={this.formatValues(personalInfo, 'member_secondary_phone_opt_out')}
              placeholder={this.state.member_secondary_phone_opt_out}
              label="Opt Out"
              showLabel={true}
              dropDownCallback={(e, item) =>
                this.handleDDSelect(e, item, 'member_secondary_phone_opt_out')
              }
              disabled={!this.props.editable}
              id={this.state.member_secondary_phone_opt_outId}
              error={this.state.member_secondary_phone_opt_outError}
              errorText={this.state.member_secondary_phone_opt_outErrorText}
            />
          </div>
          <div className="memberRow">
            <InputText
              customClassName="fullColumn"
              fieldName="email"
              label="Email"
              showLabel={true}
              disabled={!this.props.editable}
              value={this.state.email}
              error={this.state.emailError}
              onChange={e => this.handleText(e, 'email')}
              errorText="Please enter a valid email."
            />
          </div>
          <div className="memberRow">
            <DropDown
              customClassName="oneColumn"
              items={this.formatValues(personalInfo, 'member_spoken_language', 'name')}
              placeholder={this.state.member_spoken_language}
              label="Spoken Language"
              showLabel={true}
              dropDownCallback={(e, item) =>
                this.handleDDSelect(e, item, 'member_spoken_language')
              }
              disabled={!editable || externalEligibilityApi}
              id={this.state.member_spoken_languageId}
              error={this.state.member_spoken_languageError}
              errorText={this.state.member_spoken_languageErrorText}
            />
            <DropDown
              customClassName="oneColumn"
              items={this.formatValues(personalInfo, 'member_written_language', 'name')}
              placeholder={this.state.member_written_language}
              label="Written Language"
              showLabel={true}
              dropDownCallback={(e, item) =>
                this.handleDDSelect(e, item, 'member_written_language')
              }
              id={this.state.member_written_languageId}
              error={this.state.member_written_languageError}
              errorText={this.state.member_written_languageErrorText}
              disabled={!editable || externalEligibilityApi}
            />
            <DropDown
              customClassName="oneColumn"
              items={this.formatValues(personalInfo, 'member_ethnicity', 'name')}
              placeholder={this.state.member_ethnicity}
              label="Ethnicity"
              showLabel={true}
              dropDownCallback={(e, item) =>
                this.handleDDSelect(e, item, 'member_ethnicity')
              }
              disabled={!editable}
              id={this.state.member_ethnicityId}
              error={this.state.member_ethnicityError}
              errorText={this.state.member_ethnicityErrorText}
            />
          </div>
          <div className="memberRow">
            <TextAreaField
              customClassName="fullColumn"
              fieldName="internalNotes"
              label="Member Notes (Internal)"
              showLabel={true}
              disabled={!this.props.editable}
              value={this.state.internalNotes}
              onChangeCallback={e => this.handleText(e, 'internalNotes')}
              placeholder={
                'These notes are for internal viewing only and are not visible to drivers. Examples include anything that contains member PHI, compliant driver information, and internal reminders.'
              }
            />
            <TextAreaField
              customClassName="fullColumn"
              fieldName="otherDetails"
              label="Default Ride Notes (Visible to Transport)"
              showLabel={true}
              disabled={!this.props.editable}
              value={this.state.otherDetails}
              onChangeCallback={e => this.handleText(e, 'otherDetails')}
              placeholder={
                'These notes are visible to NEMT and rideshare drivers and should include transport relevant information. Examples include gate codes, special assistance needs, WCV and STR information (height, weight, steps, etc.), and pickup instructions.'
              }
            />
          </div>
          {this.state.showLoader ? (
            <LoadingModal label="Saving Member Info..." isOpen={this.state.showLoader} />
          ) : null}
        </div>
      </Container>
    );
  }
}

const PersonalInfoEditButton = ({
  editable,
  permission,
  handleEdit,
  handleSave,
  handleCancel
}) => {
  return (
    <>
      {' '}
      {permission === PERMISSIONS.EDIT || permission === PERMISSIONS.CREATE_EDIT ? (
        <>
          {editable ? (
            <>
              <a onClick={handleCancel}>Cancel</a>
              <a onClick={handleSave}>Save</a>
            </>
          ) : (
            <a onClick={handleEdit}>Edit</a>
          )}
        </>
      ) : null}
    </>
  );
};

PersonalInfo.propTypes = {
  user: PropTypes.object,
  hospitalData: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
  editable: PropTypes.bool,
  edit: PropTypes.func,
  cancel: PropTypes.func,
  selectedId: PropTypes.string,
  passengerId: PropTypes.number,
  personalInfo: PropTypes.object,
  putMemberProfile: PropTypes.func,
  permission: PropTypes.string,
  hospitals: PropTypes.array,
  error: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
  success: PropTypes.object,
  clearData: PropTypes.func,
  setEditButton: PropTypes.func,
  setErrorMsg: PropTypes.func,
  setSelectionCallback: PropTypes.func,
  setIsChildDataLoading: PropTypes.func
};

PersonalInfo.defaultProps = {
  user: {},
  hospitalData: {},
  editable: false,
  edit: () => {},
  cancel: () => {},
  selectedId: 0,
  passengerId: 0,
  personalInfo: {},
  putMemberProfile: () => {},
  permission: 'view',
  hospitals: [],
  error: {},
  success: {},
  clearData: () => {},
  setEditButton: () => {},
  setErrorMsg: () => {},
  setSelectionCallback: () => {},
  setIsChildDataLoading: () => {}
};

const mapStateToProps = state => ({
  user: state.user,
  hospitalData: state.user.hospitalData,
  hospitals: state.user.hospitals,
  personalInfo: _.get(state, 'memberProfile.formData.personalInfo', {}),
  error: state.memberProfile.error,
  success: state.memberProfile.success
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      putMemberProfile: data => putMemberProfile(data),
      clearData: () => clearData()
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(PersonalInfo);
