import React from 'react';
import { Icon, TOKENS } from '@SRHealth/frontend-lib';
import type { ProviderNemtModel } from '~/models/ProviderNemt.model';
import type { ProviderRideshareModel } from '~/models/ProviderRideshare.model';
import type { ProviderPublicModel } from '~/models/ProviderPublic.model';

interface BookedProviderInfoProps {
  selectedProvider: ProviderNemtModel | ProviderRideshareModel | ProviderPublicModel;
}

export default function BookedProviderInfo({
  selectedProvider
}: BookedProviderInfoProps) {
  if (selectedProvider.name === 'Uber' || selectedProvider.name === 'Lyft') {
    return (
      <div className="flex flex-col gap-[4px] text-center py-[8px] items-center">
        <p className="heading-base">Confirmed with</p>
        <Icon
          type={selectedProvider.name}
          className={`fill-current text-${TOKENS.COMPONENT.BUTTON.DEFAULT}`}
        />
      </div>
    );
  }

  return (
    <div className="flex flex-col gap-[4px] text-center py-[8px]">
      <p className="heading-base">Requested with</p>
      <p className="heading-lg">{selectedProvider.name}</p>
    </div>
  );
}
