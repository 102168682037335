import { Icon, TOKENS } from '@SRHealth/frontend-lib';
import React from 'react';
import NoSupplyAlerts from '~/Pages/RideBooking/Components/Shared/NoSupplyAlerts';

export type ProviderHeaderProps = {
  providerName?: string;
  hasError?: boolean;
};

function getIcon(
  providerName: ProviderHeaderProps['providerName'],
  hasError: ProviderHeaderProps['hasError']
) {
  if (providerName) {
    return (
      <Icon
        type="Verified"
        width={24}
        height={24}
        className={`fill-current text-${TOKENS.ICON.ACTIVE}`}
      />
    );
  } else if (hasError) {
    return (
      <Icon
        type="Error"
        width={24}
        height={24}
        className={`fill-current text-${TOKENS.ICON.ERROR}`}
      />
    );
  }

  return null;
}

function getText(providerName: ProviderHeaderProps['providerName']) {
  return providerName ? (
    <>
      <div className="heading-base">Selected Transport Provider: </div>
      <div className="heading-lg">{providerName}</div>
    </>
  ) : (
    <div className="heading-base">Select Transport Provider: </div>
  );
}

function getTextColor(hasError: ProviderHeaderProps['hasError']) {
  return hasError ? `text-${TOKENS.FONT.ALERT}` : '';
}

export default function ProviderHeader({
  providerName = '',
  hasError = false
}: ProviderHeaderProps) {
  return (
    <div
      className={`reset-div border-1 rounded-[4px] shadow-b-wrap border-${TOKENS.BORDER.NEUTRAL_LT} `}
      style={{ padding: '8px 24px' }}
    >
      <div
        className={`flex flex-row nowrap gap-[4px] items-center h-[32px] py-[4px] ${getTextColor(hasError)}`}
      >
        {getIcon(providerName, hasError)}
        {getText(providerName)}
      </div>

      <NoSupplyAlerts alertType="warning" />
    </div>
  );
}
