import type { ChatRecord } from '~/types';
import React from 'react';
import { CHAT_CANCELLATION_REASON } from '~/constants';
import { isEmpty } from '@SRHealth/frontend-lib';
import { formatPhoneNumber } from '~/utilities/helperFunctions';
import SvgClose from '~/Shared/Components/Svgs/SvgClose';

type LiveChatHeaderProps = {
  selectedChat: ChatRecord;
  isCancelEnabled: boolean;
  isReassignEnabled: boolean;
  handleWindowToggle: () => void;
  handleCancelRequest: (requestId: string) => void;
  handleReassignRequest: (requestId: string) => void;
};

const VALID_STATUS = ['open', 'active'];

const LiveChatHeader = ({
  selectedChat,
  isCancelEnabled,
  isReassignEnabled,
  handleWindowToggle,
  handleCancelRequest,
  handleReassignRequest
}: LiveChatHeaderProps) => {
  const companyName = selectedChat?.rideDetails?.vehicleOwnerInfo?.companyName ?? '';
  const companyPhone = selectedChat?.rideDetails?.vehicleOwnerInfo?.mobileNo ?? '';
  const nemtResponderId = selectedChat?.nemtResponderId ?? 0;
  const nemtUser = selectedChat?.users?.[nemtResponderId] ?? {};
  const isNemtUser = !isEmpty(nemtUser);
  const status = selectedChat?.requestStatus ?? '';
  const cancellationReason = selectedChat?.cancellationReason ?? 0;
  const rideId = selectedChat?.rideDetails?.rideId ?? 0;

  const _isCancelEnabled =
    isCancelEnabled && VALID_STATUS.includes(status) && rideId === 0;
  let _isReassignEnabled =
    isReassignEnabled && VALID_STATUS.includes(status) && rideId === 0;

  // check if cancelled by NEMT (e.g. unable to fulfill) and enable
  if (
    status === 'cancelled' &&
    cancellationReason === CHAT_CANCELLATION_REASON.BY_NEMT_RESPONDER
  ) {
    _isReassignEnabled = true;
  }

  return (
    <div className="liveChatHeader">
      <div className="blueBar">
        <div className="left">
          <h3>{companyName}</h3>
          <h4>
            {isNemtUser ? (
              <span>
                {nemtUser.firstName} {nemtUser.lastName}
              </span>
            ) : null}

            {companyPhone ? (
              <sub>
                {isNemtUser ? ' - ' : null}
                {formatPhoneNumber(companyPhone)}
              </sub>
            ) : null}
          </h4>
        </div>
        <button className="minimizeChat" onClick={handleWindowToggle}>
          Close <SvgClose />
        </button>
      </div>
      <div className="actionBar">
        <button
          className="cancelChat"
          disabled={!_isCancelEnabled}
          onClick={() => handleCancelRequest(selectedChat.requestId)}
        >
          Cancel Request
        </button>
        <button
          className="reassignChat"
          disabled={!_isReassignEnabled}
          onClick={() => handleReassignRequest(selectedChat.requestId)}
        >
          Select Another NEMT
        </button>
      </div>
    </div>
  );
};

export default LiveChatHeader;
