import { Accordion } from '@SRHealth/frontend-lib';
import React from 'react';
import { getTripCardLabel } from '../RecurringRides.utils';
import TripCardSummary from './TripCardSummary';
import type { RideTime, RideType } from '~/models';

export type TripCardProps = {
  date: DateString;
  isActive: boolean;
  // isEditable?: boolean;
  onToggle: (val: DateString | null) => void;
  timeOne: RideTime;
  timeTwo: RideTime | undefined;
  timezone: string;
  initialRideType: RideType;
};

export default function TripCard({
  date,
  isActive,
  onToggle,
  timeOne,
  timeTwo,
  timezone,
  initialRideType
}: TripCardProps) {
  const handleToggle = () => (isActive ? onToggle(null) : onToggle(date!));

  return (
    <Accordion isOpen={isActive} onToggle={handleToggle} label={getTripCardLabel(date!)}>
      <TripCardSummary
        date={date}
        timeOne={timeOne}
        timeTwo={timeTwo}
        timezone={timezone}
        initialRideType={initialRideType}
      />
    </Accordion>
  );
}
