import React, { useEffect } from 'react';
import {
  Alert,
  Button,
  Icon,
  Input,
  isString,
  Link,
  TOKENS,
  useModel
} from '@SRHealth/frontend-lib';
import type { AddressModel, RideModel, RideTime, RideType } from '~/models';
import AddressForm from './AddressForm';
import Divider from './Divider';
import type { AddressFieldType } from '../Rides.types';
import { updateTimezoneOnRideChange } from '../Rides.utils';
import RidesTimePicker from './RidesTimePicker';
import { useAppSelector } from '~/Modules';
import type { DuplicateRide } from '~/Modules/rideBooking';
import {
  selectAddressTypes,
  selectIsProviderBlocked,
  selectLastRideTime,
  selectRemainingRidesAllowed
} from '~/Modules/rideBooking';
import AddressTray from './AddressTray';
import RideLayout from './RideLayout';
import { SavedLocationsProvider } from './SavedLocations.context';
import RideLimitWarning from './RideLimitWarning';
import RideMileageWarning from './RideMileageWarning';

export type RideFormProps = {
  ride: RideModel;
  isReturnRide: boolean;
  onFlipAddresses: () => void;
  onAddressChange: (type: AddressFieldType, address: AddressModel) => void;
  onTimeChange: (inputId: number, value: RideTime | undefined) => void;
  onReviewRide: () => void;
  onAddRide: () => void;
  onRemoveRide: () => void;
  onRideTypeChange: (newType: 'arriveBy' | 'departAt') => void;
  hardBlockDuplicateRides: DuplicateRide[] | [];
};

export default function RideForm({
  ride,
  isReturnRide,
  onFlipAddresses,
  onAddressChange,
  onTimeChange,
  onReviewRide,
  onAddRide,
  onRemoveRide,
  onRideTypeChange,
  hardBlockDuplicateRides
}: RideFormProps) {
  const { model } = useModel(ride);
  const date = useAppSelector(s => s.rideBooking.date.date);
  const addressTypes = useAppSelector(state => selectAddressTypes(state));
  const lastRideTime = useAppSelector(selectLastRideTime);
  const lastRideTimezone = useAppSelector(s => s.rideBooking.rides.at(-2)?.timezone);

  /** Figure out if we're hard blocked from reviewing the ride. */
  const isProviderBlocked = useAppSelector(s =>
    selectIsProviderBlocked(s, model.departAddress.type, model.arriveAddress.type)
  );

  const [remainingRidesAllowed, isHardBlock] = useAppSelector(
    selectRemainingRidesAllowed
  );

  const isReturnRideDisabled = remainingRidesAllowed <= 0 && isHardBlock;
  const isDisabled =
    isProviderBlocked === 'Hard' ||
    !!model.ruleErrors?.distance?.['mileage-hard-block'] ||
    hardBlockDuplicateRides.length > 0;

  // Get the timezone for the ride's address when the ride type or address changes.
  // If we don't have the corresponding address for the ride type, clear the timezone.
  useEffect(
    () => updateTimezoneOnRideChange(model, 'departAt'),
    [model.type, model.departAddress]
  );
  useEffect(
    () => updateTimezoneOnRideChange(model, 'arriveBy'),
    [model.type, model.arriveAddress]
  );
  // Remove mileage hard block when the address changes
  useEffect(() => {
    if (model.ruleErrors?.distance?.['mileage-hard-block']) {
      delete model.ruleErrors.distance?.['mileage-hard-block'];
    }
  }, [model.departAddress, model.arriveAddress]);

  return (
    <SavedLocationsProvider>
      <RideLayout label={isReturnRide ? 'Return Ride' : 'Initial Ride'}>
        <article
          className="reset-div py-[24px] px-[8px] flex flex-col gap-[8px] self-center"
          style={{ width: '436px' }}
        >
          <div className="flex flex-col gap-[16px] pb-[8px] relative">
            <div className="flex items-center gap-[4px]">
              <AddressForm
                type="depart"
                address={model.departAddress}
                addressTypes={addressTypes}
                onAddressChange={onAddressChange}
              />
            </div>
            <Icon
              type="SwitchDirections"
              onClick={onFlipAddresses}
              className={`relative w-[24px] h-[24px] fill-current cursor-pointer text-${TOKENS.COMPONENT.INPUT.SELECTED} hover:text-${TOKENS.COMPONENT.BUTTON.HOVER}`}
              style={{ left: 'calc(100% - 32px)', margin: '-8px 0 -32px' }}
            />
            <div className="flex items-center gap-[4px]">
              <AddressForm
                type="arrive"
                address={model.arriveAddress}
                addressTypes={addressTypes}
                onAddressChange={onAddressChange}
              />
            </div>

            {isProviderBlocked === 'Hard' && (
              <Alert
                type="error"
                label="At least one location must be a Care Provider."
              />
            )}

            {isString(model.ruleErrors?.distance?.['is-greater-than-0']) && (
              <Alert
                type="error"
                label={model.ruleErrors.distance['is-greater-than-0'] as string}
              />
            )}
          </div>
          <AddressTray addressTypes={addressTypes} onAddressChange={onAddressChange} />
          <Divider />
          <div className="flex flex-row">
            <div style={{ flex: '3', visibility: isReturnRide ? 'hidden' : 'visible' }}>
              <Input
                inputId={2}
                type="radio"
                name="type"
                label=" "
                options={[
                  { label: 'Arrive By', value: 'arriveBy' },
                  { label: 'Depart At', value: 'departAt' }
                ]}
                value={model.type}
                onChange={(_, value: RideType) => onRideTypeChange(value)}
                error={'type' in model.ruleErrors}
                className="flex-1"
              />
            </div>
            <div className="min-w-0" style={{ flex: '2' }}>
              <RidesTimePicker
                inputId={3}
                type={model.type}
                date={date}
                time={model.time}
                timezone={model.timezone}
                lastRideTime={lastRideTime}
                lastRideTimezone={lastRideTimezone}
                onChange={onTimeChange}
                error={'time' in model.ruleErrors}
                label={
                  model.type === 'arriveBy' ? 'Appointment Time' : 'Depart Pick Up Time'
                }
                isReturnRide={isReturnRide}
              />
            </div>
          </div>
          <Divider />
          <div className="flex flex-col">
            <Input
              inputId={4}
              type="textarea"
              name="notes"
              label="Ride Note"
              placeholder="Any additional message for the driver"
              caption="For this ride only"
              value={model.notes}
              onChange={(_, value: string) => (model.notes = value)}
              error={'notes' in model.ruleErrors}
            />
          </div>
        </article>

        <footer
          className="flex flex-col px-[8px] items-center gap-[8px] self-stretch"
          style={{ paddingBottom: '24px' }}
        >
          <Divider />
          <RideLimitWarning
            remainingRidesAllowed={remainingRidesAllowed}
            isHardBlock={isHardBlock}
            isInitialRide={!isReturnRide}
          />

          <RideMileageWarning rideModel={model} />

          {hardBlockDuplicateRides.length > 0 ? (
            <div style={{ width: '425px' }}>
              <Alert
                type="error"
                label={`The member has already booked a ride for this date, time and location. Ride ID: ${hardBlockDuplicateRides?.[0]?.id}`}
              />
            </div>
          ) : null}

          {!isReturnRide && (
            <>
              <div className={`text-sm-italic text-center text-${TOKENS.FONT.NEUTRAL}`}>
                Does the member need a return ride?
              </div>
              <Button
                label="Return Ride"
                iconLeft="AddCircleOutline"
                size="sm"
                onClick={onAddRide}
                disabled={isDisabled || isReturnRideDisabled}
                alt
              />
            </>
          )}

          {isReturnRide && (
            <div
              className="reset-div flex flex-row"
              style={{ width: '436px', justifyContent: 'flex-end' }}
            >
              <Link
                id="remove-ride"
                label="Remove Return"
                iconLeft="RemoveCircleOutline"
                size="sm"
                onClick={onRemoveRide}
              />
            </div>
          )}
        </footer>
      </RideLayout>

      <div className="pt-[12px] pb-[24px]">
        <Button
          label="Review Ride"
          size="sm"
          onClick={onReviewRide}
          disabled={isDisabled}
        />
      </div>
    </SavedLocationsProvider>
  );
}
