import { createAppAsyncThunk } from '~/Modules';
import { getDuplicateRides } from '~/services/rideBooking.service';
import { getTimeRestrictionsThunk } from './getTimeRestrictions.thunk';
import type { DuplicateRide } from '~/Modules/rideBooking';

export const completeDateThunk = createAppAsyncThunk(
  'rideBooking/completeDate',
  // eslint-disable-next-line @typescript-eslint/no-inferrable-types
  async (checkForDuplicates: boolean = true, { dispatch, getState, rejectWithValue }) => {
    const { passengerInfo, date: dateModel, rides } = getState().rideBooking;

    if (!passengerInfo.passengerId || !dateModel.date) {
      throw Error('passengerId and date are required to complete the Date section.');
    }

    let duplicateRides: DuplicateRide[] | null = null;
    // If we're checking for duplicates, do so and early return if any are found.
    if (checkForDuplicates && dateModel.date && dateModel.propertyIsDirty('date')) {
      duplicateRides = (
        await getDuplicateRides(passengerInfo.toJSON(), { date: dateModel.date })
      )?.data;

      // If duplicates exist, return them immediately without committing changes.
      if (duplicateRides?.length) {
        return rejectWithValue({ message: 'DuplicateRidesFound', duplicateRides });
      }
    }

    const modelWasDirty = dateModel.propertyIsDirty('date');

    await getState()
      .rideBooking.date.commit()
      .then(() => dispatch(getTimeRestrictionsThunk()))
      // Reset ride times only after the date has been successfully updated.
      .then(() => {
        if (modelWasDirty) rides.forEach(ride => (ride.time = undefined));
      });

    return duplicateRides;
  }
);
