import { Card } from '@SRHealth/frontend-lib';
import React from 'react';
import type { FundingSourceProps } from '~/models';
import { useAppDispatch, useAppSelector } from '~/Modules';
import { editFundingSource, selectActiveTreatment } from '~/Modules/rideBooking';
import type { DependentField as DependentFieldRecord } from '~/Modules/user';
import type { ComplianceRecord } from '~/types';
import { NOOP } from '~/utilities/helperFunctions';
import { crawlDependentFields } from '../FundingSource.utils';

type SummaryProps = {
  modelData: Readonly<FundingSourceProps>;
  /** The name of the facility selected. */
  facility?: string;
  /** A list of hospital network compliance fields that the passenger
   * must fill out. */
  complianceFields?: ComplianceRecord[];
  /** A list of hospital network dependent fields that the passenger
   * must fill out. */
  dependentFields?: DependentFieldRecord;
  /** A list of custom fields that the hospital requires. */
  hospitalCustomFields: BE.HospitalCustomField[];
};

export default function Summary({
  modelData,
  facility,
  dependentFields,
  complianceFields = [],
  hospitalCustomFields = []
}: SummaryProps) {
  const appDispatch = useAppDispatch();

  const activeTreatment = useAppSelector(selectActiveTreatment);

  const _complianceFields = complianceFields.reduce(
    (acc, field) => {
      if (field.input_type === 'text') {
        acc.push({ ...field, value: modelData.complianceInfo[field.id]! });
      } else if (field.input_type === 'select') {
        const modelVal = modelData.complianceInfo[field.id];

        acc.push({
          ...field,
          value: field.compliance_options.find(o => o.id === modelVal)?.option_value ?? ''
        });
      }

      return acc;
    },
    [] as (ComplianceRecord & { value: string | number | boolean | null })[]
  );

  const _dependentFields = crawlDependentFields(dependentFields!, modelData);

  const _hospitalCustomFields = hospitalCustomFields.reduce(
    (acc, field) => {
      acc.push({ ...field, value: modelData.hospitalCustomFields[field.id]! });

      return acc;
    },
    [] as (BE.HospitalCustomField & { value: string | number | boolean | null })[]
  );

  const hasSummaryData =
    activeTreatment ||
    _complianceFields.length ||
    _dependentFields.length ||
    _hospitalCustomFields.length;

  // If there's no data to show then just return null.
  if (!hasSummaryData) return null;

  return (
    <Card
      label="Trip Questions"
      editable={false}
      onEdit={() => appDispatch(editFundingSource())}
      onCancel={NOOP}
    >
      <div className="flex flex-col gap-[12px] text-sm">
        {facility && <SummaryLine label="Facility Service" value={facility} />}
        {activeTreatment && (
          <SummaryLine label="Treatment Type" value={activeTreatment?.treatment_name} />
        )}

        {_complianceFields.map(field => (
          <SummaryLine key={field.id} label={field.input_name} value={field.value} />
        ))}

        {_dependentFields.map(({ label, value }) => (
          <SummaryLine key={label} label={label} value={value} />
        ))}

        {_hospitalCustomFields.map(field => (
          <SummaryLine key={field.id} label={field.field_label} value={field.value} />
        ))}
      </div>
    </Card>
  );
}

type SummaryLineProps = {
  label: string;
  value: string | number | boolean | null;
};

function SummaryLine({ label, value }: SummaryLineProps) {
  return (
    <div>
      <span className="font-bold">{label}: </span>
      <span>{value}</span>
    </div>
  );
}
