import _ from 'lodash-es';
import PropTypes from 'prop-types';
import InputText from '~/Shared/Components/InputText';
import { connect } from 'react-redux';
import LoadingModal from '~/Shared/Components/LoadingModal';
import React from 'react';
import { bindActionCreators } from 'redux';
import { putMemberProfile, clearData } from '~/Modules/memberProfile/actions';
import { PERMISSIONS } from '~/Pages/MemberProfiles/MemberProfiles.constants';
import { Container } from '@material-ui/core';

class FreeForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isDirty: false
    };
  }

  componentDidUpdate(prevProps) {
    const { freeForm, success, error, editable, permission } = this.props;

    const state = {};

    if (!_.isEqual(prevProps.freeForm, freeForm) && _.isEmpty(prevProps.freeForm)) {
      Object.assign(state, this.initialize(), { showLoader: false });
    }

    if (!_.isEmpty(success) && _.isEmpty(prevProps.success)) {
      state.success = this.props.success.formType === 'freeform' ? success.message : '';
      state.showLoader = false;
    }

    if (!_.isEmpty(error) && _.isEmpty(prevProps.error)) {
      state.error = error;
      state.showLoader = false;

      // if it's an array then there are field validation
      if (Array.isArray(this.props.error)) {
        for (let i = 0; i < this.props.error.length; i++) {
          const errorObj = this.props.error[i];
          const key = Object.keys(errorObj)[0];
          state[`${key}Error`] = true;
          state[`${key}ErrorText`] = errorObj[key];
        }
      }
    }

    if (editable !== prevProps.editable || permission !== prevProps.permission) {
      const permission = Object.keys(this.props.freeForm).length
        ? this.props.permission
        : 'view';

      this.props.setEditButton(
        <FreeFormEditButton
          permission={permission}
          editable={this.props.editable}
          handleEdit={this.handleEdit}
          handleCancel={this.handleCancel}
          handleSave={this.handleSave}
        />
      );
    }

    if (Object.keys(state).length) {
      this.setState(state, () => {
        this.props.setIsChildDataLoading(state.showLoader);
        if (this.state.error !== state.error) {
          this.props.setErrorMsg(state.error);
        }
      });
    }
  }

  componentDidMount() {
    const state = this.initialize();

    this.setState(state, () => {
      const permission = Object.keys(this.props.freeForm).length
        ? this.props.permission
        : 'view';

      this.props.setIsChildDataLoading(state.showLoader);

      this.props.setEditButton(
        <FreeFormEditButton
          permission={permission}
          editable={this.props.editable}
          handleEdit={this.handleEdit}
          handleCancel={this.handleCancel}
          handleSave={this.handleSave}
        />
      );
    });
  }

  componentWillUnmount() {
    this.props.clearData();
    this.props.cancel();
  }

  /**
   * Initializes an object to be used as a component state.
   * @returns {Object} components new state
   * @memberof Notes
   */
  initialize = () => {
    const initialState = Object.assign({}, _.cloneDeep(this.props.freeForm), {
      success: '',
      error: '',
      showLoader: false,
      isDirty: false
    });
    return initialState;
  };

  /**
   * Event handler for clicking the cancel link on the form - resets form to redux state
   * @param {event} e The event for the click
   * @returns {undefined} nothing
   * @memberof FreeForm
   */
  handleCancel = e => {
    const state = { ...this.initialize(), showLoader: false };

    this.setState(state, () => {
      this.props.cancel(e);
      this.props.setIsChildDataLoading(state.showLoader);
    });
  };

  /**
   * edit member profile
   * @return {undefined}
   */
  handleEdit = () => {
    this.setState({ success: '' }, this.props.edit);
  };

  /**
   * Validates, then gathers all forms values and dispatches action to save to api
   * @return {undefined} nothing
   * @memberof FreeForm
   */
  handleSave = () => {
    const memberProfile = {};

    for (const key in this.state) {
      const stateProp = this.state[key];

      if (typeof stateProp === 'object' && 'value' in stateProp) {
        memberProfile[key] = stateProp.value;
      }
    }

    const newState = {
      success: '',
      error: '',
      showLoader: true,
      isDirty: false
    };

    this.setState(newState, () => {
      this.props.clearData();
      this.props.setIsChildDataLoading(newState.showLoader);
      this.props.putMemberProfile({
        memberProfile,
        passengerId: this.props.selectedId,
        formType: 'freeform'
      });
      this.props.cancel();

      if (this.state.error !== newState.error) {
        this.props.setErrorMsg(newState.error);
      }
    });
  };

  /**
   * Handler for changes to a text form
   * @param {event} e The typed change event
   * @param {string} id The form element that was changed
   * @return {undefined} nothing
   */
  handleText = (e, id) => {
    this.setState({
      [id]: { value: e.target.value },
      isDirty: true
    });
  };

  /**
   * render form
   * @return {jsx} returns jsx.
   */
  render() {
    const { showLoader } = this.state;
    const { freeForm, editable } = this.props;
    return (
      <Container className="parentTab customfields">
        <div className="childTab freeform">
          <>
            {Object.keys(freeForm).length === 0 ? (
              <div className="memberRow">
                <p>There are no free form custom fields</p>
              </div>
            ) : (
              Object.keys(freeForm).map(slug => (
                <div className="memberRow" key={slug}>
                  <InputText
                    customClassName="fullColumn"
                    placeholder=""
                    fieldName={slug}
                    label={freeForm[slug].label}
                    showLabel={true}
                    disabled={!editable}
                    value={_.get(this.state, `${slug}.value`, '')}
                    inputId={slug}
                    error={_.get(this.state, `${slug}Error`, false)}
                    errorText={_.get(this.state, `${slug}ErrorText`, '')}
                    onChange={e => this.handleText(e, slug)}
                  />
                </div>
              ))
            )}
          </>
          {showLoader && (
            <LoadingModal label="Saving Member Free Forms..." isOpen={true} />
          )}
        </div>
      </Container>
    );
  }
}

const FreeFormEditButton = ({
  editable,
  permission,
  handleEdit,
  handleSave,
  handleCancel
}) => {
  return (
    <>
      {' '}
      {permission === PERMISSIONS.EDIT || permission === PERMISSIONS.CREATE_EDIT ? (
        <>
          {editable ? (
            <>
              <a onClick={handleCancel}>Cancel</a>
              <a onClick={handleSave}>Save</a>
            </>
          ) : (
            <a onClick={handleEdit}>Edit</a>
          )}
        </>
      ) : null}
    </>
  );
};

FreeForm.propTypes = {
  user: PropTypes.object,
  freeForm: PropTypes.object,
  selectedId: PropTypes.string,
  editable: PropTypes.bool,
  edit: PropTypes.func,
  cancel: PropTypes.func,
  permission: PropTypes.string,
  setIsChildDataLoading: PropTypes.func,
  putMemberProfile: PropTypes.func,
  clearData: PropTypes.func
};

FreeForm.defaultProps = {
  user: {},
  freeForm: {},
  selectedId: null,
  editable: false,
  edit: () => {},
  cancel: () => {},
  permission: 'view',
  setIsChildDataLoading: () => {},
  putMemberProfile: () => {},
  clearData: () => {}
};

const mapStateToProps = state => ({
  user: state.user,
  freeForm: _.get(state, 'memberProfile.formData.freeForm', {}),
  error: state.memberProfile.error,
  success: state.memberProfile.success
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      putMemberProfile: data => putMemberProfile(data),
      clearData: () => clearData()
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(FreeForm);
