import { createSelector } from '@reduxjs/toolkit';
import type { AppStore } from '~/types';
import type { BenefitsUsageAndLimits } from '../RideBooking.types';
import { selectActiveTreatment } from './selectActiveTreatment';

const selectBenefitsUsage = (state: AppStore) =>
  state.memberProfile.formData.benefits.benefitsUsage;

const selectBenefitLimits = (state: AppStore) => state.memberProfile.benefitLimits;

/**
 * Builds the benefits usage and limits object based on the benefit usage and limits data.
 * Yes, all this data exists in the store, but this function coheres it instead of needing to know where to find the
 * hardblock, limit, how to distinguish between a benefit category limit and a non-benefit category limit, etc
 * for every single benefit.
 *
 * If the passenger is on a health plan with treatments enabled then the benefits usage
 * and limit will only return benefit categories associated with the treatment.
 */
export const selectBenefitsUsageAndLimits = createSelector(
  [selectBenefitsUsage, selectBenefitLimits, selectActiveTreatment],
  (benefitsUsage, benefitLimits, activeTreatment): BenefitsUsageAndLimits => {
    const benefitsUsageAndLimits: BenefitsUsageAndLimits = {
      all: {
        ridesPerYear: {
          usage: benefitsUsage.all.ridesUsedByYear,
          limit: benefitLimits.rides_per_year?.blockLimits?.value ?? null,
          isHardBlock: benefitLimits.rides_per_year?.blockLimits?.bookAfterBlock === 0
        },
        ridesPerMonth: {
          usage: benefitsUsage.all.ridesUsedByMonth,
          limit: benefitLimits.rides_per_month?.blockLimits?.value ?? null,
          isHardBlock: benefitLimits.rides_per_month?.blockLimits?.bookAfterBlock === 0
        },
        costPerYear: {
          usage: benefitsUsage.all.costUsedByYear,
          limit: benefitLimits.cost_per_year?.blockLimits?.value ?? null,
          isHardBlock: benefitLimits.cost_per_year?.blockLimits?.bookAfterBlock === 0
        }
      }
    };

    const activeTreatmentBenefitCategoryIds = JSON.parse(
      activeTreatment?.benefitCategoryIds ?? '[]'
    );

    // Filter the benefit category limits to just the relevant ones (the health plan often can have multiple benefit categories, but we only care about the ones associated with the active treatment)
    const activeTreatmentBenefitCategoryLimits = Object.values(benefitLimits).filter(
      benefitLimit =>
        benefitLimit.benefitCategoryId &&
        activeTreatmentBenefitCategoryIds.includes(benefitLimit.benefitCategoryId)
    );

    for (const activeTreatmentBenefitCategoryLimit of activeTreatmentBenefitCategoryLimits) {
      const benefitKey =
        `benefit-category-${activeTreatmentBenefitCategoryLimit.benefitCategoryId}` as `benefit-category-${number}`;
      const usage = benefitsUsage[benefitKey]?.ridesUsedByYear || {};

      benefitsUsageAndLimits[benefitKey] = {
        ridesPerYear: {
          usage,
          limit: activeTreatmentBenefitCategoryLimit.blockLimits?.value ?? null,
          isHardBlock:
            activeTreatmentBenefitCategoryLimit.blockLimits?.bookAfterBlock === 0
        }
      };
    }

    return benefitsUsageAndLimits;
  }
);
