import React from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';
import _, { cloneDeep } from 'lodash-es';
import { getRides, getAllRides, resetCsv, clearRides } from '~/Modules/rideHistory';
import { getRide, clearRide } from '~/Modules/rideDetails';
import PageFrame from '~/Shared/Components/PageFrame/PageFrame';
import CalendarRange from '~/Shared/Components/Calendar/CalendarRange';
import RowPagination from '~/Shared/Components/RowPagination';
import Row from './Components/Row';
import Sort from './Components/Sort';
import Search from '~/Shared/Components/Search';
import { DEFAULT_RIDE_FIELDS, DATE_TEXT_FORMAT, DAY_START, DAY_END } from '~/constants';
import { toCSV } from '~/utilities/csvHelpers';
import { laravelUrl } from '~/utilities/helperFunctions';
import { fixUTCDate } from '~/utilities/timesAndDates';
import LoadingModal from '~/Shared/Components/LoadingModal';
import SvgArrow from '~/Shared/Components/Svgs/SvgArrow';
import SvgInsertInvitation from '~/Shared/Components/Svgs/SvgInsertInvitation';
import SvgDownload from '~/Shared/Components/Svgs/SvgDownload';
import moment from 'moment';
import RideDetails from '~/Pages/RideDetails/RideDetails';
import DownloadJs from 'downloadjs';
import Loading from 'react-loading';
import { MapboxMap, svgs } from 'mapbox-components';
import { removeDiacritics } from '~/utilities/removeDiacritics';

class RideHistory extends React.Component {
  /**
   * constructor
   * @param {object} props, list of props passed down from RideReport.js
   * @return {undefined} returns nothing
   */
  constructor(props) {
    super(props);

    this.state = {
      params: DEFAULT_RIDE_FIELDS,
      showDialog: false,
      showModal: false,
      openCalendar: false,
      startDate: DAY_START,
      endDate: DAY_END,
      showRideDetails: false,
      medicalId: null,
      hospitalId: null,
      redirect: false,
      dateOfBirth: '',
      healthPlanId: null,
      healthSubPlanId: null
    };
  }

  /**
   * initialize app with data, now we no longer have to squeeze in intial state
   * @return {undefined} returns nada
   */
  componentDidMount() {
    // eslint-disable-next-line no-undef
    const params = new URL(document.location).searchParams;
    const rideFields = {
      ...DEFAULT_RIDE_FIELDS
    };

    if (params.get('id') && params.get('toDate')) {
      rideFields.query = params.get('id');
      rideFields.startLimit = 0;
      rideFields.toDate = params.get('toDate');
    }
    this.processGetRides(rideFields);

    window.addEventListener('keydown', this.handleKeyDown.bind(this));
  }

  /**
   * check to see if we want to perform a state update
   * @param {object} prevProps - the previous props before the update
   * @return {undefined} returns nada
   */
  componentDidUpdate(prevProps) {
    if (!_.isEqual(prevProps, this.props)) {
      this.setState({ showDialog: false });
    }
    if (!_.isEmpty(this.props.csvRows)) {
      this.downloadCSV(this.props.csvRows);
      this.props.resetCsv();
    }
  }

  /**
   * clear rides from redux when exiting page
   * @return {undefined} returns nada
   */
  componentWillUnmount() {
    this.props.clearRides();
    window.removeEventListener('keydown', this.handleKeyDown.bind(this));
  }

  /**
   * Handles key down events
   * @param {Object} e SyntheticEvent
   * @returns {undefined}
   */
  handleKeyDown(e) {
    const ESC_KEY = 27;
    if (e.keyCode === ESC_KEY) {
      this.hideRideDetails();
    }
  }

  /**
   * parse into csv and trigger download using downloadjs library
   * downloadjs must be in package.json or this won't work!
   * @param {array} rows - array of objects of rows for forming csv
   * @returns {undefined}
   */
  downloadCSV(rows) {
    //fix date for rows
    const myRows = cloneDeep(rows);

    for (let i = 0; i < myRows.length; i++) {
      myRows[i].pickupDate = fixUTCDate(
        myRows[i].pickupDate,
        this.props.user.userData.timezone_format,
        'YYYY-MM-DD HH:mm:ss'
      );
      myRows[i].pickupAddress = removeDiacritics(myRows[i].pickupAddress);
      myRows[i].dropoffAddress = removeDiacritics(myRows[i].dropoffAddress);
    }
    DownloadJs(toCSV(myRows), 'ridereport.csv', 'text/csv');
  }

  /**
   * call dispatch to get all rows for csv
   * @return {undefined} returns nothing
   */
  getCSV = () => {
    const csvParams = {
      fromDate: this.state.params.fromDate,
      toDate: this.state.params.toDate,
      orderType: this.state.params.orderType,
      orderBy: this.state.params.orderBy,
      hospitalId: this.state.params.hospitalId,
      limit: 500 // Added in ENG-3601
    };

    const queryVal = _.get(this.state.params, 'query', false);

    if (queryVal !== false) {
      csvParams.query = queryVal;
    }

    this.props.getAllRides(csvParams);
  };

  /**
   * function to return redux action that grabs rides
   * passed down to child components as a prop
   * @param {object} data, parameters for request
   * @param {boolean} showDialog - show dialog or no
   * @return {function} return redux action
   */
  processGetRides = (data, showDialog = true) => {
    if (_.has(data, 'query') && data.query === '') {
      _.unset(data, 'query');
    }

    this.setState({ params: data, showDialog });

    return this.props.getRides(data);
  };

  /**
   * function to return redux action that grabs rides
   * also adds search parameter
   * @param {object} data, parameters for request
   * @return {function} return redux action
   */
  processSearch = data => {
    this.processGetRides(data);
  };

  /**
   * choose date range for ride history report
   * @param {object} data - params from calendar picker
   * @return {undefined}
   */
  processDate = data => {
    const params = cloneDeep(this.state.params);
    params.fromDate = data.startDate;
    params.toDate = data.endDate;
    this.setState({ startDate: data.startDate, endDate: data.endDate });
    this.processGetRides(params);
  };

  /**
   * open and close modal
   * @return {undefined} returns nothing.
   */
  handleClick = () => {
    if (!this.state.showModal) {
      document.addEventListener('click', this.handleOutsideClick, false);
    } else {
      document.removeEventListener('click', this.handleOutsideClick, false);
    }

    this.setState(prevState => ({
      showModal: !prevState.showModal
    }));
  };

  /**
   * close modal by clicking outside of modal
   * @param {object} e - event
   * @returns {undefined} returns nothing.
   */
  handleOutsideClick = e => {
    if (this.node === null) {
      return;
    }
    if (this.node.contains(e.target)) {
      return;
    }

    this.handleClick();
  };

  /**
   * open DayPicker calendar
   * @return {undefined}
   */
  openCalendar = () => {
    this.setState({ openCalendar: true });
  };

  /**
   * close DayPicker calendar
   * @return {undefined}
   */
  closeModal = () => {
    this.setState({ openCalendar: false });
  };

  /**
   * opens ride details modal
   * @param {object} ride - ride
   * @returns {undefined} returns nothing.
   */
  showRideDetails = ride => {
    this.setState(
      {
        showRideDetails: true,
        ride
      },
      () => {
        this.props.getRide(ride.id);
      }
    );
  };

  /**
   * Hide ride details modal
   * @returns {undefined}
   */
  hideRideDetails = () => {
    this.setState({ showRideDetails: false }, () => {
      this.props.clearRide();
    });
  };

  /**
   * ends ride booking process. Hide scheduled rides and header and replace with ride booking form
   * @return {undefined} - return nothing
   */
  closeRequestNewRide = () => {
    this.setState({
      showRideDetails: false
    });
  };

  /**
   * rebook inside ride details report
   * @param {string} medicalId - medical id
   * @param {integer} hospitalId - hospital id
   * @param {integer} originalRideId - ride id
   * @param {integer} healthPlanId - health paln id
   * @param {integer} dateOfBirth - date of birth
   * @param {boolean} redirect - redirect or not
   * @param {integer} healthSubPlanId - health sub plan id
   * @return {undefined}
   */
  rebook = (
    medicalId,
    hospitalId,
    originalRideId,
    healthPlanId,
    dateOfBirth,
    redirect,
    healthSubPlanId
  ) => {
    this.setState({
      medicalId,
      hospitalId,
      healthPlanId,
      dateOfBirth,
      redirect: true, // here we need to set redirect to true so that we can redirect to the booking page
      healthSubPlanId
    });
  };

  /**
   * render modal and calendar
   * @return {jsx} returns jsx.
   *
   */
  render() {
    const rideDetails = this.props.rideDetails;

    return (
      <div className="ScheduledRides">
        <PageFrame>
          <section className="RideHistory">
            <section className="reportHeading">
              <div className="searchColumn">
                <div className="calendarField" onClick={this.openCalendar}>
                  <SvgInsertInvitation className="whiteCalendar" />
                  <span className="dateContent">
                    {moment(this.state.startDate).format(DATE_TEXT_FORMAT)} to{' '}
                    {moment(this.state.endDate).format('MMM D')}
                  </span>
                  <SvgArrow className="whiteDownArrow" />
                </div>
                {this.state.openCalendar ? (
                  <CalendarRange
                    closeCalendar={this.closeModal}
                    openCalendar={this.state.openCalendar}
                    startDate={this.state.startDate}
                    endDate={this.state.endDate}
                    disabledDays={[]}
                    submitModal={this.processDate}
                  />
                ) : null}
                <Search
                  getRows={this.processSearch}
                  placeHolder="Search by Name, Pass / Ride ID"
                  defaultFields={this.state.params}
                  charactersMin={3}
                  className="ridesSearch"
                />
              </div>
              <div className="downloadColumn">
                <a className="exportLink" onClick={this.handleClick}>
                  Download <SvgDownload className="whiteDownArrow" />
                </a>
                <ul
                  className={`exportDropDown${this.state.showModal ? ' show' : ''}`}
                  ref={node => (this.node = node)}
                >
                  <li>
                    <a className="exportButton" onClick={this.getCSV}>
                      Export Rides
                    </a>
                  </li>
                  {this.props.user?.userData?.role &&
                  this.props.user.userData.role !== 'CaseManager' &&
                  this.props.user.userData.role !== 'HealthPlanOwner' ? (
                      <li>
                        <a href={laravelUrl('/vehicle/export-rides')}>Custom Export</a>
                      </li>
                    ) : null}
                </ul>
              </div>
            </section>
            <section className="reportCont">
              {this.props.errorMessage ? (
                <p className="errorMessage">{this.props.errorMessage}</p>
              ) : (
                <table>
                  <thead>
                    <tr role="row">
                      <th>
                        <Sort
                          title="Ride Id"
                          getRows={this.processGetRides}
                          defaultFields={this.state.params}
                          orderByField="id"
                        />
                      </th>
                      <th>
                        <Sort
                          title="Sub Plan"
                          getRows={this.processGetRides}
                          defaultFields={this.state.params}
                          orderByField="healthSubPlanName"
                        />
                      </th>
                      <th>
                        <Sort
                          title="Pickup Date"
                          getRows={this.processGetRides}
                          defaultFields={this.state.params}
                          orderByField="pickupDate"
                        />
                      </th>
                      <th>
                        <Sort
                          title="Status"
                          getRows={this.processGetRides}
                          defaultFields={this.state.params}
                          orderByField="status"
                        />
                      </th>
                      <th>
                        <Sort
                          title="Passenger Name"
                          getRows={this.processGetRides}
                          defaultFields={this.state.params}
                          orderByField="passengerName"
                        />
                      </th>
                      <th>
                        <Sort
                          title="Medical ID"
                          getRows={this.processGetRides}
                          defaultFields={this.state.params}
                          orderByField="medicalId"
                        />
                      </th>
                      <th>
                        <Sort
                          title="Transport Type"
                          getRows={this.processGetRides}
                          defaultFields={this.state.params}
                          orderByField="transportType"
                        />
                      </th>
                      <th>Total Supplier Cost</th>
                      <th>
                        <Sort
                          title="Billable Distance"
                          getRows={this.processGetRides}
                          defaultFields={this.state.params}
                          orderByField="distance"
                        />
                      </th>
                      <th>Pickup Address</th>
                      <th>Drop-off Address</th>
                    </tr>
                  </thead>
                  <tbody>
                    {this.props.rows.map((rows, i) => {
                      return (
                        <Row
                          rowData={rows}
                          key={i}
                          showCost={this.props.user.userData.showCost}
                          showRideDetails={this.showRideDetails}
                        />
                      );
                    })}
                  </tbody>
                </table>
              )}
            </section>
            <RowPagination
              getRows={this.processGetRides}
              defaultFields={this.state.params}
              totalCount={this.props.totalCount}
              rowsReturned={this.props.rowsReturned}
              begin={this.props.begin}
              rowsPerPage={this.props.maxRowsReturned}
            />
          </section>
          {this.state.showDialog ? (
            <LoadingModal isOpen={this.state.showDialog} closeTimeout={60000} />
          ) : null}
          {this.state.showRideDetails ? (
            <div className="rideDetailsContainer" onClick={() => this.hideRideDetails()}>
              <div
                className="rideDetailsWrapper"
                style={{
                  width:
                    rideDetails.ride &&
                    rideDetails.ride.data &&
                    rideDetails.ride.data.ride.mode === 'Public'
                      ? '67%'
                      : '80%'
                }}
                onClick={e => e.stopPropagation()}
              >
                {_.isEmpty(this.props.rideDetails) ? (
                  <Loading type="spin" className="rideDetailsLoading" />
                ) : (
                  <RideDetails
                    vehicleTypes={this.props.user.vehicleTypes}
                    rideData={this.props.rideDetails.ride.data}
                    mapboxAccessToken={process.env.REACT_APP_MAPBOX_TOKEN}
                    downloadUrl={`//${process.env.REACT_APP_API}/ride/report/${this.state.ride.id}?download=pdf`}
                    showColumnHeader={true}
                    handleClose={() => this.hideRideDetails()}
                    directions={this.props.directions}
                    canRebook={true}
                    MapboxMap={MapboxMap}
                    svgs={svgs}
                    features={this.props.user.features}
                    rebookCallback={this.rebook}
                  />
                )}
              </div>
            </div>
          ) : null}
          {this.state.redirect ? (
            <Redirect
              to={{
                pathname: '/ride/scheduled',
                state: {
                  medicalId: this.state.medicalId,
                  hospitalId: this.state.hospitalId,
                  originalRideId: this.state.ride.id,
                  healthPlanId: this.state.healthPlanId,
                  dateOfBirth: this.state.dateOfBirth,
                  healthSubPlanId: this.state.healthSubPlanId
                }
              }}
            />
          ) : null}
        </PageFrame>
      </div>
    );
  }
}

RideHistory.propTypes = {
  user: PropTypes.object,
  reportType: PropTypes.string,
  totalCount: PropTypes.number,
  rowsReturned: PropTypes.number,
  rows: PropTypes.array,
  getRides: PropTypes.func,
  begin: PropTypes.number,
  maxRowsReturned: PropTypes.number,
  errorMessage: PropTypes.string,
  resetCsv: PropTypes.func,
  csvRows: PropTypes.array,
  clearRides: PropTypes.func,
  getAllRides: PropTypes.func,
  rideDetails: PropTypes.object,
  getRide: PropTypes.func,
  clearRide: PropTypes.func,
  directions: PropTypes.object
};

RideHistory.defaultProps = {
  reportType: 'Ride History',
  totalCount: 0,
  rowsReturned: 0,
  rows: [],
  getRides: {},
  maxRowsReturned: 0,
  begin: 0,
  errorMessage: '',
  user: {},
  resetCsv: () => {},
  clearRides: () => {},
  csvRows: [],
  getAllRides: () => {},
  rideDetails: {},
  getRide: () => {},
  clearRide: () => {},
  directions: {}
};

const mapStateToProps = state => ({
  reportType: state.rideHistory.reportType,
  totalCount: state.rideHistory.totalCount,
  rowsReturned: state.rideHistory.rowsReturned,
  rows: state.rideHistory.rows,
  csvRows: state.rideHistory.csvRows,
  begin: state.rideHistory.begin,
  maxRowsReturned: state.rideHistory.maxRowsReturned,
  errorMessage: state.rideHistory.errorMessage,
  error: state.rideHistory.error,
  params: state.rideHistory.params,
  user: state.user,
  hospitalData: state.user.hospitalData,
  hospitalUsers: state.user.hospitalUsers,
  nodeUserType: state.user.nodeUserType,
  hospitalNames: state.user.hospitalNames,
  rideDetails: state.rideDetails,
  directions: state.directions
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      getRides: data => getRides(data),
      getAllRides: data => getAllRides(data),
      resetCsv: () => resetCsv(),
      clearRides: () => clearRides(),
      getRide: data => getRide(data),
      clearRide: data => clearRide(data)
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(RideHistory);
