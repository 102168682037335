import type {
  ProviderNemtModel,
  ProviderPublicModel,
  ProviderRideshareModel
} from '~/models';
import { createAppAsyncThunk } from '~/Modules';
import { book } from '~/services/rideBooking.service';

export const completeTransportProviderThunk = createAppAsyncThunk(
  'rideBooking/completeTransportProvider',
  async (_, { getState }) => {
    const transportProvider = getState().rideBooking.transportProvider;
    await transportProvider.commit();
    const transportProviderId = (
      transportProvider as ProviderRideshareModel | ProviderNemtModel
    ).id;

    const publicCards = (transportProvider as ProviderPublicModel)?.cards?.map(card =>
      card.toJSON()
    );

    const rideRequestId = getState().rideBooking.meta.rideRequestId;

    const { rideIds } = await book(rideRequestId, transportProviderId, publicCards);
    return { rideIds };
  }
);
