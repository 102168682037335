import React from 'react';
import { Icon, Input, TOKENS, useModel } from '@SRHealth/frontend-lib';
import type { PublicCardModel } from '~/models';
import type {
  PublicCardNameOption,
  PublicCardQuantityOption
} from '~/Pages/RideBooking/Hooks/usePublic';

type PublicCardProps = {
  cardModel: PublicCardModel;
  nameOptions: PublicCardNameOption[];
  quantityOptions: PublicCardQuantityOption[];
  onCardDelete?: () => void;
};

export default function PublicCard({
  cardModel,
  nameOptions,
  quantityOptions,
  onCardDelete
}: PublicCardProps) {
  const { model } = useModel(cardModel);

  return (
    <div
      className={
        `flex w-full gap-[16px] py-[16px] ` +
        `border-b-1 border-b-solid border-${TOKENS.BORDER.NEUTRAL_LT}`
      }
    >
      <Input
        name="name"
        inputId={0}
        type="select"
        label="Card type"
        options={nameOptions}
        value={model.name}
        placeholder="Select One"
        style={{ width: '50%' }}
        onChange={(_, val: string) => {
          const selectedOption = nameOptions.find(opt => opt.value === val);
          model.name = val;
          model.typeId = selectedOption!.id;
        }}
        error={'name' in model.ruleErrors}
        required
      />

      <Input
        name="cost"
        inputId={1}
        type="single"
        label="Cost"
        value={`$${model.cost}`}
        placeholder="$0.00"
        style={{ width: '20%' }}
        onChange={(_, val: string) => {
          // Remove the dollar sign if it exists
          model.cost = val.replace('$', '');
        }}
        error={'cost' in model.ruleErrors}
        onBlur={() => {
          // Convert to number, make sure it's positive
          const numValue = Math.abs(parseFloat(model.cost) || 0);
          // format to 2 decimal places
          model.cost = numValue.toFixed(2);
        }}
        required
      />

      <Input
        name="quantity"
        inputId={2}
        type="select"
        label="Quantity"
        options={quantityOptions}
        value={model.quantity}
        placeholder="1"
        style={{ width: '20%' }}
        onChange={(_, val: number) => (model.quantity = val)}
        error={'quantity' in model.ruleErrors}
        required
      />

      {onCardDelete && (
        <Icon
          type="Close"
          className={`cursor-pointer fill-current w-[24px] text-${TOKENS.ICON.ACTIVE}`}
          onClick={onCardDelete}
        />
      )}
    </div>
  );
}
