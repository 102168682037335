import React from 'react';
import { Alert } from '@SRHealth/frontend-lib';
import { useAppSelector } from '~/Modules';

export type NoSupplyAlertsProps = {
  alertType?: 'warning' | 'error';
};

export default function NoSupplyAlerts({ alertType = 'warning' }: NoSupplyAlertsProps) {
  const noSupplies = useAppSelector(s => s.rideBooking.meta.noSupplies);

  const noSupplyEntries = Object.entries(noSupplies);
  if (noSupplyEntries.length === 0) {
    return null;
  }
  return (
    <div className="flex flex-col gap-[12px]" style={{ paddingBottom: '4px' }}>
      {noSupplyEntries.map(([transportProvider, errors], index) => {
        if (!errors.length) return null;
        const providerName = `${transportProvider.charAt(0).toUpperCase()}${transportProvider.substring(1)}`;
        return (
          <Alert
            key={index}
            type={alertType}
            label={`${providerName} cannot be booked. Reason: ${errors[0]}`}
          />
        );
      })}
    </div>
  );
}
