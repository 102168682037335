import type { UserStore } from '~/Modules/user';
import type { ChatRecord } from '~/types';
import React from 'react';
import { CHAT_CANCELLATION_REASON } from '~/constants';

type LiveChatConversationListItemProps = {
  chat: ChatRecord;
  user: UserStore;
  selectedRequestId: string;
  // Yeah... this isn't great but the chat redux module doesn't have any types
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  setSelectedRequest: (params: any) => void;
};

const LiveChatConversationListItem = ({
  chat,
  user,
  selectedRequestId,
  setSelectedRequest
}: LiveChatConversationListItemProps) => {
  const rideDetails = chat.rideDetails || {};
  const messages = chat.messages || [];
  const userId = user?.userData?.id ?? 0;
  const messageCount = messages.reduce((count, message) => {
    const reader = message.readers.find(reader => reader.id === userId);

    // increment if not a listed reader and not the original sender
    return !reader && message.senderId !== userId ? count + 1 : count;
  }, 0);

  const classNames = [chat.requestStatus.toLowerCase()];

  if (chat.requestId === selectedRequestId) {
    classNames.push('selected');
  }

  // add unableToFulfill
  if (
    chat.requestStatus === 'cancelled' &&
    chat.cancellationReason === CHAT_CANCELLATION_REASON.BY_NEMT_RESPONDER
  ) {
    classNames.push('unableToFulfill');
  }

  return (
    <li
      className={classNames.join(' ')}
      onClick={() => setSelectedRequest({ requestId: chat.requestId })}
    >
      {rideDetails.patientName} {rideDetails.patientLastName}
      {messageCount > 0 ? <sup>{messageCount}</sup> : null}
    </li>
  );
};

export default LiveChatConversationListItem;
