/** Contains the standardized messaging specific to just the Rides section. */
export const RIDES_TXT = Object.freeze({
  /** The warning displayed when a user books a ride with an unapproved provider. */
  UNAPPROVED_PROVIDER_WARNING:
    'The Care Provider is unapproved. This trip is subject to review and may be declined by your insurance.',
  /** Hard block blocking warning message when a member has reached their ride limit for return rides */
  RETURN_RIDE_HARD_BLOCK: 'Cannot book return ride. Ride limit has been reached.',
  /** Soft block warning message when a ride exceeds the member's ride limit */
  // eslint-disable-next-line quotes
  RIDE_LIMIT_EXCEEDED_SOFT_BLOCK: "This ride exceeds the member's ride limit."
} as const);
