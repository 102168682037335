import { createSelector } from '@reduxjs/toolkit';
import type { AppStore } from '~/types';
import { selectActiveBenefitCategoryIds } from './selectActiveBenefitCategoryIds';
import {
  addProposedRidesToBenefitsUsage,
  findMostRelevantBenefitRemaining,
  getBenefitsUnderRemainingThreshold
} from '../RideBooking.utils';
import { selectIgnoreBenefitLimits } from './selectIgnoreBenefitLimits';
import { selectBenefitsUsageAndLimits } from './selectBenefitsUsageAndLimits';
import { selectTreatAllBlocksAsSoft } from './selectTreatAllBlocksAsSoft';

const selectDate = (s: AppStore) => s.rideBooking.date.date;
const selectRides = (s: AppStore) => s.rideBooking.rides;

/** Returns the remaining rides allowed for the member and whether the ride limit is a hard block or not. */
export const selectRemainingRidesAllowed = createSelector(
  [
    selectDate,
    selectBenefitsUsageAndLimits,
    selectActiveBenefitCategoryIds,
    selectRides,
    selectIgnoreBenefitLimits,
    selectTreatAllBlocksAsSoft
  ],
  (
    date,
    benefitsUsageAndLimits,
    benefitCategoryIds,
    rides,
    ignoreBenefitLimits,
    treatAllBlocksAsSoftBlocks
  ): [number, boolean] => {
    if (ignoreBenefitLimits) {
      return [Infinity, false];
    }

    const dates = rides.length === 2 ? [date!, date!] : [date!];
    /** Setting the default values for the remaining rides and hard block */
    let currentRemainingRides = Infinity;
    let currentHardBlock = false;

    const projectedBenefitsUsageAndLimits = addProposedRidesToBenefitsUsage(
      benefitsUsageAndLimits,
      dates,
      benefitCategoryIds
    );

    /** Getting the benefits that have less than 1 projected remaining rides */
    const projectedBenefitsRemaining = getBenefitsUnderRemainingThreshold(
      1,
      projectedBenefitsUsageAndLimits,
      treatAllBlocksAsSoftBlocks
    );

    const mostRelevantBenefit = findMostRelevantBenefitRemaining(
      projectedBenefitsRemaining,
      date
    );

    if (mostRelevantBenefit) {
      currentRemainingRides = mostRelevantBenefit.remaining;
      currentHardBlock = mostRelevantBenefit.isHardBlock;
    }

    return [currentRemainingRides, currentHardBlock];
  }
);
