import type { UserStore } from '~/Modules/user';
import type { ChatMessage, ChatRecord } from '~/types';
import React from 'react';
import LiveChatMessage from './LiveChatMessage';
import LiveChatSuggestion from './LiveChatSuggestion';
import LiveChatTextInput from './LiveChatTextInput';

const MESSAGE_TYPE = {
  NEMT_SUGGEST_RIDE: 'nemtSuggestRide',
  CARE_ACCEPT_SUGGESTION: 'careAcceptSuggestion',
  CARE_REJECT_SUGGESTION: 'careRejectSuggestion'
};

const REQUEST_STATUS = {
  ACTIVE: 'active'
};

type LiveChatConversationProps = {
  user: UserStore;
  selectedChat: ChatRecord;
  handleConfirmRide: (requestId: string) => void;
  handleRejectRide: (requestId: string) => void;
  onNewMessage: () => void;
  sendMessage: (
    requestId: string | undefined,
    text: string | undefined,
    type: any
  ) => void;
};

class LiveChatConversation extends React.Component<LiveChatConversationProps> {
  protected declare scrollableContainer: HTMLDivElement | null;

  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidUpdate({ selectedChat }) {
    if (selectedChat.messages.length !== this.props.selectedChat.messages.length) {
      this.props.onNewMessage();
    }

    // keep scroll at bottom on state change (i.e. message update)
    if (this.scrollableContainer) {
      this.scrollableContainer.scrollTop = this.scrollableContainer.scrollHeight;
    }
  }

  /**
   * Determine whether to render the NEMT suggestion confirmation dialog
   * @param  {Object}  [message={}] Message
   * @return {Boolean}              true/false
   */
  hasSuggestionDisplay = (message: ChatMessage) => {
    const requestStatus = this.props?.selectedChat?.requestStatus ?? '';
    const messages = this.props?.selectedChat?.messages ?? [];
    const suggestedAppointmentTime =
      this.props?.selectedChat?.rideDetails?.suggestedAppointmentTime ?? '';
    const suggestedPickupTime =
      this.props?.selectedChat?.rideDetails?.suggestedPickupTime ?? '';

    let mostRecentId = 0;
    let mostRecentRejectId = 0;

    // return quickly if no suggested times found
    if (!suggestedAppointmentTime && !suggestedPickupTime) {
      return false;
    }

    // get most recent suggestion message ID
    // and most recent reject message ID
    messages.forEach(msg => {
      switch (msg.type) {
        case MESSAGE_TYPE.NEMT_SUGGEST_RIDE:
          if (msg.id > mostRecentId) {
            mostRecentId = msg.id;
          }
          break;
        case MESSAGE_TYPE.CARE_REJECT_SUGGESTION:
          if (msg.id > mostRecentRejectId) {
            mostRecentRejectId = msg.id;
          }
          break;
        default:
          break;
      }
    });

    // evaluate if message is right type, request is active, the request has
    // suggested times, the suggestion is the most recent suggestion and the
    // suggestion is after the most recent rejection
    return (
      message.type === MESSAGE_TYPE.NEMT_SUGGEST_RIDE &&
      requestStatus === REQUEST_STATUS.ACTIVE &&
      message.id === mostRecentId &&
      mostRecentId > mostRecentRejectId
    );
  };

  render() {
    const messages = this.props?.selectedChat?.messages ?? [];

    return (
      <div className="liveChatConversation">
        <div
          className="liveChatMessageListWrapper"
          ref={el => (this.scrollableContainer = el)}
        >
          <div className="liveChatMessageList">
            {messages.map(message =>
              this.hasSuggestionDisplay(message) ? (
                <LiveChatSuggestion
                  key={message.id + '-s-' + (Math.random() * 1000).toString()}
                  selectedChat={this.props.selectedChat}
                  message={message}
                  handleConfirmRide={this.props.handleConfirmRide}
                  handleRejectRide={this.props.handleRejectRide}
                />
              ) : (
                <LiveChatMessage
                  key={message.id + '-m-' + (Math.random() * 1000).toString()}
                  user={this.props.user}
                  selectedChat={this.props.selectedChat}
                  message={message}
                />
              )
            )}
          </div>
        </div>
        <LiveChatTextInput
          selectedRequestId={this.props.selectedChat.requestId}
          sendMessage={this.props.sendMessage}
        />
      </div>
    );
  }
}

export default LiveChatConversation;
