import { isNumber, type DateString } from '@SRHealth/frontend-lib';
import type {
  BenefitRemaining,
  BenefitsUsageAndLimits,
  DuplicateRide,
  RideBookingSection,
  RideBookingStore,
  UsageAndLimit
} from './RideBooking.types';
import { RIDE_SECTION_ORDER } from './RideBooking.constants';
import type { RideModel, RidePropertyRule, RideProps, RideType } from '~/models';
import type { RideMileageBenefitLimit, PerRideBenefit } from '../memberProfile';
import { isEmpty } from '~/utilities/guards';
import { getRideRouteData } from '~/Pages/RideBooking/Components/Rides/Rides.utils';
import { getDuplicateRides } from '~/services/rideBooking.service';
import type { AppStore } from '~/types';
import moment from 'moment';

/**
 * Determine if the date selected is in the past.
 * @param date
 * @returns
 */
export function isPastBooking(date: RideBookingStore['date']['date']): boolean {
  if (!date) return false;

  const dateArr = date.split('-');

  if (Number(date[0]) > new Date().getFullYear()) return false;

  if (Number(dateArr[1]) > new Date().getMonth()) return false;

  return (
    Number(date[0]) <= new Date().getFullYear() &&
    Number(dateArr[1]) <= new Date().getMonth() + 1 &&
    Number(dateArr[2]) < new Date().getDate()
  );
}

/** Move on to the next section in the RBF, relative to current active section. */
export function toNextSection(meta: RideBookingStore['meta'], skip = 0) {
  meta.previousActiveSection = meta.activeSection;
  meta.activeSection = meta.activeSection + (1 + skip);
}

/** Change the RBF to a specific section. */
export function toSection(meta: RideBookingStore['meta'], section: RideBookingSection) {
  if (meta.activeSection === RIDE_SECTION_ORDER[section]) return;

  meta.previousActiveSection = meta.activeSection;
  meta.activeSection = RIDE_SECTION_ORDER[section];
}

/** Utility to attempt to scroll a section of the RBF into view. */
export function scrollToSection(section: RideBookingSection) {
  return document.querySelector(`[data-testid="section-${section}"]`)?.scrollIntoView();
}

/** Default behavior to execute whenever completing a section of the RBF. */
export function completeSection(section: RideBookingSection, skip?: number) {
  return (state: RideBookingStore) => {
    toNextSection(state.meta, skip);
    scrollToSection(section);
  };
}

/**
 * Helper function to cap usage values to their limit.
 * Iterates over each usage period and, if the usage exceeds the limit,
 * sets the usage value to the limit.
 *
 * @param usageAndLimit - The usage and limit record for a benefit type.
 */
function capUsageToLimit(usageAndLimit: UsageAndLimit): void {
  const limit = usageAndLimit.limit;

  for (const usagePeriod of Object.keys(usageAndLimit.usage)) {
    const usageValue = usageAndLimit.usage[usagePeriod];
    if (limit && usageValue > limit) {
      usageAndLimit.usage[usagePeriod] = limit;
    }
  }
}

/**
 * Zero out all benefit overages across all benefit types
 * in the entire benefitsUsageAndLimits object. This is used to prevent existing overages
 * from being counted when adding new rides, such as in the recurring rides section.
 *
 * @param benefitsUsageAndLimits - The benefits usage and limits record.
 * @returns The updated benefitsUsageAndLimits with capped usage values.
 */
function zeroOutAllBenefitOverages(
  benefitsUsageAndLimits: BenefitsUsageAndLimits
): BenefitsUsageAndLimits {
  for (const usageAndLimits of Object.values(benefitsUsageAndLimits)) {
    for (const limitType of ['ridesPerYear', 'ridesPerMonth'] as const) {
      if (limitType in usageAndLimits) {
        const usageAndLimit = usageAndLimits[limitType] as UsageAndLimit;
        capUsageToLimit(usageAndLimit);
      }
    }
  }
  return benefitsUsageAndLimits;
}

/**
 * Takes a BenefitsUsageAndLimits record and adds the proposed new rides to the projected usage.
 *
 *
 * @param originalBenefitsUsageAndLimits
 * @param proposedNewRideDates
 * @param activeBenefitCategoryIds
 * @param newRidesAreRoundTrips
 * @param ignoreExistingOverages
 * @returns
 */
export function addProposedRidesToBenefitsUsage(
  originalBenefitsUsageAndLimits: BenefitsUsageAndLimits,
  proposedNewRideDates: DateString[],
  /** For if a benefit category needs to be updated as well, pass in the benefit category ids. */
  activeBenefitCategoryIds: number[] = [],
  /** If true, the function will increment benefit usage by 2 for every date provided. */
  newRidesAreRoundTrips = false,
  /** If "ignoreExistingOverages" is true, the function will ignore any existing overages
   * and just add the new rides to the usage. E.g. If the benefit has a limit of 10 and prior
   * usage of 12, this will set the prior usage to 10 before adding the proposed new rides. */
  ignoreExistingOverages = false
): BenefitsUsageAndLimits {
  const benefitsUsageAndLimits = structuredClone(originalBenefitsUsageAndLimits);

  // If we need to ignore existing overages, do it once upfront for all benefit types
  if (ignoreExistingOverages) {
    zeroOutAllBenefitOverages(benefitsUsageAndLimits);
  }

  proposedNewRideDates.forEach(date => {
    const [year, month] = date.split('-');
    const monthKey = `${year}-${month}`;

    const modifier = newRidesAreRoundTrips ? 2 : 1;

    benefitsUsageAndLimits.all.ridesPerYear.usage[year] ??= 0;
    benefitsUsageAndLimits.all.ridesPerMonth.usage[monthKey] ??= 0;

    // Update all rides usage
    benefitsUsageAndLimits.all.ridesPerYear.usage[year] += modifier;
    benefitsUsageAndLimits.all.ridesPerMonth.usage[monthKey] += modifier;

    // Update benefit category usage
    for (const categoryId of activeBenefitCategoryIds) {
      const benefitCategoryKey = `benefit-category-${categoryId}` as const;

      // If the benefit category doesn't exist, skip it.
      if (!(benefitCategoryKey in benefitsUsageAndLimits)) continue;

      benefitsUsageAndLimits[benefitCategoryKey].ridesPerYear.usage[year] ??= 0;
      benefitsUsageAndLimits[benefitCategoryKey].ridesPerYear.usage[year] += modifier;
    }
  });

  return benefitsUsageAndLimits;
}

/**
 * From a BenefitsUsageAndLimits object, iterate through all time periods for a given
 * benefit and identify if the remaining rides are below a certain threshold.
 * @param benefitType
 * @param benefit
 * @param usageAndLimit
 * @param remainingThreshold
 * @param treatAllBlocksAsSoftBlocks
 * @returns
 */
export function createBenefitRemainingRecord(
  benefitType: keyof BenefitsUsageAndLimits,
  benefit: 'ridesPerYear' | 'ridesPerMonth',
  usageAndLimit: UsageAndLimit,
  remainingThreshold: number,
  treatAllBlocksAsSoftBlocks = false
): BenefitRemaining[] {
  const benefitCategoryId = benefitType.startsWith('benefit-category-')
    ? Number(benefitType.split('-')[2])
    : undefined;

  return Object.keys(usageAndLimit.usage).reduce((acc, timePeriod) => {
    const usage = usageAndLimit.usage[timePeriod];
    const limit = usageAndLimit.limit;

    if (isNumber(limit)) {
      const remaining = limit - usage;

      if (remaining < remainingThreshold) {
        const [year, month] = timePeriod.split('-');

        acc.push({
          remaining,
          year,
          month,
          isHardBlock: treatAllBlocksAsSoftBlocks ? false : usageAndLimit.isHardBlock,
          benefitCategoryId,
          benefit: `${benefitType}.${benefit}` as BenefitRemaining['benefit']
        });
      }
    }

    return acc;
  }, [] as BenefitRemaining[]);
}

/**
 * Retrieve BenefitRemaining records for each benefit below a given threshold.
 * @param remainingThreshold
 * @param originalBenefitsUsageAndLimits
 * @param treatAllBlocksAsSoftBlocks
 * @param countOnlyNewUsageIfAlreadyExceeded
 * @returns
 */
export function getBenefitsUnderRemainingThreshold(
  /** Get the benefits that have less than this number of remaining rides */
  remainingThreshold: number,
  originalBenefitsUsageAndLimits: BenefitsUsageAndLimits,
  treatAllBlocksAsSoftBlocks: boolean
): BenefitRemaining[] {
  const benefitUsageAndLimits = structuredClone(originalBenefitsUsageAndLimits);

  // Get the benefits that are projected to have less than the threshold number of remaining rides
  let benefitsUnderThreshold: BenefitRemaining[] = [];

  for (const entry of Object.entries(benefitUsageAndLimits)) {
    const benefitsUsageAndLimitsKey = entry[0] as keyof BenefitsUsageAndLimits;
    const benefitUsageAndLimit = entry[1];

    if ('ridesPerYear' in benefitUsageAndLimit) {
      const remainingRecords = createBenefitRemainingRecord(
        benefitsUsageAndLimitsKey,
        'ridesPerYear',
        benefitUsageAndLimit['ridesPerYear'],
        remainingThreshold,
        treatAllBlocksAsSoftBlocks
      );

      if (remainingRecords) {
        benefitsUnderThreshold = benefitsUnderThreshold.concat(remainingRecords);
      }
    }

    if ('ridesPerMonth' in benefitUsageAndLimit) {
      const remainingRecords = createBenefitRemainingRecord(
        benefitsUsageAndLimitsKey,
        'ridesPerMonth',
        benefitUsageAndLimit['ridesPerMonth'],
        remainingThreshold,
        treatAllBlocksAsSoftBlocks
      );

      if (remainingRecords) {
        benefitsUnderThreshold = benefitsUnderThreshold.concat(remainingRecords);
      }
    }
  }

  return benefitsUnderThreshold;
}

/**
 * Find the benefit remaining that's most pressing/relevant to the user
 * (i.e. hard block limits take precedence over soft block limits,
 * lower remaining rides take precedence over higher remaining rides, etc)
 */
export function findMostRelevantBenefitRemaining(
  benefits: BenefitRemaining[],
  /** Optional param to filter the benefits by the date's year and month */
  date?: DateString
): BenefitRemaining | undefined {
  if (!benefits.length) return undefined;

  const sorted = benefits.sort((a, b) => {
    // Hard block should be considered more pressing
    if (a.isHardBlock !== b.isHardBlock) {
      return a.isHardBlock ? -1 : 1;
    }

    // If both are equally hard or soft,
    // then the one with fewer remaining rides is more pressing
    return a.remaining - b.remaining;
  });

  // If there's a date, then we want to make sure the benefit is for the date's year and month
  if (date) {
    const [year, month] = date.split('-');

    return sorted.find(
      benefit => benefit.year === year && (!benefit?.month || benefit.month === month)
    );
  }

  return sorted[0];
}

/**
 * Given a ride type, this function will generate the ride property rules for the
 * mileage hard blocks.
 */
export function genRideMileageHardBlockRule(
  rideType: RideType,
  mileageRestriction: Record<RideMileageBenefitLimit, PerRideBenefit>,
  treatAllBlocksAsSoftBlocks = false
): RidePropertyRule {
  return [
    `mileage-hard-block`,
    'distance',
    (distance: RideProps['distance'], _m, isCommit) => {
      // Create a minimal ride object to perform the mileage check
      const rideForCheck = { type: rideType, distance };
      const { isHardBlocked, limit } = checkMileageLimit(
        rideForCheck,
        mileageRestriction
      );

      // On commit, enforce the hard block only if the ride is blocked with a hard block and we're not treating blocks as soft.
      if (isCommit && isHardBlocked && !treatAllBlocksAsSoftBlocks) {
        throw new Error(
          `Cannot book ride. ${distance} miles of ${limit} mileage limit reached.`
        );
      }

      return true;
    }
  ];
}

/** Check to see if the ride exceeds the mileage limit */
export function checkMileageLimit(
  ride: Pick<RideModel, 'type' | 'distance'>,
  mileageRestriction: Record<RideMileageBenefitLimit, PerRideBenefit>
): {
  isBlocked: boolean;
  isHardBlocked: boolean;
  distance: string;
  limit: number;
} {
  const [limit, bookAfterBlock] = getMileageLimit(ride.type, mileageRestriction);

  // Check if a mileage limit even exists for this member's health plan/configuration
  const hasMileageLimit = isNumber(limit) && isNumber(bookAfterBlock);

  // Check if the ride distance exceeds the limit
  const isBlocked = hasMileageLimit && Number(ride.distance) > limit;

  const isHardBlocked = isBlocked && bookAfterBlock === 0;

  return {
    isBlocked,
    isHardBlocked,
    distance: ride.distance as string,
    limit
  };
}

/** Helper function to get mileage limit based on the type of perRideBenefit */
function getMileageLimit(
  rideType: RideType,
  mileageRestriction: Record<RideMileageBenefitLimit, PerRideBenefit>
) {
  const restrictionType =
    rideType === 'arriveBy' ? 'to_care_miles_per_ride' : 'from_care_miles_per_ride';

  if (isEmpty(mileageRestriction?.[restrictionType]?.blockLimits)) {
    return [];
  }

  const { value, bookAfterBlock } = mileageRestriction[restrictionType].blockLimits;
  return [value, bookAfterBlock];
}

type ProcessNewPrimaryRideParams = {
  getState: () => AppStore;
  rideIndex: number;
  treatAllBlocksAsSoftBlocks: boolean;
};

/**
 * Common logic for adding a primary ride in the rideBooking store.
 */
export async function processNewPrimaryRide({
  getState,
  rideIndex,
  treatAllBlocksAsSoftBlocks = false
}: ProcessNewPrimaryRideParams): Promise<void> {
  const state = getState();
  const ride = state.rideBooking.rides[rideIndex];
  const { date, passengerInfo } = state.rideBooking;
  const perRideBenefits = state.memberProfile.formData?.benefits?.perRideBenefits;

  // Retrieve ride route data and update distance and duration
  const routeData = await getRideRouteData(ride, date.date);
  [ride.distance, ride.duration] = [routeData?.[0], routeData?.[1]];

  // Generate and add mileage hard block rules.
  // If the user's role allows benefit override, then the mileage block is treated as soft (i.e. not enforced as a hard block).
  const mileageHardBlockRule = genRideMileageHardBlockRule(
    ride.type,
    perRideBenefits,
    treatAllBlocksAsSoftBlocks
  );
  ride.addRule(...mileageHardBlockRule);

  // Commit the ride
  await ride.commit();

  const updatedRide = ride.toJSON();

  // Check for duplicate rides
  const duplicateRidesResponse = await getDuplicateRides(
    passengerInfo.toJSON(),
    date.toJSON(),
    [updatedRide]
  );

  const hardBlockDuplicateRides = (duplicateRidesResponse.data as DuplicateRide[]).filter(
    dupeRide => dupeRide.block === 'hard'
  );

  if (hardBlockDuplicateRides.length > 0) {
    throw {
      message: 'hardBlockDuplicateRides',
      hardBlockDuplicateRides
    };
  }
}

/**
 * Determines which years a user can book for based on benefits usage and date restrictions.
 * The effective earliest date is whichever is later: the eligibility start date or today's date minus pastBookingDays.
 * The effective end date is whichever is smaller: the eligibility end date or one year from today.
 */
export function getBookableYears(
  usedUpBenefitPeriods: Set<string>,
  eligibilityStartDate: string,
  eligibilityEndDate: string,
  dateRestrictionsStartDate: string | undefined,
  dateRestrictionsEndDate: string | undefined
): string[] {
  if (
    !eligibilityStartDate ||
    !eligibilityEndDate ||
    !dateRestrictionsStartDate ||
    !dateRestrictionsEndDate
  ) {
    return [];
  }

  // Define effectiveStart to be the later of the original eligibility start date and date restrictions start date.
  const effectiveStart = moment.max(
    moment(eligibilityStartDate, 'MM/DD/YYYY'),
    moment(dateRestrictionsStartDate)
  );

  // Define effectiveEnd as the earlier of eligibilityEnd and date restrictions end date.
  const effectiveEnd = moment.min(
    moment(eligibilityEndDate, 'MM/DD/YYYY'),
    moment(dateRestrictionsEndDate)
  );

  const effectiveStartYear = effectiveStart.year();
  const effectiveEndYear = effectiveEnd.year();

  // Generate array of years in range from effective start year to effective end year.
  const yearsInRange: string[] = [];
  for (let year = effectiveStartYear; year <= effectiveEndYear; year++) {
    yearsInRange.push(year.toString());
  }

  // Filter out years that are completely blocked.
  return yearsInRange.filter(year => !usedUpBenefitPeriods.has(year));
}
