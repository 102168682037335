import { Link, Pagination, TOKENS } from '@SRHealth/frontend-lib';
import React, { useEffect, useState } from 'react';
import TripCard from './TripCard';
import type { RideModel } from '~/models';
import { RIDE_PAGE_SIZE } from '../RecurringRides.constants';
import { getTripCardLabel } from '../RecurringRides.utils';
import TripCardSummary from './TripCardSummary';

type TripCardsProps = {
  rides: RideModel[];
  initialRideDate: DateString;
  dates: DateString[];
  isEditable?: boolean;
  onEdit?: () => void;
};

export default function TripCards({
  rides,
  initialRideDate,
  dates,
  onEdit
}: TripCardsProps) {
  const [activePage, setActivePage] = useState(1);
  const [activeCard, setActiveCard] = useState<DateString | null>(null);

  const [timeOne, timeTwo, timezone, initialRideType] = [
    rides[0].time!,
    rides[1]?.time,
    rides[0].timezone!,
    rides[0].type
  ];

  const ridePageIndex = (activePage - 1) * RIDE_PAGE_SIZE;

  /** If total amount of pages has decreased to lower than the active page
   * size then set the active page to the last page. */
  useEffect(() => {
    if (activePage > 1 && dates.length < activePage * RIDE_PAGE_SIZE) {
      setActivePage(Math.ceil(dates.length / RIDE_PAGE_SIZE) || 1);
    }
  }, [dates.length]);

  return (
    <div className="flex flex-col gap-[12px]">
      <div className="flex flex-row justify-between">
        <p className="flex heading-base items-center">Trips</p>
        {onEdit && (
          <Link href="#" iconLeft="Edit" label="Edit" size="md" onClick={onEdit} />
        )}
      </div>
      <div className="flex flex-col gap-[16px]">
        <div
          className={`flex flex-col p-[16px] shadow-b bg-${TOKENS.SURFACE.PRIMARY.NEUTRAL.XXXLT}`}
        >
          <p className="heading-base">Starts On</p>
          <div className="flex flex-row">
            <p className="heading-sm py-[8px]">{getTripCardLabel(initialRideDate)}</p>
          </div>
          <TripCardSummary
            date={initialRideDate}
            timeOne={timeOne}
            timeTwo={timeTwo}
            timezone={timezone}
            initialRideType={initialRideType}
          />
        </div>

        <div className="flex flex-col shadow-b" style={{ padding: '0 16px' }}>
          <div className="flex flex-col gap-[16px]">
            {dates.slice(ridePageIndex, ridePageIndex + RIDE_PAGE_SIZE).map(rideDate => (
              <TripCard
                key={rideDate}
                date={rideDate}
                timeOne={timeOne}
                timeTwo={timeTwo}
                timezone={timezone}
                initialRideType={initialRideType}
                isActive={activeCard === rideDate}
                onToggle={setActiveCard}
              />
            ))}
          </div>
          {dates.length > RIDE_PAGE_SIZE && (
            <Pagination
              className="h-[24px]"
              style={{
                justifyContent: 'end',
                padding: '6px 0'
              }}
              currentPage={activePage}
              pageCount={Math.ceil(dates.length / RIDE_PAGE_SIZE)}
              onPageChange={setActivePage}
            />
          )}
        </div>
      </div>
    </div>
  );
}
