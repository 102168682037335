import React, { Component } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash-es';
import { connect } from 'react-redux';
import { clearData } from '~/Modules/memberProfile/actions';
import {
  deleteMemberUpload,
  createMemberUpload,
  readMemberUpload,
  updateMemberUpload,
  listMemberUploads,
  clearMemberUploads,
  CREATE_MEMBER_UPLOAD_SUCCESS,
  UPDATE_MEMBER_UPLOAD_SUCCESS,
  DELETE_MEMBER_UPLOAD_SUCCESS,
  CREATE_MEMBER_UPLOAD_ERROR,
  UPDATE_MEMBER_UPLOAD_ERROR,
  DELETE_MEMBER_UPLOAD_ERROR,
  READ_MEMBER_UPLOAD_ERROR
} from '~/Modules/memberProfileUploads';
import SvgPencil from '~/Shared/Components/Svgs/SvgPencil';
import SvgDownload from '~/Shared/Components/Svgs/SvgDownload';
import SvgTrash from '~/Shared/Components/Svgs/SvgTrash';
import SvgAlert from '~/Shared/Components/Svgs/SvgAlert';
import RowPagination from '~/Shared/Components/RowPagination';
import LoadingModal from '~/Shared/Components/LoadingModal';
import Create from './Modals/Create';
import Delete from './Modals/Delete';
import { dateCheck, statusNotifications } from '~/utilities/helperFunctions';
import { MEMBER_PROFILE_UPLOAD_LIMIT, DAY_START } from '~/constants';
import moment from 'moment';
import { truncateElipse } from '~/utilities/strings';
import ReactTooltip from 'react-tooltip';
import { PERMISSIONS } from '~/Pages/MemberProfiles/MemberProfiles.constants';

const SIZE_LIMIT = 1024 * 1024 * 10; // 10 MEG FILE SIZE LIMIT

class Custom extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showLoader: false,
      showLoaderText: 'Uploading file...',
      params: {
        offset: 0,
        limit: MEMBER_PROFILE_UPLOAD_LIMIT
      },
      showCreate: false,
      showDelete: false,
      expirationError: false,
      descriptionError: false,
      description: '',
      expiration: '',
      deleteStatus: '',
      createStatus: '',
      actionType: 'edit',
      filesError: false,
      filesErrorText: 'File size too large. Max file size is 10 MB.',
      id: '',
      filename: ''
    };

    this.now = moment(DAY_START);
  }

  componentDidMount() {
    const state = this.initialize();
    this.setState(state, () => {
      this.getFiles();
      this.props.setIsChildDataLoading(true);
      this.props.setEditButton(
        <CustomEditButton
          permission={this.props.permission}
          callback={this.handleCreate}
        />
      );
    });
  }

  componentDidUpdate(prevProps) {
    const { passengerId, results, timestamp, permission } = this.props;

    const state = {};

    if (passengerId !== prevProps.passengerId) {
      this.getFiles();
    }

    if (timestamp !== prevProps.timestamp && !_.isEmpty(results)) {
      if (
        results.type === UPDATE_MEMBER_UPLOAD_SUCCESS ||
        results.type === CREATE_MEMBER_UPLOAD_SUCCESS
      ) {
        state.createStatus = 'success';
        state.showCreate = false;
      }
      if (results.type === DELETE_MEMBER_UPLOAD_SUCCESS) {
        state.deleteStatus = 'success';
        state.showDelete = false;
      }
      if (
        results.type === UPDATE_MEMBER_UPLOAD_ERROR ||
        results.type === CREATE_MEMBER_UPLOAD_ERROR
      ) {
        state.createStatus = 'error';
      }
      if (results.type === DELETE_MEMBER_UPLOAD_ERROR) {
        state.deleteStatus = 'error';
      }
      if (results.type === READ_MEMBER_UPLOAD_ERROR) {
        statusNotifications('Error downloading file.', 'error', 5);
      }
      state.isDirty = false;
      state.showLoader = false;
    }

    if (permission !== prevProps.permission) {
      this.props.setEditButton(
        <CustomEditButton
          permission={this.props.permission}
          callback={this.handleCreate}
        />
      );
    }

    if (Object.keys(state).length) {
      this.setState(state, () => {
        this.props.setIsChildDataLoading(state.showLoader);
      });
    }
  }

  componentWillUnmount() {
    this.props.dispatch(clearData());
    this.props.dispatch(clearMemberUploads());
    this.handleCancel();
  }

  /**
   * Initializes an object to be used as a component state.
   * @returns {Object} components new state
   * @memberof Notes
   */
  initialize() {
    return {
      success: '',
      error: '',
      editCustom: false,
      isDirty: false
    };
  }

  /**
   * Event handler for clicking the cancel link on the form - resets form to redux state
   * @returns {undefined} nothing
   * @memberof Notes
   */
  handleCancel = () => {
    const state = this.initialize();

    this.setState(state, () => {
      this.props.cancel('editCustom');
    });
  };

  /**
   * Handles changing of text in form. Marks form as dirty and changes component stats
   * @param {event} e The typing event
   * @param {string} id The identifier for the form element that is changing
   * @returns {undefined} nothing
   * @memberof Notes
   */
  handleText = (e, id) => {
    const val = e.target.value;

    const state = {
      [id]: val,
      isDirty: true
    };

    state[`${id}Error`] = false;

    if (id === 'description') {
      if (val.length > 64) {
        state[`${id}Error`] = true;
      }
    }

    if (id === 'expiration') {
      if (val.length === 10 && val.indexOf('_') === -1) {
        const check = dateCheck(val, 'MM/DD/YYYY', 10);
        state[`${id}Error`] = !check;
      }
    }

    this.setState(state);
  };

  /**
   * Validates then gathers all forms values and dispatches action to save to api
   * @param {object} fileData - file data
   * @param {string} description - description text
   * @param {string} expiration - expiration date
   * @param {string} actionType - create or edit
   * @returns {undefined} nothing
   * @memberof Notes
   */
  handleSubmit = (fileData, description, expiration, actionType) => {
    const { dispatch, selectedId } = this.props;

    if (!this.validation(fileData, actionType)) return;

    if (actionType === 'create') {
      const files = new FormData();
      files.append('file', fileData);
      files.append('expiration', expiration);
      files.append('description', description);

      this.setState(
        {
          showLoader: true,
          showLoaderText: 'Uploading file...'
        },
        () => {
          dispatch(createMemberUpload(files, selectedId));
          this.props.setIsChildDataLoading(true);
        }
      );
    } else {
      const params = { description, expiration };

      this.setState(
        {
          showLoader: true,
          showLoaderText: 'Updating file...'
        },
        () => {
          dispatch(updateMemberUpload(params, selectedId, this.state.id));
          this.props.setIsChildDataLoading(true);
        }
      );
    }
  };

  /**
   * handleEdit
   * @param {object} fileData - fileData
   * @param {string} expiration - expiration formatted
   * @return {undefined}
   * @memberof Custom
   */
  handleEdit = (fileData, expiration) => {
    this.setState(
      {
        showCreate: true,
        actionType: 'edit',
        expiration,
        description: fileData.description,
        filename: fileData.filename,
        id: fileData.id
      },
      () => {
        this.props.setIsChildBeingEdited(true);
      }
    );
  };

  /**
   * handleCreate
   * @return {undefined}
   * @memberof Custom
   */
  handleCreate = () => {
    this.setState({ showCreate: true, actionType: 'create' });
  };

  /**
   * handleDelete
   * @param {string} id = file id
   * @return {undefined}
   * @memberof Custom
   */
  handleDelete = id => {
    this.setState({ showDelete: true, id });
  };

  /**
   * delete
   * @return {undefined}
   * @memberof Custom
   */
  delete = () => {
    const { dispatch, selectedId } = this.props;
    const id = this.state.id;

    this.setState(
      {
        showLoader: true,
        showLoaderText: 'Deleting file...'
      },
      () => {
        dispatch(deleteMemberUpload(selectedId, id));
        this.props.setIsChildDataLoading(true);
      }
    );
  };

  /**
   * read api for retrieving file for download
   * @param {string} fileId - file id string
   * @param {string} fileName - file name
   * @return {undefined}
   * @memberof Custom
   */
  handleDownload = (fileId, fileName) => {
    const { dispatch, selectedId } = this.props;

    this.setState(
      {
        showLoader: true,
        showLoaderText: `Downloading ${fileName}...`
      },
      () => {
        dispatch(readMemberUpload(fileId, selectedId, fileName));
        this.props.setIsChildDataLoading(true);
      }
    );
  };

  /**
   * closeEdit
   * @return {undefined}
   * @memberof Custom
   */
  closeEdit = () => {
    const state = this.reset();
    state.showCreate = false;
    this.setState(state);
  };

  /**
   * closeDelete
   * @return {undefined}
   * @memberof Custom
   */
  closeDelete = () => {
    const state = this.reset();
    state.showDelete = false;
    this.setState(state);
  };

  /**
   * reset
   * @return {object} - return rest object for state
   * @memberof Custom
   */
  reset() {
    return {
      expirationError: false,
      descriptionError: false,
      description: '',
      expiration: '',
      deleteStatus: '',
      createStatus: ''
    };
  }

  /**
   * getFiles
   * @param {object} fields - offset and limit fields
   * @return {undefined}
   */
  getFiles = (fields = {}) => {
    const { dispatch, selectedId } = this.props;

    if (!selectedId) return;

    const params = { passengerId: selectedId };

    if (_.isEmpty(fields)) {
      params.offset = 0;
      params.limit = MEMBER_PROFILE_UPLOAD_LIMIT;
    } else {
      Object.assign(params, fields);
    }

    dispatch(listMemberUploads(params));
  };

  /**
   * Validates the components state that contains the form elements
   * Sets state error messages if not.Appt_detail
   * @param {object} files - file data
   * @param {string} actionType - edit or create
   * @return {boolean} valid If the form is valid.
   */
  validation(files, actionType) {
    let valid = true;

    const state = {
      expirationError: false,
      descriptionError: false,
      filesError: false,
      filesErrorText: ''
    };

    if (this.state.expiration.indexOf('_') === -1) {
      if (this.state.expiration && !dateCheck(this.state.expiration, 'MM/DD/YYYY', 10)) {
        state.expirationError = true;
        valid = false;
      }
    } else {
      state.expirationError = true;
      valid = false;
    }

    if (this.state.description && this.state.description.length > 64) {
      state.descriptionError = true;
      valid = false;
    }

    if (actionType === 'create') {
      if (_.get(files, 'name', '') === '') {
        valid = false;
        state.filesError = true;
        state.filesErrorText = 'Please upload a file.';
      } else if (files.size > SIZE_LIMIT) {
        valid = false;
        state.filesError = true;
        state.filesErrorText = 'File size too large. Max file size is 10 MB.';
      }
    }

    this.setState(state);

    return valid;
  }

  /**
   * render custom form
   * @return {jsx} returns jsx.
   */
  render() {
    const { permission, uploads } = this.props;

    const {
      showCreate,
      showDelete,
      createStatus,
      deleteStatus,
      description,
      descriptionError,
      expiration,
      expirationError,
      filesError,
      filesErrorText,
      showLoader
    } = this.state;

    return (
      <div className="childTab custom">
        <>
          <div className="memberRow">
            {uploads.length ? (
              <div className="uploadTable">
                <table>
                  <thead>
                    <tr>
                      <th>Name</th>
                      <th>Description</th>
                      <th>Status</th>
                      <th>Exp. Date</th>
                      <th>Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    {uploads.map((fileData, key) => {
                      // const expiration = fileData.expiration !== '0000-00-00 00:00:00' ? moment(fileData.expiration, 'YYYY-MM-DD h:mm:s').format('MM/DD/YYYY') : '-';
                      const status = fileData.expiration
                        ? moment(fileData.expiration, 'MM/DD/YYYY').isBefore(this.now)
                          ? 'Expired'
                          : 'Valid'
                        : '-';
                      return (
                        <tr key={key}>
                          <td>
                            {fileData.filename.length > 30 ? (
                              <>
                                <span data-tip={fileData.filename} data-for="fileNameTip">
                                  {truncateElipse(fileData.filename, 30, '...', 'middle')}
                                </span>
                                <ReactTooltip id="fileNameTip" place="right" />
                              </>
                            ) : (
                              fileData.filename
                            )}
                          </td>
                          <td>{fileData.description}</td>
                          <td>
                            <span className={status}>{status}</span>
                            {status === 'Expired' ? (
                              <SvgAlert className="svgWarning" />
                            ) : null}
                          </td>
                          <td>{fileData.expirationShow}</td>
                          <td>
                            {permission === PERMISSIONS.EDIT ||
                            permission === PERMISSIONS.CREATE_EDIT ? (
                                <>
                                  <a
                                    className="actionLinks"
                                    onClick={() =>
                                      this.handleEdit(fileData, fileData.expiration)
                                    }
                                  >
                                    <SvgPencil className="rowIcon" />
                                  </a>
                                  <a
                                    className="actionLinks"
                                    onClick={() => this.handleDelete(fileData.id)}
                                  >
                                    <SvgTrash className="rowIcon" />
                                  </a>
                                </>
                              ) : null}
                            <a
                              className="actionLinks"
                              onClick={() =>
                                this.handleDownload(fileData.id, fileData.filename)
                              }
                            >
                              <SvgDownload className="rowIcon" />
                            </a>
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              </div>
            ) : (
              <p className="noUploads">There are no uploads.</p>
            )}
            <RowPagination
              getRows={this.getFiles}
              defaultFields={this.state.params}
              totalCount={this.props.total}
              rowsReturned={this.props.limit}
              begin={this.props.offset}
              rowsPerPage={this.props.uploads.length}
              offsetField="offset"
              pageRangeDisplayed={3}
            />
          </div>
        </>

        {showLoader ? (
          <LoadingModal label={this.state.showLoaderText} isOpen={true} />
        ) : null}
        {showDelete ? (
          <Delete
            isOpen={true}
            closeModal={this.closeDelete}
            status={deleteStatus}
            handleSubmit={this.delete}
            fileId={this.state.id}
          />
        ) : null}
        {showCreate ? (
          <Create
            actionType={this.state.actionType}
            isOpen={true}
            closeModal={this.closeEdit}
            handleDescription={e => this.handleText(e, 'description')}
            handleDate={e => this.handleText(e, 'expiration')}
            description={description}
            expiration={expiration}
            expirationError={expirationError}
            status={createStatus}
            descriptionError={descriptionError}
            filesError={filesError}
            handleSubmit={this.handleSubmit}
            filesErrorText={filesErrorText}
            filename={this.state.filename}
            uploadSizeLimit={SIZE_LIMIT}
          />
        ) : null}
      </div>
    );
  }
}

const CustomEditButton = ({ permission, callback }) => {
  return (
    <>
      {permission === PERMISSIONS.EDIT || permission === PERMISSIONS.CREATE_EDIT ? (
        <a onClick={callback}>Add Upload</a>
      ) : null}
    </>
  );
};

Custom.propTypes = {
  user: PropTypes.object,
  uploads: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
  permission: PropTypes.string,
  selectedId: PropTypes.string,
  setIsChildBeingEdited: PropTypes.func,
  setIsChildDataLoading: PropTypes.func,
  putMemberProfile: PropTypes.func,
  clearData: PropTypes.func,
  totalCount: PropTypes.number,
  rowsReturned: PropTypes.number,
  offset: PropTypes.number,
  limit: PropTypes.number,
  total: PropTypes.number,
  editCustom: PropTypes.bool,
  handleEdit: PropTypes.func,
  handleCancel: PropTypes.func,
  showLoader: PropTypes.bool,
  dispatch: PropTypes.func,
  passengerId: PropTypes.number,
  results: PropTypes.object,
  timestamp: PropTypes.string
};

Custom.defaultProps = {
  user: {},
  uploads: {},
  permission: 'view',
  selectedId: null,
  setIsChildBeingEdited: () => {},
  setIsChildDataLoading: () => {},
  putMemberProfile: () => {},
  clearData: () => {},
  totalCount: MEMBER_PROFILE_UPLOAD_LIMIT,
  rowsReturned: MEMBER_PROFILE_UPLOAD_LIMIT,
  offset: 0,
  limit: MEMBER_PROFILE_UPLOAD_LIMIT,
  total: 0,
  editCustom: false,
  handleEdit: () => {},
  handleCancel: () => {},
  showLoader: false,
  dispatch: () => {},
  passengerId: 0,
  results: {},
  timestamp: ''
};

const mapStateToProps = state => ({
  user: state.user,
  uploads: _.get(state, 'memberProfileUploads.list.items', []),
  total: _.get(state, 'memberProfileUploads.list.total', 0),
  limit: _.get(state, 'memberProfileUploads.list.limit', MEMBER_PROFILE_UPLOAD_LIMIT),
  offset: _.get(state, 'memberProfileUploads.list.offset', 0),
  totalCount: _.get(state, 'memberProfileUpload.totalCount', 0),
  rowsReturned: _.get(state, 'memberProfileUpload.totalCount', 0),
  passengerId: _.get(state, 'memberProfile.formData.personalInfo.id', 0),
  dispatch: state.dispatch,
  results: state.memberProfileUploads.results,
  timestamp: _.get(state, 'memberProfileUploads.results.timestamp', '')
});

export default connect(mapStateToProps)(Custom);
