import type { Binary } from '~/types';
import { setPassengerInfo } from '~/Modules/rideBooking';
import type { AppThunk } from '~/Modules';
import {
  PASSENGER_INFO_DEFAULT,
  passengerInfoFactory,
  type PassengerInfoModel,
  type PassengerInfoProps
} from '~/models';
import {
  getMemberAdditionalPassengers,
  getMemberPhoneNumbers,
  getMemberSubTransportType,
  getMemberTransportType,
  getMemberTripType,
  getTransportTypeSubTypes,
  setToAssistanceDefaults
} from './initializePassengerInfo.thunk.utils';

/** Prepopulates the RBF store using data extracted from the
 * MemberProfiles slice. Any value that cannot be propulated will
 * use a default value. */
export const initializePassengerInfoThunk = (): AppThunk<PassengerInfoModel> => {
  return function (dispatch, getState) {
    const memberProfile = getState().memberProfile;

    const passengerInfo: PassengerInfoProps = PASSENGER_INFO_DEFAULT();

    passengerInfo.passengerId = memberProfile.formData.personalInfo.id;

    passengerInfo.transportType = getMemberTransportType(memberProfile);
    passengerInfo.tripType = getMemberTripType(
      memberProfile,
      passengerInfo.transportType
    );

    // Populate assistance fields with defaults
    setToAssistanceDefaults(passengerInfo, memberProfile, passengerInfo.tripType);

    const transportTypeSubTypes = getTransportTypeSubTypes(
      memberProfile,
      passengerInfo.tripType,
      passengerInfo.transportType
    );

    passengerInfo.subTransportType = getMemberSubTransportType(
      memberProfile,
      transportTypeSubTypes
    );

    const memberPhoneNumbers = getMemberPhoneNumbers(memberProfile);
    passengerInfo.phoneNumber = memberPhoneNumbers.primary?.phone_number ?? '';
    passengerInfo.phoneNumberType = memberPhoneNumbers.primary?.phone_type ?? undefined;
    passengerInfo.phoneNumberOptOutStatus = (memberPhoneNumbers.primary?.opt_out ??
      0) as Binary;
    passengerInfo.phoneNumberOptOutPromptStatus = (
      memberPhoneNumbers.primary?.opt_out_prompt_timestamp ? 1 : 0
    ) as Binary;

    passengerInfo.alternatePhoneNumber = memberPhoneNumbers.secondary?.phone_number ?? '';
    passengerInfo.additionalPassengers = getMemberAdditionalPassengers(
      memberProfile,
      passengerInfo.transportType
    );

    const passengerInfoModel = passengerInfoFactory(passengerInfo);
    dispatch(setPassengerInfo(passengerInfoModel));

    return passengerInfoModel;
  };
};
