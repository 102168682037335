import { useMemo } from 'react';
import {
  createTypeGuard,
  Datetime,
  DAYS_OF_WEEK,
  type DayOfWeek,
  type DateString
} from '@SRHealth/frontend-lib';
import type { RecurringRideCadence } from '~/Modules/rideBooking';
import type {
  BenefitRemaining,
  BenefitsUsageAndLimits,
  DuplicateRide
} from '~/Modules/rideBooking/RideBooking.types';
import {
  addProposedRidesToBenefitsUsage,
  getBenefitsUnderRemainingThreshold
} from '~/Modules/rideBooking/RideBooking.utils';
import moment from 'moment';
import { RECURRING_RIDES_TXT } from './RecurringRides.constants';

/** Type guard for days of week DatePicker type. */
export const isDayOfWeek = createTypeGuard((v: unknown) =>
  typeof v === 'string' && DAYS_OF_WEEK.includes(v as DayOfWeek) ? (v as DayOfWeek) : null
);

/** Converts the ride's date time to a weekday identifier
 * that the DatePicker can interpret. */
export function getRideWeekDay(date: DateString): DayOfWeek {
  const datetime = new Datetime(date);

  return DAYS_OF_WEEK[datetime.getUTCDay()];
}

/** A hook to generate the `determineDateType` for the DatePicker
 * component used in RecurringRides. It sets any of the selected
 * weekdays to 'recurring' if the date is a recurring ride.
 *
 * The returned callback is memoized. */
export function useRecurringRideOptions(
  startDate: DateString,
  endDate: DateString,
  daysOfWeek?: DayOfWeek[],
  cadence?: RecurringRideCadence
) {
  const callback = useMemo(() => {
    /** Determine the multiplier */
    function getMultiplier(cadence?: RecurringRideCadence) {
      switch (cadence) {
        case 'weekly':
          return 1;
        case 'everyTwoWeeks':
          return 2;
        case 'everyThreeWeeks':
          return 3;
        case 'everyFourWeeks':
          return 4;
        default:
          return 0;
      }
    }

    const startDateObj = new Datetime(startDate);
    const endDateObj = new Datetime(endDate);
    const multiplier = getMultiplier(cadence);
    const offsets = new Array(7).fill(0).map((_, i) => startDateObj.getUTCDay() - i);

    return (date: DateString) => {
      const dateObj = new Datetime(date);

      if (
        dateObj.valueOf() > endDateObj.valueOf() ||
        dateObj.valueOf() < startDateObj.valueOf()
      ) {
        return 'disabled';
      }

      // The section is in custom mode and does not
      // have a cadence or days of week set.
      if (!cadence || !daysOfWeek) return;

      const daysDiff = Datetime.convertBaseToUnit(
        dateObj.valueOf() - startDateObj.valueOf(),
        'DAY'
      );

      for (const day of daysOfWeek) {
        const dayIndex = DAYS_OF_WEEK.indexOf(day);
        const offset = offsets[dayIndex];

        if ((daysDiff + offset) % (7 * multiplier) === 0) {
          return 'highlighted';
        }
      }

      return 'disabled';
    };
  }, [startDate, endDate, daysOfWeek, cadence]);

  return callback;
}

/** Generate the card label for a trip in a recurring ride series. */
export function getTripCardLabel(date: DateString) {
  const dateObj = new Datetime(date);

  return [
    dateObj.getUTCDay('short'),
    '-',
    dateObj.getUTCMonth('short'),
    dateObj.getUTCDate(),
    dateObj.getUTCFullYear()
  ].join(' ');
}

/** Checks if the selected recurring ride dates are projected to exceed benefit limits */
export function checkRecurringExceededLimits(
  recurringDates: DateString[],
  benefitsUsageAndLimits: BenefitsUsageAndLimits,
  activeBenefitCategoryIds: number[],
  treatAllBlocksAsSoftBlocks: boolean,
  isRoundTrip: boolean
): [string, 'warning' | 'error' | undefined] {
  const projectedBenefitsUsageAndLimits = addProposedRidesToBenefitsUsage(
    benefitsUsageAndLimits,
    recurringDates,
    activeBenefitCategoryIds,
    isRoundTrip,
    true
  );

  const projectedExceededLimits = getBenefitsUnderRemainingThreshold(
    0,
    projectedBenefitsUsageAndLimits,
    treatAllBlocksAsSoftBlocks
  );

  return formatProjectedExceededLimitsMessage(projectedExceededLimits, isRoundTrip);
}

/** Get the formatted date to display for a duplicate ride */
export const getFormattedDuplicateRideDate = (ride: DuplicateRide): string => {
  return ['Flex Time', 'Now'].includes(ride.pickupTime)
    ? moment(ride.date).format('ddd - MMM D, YYYY')
    : moment(ride.pickupTime).format('ddd - MMM D, YYYY');
};

/** Formats the alert message for dates that would exceed member's ride limits */
export function formatProjectedExceededLimitsMessage(
  exceededLimits: BenefitRemaining[],
  newRidesAreRoundTrips = false
): [string, 'warning' | 'error' | undefined] {
  if (!exceededLimits?.length) return ['', undefined];

  // Sort chronologically
  const sortedLimits = exceededLimits.sort((a, b) => {
    const dateA = new Date(parseInt(a.year), a.month ? parseInt(a.month) - 1 : 0);
    const dateB = new Date(parseInt(b.year), b.month ? parseInt(b.month) - 1 : 0);
    return dateA.getTime() - dateB.getTime();
  });

  let aggregateIsHardBlock = false;

  const limitDetails: string[] = [];

  for (const { month, year, remaining, isHardBlock } of sortedLimits) {
    // If the remaining count is positive, it means the limit is not exceeded (we shouldn't get here, but just in case)
    if (remaining >= 0) continue;

    // If any of the limits are hard blocks, the aggregate is a hard block
    aggregateIsHardBlock ||= isHardBlock;

    /** Turn remaining positive. And if new rides are round trips, divide by 2 and get
     * the ceiling (to get the number of calendar dates that need to be removed) */
    const nonNegativeOverage = Math.ceil(
      Math.abs(remaining) / (newRidesAreRoundTrips ? 2 : 1)
    );

    if (!month) {
      limitDetails.push(`${nonNegativeOverage} from ${year}`);
      continue;
    }

    const date = new Date(parseInt(year), parseInt(month) - 1);
    const monthName = date.toLocaleString('default', { month: 'long' });

    limitDetails.push(`${nonNegativeOverage} from ${monthName} ${year}`);
  }

  return [
    `${RECURRING_RIDES_TXT.REMOVE_DATES_EXCEEDING_RIDES_LIMIT} ${formatList(limitDetails)} ${RECURRING_RIDES_TXT.TO_MEET_THEIR_LIMIT}`,
    aggregateIsHardBlock ? 'error' : 'warning'
  ];
}

/** Helper function to format lists with proper grammar */
const formatList = (items: string[]): string => {
  if (items.length === 0) return '';
  if (items.length === 1) return items[0];
  if (items.length === 2) return `${items[0]} and ${items[1]}`;

  const last = items.pop();
  return `${items.join(', ')}, and ${last}`;
};
