import React, { useReducer } from 'react';
import { Section, useModal } from '@SRHealth/frontend-lib';
import Summary from './subcomponents/Summary';
import TripTypeComponent from './subcomponents/TripType';
import TripPhoneNumber from './subcomponents/TripPhoneNumber';
import AdditionalPassengers from './subcomponents/AdditionalPassengers';
import { putSmsPermissionsUpdate } from '~/services/member.service';
import OptOutModalContents from './subcomponents/OptOutModalContents';
import type { Binary } from '~/types';
import useModelSelector from '~/hooks/useModelSelector';
import Loader from '~/Pages/RideBooking/Loader';
import { useAppDispatch } from '~/Modules';
import {
  completePassengerInfoThunk,
  editPassengerInfo,
  RIDE_SECTION_ORDER,
  rollbackActiveSection,
  type PassengerInfoSection
} from '~/Modules/rideBooking';
import { shouldTriggerOptOutModal } from './PassengerInfo.utils';

const inlineCSS = `
#passenger-info {
  display: flex;
  flex-flow: column nowrap;
  align-items: center;

  #passenger-info-questions {
    width: 968px;
    padding-left: 4px;
    padding-right: 4px;

    [data-testid^="card-"]:not(
        [data-testid$="-content"],
        [date-testid$="-header"]
      )
      > div:last-child:not(
        [data-testid$="-content"],
        [date-testid$="-header"]
      ) {
      width: calc(100% - 8px);
      padding-left: 4px;
      padding-right: 4px;
    }
  }
}
`;

type PassengerInfoState = {
  section: PassengerInfoSection;
  isLoading: boolean;
};

type ReducerAction =
  | { type: 'edit'; section: PassengerInfoSection }
  | { type: 'save' }
  | { type: 'cancel' }
  | { type: 'loading'; isLoading: boolean };

const createInitialState = (): PassengerInfoState => ({
  section: 'none',
  isLoading: false
});

const reducer: React.Reducer<PassengerInfoState, ReducerAction> = (
  state: PassengerInfoState,
  action: ReducerAction
) => {
  switch (action.type) {
    case 'edit':
      return { ...state, section: action.section };

    case 'save':
      return { ...state, isLoading: false, section: 'none' };

    case 'cancel':
      return { ...state, section: 'none' };

    case 'loading':
      return { ...state, isLoading: action.isLoading };

    default:
      return state;
  }
};

const PassengerInfo = () => {
  const [, ModalActions] = useModal();
  const appDispatch = useAppDispatch();
  const { model } = useModelSelector(state => state.rideBooking.passengerInfo);
  const [state, localDispatch] = useReducer(
    reducer,
    createInitialState(),
    createInitialState
  );

  /** This callback handles the logic when the user selects to either
   * opt in or opt out of SMS messages. */
  const optOutCallback = (optOut: Binary) => () => {
    if (model.phoneNumberOptOutStatus !== optOut) {
      localDispatch({ type: 'loading', isLoading: true });
      putSmsPermissionsUpdate(
        model.passengerId,
        model.phoneNumber!,
        'mobile',
        !!optOut
      ).finally(() => {
        localDispatch({ type: 'save' });
        // The prompt has been done with this modal, tell the model
        // so that we do not continuously show the modal to the user.
        model.phoneNumberOptOutPromptStatus = 1;
        model.commit();
      });
    }
    ModalActions.setIsOpen(false);
  };

  /** Enable editing a section of the Passenger Info. If the user
   * is currently editing a different section, revert outstanding
   * changes first. */
  const handleEdit = (section: PassengerInfoSection) => {
    if (section !== state.section && model.isDirty) {
      model.revert();
    }

    localDispatch({ type: 'edit', section });
    appDispatch(editPassengerInfo(section));
  };

  /** Cancel editing a section of the Passenger Info. Any pending
   * changes are rolled back and a mixpanel event is dispatched. */
  const handleCancel = () => {
    if (model.isDirty) model.revert();

    localDispatch({ type: 'cancel' });
    appDispatch(rollbackActiveSection());
  };

  /** Commit any changes to the model and adjust the component edit
   * state to 'none'. */
  const handleSave = () => {
    if (!model.isDirty) localDispatch({ type: 'save' });

    localDispatch({ type: 'loading', isLoading: true });

    appDispatch(completePassengerInfoThunk())
      .unwrap()
      .then(() => {
        if (shouldTriggerOptOutModal(state.section, model)) {
          ModalActions.set({
            isOpen: true,
            showBackdrop: true,
            content: (
              <OptOutModalContents
                onOptOut={optOutCallback(1)}
                onOptIn={optOutCallback(0)}
              />
            )
          });
        }

        localDispatch({ type: 'save' });
      })
      .catch(() => localDispatch({ type: 'loading', isLoading: false }));
  };

  return (
    <>
      <style>{inlineCSS}</style>
      <Section id="passenger-info" icon="Passenger" label="Passenger Info">
        <Summary
          editState={state.section}
          modelData={model.toJSON()}
          onEdit={handleEdit}
          onCancel={handleCancel}
        />

        <Loader isLoading={state.isLoading} />

        <div id="passenger-info-questions" className="reset-div">
          {
            {
              none: null,
              TripType: (
                <TripTypeComponent
                  model={model}
                  onCancel={handleCancel}
                  onSave={handleSave}
                />
              ),
              TripPhoneNumber: (
                <TripPhoneNumber
                  model={model}
                  onCancel={handleCancel}
                  onSave={handleSave}
                />
              ),
              AdditionalPassengers: (
                <AdditionalPassengers
                  model={model}
                  onCancel={handleCancel}
                  onSave={handleSave}
                />
              )
            }[state.section]
          }
        </div>
      </Section>
    </>
  );
};

PassengerInfo.sectionIndex = RIDE_SECTION_ORDER['passenger-info'];

export { PassengerInfo };
