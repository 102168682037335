import React from 'react';
import { Alert } from '@SRHealth/frontend-lib';
import { RIDES_TXT } from '~/Pages/RideBooking/Components/Rides/Rides.constants';

interface RideLimitWarningProps {
  /** The current remaining rides allowed for the member (including rides in the current booking session) */
  remainingRidesAllowed?: number;
  /** Whether the ride limit is a hard block or not*/
  isHardBlock?: boolean;
  /** Whether the ride is an initial ride */
  isInitialRide?: boolean;
}

export default function RideLimitWarning({
  remainingRidesAllowed = 0,
  isHardBlock = false,
  isInitialRide = false
}: RideLimitWarningProps) {
  // Check if we're hard blocked from adding a return ride (when we have no remaining rides left)
  if (isHardBlock && isInitialRide && remainingRidesAllowed <= 0) {
    return <Alert type="error" label={RIDES_TXT.RETURN_RIDE_HARD_BLOCK} />;
  }

  // Check if we've already exceeded or are exceeding a soft block limit and should tell the user this
  if (remainingRidesAllowed <= -1) {
    return <Alert type="warning" label={RIDES_TXT.RIDE_LIMIT_EXCEEDED_SOFT_BLOCK} />;
  }

  return null;
}
