/** A list of the valid Hospital Group Features
 * supported by Care */

export const APPROVED_PROVIDERS = 'approved_providers';
export const VENUES = 'venue';
export const PUBLIC_TRANSIT = 'public_transit';
export const SAVED_ADDRESS = 'saved_address';
export const PASSENGER_EDIT = 'passenger_edit';
export const MULTI_LEG = 'multi_leg';
export const EXPENSE_REIMBURSEMENT = 'expense_reimbursement';
export const PAST_BOOKING = 'past_booking';
export const NEW_MEMBERS = 'new_members';
export const SWITCH_FUNDING_SOURCE = 'switch_funding_source';
export const ELIGIBILITY_API = 'eligibility_api';
export const DEPENDENT_FIELDS = 'dependent_fields';
export const DISABLE_UNABLE_TO_FULFILL = 'disable_unable_to_fulfill';
export const EDIT_PAST_RIDE = 'care_edit_past_ride';
export const DISABLE_ROUND_TRIP = 'disable_round_trip';
export const VEHICLE_SUB_TYPE = 'vehicle_sub_type';
export const ASSISTANCE_NEEDS = 'assistance_needs';
export const DISABLE_RECURRING_RIDES = 'disable_recurring_rides';
export const HIDE_NEW_RIDE = 'hide_new_ride';
// logic checking if member has a mobility assessment hard block and valid mobility assessment moved up to lumen
// export const MOBILITY_ASSESSMENT_HARD_BLOCK = 'mobility_assessment_hard_block';
export const CUSTOM_TEXT_MESSAGING = 'custom_text_messaging';
export const NEMT_PRICE_LOCK = 'nemt_price_lock';
export const NEW_RIDE_BOOKING_FLOW = 'new_ride_booking_flow';
export const AWV_BANNER = 'awv_banner';

const defaultExport = {
  APPROVED_PROVIDERS,
  VENUES,
  PUBLIC_TRANSIT,
  SAVED_ADDRESS,
  PASSENGER_EDIT,
  MULTI_LEG,
  EXPENSE_REIMBURSEMENT,
  PAST_BOOKING,
  NEW_MEMBERS,
  SWITCH_FUNDING_SOURCE,
  ELIGIBILITY_API,
  DEPENDENT_FIELDS,
  DISABLE_UNABLE_TO_FULFILL,
  EDIT_PAST_RIDE,
  DISABLE_ROUND_TRIP,
  VEHICLE_SUB_TYPE,
  ASSISTANCE_NEEDS,
  DISABLE_RECURRING_RIDES,
  HIDE_NEW_RIDE,
  CUSTOM_TEXT_MESSAGING,
  // logic checking if member has a mobility assessment hard block and valid mobility assessment moved up to lumen
  // MOBILITY_ASSESSMENT_HARD_BLOCK,
  NEMT_PRICE_LOCK,
  NEW_RIDE_BOOKING_FLOW,
  AWV_BANNER
} as const;

export type HospitalGroupFeature = (typeof defaultExport)[keyof typeof defaultExport];

export default defaultExport;
