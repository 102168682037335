import { Icon, Pagination, THEME, TOKENS } from '@SRHealth/frontend-lib';
import React, { useState } from 'react';
import type { DuplicateRide } from '~/Modules/rideBooking';
import { DUPLICATE_RIDES_PAGE_SIZE } from '../RecurringRides.constants';
import DuplicateRecurringRideDetails from './DuplicateRecurringRideDetails';

type DuplicateRecurringRideCardsProps = {
  duplicateRecurringRides: DuplicateRide[];
};

export default function DuplicateRecurringRideCards({
  duplicateRecurringRides
}: DuplicateRecurringRideCardsProps) {
  const [duplicateRidesPage, setDuplicateRidesPage] = useState(1);
  const duplicatePageIndex = (duplicateRidesPage - 1) * DUPLICATE_RIDES_PAGE_SIZE;

  // Get paginated duplicate rides
  const paginatedDuplicateRides = duplicateRecurringRides.slice(
    duplicatePageIndex,
    duplicatePageIndex + DUPLICATE_RIDES_PAGE_SIZE
  );

  return (
    <div
      className="flex flex-col shadow-b"
      style={{
        backgroundColor: THEME.colors[TOKENS.DISPLAY.SURFACE.ORANGE]
      }}
    >
      <div
        className="flex flex-col p-[16px] shadow-b"
        style={{
          backgroundColor: THEME.colors[TOKENS.DISPLAY.SURFACE.ORANGE],
          marginBottom: '12px',
          gap: '6px'
        }}
      >
        <div className="flex items-center">
          <Icon
            type="Error"
            className={`w-[24px] h-[24px] text-${TOKENS.DISPLAY.INDICATOR.ORANGE} fill-current`}
          />
          <p className="heading-base">Duplicate rides</p>
        </div>
        <p className="text-xsm">
          These rides are duplicates of rides in the recurring series
        </p>
      </div>
      <div
        style={{
          backgroundColor: '#FEEADB',
          padding: '0 16px'
        }}
      >
        <div className="pb-[12px]">
          {paginatedDuplicateRides?.map(ride => (
            <DuplicateRecurringRideDetails
              key={`${ride.id}-${ride.pickupTime}`}
              ride={ride}
            />
          ))}
        </div>
        {duplicateRecurringRides.length > DUPLICATE_RIDES_PAGE_SIZE && (
          <Pagination
            style={{
              justifyContent: 'end',
              paddingBottom: '12px'
            }}
            currentPage={duplicateRidesPage}
            pageCount={Math.ceil(
              duplicateRecurringRides.length / DUPLICATE_RIDES_PAGE_SIZE
            )}
            onPageChange={setDuplicateRidesPage}
          />
        )}
      </div>
    </div>
  );
}
