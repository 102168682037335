import React from 'react';
import { Button, Icon, TOKENS } from '@SRHealth/frontend-lib';
import { useHistory } from 'react-router-dom';
import moment from 'moment';
import { useAppSelector } from '~/Modules';
import type { ProviderNemtModel } from '~/models/ProviderNemt.model';
import type { ProviderRideshareModel } from '~/models/ProviderRideshare.model';
import type { ProviderPublicModel } from '~/models/ProviderPublic.model';
import BookedProviderInfo from './BookedProviderInfo';

interface TripScheduledModalProps {
  firstName: string;
  lastName: string;
  selectedProvider: ProviderNemtModel | ProviderRideshareModel | ProviderPublicModel;
  modalActions: {
    setIsOpen: (isOpen: boolean) => void;
  };
  initialRideId: number;
  returnRideId: number | undefined;
}

const TripScheduledModal = ({
  firstName,
  lastName,
  selectedProvider,
  modalActions,
  initialRideId,
  returnRideId
}: TripScheduledModalProps) => {
  const history = useHistory();
  const rides = useAppSelector(state => state.rideBooking.rides);

  /** Should display return ride id only if there are an initial and return ride
   * (don't want to show any recurring ride ids in this modal) */
  const shouldDisplayReturnRideId = returnRideId && rides.length === 2;

  // Okay to format date inside the useAppSelector (although not usually kosher) since date is not changing
  const formattedDate = useAppSelector(state =>
    moment(state.rideBooking.date.date).format('MMMM D, YYYY')
  );

  const handleViewRides = () => {
    modalActions.setIsOpen(false);
    history.push('/ride/scheduled');
  };

  return (
    <div className="flex justify-center">
      <div className="flex flex-col items-center gap-[12px]" style={{ width: '360px' }}>
        <Icon
          type="Verified"
          className={`fill-current text-${TOKENS.COMPONENT.BUTTON.DEFAULT}`}
        />

        <div className="text-center py-[8px]">
          <p className="heading-lg text-center py-[8px]">
            {`${firstName} ${lastName}'s trip has been scheduled for ${formattedDate}`}
          </p>
        </div>

        <BookedProviderInfo selectedProvider={selectedProvider} />

        <p className="body-sm text-center py-[8px] text-sm-italic">
          A reminder text with the transport provider information will be sent one day
          before the ride.
        </p>

        <div className="flex flex-col gap-[8px]" style={{ width: '275px' }}>
          <div
            className="flex justify-between"
            style={{ borderBottom: '1px solid var(--border-neutral-md, #808080)' }}
          >
            <span className="body-base">Initial Ride ID:</span>
            <span className="heading-base">{initialRideId}</span>
          </div>
          {shouldDisplayReturnRideId && (
            <div
              className="flex justify-between"
              style={{ borderBottom: '1px solid var(--border-neutral-md, #808080)' }}
            >
              <span className="body-base">Return Ride ID:</span>
              <span className="heading-base">{returnRideId}</span>
            </div>
          )}
        </div>

        <div className="py-[8px]">
          <Button
            label="View Rides"
            size="md"
            onClick={handleViewRides}
            minWidth="160px"
          />
        </div>
      </div>
    </div>
  );
};

export default TripScheduledModal;
