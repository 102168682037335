import moment from 'moment';

/** Formats (in human readable format) the periods that the user has exceeded their benefit limits for */
export const formatUsedUpBenefitPeriodsAlertText = (
  usedUpBenefitPeriods: Set<string>,
  earliestDate: string,
  latestDate: string
): string => {
  if (!usedUpBenefitPeriods.size) return '';

  // Filter out periods outside the range of selectable dates
  const [earliestYear, earliestMonth] = earliestDate?.split('-') ?? ['0000', '00'];
  const [latestYear, latestMonth] = latestDate?.split('-') ?? ['9999', '99'];

  /** Filter out months or years outside the range of selectable dates
   */
  const usedUpPeriodsToDisplay = Array.from(usedUpBenefitPeriods).filter(
    usedUpBenefitPeriod => {
      if (usedUpBenefitPeriod.length === 4) {
        // For year format (YYYY)
        return usedUpBenefitPeriod >= earliestYear && usedUpBenefitPeriod <= latestYear;
      } else {
        // For month format (YYYY-MM)
        const [exceededPeriodYear, exceededPeriodMonth] = usedUpBenefitPeriod.split('-');
        return (
          (exceededPeriodYear > earliestYear ||
            (exceededPeriodYear === earliestYear &&
              exceededPeriodMonth >= earliestMonth)) &&
          (exceededPeriodYear < latestYear ||
            (exceededPeriodYear === latestYear && exceededPeriodMonth <= latestMonth))
        );
      }
    }
  );

  if (!usedUpPeriodsToDisplay.length) return '';

  // Sort the periods chronologically
  const sortedPeriods = usedUpPeriodsToDisplay.sort((a, b) => {
    const momentA = a.length === 7 ? moment(a, 'YYYY-MM') : moment(a, 'YYYY');
    const momentB = b.length === 7 ? moment(b, 'YYYY-MM') : moment(b, 'YYYY');
    return momentA.diff(momentB);
  });

  const formatted = sortedPeriods.map(period => {
    // Check if it's a month (YYYY-MM format) or year
    if (period.length === 7) {
      const [year, month] = period.split('-');
      return moment(`${year}-${month}`, 'YYYY-MM').format('MMMM YYYY');
    }
    return period; // Just return the year
  });

  if (formatted.length === 1) {
    return formatted[0];
  }

  if (formatted.length === 2) {
    return `${formatted[0]} and ${formatted[1]}`;
  }

  const last = formatted.pop();
  return `${formatted.join(', ')}, and ${last}`;
};
