import type { ChangeEventHandler, MouseEventHandler } from 'react';
import React from 'react';
import BenefitsModal from '../BenefitsModal/BenefitsModal';
import SvgEdit from '~/Shared/Components/Svgs/SvgEdit';
import type { BillingInvoiceConfiguration } from '~/Modules/settings/index';

export const BILLING_PARAMETERS = {
  periodicity: 'periodicity',
  submissionDeadline: 'submissionDeadline',
  reviewPeriod: 'reviewPeriod',
  adjustmentsDeadline: 'adjustmentsDeadline'
};

interface BillingConfigurationProps {
  billingParameters: BillingInvoiceConfiguration;
  formData: any;
  showModal: boolean;
  formValid: boolean;
  handleCloseModal: (event: any) => void;
  handleInvoiceConfigParametersSubmitModal: () => void;
  editBillingParameter: (
    parameterValue: string,
    value?: string | number
  ) => MouseEventHandler<any>;
  updateBillingForm: (formProperty: string, fieldName: string) => ChangeEventHandler<any>;
}

const BillingConfiguration: React.FC<BillingConfigurationProps> = ({
  billingParameters,
  formData,
  showModal,
  formValid,
  handleCloseModal,
  handleInvoiceConfigParametersSubmitModal,
  editBillingParameter,
  updateBillingForm
}) => {
  const billingParameterToEdit = () => {
    const billingParameter = formData?.label ?? '';
    const value = formData?.value ?? '';
    switch (billingParameter) {
      case BILLING_PARAMETERS.periodicity: {
        return (
          <>
            <label className="customFieldInputLabel widthAuto">Invoice Period</label>
            <select
              className="labelInput widthAuto"
              value={value}
              onChange={updateBillingForm('value', BILLING_PARAMETERS.periodicity)}
            >
              <option value="Weekly">Weekly</option>
              <option value="Semi-monthly">Semi-monthly</option>
              <option value="Monthly">Monthly</option>
            </select>
          </>
        );
      }
      case BILLING_PARAMETERS.submissionDeadline: {
        return (
          <>
            <label className="customFieldInputLabel widthAuto">
              Claim Submission Deadline
            </label>
            <input
              type="number"
              min="0"
              max="365"
              className="labelInput widthAuto"
              value={value}
              onChange={updateBillingForm('value', BILLING_PARAMETERS.submissionDeadline)}
            />
            <label className="widthAuto">days</label>
          </>
        );
      }
      case BILLING_PARAMETERS.reviewPeriod: {
        return (
          <>
            <label className="customFieldInputLabel widthAuto">
              Invoice Review Period
            </label>
            <input
              type="number"
              min="0"
              max="31"
              className="labelInput widthAuto"
              value={value}
              onChange={updateBillingForm('value', BILLING_PARAMETERS.reviewPeriod)}
            />
            <label className="widthAuto">days</label>
          </>
        );
      }
      case BILLING_PARAMETERS.adjustmentsDeadline: {
        return (
          <>
            <label className="customFieldInputLabel widthAuto">
              Adjustments Deadline
            </label>
            <input
              type="number"
              min="0"
              max="365"
              className="labelInput widthAuto"
              value={value}
              onChange={updateBillingForm(
                'value',
                BILLING_PARAMETERS.adjustmentsDeadline
              )}
            />
            <label className="widthAuto">days</label>
          </>
        );
      }
    }
  };

  return (
    <div className="subTabContent">
      <table>
        <thead>
          <tr>
            <th>Parameter Name</th>
            <th>Parameter Value</th>
            <th>Action</th>
          </tr>
        </thead>
        <tbody>
          {billingParameters && (
            <>
              <tr>
                <td>Invoice Period</td>
                <td>{billingParameters.periodicity}</td>
                <td className={'actionHalf billingActions'}>
                  <button
                    onClick={editBillingParameter(
                      BILLING_PARAMETERS.periodicity,
                      billingParameters.periodicity
                    )}
                  >
                    <SvgEdit className={'tableIcon'} />
                  </button>
                </td>
              </tr>
              <tr>
                <td>Claim Submission Deadline</td>
                <td>{billingParameters.submissionDeadline}</td>
                <td className={'actionHalf billingActions'}>
                  <button
                    onClick={editBillingParameter(
                      BILLING_PARAMETERS.submissionDeadline,
                      billingParameters.submissionDeadline
                    )}
                  >
                    <SvgEdit className={'tableIcon'} />
                  </button>
                </td>
              </tr>
              <tr>
                <td>Invoice Review Period</td>
                <td>{billingParameters.reviewPeriod}</td>
                <td className={'actionHalf billingActions'}>
                  <button
                    onClick={editBillingParameter(
                      BILLING_PARAMETERS.reviewPeriod,
                      billingParameters.reviewPeriod
                    )}
                  >
                    <SvgEdit className={'tableIcon'} />
                  </button>
                </td>
              </tr>
              <tr>
                <td>Adjustments Deadline</td>
                <td>{billingParameters.adjustmentsDeadline}</td>
                <td className={'actionHalf billingActions'}>
                  <button
                    onClick={editBillingParameter(
                      BILLING_PARAMETERS.adjustmentsDeadline,
                      billingParameters.adjustmentsDeadline
                    )}
                  >
                    <SvgEdit className={'tableIcon'} />
                  </button>
                </td>
              </tr>
            </>
          )}
        </tbody>
      </table>
      <>
        {showModal ? (
          <BenefitsModal
            showX
            showModal={showModal}
            handleCloseModal={handleCloseModal}
            title="Billing Parameter"
          >
            <form className={'modalForm'} onSubmit={e => e.preventDefault()}>
              <div className="formElement billingParameterInput">
                {billingParameterToEdit()}
              </div>
              <div className="healthPlanDisclaimerMessage">
                By updating this parameter at the Health Plan level, it does not overwrite
                any value previously defined specifically at any Health Sub Plan level.
              </div>
            </form>
            <div className="buttons footer">
              <button className="exit" onClick={handleCloseModal}>
                Cancel
              </button>
              <button
                className="CTA"
                onClick={handleInvoiceConfigParametersSubmitModal}
                disabled={formValid === false}
              >
                Save
              </button>
            </div>
          </BenefitsModal>
        ) : null}
      </>
    </div>
  );
};

export default BillingConfiguration;
