import React from 'react';
import { TOKENS } from '@SRHealth/frontend-lib';

/**
 * A component that displays an asterisk to indicate that a field is required.
 * Usually this is a part of a label for a field, but in cases where we just have standalone text
 * and want to indicate that the field is required, we can use this component.
 */
const RequiredAsterisk = () => {
  return <span className={`text-${TOKENS.COMPONENT.INPUT.ERROR}`}>{' *'}</span>;
};

export default RequiredAsterisk;
