/** Message displayed when member has reached their ride limit before the recurring rides toggle */
// eslint-disable-next-line quotes
export const RECURRING_RIDES_TXT = Object.freeze({
  RIDE_LIMIT_REACHED: 'Member\'s ride limit has been reached',
  MISSING_END_DATE_ERROR_CAPTION: 'Please select an end date.',
  SELECT_END_DATE_CAPTION: 'Select an end date. Or reset to start over.',
  /** Message displayed when member's dates exceed their ride limits on the recurring rides section */
  // eslint-disable-next-line quotes
  REMOVE_DATES_EXCEEDING_RIDES_LIMIT: "Member's dates are exceeding rides limit. Remove",
  /** Second half of the message displayed when member's dates exceed their ride limits on the recurring rides section */
  TO_MEET_THEIR_LIMIT: 'to meet their limit.',
  /** Message displayed at bottom of the transport providers component when no transport providers are able to service a ride. */
  NO_SUPPLY_MSG:
    'We were unable to find transportation for this route. Please either edit your ride or cancel your trip.',
  /** Message displayed when member tries to book a recurring ride with a "Now" time. */
  NOW_RIDE_WARNING: 'Recurring rides cannot be booked with a pickup time of "Now".'
} as const);

export const RIDE_PAGE_SIZE = 4;
export const DUPLICATE_RIDES_PAGE_SIZE = 4;
