import { createAppAsyncThunk } from '~/Modules';
import { initializeDateThunk } from './initializeDate.thunk';

export const completePassengerInfoThunk = createAppAsyncThunk(
  'rideBooking/completePassengerInfo',
  async (_, { getState, dispatch }) => {
    const passengerInfo = getState().rideBooking.passengerInfo;

    const tripTypeChanged = passengerInfo.propertyIsDirty('tripType');
    const transportTypeChanged = passengerInfo.propertyIsDirty('transportType');

    // Commit any changes to the passenger info.
    await passengerInfo.commit();

    // Only reinitialize the date thunk if either tripType or transportType has changed
    // (those are the two relevant inputs for getting date restrictions)
    if (tripTypeChanged || transportTypeChanged) {
      return dispatch(initializeDateThunk());
    }
    return;
  }
);
