import React, { useEffect, useMemo, useState } from 'react';
import {
  Alert,
  Button,
  Card,
  DatePicker,
  Datetime,
  DayOfWeekSelector,
  Input,
  THEME,
  TOKENS
} from '@SRHealth/frontend-lib';
import { useAppDispatch, useAppSelector } from '~/Modules';
import {
  completeRecurringRidesThunk,
  editRecurringRides,
  resetRecurringRides,
  RIDE_SECTION_ORDER,
  selectActiveBenefitCategoryIds,
  selectBenefitsUsageAndLimits,
  selectIgnoreBenefitLimits,
  selectIsActive,
  selectRecurringDateBounds,
  selectRemainingRidesAllowed,
  toNextSection,
  type RecurringRideCadence,
  type DuplicateRide
} from '~/Modules/rideBooking';
import { NOOP } from '~/utilities/helperFunctions';
import {
  useRecurringRideOptions,
  checkRecurringExceededLimits
} from './RecurringRides.utils';
import Loader from '../../Loader';
import TripCards from './subcomponents/TripCards';
import useModelSelector from '~/hooks/useModelSelector';
import UsedUpBenefitPeriodsAlert from '../Shared/UsedUpBenefitPeriodsAlert';
import { RECURRING_RIDES_TXT } from './RecurringRides.constants';
import DuplicateRecurringRideCards from './subcomponents/DuplicateRecurringRideCards';
import { selectTreatAllBlocksAsSoft } from '~/Modules/rideBooking/selectors/selectTreatAllBlocksAsSoft';
import RequiredAsterisk from '../Shared/RequiredAsterisk';
import NoSupplyAlerts from '../Shared/NoSupplyAlerts';

const inlineCSS = `
  #section-recurring-rides [data-testid="accordion"] {
    gap: 0px;
  }

  #section-recurring-rides [data-testid^="accordion"] h2 {
    padding: 0 16px;
    border-color: ${THEME.colors['neutral-gray-xxlt']};
  }

  #section-recurring-rides [data-testid^="accordion"] h2 svg {
    fill: ${THEME.colors[TOKENS.ICON.ACTIVE]};
  }

  #section-recurring-rides [data-testid="accordion-content"] {
    padding: 0 16px;
    border-bottom: solid 1px ${THEME.colors['neutral-gray-xxlt']};
  }
`;

const recurringRidesCadenceOptions: { label: string; value: RecurringRideCadence }[] = [
  { label: 'Every Week', value: 'weekly' },
  { label: 'Every 2nd Week', value: 'everyTwoWeeks' },
  { label: 'Every 3rd Week', value: 'everyThreeWeeks' },
  { label: 'Every 4th Week', value: 'everyFourWeeks' }
];

const RecurringRides = () => {
  const appDispatch = useAppDispatch();

  const [isLoading, setIsLoading] = useState(false);
  const [duplicateRecurringRides, setDuplicateRecurringRides] = useState<DuplicateRide[]>(
    []
  );

  const rides = useAppSelector(s => s.rideBooking.rides);
  const isActive = useAppSelector(state => selectIsActive(state, 'recurring-rides'));
  const { model: recurringRides } = useModelSelector(s => s.rideBooking.recurringRides!);
  const [earliestDate, latestDate] = useAppSelector(selectRecurringDateBounds);
  const benefitsUsageAndLimits = useAppSelector(selectBenefitsUsageAndLimits);
  const activeBenefitCategoryIds = useAppSelector(selectActiveBenefitCategoryIds);
  const ignoreBenefitLimits = useAppSelector(selectIgnoreBenefitLimits);
  const treatAllBlocksAsSoftBlocks = useAppSelector(selectTreatAllBlocksAsSoft);
  const transportProviders = useAppSelector(s => s.rideBooking.meta.transportProviders);

  const [remainingRidesAllowed, isHardBlock] = useAppSelector(
    selectRemainingRidesAllowed
  );

  // Check if any ride has a time of "Now"
  const hasNowRideTime = rides.some(ride => ride.time === 'Now');
  const missingEndDate = 'endDate' in recurringRides.ruleErrors;

  const recurringRideOptions = useRecurringRideOptions(
    earliestDate,
    latestDate,
    recurringRides.daysOfWeek,
    recurringRides.cadence
  );

  /** The selected recurring dates (does not include the primary ride date, just the recurring dates) */
  const selectedRecurringDates = useMemo(() => {
    if (
      !recurringRides.endDate ||
      !recurringRides.daysOfWeek ||
      !recurringRides.cadence
    ) {
      return [];
    }

    const recurringDates: DateString[] = [];

    const lastDate = new Datetime(recurringRides.endDate);

    // Loop and decrement until they are the same date
    while (lastDate.toISODate() !== earliestDate) {
      const DateString = lastDate.toISODate();
      if (recurringRideOptions(DateString) === 'highlighted') {
        recurringDates.push(DateString);
      }

      lastDate.subtractDays(1);
    }

    return recurringDates.sort();
  }, [recurringRides.endDate, recurringRideOptions]);

  const [projectedExceededLimitsMessage, alertType] = useMemo(() => {
    if (ignoreBenefitLimits) return ['', undefined];

    return checkRecurringExceededLimits(
      [earliestDate, ...selectedRecurringDates],
      benefitsUsageAndLimits,
      activeBenefitCategoryIds,
      treatAllBlocksAsSoftBlocks,
      rides.length === 2
    );
  }, [
    ignoreBenefitLimits,
    selectedRecurringDates.length,
    benefitsUsageAndLimits,
    activeBenefitCategoryIds,
    rides.length
  ]);

  /** Quality of life. When the section is first activated, scroll down for user. */
  useEffect(() => {
    if (isActive) {
      document.getElementById('recurring-trip')!.scrollIntoView({ behavior: 'smooth' });
    }
  }, [isActive]);

  /** Completes the recurring rides. */
  function handleConfirm() {
    if (!recurringRides?.endDate) {
      // Just commit it immediately since we know it will fail.
      return recurringRides.commit().catch(NOOP);
    }

    setIsLoading(true);

    appDispatch(completeRecurringRidesThunk())
      .unwrap()
      .then(newDuplicateRecurringRides => {
        if (newDuplicateRecurringRides?.length) {
          setIsLoading(false);
          setDuplicateRecurringRides(newDuplicateRecurringRides as DuplicateRide[]);
        }
      })
      .catch(NOOP)
      .finally(() => setIsLoading(false));
  }

  /** Toggles the recurring rides section on and off. */
  function handleToggle() {
    if (!(isActive || recurringRides?.endDate)) return appDispatch(editRecurringRides());

    appDispatch(resetRecurringRides());
    appDispatch(toNextSection());
  }

  return (
    <div
      id="section-recurring-rides"
      data-testid="section-recurring-rides"
      className="reset-div relative flex flex-col p-[16px] items-center"
    >
      <style>{inlineCSS}</style>
      <Loader isLoading={isLoading} />
      <Card id="recurring-trip" label="Recurring Trip" border={false}>
        <div
          className="reset-div relative flex flex-row justify-center"
          style={{ minHeight: '30px' }}
        >
          <Input
            type="toggle"
            inputId={0}
            name="recurring-trip-toggle"
            style={{ width: '216px', position: 'absolute', left: 0 }}
            options={[{ label: 'Make Recurring', value: false }]}
            size="md"
            value={!!(isActive || recurringRides?.endDate)}
            error={false}
            onChange={handleToggle}
            disabled={(remainingRidesAllowed <= 0 && isHardBlock) || hasNowRideTime}
          />

          <div className="flex flex-col gap-[8px]">
            {!(isActive || recurringRides?.endDate) && remainingRidesAllowed <= 0 && (
              <Alert
                type={isHardBlock ? 'error' : 'warning'}
                label={RECURRING_RIDES_TXT.RIDE_LIMIT_REACHED}
              />
            )}

            {hasNowRideTime && (
              <Alert type="warning" label={RECURRING_RIDES_TXT.NOW_RIDE_WARNING} />
            )}
          </div>

          {isActive && (
            <div className="flex flex-col" style={{ gap: '32px', paddingLeft: '61px' }}>
              <div className="flex flex-col gap-[12px]">
                <div style={{ width: '378px' }}>
                  <UsedUpBenefitPeriodsAlert />
                </div>
                <p className="heading-base">
                  1. Select Repeats On
                  <RequiredAsterisk />
                </p>
                <DayOfWeekSelector
                  daysSelected={recurringRides.daysOfWeek}
                  onChange={daysOfWeek => (recurringRides.daysOfWeek = daysOfWeek)}
                  readOnly={!!recurringRides.endDate}
                />
              </div>
              <div className="flex flex-col gap-[12px]">
                <p className="heading-base">
                  2. Select Frequency
                  <RequiredAsterisk />
                </p>
                <Input
                  type="select"
                  inputId={1}
                  name="cadence"
                  size="md"
                  value={recurringRides.cadence}
                  options={recurringRidesCadenceOptions}
                  onChange={(_, cadence: RecurringRideCadence) =>
                    (recurringRides.cadence = cadence)
                  }
                  disabled={!!recurringRides.endDate}
                />
              </div>

              <div className="flex flex-col gap-[12px]">
                <p
                  className={`heading-base ${
                    missingEndDate ? `text-${TOKENS.COMPONENT.INPUT.ERROR}` : ''
                  }`}
                >
                  3. Select End Date
                  <RequiredAsterisk />
                </p>
                <DatePicker
                  id="recurring-ride-dates"
                  mode="range"
                  datesSelected={[earliestDate, ...selectedRecurringDates]}
                  earliestSelectableDate={earliestDate}
                  latestSelectableDate={latestDate}
                  determineDateType={recurringRideOptions}
                  onDateClick={endDate => {
                    recurringRides.endDate = endDate;
                  }}
                  caption={
                    missingEndDate
                      ? RECURRING_RIDES_TXT.MISSING_END_DATE_ERROR_CAPTION
                      : RECURRING_RIDES_TXT.SELECT_END_DATE_CAPTION
                  }
                  error={missingEndDate}
                />
              </div>

              {projectedExceededLimitsMessage && alertType && (
                <div style={{ width: '378px' }}>
                  <Alert type={alertType} label={projectedExceededLimitsMessage} />
                </div>
              )}
              <div className="flex flex-col" style={{ gap: '32px', width: '375px' }}>
                <TripCards
                  rides={rides}
                  dates={selectedRecurringDates}
                  initialRideDate={earliestDate}
                />
                {!!duplicateRecurringRides?.length && (
                  <DuplicateRecurringRideCards
                    duplicateRecurringRides={duplicateRecurringRides}
                  />
                )}
              </div>

              <div
                data-testid-="recurring-rides-footer"
                className="flex flex-row justify-center gap-[16px]"
              >
                <Button
                  label="Reset"
                  onClick={() => appDispatch(resetRecurringRides())}
                  minWidth="124px"
                  size="sm"
                  alt
                />
                <Button
                  label="Confirm"
                  minWidth="124px"
                  size="sm"
                  onClick={handleConfirm}
                  disabled={alertType === 'error' || missingEndDate}
                />
              </div>
            </div>
          )}

          {!isActive && recurringRides?.endDate && (
            <div className="flex flex-col" style={{ gap: '32px', width: '375px' }}>
              <TripCards
                rides={rides}
                dates={selectedRecurringDates}
                initialRideDate={earliestDate}
                onEdit={() => appDispatch(editRecurringRides())}
              />
              {!!duplicateRecurringRides?.length && (
                <DuplicateRecurringRideCards
                  duplicateRecurringRides={duplicateRecurringRides}
                />
              )}
            </div>
          )}
        </div>
      </Card>
      {!transportProviders.length ? (
        <div className="flex flex-col items-center justify-center gap-[8px]">
          <NoSupplyAlerts alertType="error" />
          <Alert type="error" label={RECURRING_RIDES_TXT.NO_SUPPLY_MSG} />
        </div>
      ) : null}
    </div>
  );
};

RecurringRides.sectionIndex = RIDE_SECTION_ORDER['recurring-rides'];

export { RecurringRides };
