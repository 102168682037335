import type { UserStore } from '~/Modules/user';
import type { ChatsStore } from '~/types';
import React from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';
import * as _ from 'lodash-es';
import Cookies from 'js-cookie';
import {
  postMessage,
  bookRide,
  cancelRequest,
  readMessages,
  selectRequest,
  reassignNemt,
  confirmSuggestion
} from '~/Modules/chats';
import LiveChatHeader from './Components/LiveChatHeader';
import LiveChatLauncher from './Components/LiveChatLauncher';
import LiveChatConversation from './Components/LiveChatConversation/LiveChatConversation';
import LiveChatReassign from './Components/LiveChatReassign';
import LiveChatConversationListItem from './Components/LiveChatConversationListItem';

type LiveChatProps = {
  user: UserStore;
  chats: ChatsStore;
  bookingData: BookingDataStore;
  postChatMessage: typeof postMessage;
  bookRideRequest: typeof bookRide;
  cancelChatRequest: typeof cancelRequest;
  putMessagesRead: typeof readMessages;
  setSelectedRequest: typeof selectRequest;
  reassignNemt: typeof reassignNemt;
  confirmSuggestion: typeof confirmSuggestion;
};

type LiveChatState = {
  focus: boolean;
  isOpen: boolean;
  path: string;
  rideId: number | null;
  selectedRequestId: string | null;
  showReassignWindow: boolean;
  isCancelEnabled: boolean;
  isReassignEnabled: boolean;
  selectedVehicleOwnerId: number;
};

class LiveChat extends React.Component<LiveChatProps, LiveChatState> {
  constructor(props) {
    super(props);

    const chatIsOpen = Cookies.get(`${process.env.REACT_APP_ENVIRONMENT}_chat_isopen`);

    this.state = {
      focus: true,
      isOpen: typeof chatIsOpen === 'undefined' || chatIsOpen === 'true',
      path: '',
      rideId: null,
      selectedRequestId: null,
      showReassignWindow: false,
      isCancelEnabled: true,
      isReassignEnabled: true,
      selectedVehicleOwnerId: 0
    };
  }

  componentDidMount() {
    window.addEventListener('focus', this.onFocus);
    window.addEventListener('blur', this.onBlur);
  }
  componentWillUnmount() {
    window.removeEventListener('focus', this.onFocus);
    window.removeEventListener('blur', this.onBlur);
  }

  componentDidUpdate(prevProps) {
    const selectedRequestId =
      Object.keys(this.props.chats).find(id => this.props.chats[id].selected) || '';
    const prevSelectedRequestId =
      Object.keys(prevProps.chats).find(id => this.props.chats[id].selected) || '';

    const sessionRequestId = window.sessionStorage.getItem(
      `${process.env.REACT_APP_ENVIRONMENT}_chat_selectedrequest`
    );
    const requestStatus = this.props?.chats?.[selectedRequestId]?.requestStatus ?? '';
    const prevRequestStatus = prevProps?.chats?.[selectedRequestId]?.requestStatus ?? '';

    // if the selected request ID has changed
    // typically due to user selection or receiving a new chat
    if (
      selectedRequestId &&
      (selectedRequestId !== prevSelectedRequestId ||
        selectedRequestId !== sessionRequestId)
    ) {
      // set selected request to session storage for later retrieval
      window.sessionStorage.setItem(
        `${process.env.REACT_APP_ENVIRONMENT}_chat_selectedrequest`,
        selectedRequestId
      );

      // override user preference to show new chat
      this.handleWindowToggle(true);
    }

    // check if selected chat status changed
    if (requestStatus !== prevRequestStatus) {
      // if it's booked or cancelled, redirect to remove "Processing" ride card
      if (
        requestStatus === 'booked' ||
        (prevRequestStatus === 'open' && requestStatus === 'cancelled')
      ) {
        const path = '/ride/scheduled';
        const rideId = this.props?.chats?.[selectedRequestId]?.rideDetails?.rideId ?? 0;

        // only redirect to scheduled ride tab if you're on cancelled, willcall or completed
        if (
          !window.location.pathname.includes('/ride/scheduled') &&
          !window.location.pathname.includes('/ride/all')
        ) {
          this.setState({
            path,
            rideId,
            selectedRequestId
          });
        }
      }
    }
  }

  onFocus = () => {
    const selectedRequestId = this.getSelectedRequestId();

    _.debounce(() => this.setMessagesRead(selectedRequestId), 500)();
    this.setState({ focus: true });
  };

  onBlur = () => this.setState({ focus: false });

  onNewMessage = () => {
    const selectedRequestId = this.getSelectedRequestId();

    if (this.state.focus && typeof selectedRequestId !== 'undefined') {
      this.setMessagesRead(selectedRequestId);
    }
  };

  /**
   * Handle window toggle (minimize/maximize)
   * @param {Boolean} [value=!this.state.isOpen] Value to set
   * @return {void}
   */
  handleWindowToggle = (value?: boolean) => {
    let _value: boolean;

    this.setState(
      ({ isOpen }) => {
        _value = typeof value === 'boolean' ? value : !isOpen;
        return { isOpen: _value };
      },
      () => Cookies.set(`${process.env.REACT_APP_ENVIRONMENT}_chat_isopen`, _value)
    );
  };

  /**
   * Handle cancel request
   * @param  {String} requestId Request ID
   * @return {void}
   */
  handleCancelRequest = requestId => {
    this.setState(
      {
        isCancelEnabled: false,
        isReassignEnabled: false
      },
      () => {
        this.props.cancelChatRequest({
          requestId,
          hospitalUserId: this.props?.chats?.[requestId]?.hospitalUserId ?? {}
        });
      }
    );
  };

  /**
   * Handle request to reassign NEMT
   * @param {Number} requestId Request ID
   * @returns {undefined}
   */
  handleReassignRequest = () =>
    this.setState({ isReassignEnabled: false, showReassignWindow: true });

  /**
   * Handle request to close reassign NEMT window
   * @returns {undefined}
   */
  handleReassignWindowClose = () =>
    this.setState({ isReassignEnabled: true, showReassignWindow: false });

  /**
   * Handle request to reassign ride request to a different NEMT company
   * @param {integer} vehicleId - id for vehicle
   * @returns {undefined}
   */
  handleReassignmentConfirmation = vehicleId => {
    const selectedChat = this.getSelectedChat();

    this.setState(
      {
        showReassignWindow: false,
        isReassignEnabled: true
      },
      () =>
        this.props.reassignNemt({
          requestId: selectedChat?.requestId,
          hospitalUserId: selectedChat?.hospitalUserId,
          vehicleOwnerId: vehicleId
        })
    );
  };

  /**
   * Handle confirm ride
   * @param  {String}     [requestId=''] Request ID
   * @return {void}
   */
  handleConfirmRide = (requestId = '') => {
    const chatRequest = this.props?.chats?.[requestId] ?? {};
    const hospitalUserId = chatRequest?.hospitalUserId ?? 0;
    const careInitiatorId = chatRequest?.careInitiatorId ?? 0;
    const careInitiator = chatRequest?.users?.[careInitiatorId] ?? {};
    const hospitalName = chatRequest?.facilityDetails?.hospitalName ?? '';

    const text = `Request suggestions accepted by ${careInitiator.firstName} ${careInitiator.lastName} at ${hospitalName}`;

    // post message and book ride
    this.sendMessage(requestId, text, 'careAcceptSuggestion');
    this.props.confirmSuggestion({ requestId });
    this.props.bookRideRequest({
      requestId,
      hospitalUserId,
      confirmData: {
        acceptedAt: Date.now()
      }
    });
  };

  /**
   * Handle reject ride
   * @param  {String}     [requestId=''] Request ID
   * @return {void}
   */
  handleRejectRide = (requestId = '') => {
    const chatRequest = this.props?.chats?.[requestId] ?? {};
    const careInitiatorId = chatRequest?.careInitiatorId ?? 0;
    const careInitiator = chatRequest?.users?.[careInitiatorId] ?? {};
    const hospitalName = chatRequest?.facilityDetails?.hospitalName ?? '';
    const text = `Time suggestions rejected by ${careInitiator.firstName} ${careInitiator.lastName} at ${hospitalName}`;

    this.sendMessage(requestId, text, 'careRejectSuggestion');
  };

  /**
   * Get sorted list of chats
   * @return {Array} Sorted list of chats
   */
  getChats = () => {
    const userId = this.props?.user?.userData?.id ?? 0;
    const validStatus = ['open', 'active', 'booked', 'cancelled'];

    return Object.keys(this.props.chats)
      .map(requestId => this.props.chats[requestId])
      .filter(
        chatRequest =>
          chatRequest.careInitiatorId === userId &&
          validStatus.includes(chatRequest.requestStatus)
      )
      .sort((a, b) => b.requestedAt - a.requestedAt);
  };

  /**
   * Get selected request ID
   * @return {String}            Request ID
   */
  getSelectedRequestId = () => {
    const chats = this.getChats();
    const selectedRequest = chats.find(chat => chat.selected);
    const sessionRequestId = window.sessionStorage.getItem(
      `${process.env.REACT_APP_ENVIRONMENT}_chat_selectedrequest`
    );
    const sessionRequest = chats.find(chat => chat.requestId === sessionRequestId);

    // check for value in props first
    if (selectedRequest) return selectedRequest.requestId;

    if (sessionRequest) return sessionRequest.requestId;

    // get newest request
    if (chats.length > 0) return chats[0].requestId;

    return '';
  };

  /**
   * Get selected chat object
   * @return {Object} Chat object
   */
  getSelectedChat = () => {
    const selectedRequestId = this.getSelectedRequestId();
    return this.getChats().find(chat => chat.requestId === selectedRequestId);
  };

  /**
   * Set request messages as read
   * @param  {String}     [requestId=''] Request ID
   * @return {void}
   */
  setMessagesRead = (requestId = '') => {
    const readerId = this.props?.user?.userData?.id ?? 0;
    const hospitalUserId = this.props?.chats?.[requestId]?.hospitalUserId ?? 0;

    this.props.putMessagesRead({
      requestId,
      hospitalUserId,
      readerId
    });
  };

  /**
   * Send message
   * @param  {String} [requestId=''] Request ID
   * @param  {String} [text='']      Message text
   * @param  {String} type           Message type (optional)
   * @return {void}
   */
  sendMessage = (requestId = '', text = '', type) => {
    const senderId = this.props?.user?.userData?.id ?? 0;
    const hospitalUserId = this.props?.chats?.[requestId]?.hospitalUserId ?? 0;

    this.props.postChatMessage({
      requestId,
      hospitalUserId,
      senderId,
      text,
      type
    });
  };

  render() {
    const chats = this.getChats();
    const selectedChat = this.getSelectedChat();
    const selectedRequestId = this.getSelectedRequestId();

    // return early if no chats found
    if (chats.length === 0) return null;

    // show chat launcher if minimized
    if (!this.state.isOpen) {
      return (
        <LiveChatLauncher
          user={this.props.user}
          chats={this.props.chats}
          handleWindowToggle={this.handleWindowToggle}
        />
      );
    }

    return (
      <div className="liveChatWindow">
        <LiveChatHeader
          selectedChat={selectedChat!}
          isCancelEnabled={this.state.isCancelEnabled}
          isReassignEnabled={this.state.isReassignEnabled}
          handleWindowToggle={this.handleWindowToggle}
          handleCancelRequest={this.handleCancelRequest}
          handleReassignRequest={this.handleReassignRequest}
        />
        <div className="liveChatBody">
          <LiveChatConversation
            user={this.props.user}
            onNewMessage={this.onNewMessage}
            selectedChat={selectedChat!}
            sendMessage={this.sendMessage}
            handleConfirmRide={this.handleConfirmRide}
            handleRejectRide={this.handleRejectRide}
          />

          <div className="liveChatConversationList">
            <ul>
              {chats.map(chat => (
                <LiveChatConversationListItem
                  key={chat.requestId}
                  chat={chat}
                  user={this.props.user}
                  selectedRequestId={selectedRequestId}
                  setSelectedRequest={this.props.setSelectedRequest}
                />
              ))}
            </ul>
          </div>
        </div>

        {this.state.path ? (
          <Redirect
            to={{
              pathname: this.state.path,
              state: {
                rideId: this.state.rideId,
                selectedRequestId: this.state.selectedRequestId
              }
            }}
          />
        ) : null}

        {this.state.showReassignWindow ? (
          <LiveChatReassign
            selectedChat={selectedChat}
            selectedVehicleOwnerId={this.state.selectedVehicleOwnerId}
            hideReassignWindow={this.handleReassignWindowClose}
            confirmReassignment={this.handleReassignmentConfirmation}
            user={this.props.user}
            chats={this.props.chats}
            bookingData={this.props.bookingData}
          />
        ) : null}
      </div>
    );
  }
}

const mapStateToProps = ({ user, chats, bookingData }) => ({ user, chats, bookingData });

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      postChatMessage: data => postMessage(data),
      bookRideRequest: data => bookRide(data),
      cancelChatRequest: data => cancelRequest(data),
      putMessagesRead: data => readMessages(data),
      setSelectedRequest: data => selectRequest(data),
      reassignNemt: data => reassignNemt(data),
      confirmSuggestion: data => confirmSuggestion(data)
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(LiveChat);
