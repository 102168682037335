import type { UserStore } from '~/Modules/user';
import type { ChatRecord, ChatMessage, ChatUser } from '~/types';
import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';

type LiveChatMessageSeenProps = {
  senderId: number;
  sentAt: string;
  userId: number;
  sender: ChatUser;
  wasRead: boolean;
};

const LiveChatMessageSeen = ({
  senderId,
  sentAt,
  userId,
  sender,
  wasRead
}: LiveChatMessageSeenProps) => {
  if (parseInt(senderId.toString()) === 0) {
    return (
      <sub>
        <div>SafeRide Bot</div>
        <div>Sent {sentAt}</div>
      </sub>
    );
  }

  if (senderId === userId) {
    return wasRead ? (
      <sub>
        <div>Me</div>
        <div>
          Sent {sentAt} | Read <span>&#10004; &#10004;</span>
        </div>
      </sub>
    ) : (
      <sub>
        <div>Waiting for dispatcher.</div>
        <div>Not seen yet.</div>
      </sub>
    );
  }

  return (
    <sub>
      <div>
        {sender.firstName} {sender.lastName}
      </div>
      <div>Sent {sentAt}</div>
    </sub>
  );
};

type LiveChatMessageProps = {
  user: UserStore;
  selectedChat: ChatRecord;
  message: ChatMessage;
};

const LiveChatMessage = ({ user, message, selectedChat }: LiveChatMessageProps) => {
  const userId = user?.userData?.id ?? 0;
  const senderId = message?.senderId ?? 0;
  const sender = selectedChat?.users?.[senderId] ?? {};
  const sentAt = moment(message.sentAt).format('h:mm a, MMM Do');

  const classNames = [
    'liveChatMessage',
    senderId === userId ? 'sent' : 'received',
    message.type
  ];

  return (
    <div className={classNames.join(' ')}>
      <div className="liveChatMessageText">{message.text}</div>
      <LiveChatMessageSeen
        wasRead={message.readers.length > 0}
        sentAt={sentAt}
        senderId={senderId}
        sender={sender}
        userId={userId}
      />
    </div>
  );
};

LiveChatMessage.propTypes = {
  user: PropTypes.object,
  selectedChat: PropTypes.object,
  message: PropTypes.object
};

LiveChatMessage.defaultProps = {
  user: {},
  selectedChat: {},
  message: {}
};

export default LiveChatMessage;
