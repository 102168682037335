import { Is, createModelFactory, type ModelType } from '@SRHealth/frontend-lib';
import type { PropertyRuleSignature } from '~/models';

export type FundingSourceProps = {
  /** The facility ID that the passenger is associated with.  */
  facilityId: number | undefined;
  /** OPTIONAL: The treatment ID that the ride will be booked under. */
  healthSubPlanTreatmentId: number | undefined;
  /** OPTIONAL: The hospital network's custom field (Compliance) values. */
  complianceInfo: Record<string, string | number | boolean | null | undefined>;
  /** OPTIONAL: The hospital network's dependent field values. */
  dependentFields: Record<string, string | number | boolean | null | undefined>;
  /** OPTIONAL: The hospital's custom field values. */
  hospitalCustomFields: Record<string, string | undefined>;
};

export type FundingSourceModel = ModelType<FundingSourceProps>;

export const FUNDING_SOURCE_DEFAULT = (
  props: Partial<FundingSourceProps> = {}
): FundingSourceProps => ({
  facilityId: undefined,
  healthSubPlanTreatmentId: undefined,
  complianceInfo: {},
  dependentFields: {},
  hospitalCustomFields: {},
  ...props
});

export const fundingSourceFactory = createModelFactory<FundingSourceModel>(
  FUNDING_SOURCE_DEFAULT(),
  {
    properties: [
      ['is-number', 'facilityId', Is.Number.strict],
      [
        // The ride custom fields are dependent on the hospital. If
        // the facility changes, reset the custom fields.
        'facility-id-resets-custom-fields',
        'facilityId',
        (v, m, isCommit) => {
          if (!isCommit && m.facilityId !== v) {
            m.hospitalCustomFields = {};
          }

          return true;
        }
      ],
      ['is-record', 'complianceInfo', Is.JsonObject.strict],
      ['is-record', 'dependentFields', Is.JsonObject.strict],
      ['is-record', 'hospitalCustomFields', Is.JsonObject.strict]
    ],
    model: []
  }
);

export type FundingSourcePropertyRule = PropertyRuleSignature<FundingSourceProps>;

/** Optional rule that's added in if the member's health sub plan requires treatments. */
export const TREATMENTS_RULE: FundingSourcePropertyRule = [
  'is-number',
  'healthSubPlanTreatmentId',
  // Only type check on commit because we're mapping this to
  // an InputSearchDropdown which allows clearing values.
  (v, _m, isCommit) => !isCommit || Is.Number.strict(v)
];
