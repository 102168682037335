import React, { useCallback, useMemo } from 'react';
import {
  addressApprovedProviderNotFoundFactory,
  type AddressApprovedProviderNotFoundModel,
  type AddressMapbox,
  type AddressModel
} from '~/models';
import { genDefaultAddressOptions } from './Address.utils';
import useMapboxSearch from '~/hooks/useMapboxSearch';
import { Input, TOKENS, useModel } from '@SRHealth/frontend-lib';
import { cleanPhoneNumber, formatPhoneNumber } from '~/utilities/phone';

export type CareProviderUnapprovedFormProps = {
  address: AddressApprovedProviderNotFoundModel;
  onAddressUpdate: (address: AddressModel) => void;
};

export default function CareProviderUnapprovedForm({
  address,
  onAddressUpdate
}: CareProviderUnapprovedFormProps) {
  const { model } = useModel(address, 0);
  const defaultMapOpt = useMemo(() => genDefaultAddressOptions(model), [model.value]);

  const [isLoading, mapboxOptions, handleMapboxInput] = useMapboxSearch(defaultMapOpt);
  const currentMapbox = mapboxOptions.find(({ value }) => value.value === model?.value);

  /** Event handler for changes to the mapbox input. */
  const handleMapboxChange = useCallback(
    (_, mapbox: AddressMapbox) => {
      const newModelProps: Partial<AddressApprovedProviderNotFoundModel> = {
        type: 'provider-not-found',
        id: model?.id,
        name: model?.name,
        facility: model?.facility,
        phone: model?.phone,
        notes: model?.notes
      };

      if (mapbox) {
        newModelProps.latitude = mapbox.latitude;
        newModelProps.longitude = mapbox.longitude;
        newModelProps.value = mapbox.value;
      } else {
        newModelProps.latitude = undefined;
        newModelProps.longitude = undefined;
        newModelProps.value = '';
      }

      onAddressUpdate(addressApprovedProviderNotFoundFactory(newModelProps));
    },
    [onAddressUpdate]
  );

  return (
    <>
      <div className="flex flex-col gap-[8px]">
        <Input
          inputId={0}
          name="address"
          label="Address"
          type="search-dropdown"
          loading={isLoading}
          options={mapboxOptions}
          value={currentMapbox?.value}
          dropdownSize={6}
          debounce={650}
          placeholder="Street, City, State, Zip Code"
          onInput={handleMapboxInput}
          onSearch={handleMapboxInput}
          onChange={handleMapboxChange}
          error={'value' in model.ruleErrors}
          required
        />
      </div>
      <div className="flex flex-col gap-[8px]">
        <Input
          inputId={1}
          name="name"
          label="Provider Name"
          type="single"
          value={model?.name}
          placeholder="Name"
          onChange={(_, v: string) => (model.name = v)}
          error={'name' in model.ruleErrors}
          required
        />
        <p className={`text-sm text-${TOKENS.FONT.NEUTRAL}`}>
          If there is no provider use &quot;Unknown&quot;
        </p>
      </div>
      <div className="flex flex-col gap-[8px]">
        <Input
          inputId={2}
          name="facility"
          label="Facility"
          type="single"
          value={model?.facility}
          placeholder="Facility"
          onChange={(_, v: string) => (model.facility = v)}
          error={'facility' in model.ruleErrors}
          required
        />
        <p className={`text-sm text-${TOKENS.FONT.NEUTRAL}`}>
          If there is no facility use &quot;Unknown&quot;
        </p>
      </div>
      <div className="flex flex-col gap-[8px]">
        <Input
          inputId={3}
          name="phone"
          label="Provider Phone Number"
          type="single"
          value={formatPhoneNumber(model.phone || '')}
          placeholder="(_ _ _) _ _ _ - _ _ _ _"
          onChange={(_, v: string) => (model.phone = cleanPhoneNumber(v))}
          error={'phone' in model.ruleErrors}
          maxLength={17} // cannot exceed 13 characters per ride_provider_notes table phone field max length, +4 characters for the parentheses, space, and dash
          required
        />
      </div>
      <div className="flex flex-col gap-[8px]">
        <Input
          inputId={4}
          name="notes"
          label="Additional Notes"
          type="textarea"
          value={model?.notes}
          placeholder="Any additional information: NPI Number, building name, floor number, etc."
          onChange={(_, v: string) => (model.notes = v)}
          error={'notes' in model.ruleErrors}
        />
        <p className={`text-sm text-${TOKENS.FONT.NEUTRAL}`}>
          Please provide any information needed for updating the provider list or for
          traveling to an unlisted destination.
        </p>
      </div>
    </>
  );
}
