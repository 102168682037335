import {
  Is,
  createModelFactory,
  createTypeGuard,
  type ModelType
} from '@SRHealth/frontend-lib';
import { type Binary, type PhoneNumberType } from '~/types';
import { isPhoneNumberType } from '~/utilities/guards';
import { getPhoneType } from '~/services/member.service';
import {
  additionalPassengerFactory,
  type PropertyRuleSignature,
  type AdditionalPassengerModel
} from '~/models';
import { isPhoneNumberValid } from '~/utilities/phone';

export type TripType = 'private' | 'public' | 'mileage-reimbursement' | 'alternate';

export const isTripType = createTypeGuard((t: unknown): TripType | null => {
  if (
    t === 'private' ||
    t === 'public' ||
    t === 'mileage-reimbursement' ||
    t === 'alternate'
  ) {
    return t;
  }

  return null;
});

export type PassengerInfoProps = {
  passengerId: number;
  tripType: TripType;
  /** Valid Transport Types are defined by the passenger sub plan. */
  transportType: string | undefined;
  subTransportType?: number;
  mobilityDevice?: string | undefined;
  serviceLevel?: string | undefined;
  /** Assistance needs. */
  specialCircumstances: string[];
  driverProvisions: string[];
  phoneNumber: string | undefined;
  phoneNumberType: PhoneNumberType | undefined;
  /** A null value indicates that we have looked up the phone number
   * in the `phone_numbers` table and not found a match. Undefined
   * indicates we have not performed the lookup. */
  phoneNumberOptOutStatus: Binary | null | undefined;
  phoneNumberOptOutPromptStatus: Binary | null | undefined;
  alternatePhoneNumber: string | undefined;
  additionalPassengers: AdditionalPassengerModel[];
};
export type PassengerInfoModel = ModelType<PassengerInfoProps>;

export type PassengerInfoPropertyRule = PropertyRuleSignature<PassengerInfoProps>;

/** Generate a copy of the default model props. May be worth looking into
 * adding a utility to just obtain this directly from the factory or from
 * a model. */
export const PASSENGER_INFO_DEFAULT = (): PassengerInfoProps => ({
  passengerId: 0,
  tripType: 'private',
  transportType: undefined,
  subTransportType: undefined,
  mobilityDevice: undefined,
  serviceLevel: undefined,
  phoneNumber: '',
  phoneNumberType: undefined,
  phoneNumberOptOutStatus: undefined,
  phoneNumberOptOutPromptStatus: undefined,
  alternatePhoneNumber: undefined,
  additionalPassengers: [],
  // Assistance needs
  specialCircumstances: [],
  driverProvisions: []
});

export const passengerInfoFactory = createModelFactory<PassengerInfoModel>(
  PASSENGER_INFO_DEFAULT(),
  {
    properties: [
      ['is-number', 'passengerId', v => Is.Number.strict(v) && v > 0],
      ['is-trip-type', 'tripType', isTripType.strict],
      [
        // If we are changing trip type then the transport type needs to be reset.
        'resets-transport-type',
        'tripType',
        (v, m, isCommit) => {
          if (!isCommit && v !== m.tripType) {
            m.transportType = undefined;
            m.subTransportType = undefined;
          }

          return true;
        }
      ],
      [
        // Non-private trips cannot have mobility devices or service levels.
        'supports-service-level-or-mobility-device',
        'tripType',
        (v, m, isCommit) => {
          if (isCommit && v !== 'private') {
            m.serviceLevel &&= undefined;
            m.mobilityDevice &&= undefined;
          }

          if (!isCommit) {
            m.subTransportType = undefined;
          }

          return true;
        }
      ],
      [
        'is-transport-type',
        'transportType',
        (v, _, isCommit) => isCommit && Is.String.strict(v)
      ],
      [
        'resets-sub-transport-type',
        'transportType',
        (v, m, isCommit) => {
          if (isCommit && v !== m.transportType) {
            m.subTransportType = undefined;
          }

          return true;
        }
      ],
      [
        // Ensures that changing the transport type to Lyft or Uber
        // will update the transport type for all additional passengers. We
        // do not support mixed transport types for additional passengers if
        // the main passenger is using Lyft or Uber.
        'rideshare-type-updates-additional-passengers',
        'transportType',
        (v, m) => {
          if (v === 'LFT' || v === 'UBR') {
            m.additionalPassengers = m.additionalPassengers.map(p => {
              p.transportType = v;
              return p;
            });
          }
          return true;
        }
      ],
      ['is-string', 'subTransportType', v => Is.Undefined(v) || Is.Number.strict(v)],
      [
        'is-string-or-undefined',
        'mobilityDevice',
        v => Is.Undefined(v) || Is.String.strict(v)
      ],
      [
        'is-string-for-private-trip',
        'mobilityDevice',
        (v, m, isCommit) => {
          if (isCommit && m.tripType === 'private' && !v) {
            throw Error(
              'Invalid trip transport type. A mobility device must be selected for private trips.'
            );
          }

          return true;
        }
      ],
      [
        'is-string-or-undefined',
        'serviceLevel',
        v => Is.Undefined(v) || Is.String.strict(v)
      ],
      [
        'is-string-for-private-trip',
        'serviceLevel',
        (v, m, isCommit) => {
          if (isCommit && m.tripType === 'private' && !v) {
            throw Error(
              'Invalid trip transport type. A driver assistance option must be selected for private trips.'
            );
          }

          return true;
        }
      ],
      ['is-string', 'phoneNumber', Is.String.strict],
      [
        'is-phone-domestic-number',
        'phoneNumber',
        (v: string, _m, isCommit) => {
          if (isCommit && v.replace(/\D/g, '').length < 10) {
            throw Error(
              'Invalid phone domestic number. Must be a valid 10-digit phone number.'
            );
          }

          return true;
        }
      ],
      [
        'reset-type-on-change',
        'phoneNumber',
        (v, m) => {
          if (v !== m.phoneNumber && !Is.Undefined(m.phoneNumberType)) {
            m.phoneNumberType = undefined;
          }

          return true;
        }
      ],
      [
        'reset-opt-out-on-change',
        'phoneNumber',
        (v, m) => {
          if (v !== m.phoneNumber && !Is.Undefined(m.phoneNumberOptOutStatus)) {
            m.phoneNumberOptOutStatus = undefined;
          }

          return true;
        }
      ],
      [
        'reset-opt-out-prompt-on-change',
        'phoneNumber',
        (v, m) => {
          if (v !== m.phoneNumber && !Is.Undefined(m.phoneNumberOptOutPromptStatus)) {
            m.phoneNumberOptOutPromptStatus = undefined;
          }

          return true;
        }
      ],
      [
        'is-phone-number-type',
        'phoneNumberType',
        v => Is.Undefined(v) || isPhoneNumberType.strict(v)
      ],
      [
        'is-binary',
        'phoneNumberOptOutStatus',
        v => Is.Undefined(v) || Is.Null(v) || Is.Binary.strict(v)
      ],
      [
        'is-binary',
        'phoneNumberOptOutPromptStatus',
        v => Is.Undefined(v) || Is.Null(v) || Is.Binary.strict(v)
      ],
      [
        'is-string-or-undefined',
        'alternatePhoneNumber',
        v => Is.Undefined(v) || Is.String.strict(v)
      ],
      [
        'is-additional-passenger',
        'additionalPassengers',
        v => {
          if (!Array.isArray(v)) {
            throw TypeError('Invalid additional passengers data. Must be an array.');
          }

          if (!v.every(m => additionalPassengerFactory.createdModel(m))) {
            throw TypeError(
              'Invalid additional passengers data. Must be an array of additional passengers models.'
            );
          }

          return true;
        }
      ],
      [
        'limit-five',
        'additionalPassengers',
        (v: AdditionalPassengerModel[]) => v.length <= 5
      ],
      [
        'is-string-array',
        'specialCircumstances',
        v => Array.isArray(v) && !!v.every(Is.String.strict)
      ]
    ],
    model: [
      [
        'valid-trip-phone-number-and-type',
        async model => {
          if ('phoneNumber' in model.ruleErrors) {
            throw Error(
              'Invalid trip phone number data. Must have a valid phone number.'
            );
          }

          if (!model.phoneNumber && model.phoneNumberType) {
            throw TypeError(
              'Invalid trip phone number data. Must have both phone number and type.'
            );
          }

          if (model.phoneNumber) {
            if (!isPhoneNumberValid(model.phoneNumber.replace(/\D/g, ''))) {
              model.ruleErrors.phoneNumber ??= {};
              model.ruleErrors.phoneNumber['valid-trip-phone-number-and-type'] =
                'Invalid phone number. Unable to determine phone number type.';

              throw Error('Invalid phone number. Unable to determine phone number type.');
            }

            if (!model.phoneNumberType) {
              const { data } = await getPhoneType(
                model.passengerId.toString(),
                model.phoneNumber
              );

              model.phoneNumberType = data.type;
              model.phoneNumberOptOutStatus = data.passengerPhoneNumber.optOut;
              model.phoneNumberOptOutPromptStatus = data.passengerPhoneNumber
                .optOutPromptTimestamp
                ? 1
                : 0;
            }
          }

          return true;
        }
      ],
      [
        'valid-trip-alternate-phone-number',
        async model => {
          if ('alternatePhoneNumber' in model.ruleErrors) {
            throw Error(
              'Invalid alternate phone number data. Must have a valid phone number.'
            );
          }

          if (model.alternatePhoneNumber) {
            if (!isPhoneNumberValid(model.alternatePhoneNumber.replace(/\D/g, ''))) {
              model.ruleErrors.alternatePhoneNumber ??= {};
              model.ruleErrors.alternatePhoneNumber['valid-trip-alternate-phone-number'] =
                'Invalid alternate phone number.';

              throw Error('Invalid alternate phone number.');
            }
          }

          return true;
        }
      ],
      [
        'valid-additional-passengers',
        async (model, isCommit) => {
          const additionalPassengers = model.additionalPassengers;

          const commitValues = await Promise.all(
            additionalPassengers.map(passenger => {
              passenger.fastValidate = false;
              passenger.throwOnValidationError = false;

              return isCommit ? passenger.commit() : passenger.validate();
            })
          );

          const invalidPassengerIndexes: number[] = [];
          commitValues.forEach((v: boolean, i) => {
            if (!v) invalidPassengerIndexes.push(i);
          });

          if (invalidPassengerIndexes.length) {
            throw Error(
              'Invalid additional passengers data. The following passengers' +
                ` are invalid: ${invalidPassengerIndexes.join(', ')}`
            );
          }

          return true;
        }
      ]
    ]
  }
);
