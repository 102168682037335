import SimpleCheckbox from 'Common/Components/SimpleCheckbox';
import { addDays } from 'date-fns';
import _ from 'lodash-es';
import moment from 'moment-timezone';
import PropTypes from 'prop-types';
import TimePicker from 'rc-time-picker';
import React from 'react';
import { connect } from 'react-redux';
import ReactTooltip from 'react-tooltip';
import { bindActionCreators } from 'redux';
import {
  DATE_FORMAT_INPUT,
  DATE_TIME_FORMAT,
  DAY_START,
  LYFT_VEHICLE_ID,
  REGEX_TIME,
  TIME_FORMAT,
  UBER_VEHICLE_ID
} from '~/constants';
import { updateBookingData, updateRideData } from '~/Modules/bookingData';
import { checkLocation } from '~/Modules/mapbox';
import Radio from '~/Shared/Components/Radio/Radio';
import {
  didBookingDataUpdate,
  didBookingTimeUpdate,
  standardizeRideAddressFieldPrefixes
} from '~/utilities/editRide.helper';
import {
  NOOP,
  allowSameDayMultiLeg,
  timeWithTimezone
} from '~/utilities/helperFunctions';
import { calculateDistance } from '~/utilities/map';
import validateRideForm from '~/utilities/rideFormValidation';
import { csrTimezoneAdjustedDate } from '~/utilities/users';
import Calendar from '../../../Calendar/Calendar';
import InputText from '../../../InputText';
import SvgInfo from '../../../Svgs/SvgInfo';
import SvgInsertInvitation from '../../../Svgs/SvgInsertInvitation';

const ARRIVAL_TEXT =
  'The time in which you want to arrive to your final destination (generally to an appointment)';
const DEPART_TEXT =
  'The time in which you want to depart from your starting destination (generally from an appointment)';
const ON_DEMAND_TEXT = `If you're ready to depart now from your destination`;
const CHECK_LOCATION_ERROR = 'One of the addresses must be an approved location.';
const WILL_CALL_FLEX_TOOLTIP =
  'Rideshare rides can be initiated by the rider via text or within this portal. <br />  <br /> NEMT rides can only be initiated within this portal.';
const WILL_CALL_NO_FLEX_TOOLTIP = 'Initiate ride when rider is ready.';
class AppointmentTime extends React.Component {
  /**
   * constructor
   * @param {object} props, list of props passed down from RideReport.js
   * @return {undefined} returns nothing
   */
  constructor(props) {
    super(props);
    this.refApptEnds = {};
    this.refApptTime = {};

    this.state = {
      openCalendar: false,
      openTimePicker: false,
      selectedDate: this.getSelectedDate(),
      selectedTime: this.getSelectedTime(),
      bookingType: this.getBookingType(),
      timeSelectorLeft: 'timeSelectors left',
      extraTime: null,
      bookingTypeError: '',
      isWillCall: this.isWillCall(this.getSelectedTime()),
      appointmentErrorText: '',
      disabledDays: this.getDisabledDays(),
      fromMonth: this.getFromMonth(),
      toMonth: this.getDefaultCalendarDate(),
      willCallTooltip: WILL_CALL_NO_FLEX_TOOLTIP,
      didUpdateRide: false,
      didUpdateDate: false,
      timezone: this.getTimezone()
    };
    this.currentRideIndex = -1;
    this.verifyLocation = false;
  }

  /**
   * initialize component state
   * @return {undefined}
   */
  componentDidMount() {
    const { bookingData, user, editBookedRide } = this.props;

    const state = {};
    const rideData = this.getRideData();

    // No null checks. We want this to error loudly if hospitalData
    // is not populated. A lot of care depends on it
    const hospData = user.hospitalData;

    //determine Will Call tooltip
    const hospitalObjectOfBooking = !Array.isArray(hospData)
      ? hospData.lyftFlexStatus
      : hospData.filter(({ id }) => id === bookingData?.hospitalId)[0].lyftFlexStatus;

    if (hospitalObjectOfBooking === 'active') {
      this.setState({ willCallTooltip: WILL_CALL_FLEX_TOOLTIP });
    }

    // get time custom fields
    if (Object.hasOwn(rideData, 'timeCustomFields')) {
      _.forEach(rideData.timeCustomFields, (val, key) => {
        const idVal = `time_custom_fields[${key}]`;
        state[idVal] = val;
      });
    }

    // get extra time field
    if (rideData?.extraTime) {
      state.extraTime = rideData.extraTime;
    }

    // this.props.bookingData.selectedDate is null if you're booking a new ride.
    // for BLEG for a roundtrip booking this condition will be true.
    if (Object.hasOwn(rideData, 'selectedDate')) {
      // are you editing an already booked ride or a ride in confirmation status?
      if (this.props.editForm === true) {
        if (!editBookedRide) {
          this.resetBookingData();
        }
      }
    }

    const locationsAllow = _.get(this.props, 'patientDetails.locations', 0);
    if (locationsAllow === 1) {
      this.verifyLocation = true;
    }

    this.setState(state);
  }

  componentDidUpdate(prevProps) {
    const prevBookingData = prevProps.bookingData;
    const {
      editRide,
      bookingData,
      checkLocationData,
      pickupTimezone,
      toggleLoading,
      transportTypeValidate
    } = this.props;

    const newState = {};

    if (prevProps.pickupTimezone !== pickupTimezone && !editRide) {
      newState.selectedTime = this.getSelectedTime();
      newState.selectedDate = this.getSelectedDate();
      newState.disabledDays = this.getDisabledDays();
      newState.fromMonth = this.getFromMonth();
      newState.toMonth = this.getDefaultCalendarDate();
      newState.timezone = this.getTimezone();
    }

    if (
      _.has(bookingData, 'currentRideIndex') &&
      bookingData.currentRideIndex !== prevBookingData.currentRideIndex
    ) {
      this.currentRideIndex = bookingData.currentRideIndex;
    }

    // location data address check
    if (!_.isEqual(checkLocationData, prevProps.checkLocationData)) {
      toggleLoading(false);

      if (checkLocationData.status === false) {
        transportTypeValidate(['pickupAddress', 'dropoffAddress'], CHECK_LOCATION_ERROR);
      } else {
        transportTypeValidate([], '');
        this.submitTimeInfo();
      }
    }

    // This logic should only trigger the first time either
    // the ride address or the datetime data changes
    if (!this.state.didUpdateRide) {
      const rideUpdate = didBookingDataUpdate(prevBookingData, bookingData);
      if (rideUpdate || editRide !== true) {
        newState.didUpdateRide = true;
      }
    }

    // check if we need to set state.didUpdateDate to true
    if (!this.state.didUpdateDate && didBookingTimeUpdate(prevBookingData, bookingData)) {
      newState.didUpdateDate = true;
    }

    if (Object.keys(newState).length > 0) {
      this.setState(newState);
    }
  }

  isMultileg = () => {
    const { bookingData } = this.props;

    return bookingData?.bookingType === 'multileg';
  };

  isWillCall = string => {
    return string === 'Will Call';
  };

  allowWillCall = bookingType => {
    const { bookingData, isBleg } = this.props;

    return (
      (isBleg || bookingType === 'fromcare') &&
      !bookingData?.isPastRide &&
      !bookingData?.pastRide
    );
  };

  getTimeFormat = time => {
    return this.isWillCall(time) ? time : time.format(TIME_FORMAT);
  };

  getTimezone = (bookingType = this.getBookingType()) => {
    const { pickupTimezone, dropoffTimezone, isBleg } = this.props;
    const { timezone_format } = this.props.user.userData;

    if (!isBleg && bookingType === 'tocare' && dropoffTimezone) return dropoffTimezone;
    if (isBleg && dropoffTimezone) return dropoffTimezone;
    if (pickupTimezone) return pickupTimezone;
    if (timezone_format) return timezone_format;
    return moment.tz.guess();
  };

  getMomentWithTimezone = () => {
    const timezone = this.getTimezone();

    return moment.tz(timezone);
  };

  getPreviousLegTime = () => {
    const { bookingData } = this.props;

    let prevLegTime = _.get(
      this.props,
      `bookingData.rides.${bookingData.currentRideIndex - 1}.apptTime`,
      ''
    );
    if (this.isWillCall(prevLegTime)) {
      prevLegTime = this.getMomentWithTimezone().format(TIME_FORMAT);
    }

    return prevLegTime;
  };

  getMultilegTime = (legDepth, currentLeg) => {
    const { bookingData } = this.props;
    const currentRide = bookingData.rides[currentLeg];

    if (currentLeg === 0) {
      if (legDepth === 0) {
        if (currentRide.apptTime && !this.isWillCall(currentRide.apptTime)) {
          return moment(currentRide.apptTime, TIME_FORMAT);
        }
        return this.addTime(
          this.getMomentWithTimezone().format(TIME_FORMAT),
          5,
          'minutes'
        );
      } else {
        return this.addTime(
          this.isWillCall(currentRide.apptTime)
            ? this.getMomentWithTimezone().format(TIME_FORMAT)
            : currentRide.apptTime,
          legDepth,
          'hour'
        );
      }
    }

    if (
      currentRide.apptTime &&
      !this.isWillCall(currentRide.apptTime) &&
      legDepth !== 0
    ) {
      return this.addTime(currentRide.apptTime, legDepth, 'hour');
    }

    return this.getMultilegTime(legDepth + 1, currentLeg - 1);
  };

  getMultiLegSelectedTime = (legDepth, currentLeg, returnWillCall) => {
    const { bookingData, legIndex } = this.props;

    if (bookingData?.rides?.[legIndex].apptTime) {
      if (this.isWillCall(bookingData.rides?.[legIndex].apptTime)) {
        if (returnWillCall) {
          return 'Will Call';
        }
      } else {
        return moment(bookingData.rides?.[legIndex].apptTime, TIME_FORMAT);
      }
    }

    return this.getMultilegTime(legDepth, currentLeg);
  };

  getBlegSelectedTime = returnWillCall => {
    const { bookingData } = this.props;

    if (this.isWillCall(bookingData.apptEnds)) {
      return returnWillCall ? 'Will Call' : this.addTime(bookingData.apptTime, 1, 'hour');
    }

    if (bookingData.apptTime === bookingData.apptEnds) {
      return this.addTime(bookingData.apptTime, 1, 'hour');
    }

    return bookingData.apptEnds;
  };

  getSelectedTime = (returnWillCall = true) => {
    const { legIndex, isBleg, bookingData } = this.props;

    let selectedTime;
    if (this.isMultileg()) {
      return this.getMultiLegSelectedTime(0, legIndex, returnWillCall);
    } else if (isBleg && bookingData?.apptEnds) {
      selectedTime = this.getBlegSelectedTime(returnWillCall);
    } else if (bookingData?.apptTime) {
      selectedTime = bookingData.apptTime;
    }

    if (
      !selectedTime ||
      (this.isWillCall(selectedTime) &&
        (!returnWillCall || !this.allowWillCall(this.getBookingType())))
    ) {
      return this.addTime(this.getMomentWithTimezone().format(TIME_FORMAT), 5, 'minutes');
    }

    return this.isWillCall(selectedTime)
      ? 'Will Call'
      : moment(selectedTime, TIME_FORMAT);
  };

  /**
   * Transforms the selected time to the format in which it is displayed
   * @param {string} selectedTime
   * @return {string} display string
   */
  getTimeDisplay = selectedTime => {
    return this.isWillCall(selectedTime)
      ? 'Will Call'
      : timeWithTimezone(
        selectedTime,
        this.getTimezone(this.state.bookingType),
        TIME_FORMAT
      );
  };

  getDateWithTimezone = selectedDate => {
    if (selectedDate) {
      return moment(selectedDate, 'MM/DD/YYYY').toDate();
    }

    return csrTimezoneAdjustedDate(
      this.getMomentWithTimezone().toDate(),
      this.getTimezone()
    );
  };

  getMultilegSelectedDate = () => {
    const { bookingData, legIndex, savedRideData } = this.props;

    const firstLegDate = bookingData.rides[0]?.selectedDate;

    if (legIndex !== 0) {
      return this.getDateWithTimezone(firstLegDate);
    }

    if (firstLegDate) {
      const savedDate = this.getDateWithTimezone(firstLegDate);

      if (
        this.addDays(DAY_START, 1) <= savedDate ||
        bookingData.pastRide ||
        savedRideData.isPastRide
      ) {
        return savedDate;
      }
    }

    let date = this.getDateWithTimezone(false);

    if (bookingData.pastRide) {
      return this.addDays(date, -1);
    }

    if (allowSameDayMultiLeg().indexOf(bookingData.hospitalId) === -1) {
      date = this.addDays(date, 1);
    }

    return date;
  };

  getSelectedDate = () => {
    const { bookingData, isBleg } = this.props;

    if (this.isMultileg()) {
      return this.getMultilegSelectedDate();
    }

    let date = this.getDateWithTimezone(bookingData.selectedDate);
    const currentDateWithTimezone = this.getDateWithTimezone(false);

    if (bookingData.pastRide && !isBleg && date >= currentDateWithTimezone) {
      date = this.addDays(currentDateWithTimezone, -1);
    }

    return date;
  };

  getBookingType = () => {
    const { bookingData, legIndex, isBleg } = this.props;

    if (this.props.roundTrip) {
      return isBleg ? 'fromcare' : 'tocare';
    }

    if (this.isMultileg()) {
      if (
        bookingData?.rides[legIndex]?.bookingType &&
        ['tocare', 'fromcare'].includes(bookingData.rides[legIndex].bookingType)
      ) {
        return bookingData.rides[legIndex].bookingType;
      }

      return 'fromcare';
    }

    if (bookingData?.bookingType) {
      return bookingData.bookingType;
    }

    return 'fromcare';
  };

  getDefaultCalendarDate = () => {
    return csrTimezoneAdjustedDate(
      this.getMomentWithTimezone().toDate(),
      this.getTimezone()
    );
  };

  getDisabledDays = () => {
    const { bookingData, savedRideData } = this.props;

    const baseDate = this.getDefaultCalendarDate();

    let disabledDays = [{ before: baseDate }];

    if (this.isMultileg()) {
      if (allowSameDayMultiLeg().indexOf(bookingData.hospitalId) === -1) {
        disabledDays = [{ before: this.addDays(baseDate, 1) }];
      }
    }

    if (bookingData.pastRide) {
      const { pastBookingDays = 0 } = bookingData;
      disabledDays = [
        {
          before: addDays(baseDate, -pastBookingDays),
          after: this.isMultileg() ? addDays(baseDate, -1) : addDays(baseDate, 0)
        }
      ];
    }

    if (savedRideData.isPastRide) {
      disabledDays = [
        {
          before: addDays(baseDate, -this.props.user.pastEditDays),
          after: addDays(baseDate, -1)
        }
      ];
    }

    return disabledDays;
  };

  getFromMonth = () => {
    const { bookingData, savedRideData, user } = this.props;

    let fromMonth = this.getDefaultCalendarDate();

    if (bookingData.pastRide) {
      const { pastBookingDays = 0 } = bookingData;

      fromMonth = addDays(new Date().setHours(0, 0, 0, 0), -pastBookingDays);
    }

    //if editing a ride from the past as opposed to a booked 'past ride'
    if (savedRideData.isPastRide) {
      fromMonth = addDays(new Date().setHours(0, 0, 0, 0), -user.pastEditDays);
    }

    return fromMonth;
  };

  /**
   * handles text input
   * @param {object} e - event
   * @returns {undefined} returns nothing
   */
  changeCustomFields = e => {
    const id = e.target.id;
    const val = e.target.value;
    const stateObj = {};

    stateObj[id] = val;

    if ((id === 'extraTime' || id.indexOf('time_custom_fields')) > -1 && val !== '') {
      const validate = this.validateExtraTime(val);
      stateObj.extraTimeError = !validate;
    }
    this.setState(stateObj);
  };

  /**
   * @param {event} e - event
   * @return {undefined}
   */
  handleRadioClick = e => {
    const { timezone } = this.state;

    e.preventDefault();
    const bookingType = e.target.value;
    let state = {
      bookingType,
      bookingTypeError: ''
    };
    if (bookingType === 'ondemand') {
      // reset date and time
      const selectedDate = moment.tz(timezone).format(DATE_FORMAT_INPUT);
      const prevApptTime = moment.tz(timezone).format(TIME_FORMAT);

      const initialTimes = this.addTimeAndReturnState(
        prevApptTime,
        selectedDate,
        5,
        'minutes',
        state
      );

      state.isWillCall = false;

      state = Object.assign(state, initialTimes);
    } else if (this.state.isWillCall) {
      state.selectedTime = this.getSelectedTime(false);
      state.isWillCall = false;
    }

    this.setState(state);
  };

  /**
   * open time component
   * @return {undefined}
   */
  handleTimeClick = () => {
    if (
      (this.state.isWillCall === false || this.state.bookingType === 'tocare') &&
      this.state.bookingType !== 'ondemand'
    ) {
      this.toggleOpen();
    }
  };

  /**
   * handle time change event and save value into component state
   * @param {date} time - date object of time
   * @return {undefined}
   */
  handleChange = time => {
    if (time !== null) {
      if (this.state.appointmentErrorText === '') {
        this.setState({
          selectedTime: time
        });
      } else {
        this.setState({ selectedTime: time }, () => {
          this.submitTimeInfo(false);
        });
      }
    }
  };

  /**
   * open DayPicker calendar component
   * @return {undefined}
   */
  handleDateClick = () => {
    const showCalendar = this.showCalendar();

    if (showCalendar) {
      this.setState({ openCalendar: true });
    }
  };

  /**
   * submit handler for calendar modal
   * @param {object} params - date values
   * @return {undefined}
   */
  submitModal = params => {
    if (this.state.appointmentErrorText === '') {
      this.setState({ selectedDate: params.startDate });
    } else {
      this.setState({ selectedDate: params.startDate }, () => {
        this.submitTimeInfo(false);
      });
    }
  };

  /**
   * close calendar modal
   * @return {undefined}
   */
  closeModal = () => {
    this.setState({ openCalendar: false });
  };

  /**
   * toggle for opening time picker
   * @return {undefined}
   */
  toggleOpen = () => {
    this.setState(prevState => ({
      openTimePicker: !prevState.openTimePicker
    }));
  };

  /**
   * submit handler for time component
   * updates application and component state
   * @param {boolean} doSubmit - submit the form, default to true
   * @return {false} returns false if not validated
   */
  submitTimeInfo = async (doSubmit = true) => {
    const { bookingType, didUpdateDate, didUpdateRide, selectedTime } = this.state;
    const { bookingData: _bookingData, user, editRide } = this.props;

    if (this.verifyLocation && !this.props?.checkLocationData?.status) {
      const params = {
        hospitalId: _bookingData.hospitalId,
        healthPlanId: _bookingData.healthPlanId,
        healthSubPlanId: _bookingData.healthSubPlanId,
        pickupAddress: _bookingData.pickupAddress,
        dropoffAddress: _bookingData.dropoffAddress
      };
      this.props.toggleLoading(true, 'Verifying addresses...');
      this.props.checkLocation(params);
      return false;
    }

    let bookingData = this.isMultileg()
      ? _.cloneDeep(_bookingData.rides[this.currentRideIndex])
      : _.cloneDeep(_bookingData);

    bookingData.bookingType = bookingType;

    bookingData.healthPlanId ??= _bookingData.healthPlanId;

    if (bookingData.status === 'Incomplete') {
      bookingData.status = 'Processing';
    }

    // Hard block is missing from rides for multilegs. Make sure it's set
    // for validation purposes
    if (_bookingData.approvedProvidersHardBlock) {
      bookingData.approvedProvidersHardBlock = _bookingData.approvedProvidersHardBlock;
    }

    const oldBookingData = _.cloneDeep(bookingData);
    const formattedTimeOrWillCall = this.getTimeFormat(selectedTime);
    const isRideShare = [LYFT_VEHICLE_ID, UBER_VEHICLE_ID].includes(
      bookingData.transportType
    );

    bookingData.apptEnds = formattedTimeOrWillCall;
    if (this.props.isBleg) {
      bookingData.bookingType = 'roundtrip';
    } else {
      bookingData.apptTime = formattedTimeOrWillCall;

      // The updates we perform on bookingData here don't actually carry over
      // to the backend (and ultimately back to the FE) the same way we pass them
      // so we have to standardize oldBookingData too. Yay!
      oldBookingData.apptEnds = oldBookingData.apptTime;
    }

    let valid = true;
    if (!_.isNil(this.state.extraTime)) {
      const validate = this.validateExtraTime(this.state.extraTime);
      this.setState({ extraTimeError: !validate });

      if (validate) {
        bookingData.extraTime = this.state.extraTime;
      } else {
        valid = false;
      }
    }

    bookingData.selectedDate = moment(this.state.selectedDate).format(DATE_FORMAT_INPUT);

    // if you update selected date through calendar picker for 1st ride in group of multi leggged rides
    if (
      this.currentRideIndex === 0 &&
      _.has(this.props, 'bookingData.rides') &&
      _bookingData.rides.length > 1
    ) {
      // update selectedDate for all rides
      const bData = _bookingData;

      bData.rides = bData.rides.map((ride, i) => {
        if (i !== 0) {
          ride.selectedDate = bookingData.selectedDate;
        }
        return ride;
      });

      this.props.updateBookingData(bData);
    }

    const timeCustomFields = this.getTimeCustomFields(
      user?.userData?.role,
      _bookingData.hospitalId
    );

    if (timeCustomFields.length > 0) {
      const timeCust = this.validateExtraTimeFields(timeCustomFields, valid);

      if (!editRide && (timeCust || bookingData?.timeCustomFields)) {
        bookingData.timeCustomFields = timeCust;
      }
    }

    // remove venue id if it's null
    if (Object.hasOwn(bookingData, 'pickupVenueId') && !bookingData.pickupVenueId) {
      _.unset(bookingData, 'pickupVenueId');
    }

    if (Object.hasOwn(bookingData, 'dropoffVenueId') && !bookingData.dropoffVenueId) {
      _.unset(bookingData, 'dropoffVenueId');
    }

    // check location
    const checkFormValid = this.checkFormsValid(bookingData);

    if (!valid || !checkFormValid || !doSubmit) {
      return false;
    }

    const { success, distance } = await calculateDistance({
      from: `${bookingData.pickupLongitude},${bookingData.pickupLatitude}`,
      to: `${bookingData.dropoffLongitude},${bookingData.dropoffLatitude}`
    });

    if (success) {
      bookingData.distance = distance;
    }

    /*
     * since we submit both transportation data changes and appointment time changes here, we want to make sure the data actually
     * changed before we call either the updateDate or updateRide endpoints in the parent component.
     */
    if (this.props.editRide === true && !_.isEmpty(this.props.savedRideData)) {
      const ride = standardizeRideAddressFieldPrefixes(this.props.savedRideData);

      if (bookingData.displayApprovedProviders) {
        ride.dropoffAdditionalNotes = ride.dropoffAdditionalNotes ?? '';
        ride.pickupAdditionalNotes = ride.pickupAdditionalNotes ?? '';

        // We don't care if these fields so just make them equivalent
        ride.showDropoffApprovedProvider = bookingData.showDropoffApprovedProvider;
        ride.showPickupApprovedProvider = bookingData.showPickupApprovedProvider;
      }
    }

    if (this.currentRideIndex > -1 && this.isMultileg()) {
      const rideData = _.cloneDeep(bookingData);

      if (bookingData?.distance) {
        rideData.distance = bookingData.distance;
      }
      bookingData = this.props.bookingData;
      this.props.updateRideData(rideData, this.currentRideIndex);
      bookingData.rides[this.currentRideIndex] = rideData;
    } else {
      this.props.updateBookingData(bookingData);
    }

    // Ride share updates all go through the same endpoint. Calling updateDate misses out
    // on multiple date related fields so we have to add a "special" check for rideshare here.
    if (isRideShare && (didUpdateRide || didUpdateDate)) {
      this.props.updateAppointmentInfo(bookingData, true);
    } else {
      // For NEMT if Time and appointmentInfo both are changed then submit to one common endpoint rides/edit
      // There isn't really a point to this because the updateDate and updateAppointmentInfo target completely
      // different endpoints and logic so passing true for the second parameter in updateAppointInfo is NOT the
      // same. But hey, let's not break things further!
      if (didUpdateRide) {
        const submitTimeDataWithEditRide =
          didUpdateDate &&
          !this.isWillCall(bookingData.apptTime) &&
          parseInt(bookingData.transportType, 10) !== 8;

        this.props.updateAppointmentInfo(bookingData, submitTimeDataWithEditRide);
      }

      if (didUpdateDate) {
        this.props.updateDate(bookingData);
      }
    }
  };

  /**
   * removes round trip, takes you back to one way or round trip selection component
   * @return {undefined}
   */
  cancelRoundTrip = () => {
    const bookingData = _.clone(this.props.bookingData);

    bookingData.bookingType = 'tocare';
    _.unset(bookingData, 'apptEnds');
    this.props.updateBookingData(bookingData);
    this.props.cancelRoundTrip();
  };

  /**
   * handle will call check box
   * @param {event} e - synthetic event
   * @return {undefined}
   */
  handleWillCall = e => {
    const state = e.target.checked
      ? { isWillCall: e.target.checked, selectedTime: 'Will Call' }
      : {
        isWillCall: e.target.checked,
        selectedTime: this.getSelectedTime(false)
      };

    const callback = !this.state.appointmentErrorText
      ? () => {}
      : () => {
        this.submitTimeInfo(false);
      };

    this.setState(state, callback);
  };

  /**
   * client side validation for ride booking times
   * @param {object} rideData - data
   * @returns {boolean} returns true if it validates, false if it doesn't
   */
  checkFormsValid(rideData) {
    const { timezone } = this.state;
    // clear out triggered error styles
    let timeSelectorLeft = 'timeSelectors left';
    let errorClass = '';
    let bookingTypeError = '';
    const extraTimeError = '';
    let appointmentErrorText = '';

    const errMsg = [];
    const errFields = [];
    if (!validateRideForm(rideData, errFields, errMsg)) {
      this.props.transportTypeValidate(errFields, errMsg[0]);
      return false;
    } else {
      this.props.transportTypeValidate([], '');
    }

    const hourRegex = RegExp(REGEX_TIME);
    if (!this.isWillCall(rideData.apptTime) && !hourRegex.test(rideData.apptTime)) {
      errorClass = ' error';
      appointmentErrorText = 'The time is in an incorrect format. ';
    }

    const apptTimeVal = rideData.apptTime;

    const apptTimeStr = `${rideData.selectedDate} ${apptTimeVal}`;
    const apptTime = moment.tz(apptTimeStr, DATE_TIME_FORMAT, timezone);
    const isAfterCurrentTime = apptTime.isAfter(new Date());

    // past booking
    const isPastRide = this.props?.bookingData?.pastRide ?? false;
    const rideFromPast = this.props?.savedRideData?.isPastRide ?? false;

    if (
      !isAfterCurrentTime &&
      !this.isWillCall(apptTimeVal) &&
      !isPastRide &&
      !rideFromPast
    ) {
      errorClass = ' error';
      appointmentErrorText += 'The booking time selected is too early. ';
    }

    if (this.props.roundTrip === false && this.props.editRide === false) {
      if (this.state.bookingType === '') {
        bookingTypeError = ' error';
      }
    }

    let pickupVenueStatus = true;
    let dropoffVenueStatus = true;

    if (
      rideData?.showPickupVenue &&
      (!rideData.pickupEntranceName || !rideData.pickupVenueName)
    ) {
      pickupVenueStatus = false;
    }

    if (
      rideData?.showDropoffVenue &&
      (rideData.dropoffEntranceName === '' || rideData.dropoffVenueName === '')
    ) {
      dropoffVenueStatus = false;
    }

    // for roundtrip we have to check 2 time fields and make sure end time is not before start time
    if (this.props.roundTrip && this.props.isBleg) {
      const endTimeVal = rideData.apptEnds;
      if (!endTimeVal === '') {
        errorClass = ' error';
      } else {
        let apptEnds = '';
        if (this.isWillCall(endTimeVal)) {
          apptEnds = rideData.apptEnds;
        } else if (hourRegex.test(rideData.apptEnds)) {
          apptEnds = moment.tz(
            `${rideData.selectedDate} ${rideData.apptEnds}`,
            DATE_TIME_FORMAT,
            timezone
          );

          if (apptEnds.isBefore(moment()) && !isPastRide) {
            errorClass = ' error';
            appointmentErrorText += 'The dropoff time selected is too early. ';
          }

          if (apptTime.isAfter(apptEnds) && !isPastRide) {
            errorClass = ' error';
            appointmentErrorText += 'Pickup time needs to be before dropoff time. ';
          }
        } else {
          //regex failed
          errorClass = ' error';
        }
      }
    } else {
      // a leg, check address information
      const fields = [
        'pickupAddress',
        'dropoffAddress',
        'pickupLatitude',
        'dropoffLatitude',
        'pickupLongitude',
        'dropoffLongitude'
      ];
      const fieldsThatExist = Object.keys(rideData);
      const difference = _.difference(fields, fieldsThatExist);

      // verify venue mapping
      if (!pickupVenueStatus && difference.indexOf('pickupAddress') === -1) {
        difference.push('pickupAddress');
      }

      if (!dropoffVenueStatus && difference.indexOf('dropoffAddress') === -1) {
        difference.push('dropoffAddress');
      }

      if (difference.length > 0) {
        // access callback to modify TransportType fields
        this.props.transportTypeValidate(difference);
        return false;
      }

      // for multileg rides, make sure current leg time is after previous leg time
      const { bookingData } = this.props;
      if (this.isMultileg() && this.currentRideIndex > 0) {
        const prevRide = bookingData.rides[this.currentRideIndex - 1];
        const prevLegDate = moment(
          `${prevRide.selectedDate} ${prevRide.apptTime}`,
          DATE_TIME_FORMAT
        );
        const currentDate = moment(
          `${rideData.selectedDate} ${rideData.apptTime}`,
          DATE_TIME_FORMAT
        );

        // each leg has to be after the previous leg
        if (
          moment(currentDate).isBefore(prevLegDate) &&
          !this.isWillCall(rideData.apptTime)
        ) {
          errorClass = ' error';
          appointmentErrorText += ` The booking time entered for this ride must be after the previous ride.`;
        }
      }
    }

    timeSelectorLeft = `${timeSelectorLeft}${errorClass}`;
    this.setState({ timeSelectorLeft, bookingTypeError, appointmentErrorText });
    if (
      errorClass === ' error' ||
      bookingTypeError === ' error' ||
      extraTimeError === ' error'
    ) {
      return false;
    }

    return true;
  }

  /**
   * return time custom fields
   * @param {string} userRole - user role
   * @param {integer} hospitalId - hospital id
   * @return {array} - returns custom fields
   */
  getTimeCustomFields(userRole, hospitalId) {
    if (userRole === 'HospitalOwner' || userRole === 'HospitalNetworkManager') {
      if (!_.isNil(this.props.timeCustomFields[hospitalId])) {
        return this.props.timeCustomFields[hospitalId];
      }
      return [];
    }
    return this.props.timeCustomFields;
  }

  /**
   * Create custom field id string from field id
   * @param {string | number} id
   * @returns {string}
   */
  getTimeCustomFieldId = id => `time_custom_fields${id}`;

  /**
   * function that checks to see if a calendar should be shown
   * @return {boolean} - returns true if calendar should be displayed, false if not
   */
  showCalendar() {
    let showCalendar = true;
    if (this.props.roundTrip && this.props.isBleg) {
      showCalendar = false;
    }
    if (this.state.bookingType === 'ondemand') {
      showCalendar = false;
    }

    // for multiple leg booking disable date field after first leg
    if (this.currentRideIndex > 0) {
      showCalendar = false;
    }

    return showCalendar;
  }

  /**
   * if ride is edited then remove any location data, ride note data, b leg appt
   * @return {undefined}
   */
  resetBookingData() {
    const bookingData = _.cloneDeep(this.props.bookingData);
    if (this.props.roundTrip) {
      _.unset(bookingData, 'apptEnds');
    }
    _.unset(bookingData, 'rideCustomFields');
    _.unset(bookingData, 'payWithCard');
    _.unset(bookingData, 'repeatAppointmentData');

    this.props.updateBookingData(bookingData, true);
  }

  /**
   * check extra time to make sure its a number
   * @param {integer} extraTime - value to check
   * @return {boolean} - returns true or false
   */
  validateExtraTime(extraTime) {
    const regex = /^[0-9]\d*$/;
    return regex.test(extraTime);
  }

  /**
   * Iterates through each of the custom time fields and validates them.
   * If validation fails for any then argument "valid" will be changed
   * @param {array} timeCustomFields The custom fields
   * @param {boolean} valid Boolean passed by reference and adjusted in validation fails
   * @returns {object} Returns an object with "id" => "custom field value"
   */
  validateExtraTimeFields(timeCustomFields, valid) {
    if (timeCustomFields.length < 1) {
      return {};
    }

    let _valid = valid;

    const timeCust = {};

    for (let i = 0; i < timeCustomFields.length; i++) {
      const timeId = timeCustomFields[i].id;
      const timeVal = this.state[`time_custom_fields[${timeId}]`];

      if (timeVal !== undefined && timeVal !== '') {
        const validate = this.validateExtraTime(timeVal);

        this.setState({ extraTimeError: !validate });
        if (validate) {
          timeCust[timeId] = timeVal;
        } else if (_valid) {
          _valid = false;
        }
      }
    }

    valid = _valid;
    return timeCust;
  }

  addDays = (date, days) => {
    const result = new Date(date);
    result.setDate(result.getDate() + days);
    return result;
  };

  addTime = (previousTime, timeToAdd, timeField) => {
    return moment(previousTime, TIME_FORMAT).add(timeToAdd, timeField);
  };

  /**
   * helper function for adding one hour for round trip and multiple ride booking
   * @param {date} previousTime - previous time that will be updated
   * @param {string} selectedDate - date selected in previous booked leg
   * @param {integer} timeToAdd - amount of time to add
   * @param {string} timeField - hour, minute ,etc
   * @param {object} timeFields - fields consisting values that will update state with new booking time values
   * @return {object} - returns updated time values of 1 hour
   */
  addTimeAndReturnState(previousTime, selectedDate, timeToAdd, timeField, timeFields) {
    const updatedTime = this.addTime(previousTime, timeToAdd, timeField);

    timeFields.selectedTime = updatedTime;
    timeFields.selectedDate = moment(selectedDate, DATE_FORMAT_INPUT).toDate();

    return timeFields;
  }

  /**
   * simple helper function that extracts ride data. for editing booked rides, or new ride just export bookingData
   * if youre editing rides in the booking process, extract ride in the booked rides array
   * @return {object} - returns ride object
   */
  getRideData() {
    const bookingData = _.cloneDeep(this.props.bookingData);
    let ride = {};
    this.currentRideIndex = _.get(bookingData, 'currentRideIndex', -1);
    if (this.currentRideIndex > -1 && this.isMultileg()) {
      if (this.props.legIndex > -1) {
        this.currentRideIndex = this.props.legIndex;
      }
      ride = _.get(bookingData, `rides.${this.currentRideIndex}`, {});
    } else {
      ride = bookingData;
    }

    return ride;
  }

  render() {
    const {
      extraTime = '',
      extraTimeError,
      timeSelectorLeft: _timeSelectorLeft,
      timePickerClass,
      bookingType,
      bookingTypeError,
      openTimePicker,
      selectedTime,
      selectedDate,
      disabledDays,
      fromMonth,
      toMonth,
      appointmentErrorText,
      isWillCall,
      willCallTooltip,
      openCalendar
    } = this.state;
    const { user, bookingData, roundTrip, editRide, isBleg } = this.props;

    const userRole = user?.userData?.role ?? '';
    const hospitalId = bookingData?.hospitalId ?? '';

    const dateDisplay = moment(selectedDate).format(DATE_FORMAT_INPUT);
    const timeCustomFields = this.getTimeCustomFields(userRole, hospitalId);

    const shouldShowCalendar = this.showCalendar();
    const shouldShowCustomFields = bookingType !== 'ondemand' || (roundTrip && isBleg);

    const timeSelectorLeft =
      bookingType === 'ondemand' ? `${_timeSelectorLeft} disabled` : _timeSelectorLeft;

    const extraTimeClass = extraTimeError === true ? 'customField error' : 'customField';

    return (
      <div className="appointmentTime">
        <div className="appointmentTimeSection clearfix">
          <div className="rideTypeCont">
            {!roundTrip && !editRide ? (
              <>
                {!this.isMultileg() ? (
                  <AppointmentTimeBookingTypeRadio
                    value={'ondemand'}
                    error={bookingTypeError}
                    label="Depart Now"
                    tooltip={ON_DEMAND_TEXT}
                    bookingType={bookingType}
                    onChange={this.handleRadioClick}
                  />
                ) : null}
                <AppointmentTimeBookingTypeRadio
                  value={'tocare'}
                  error={bookingTypeError}
                  label="Arrive By"
                  tooltip={ARRIVAL_TEXT}
                  bookingType={bookingType}
                  onChange={this.handleRadioClick}
                />
                <AppointmentTimeBookingTypeRadio
                  value={'fromcare'}
                  error={bookingTypeError}
                  label="Depart At"
                  tooltip={DEPART_TEXT}
                  bookingType={bookingType}
                  onChange={this.handleRadioClick}
                />
              </>
            ) : bookingType === 'tocare' ? (
              <>
                <AppointmentTimeBookingTypeRadio
                  value={'tocare'}
                  error={bookingTypeError}
                  label="Arrive By"
                  tooltip={ARRIVAL_TEXT}
                  bookingType={bookingType}
                  className="rideTypes addPadding"
                />
              </>
            ) : (
              <>
                <AppointmentTimeBookingTypeRadio
                  value={'fromcare'}
                  error={bookingTypeError}
                  label="Depart At"
                  tooltip={DEPART_TEXT}
                  bookingType={bookingType}
                  className="rideTypes addPadding"
                />
              </>
            )}
          </div>

          <div className="timeCont">
            <div className={timeSelectorLeft}>
              <span className="timeSelect" onClick={this.handleTimeClick}>
                {this.getTimeDisplay(selectedTime)}
              </span>

              {openTimePicker ? (
                <TimePicker
                  showSecond={false}
                  defaultValue={selectedTime}
                  className={timePickerClass}
                  onChange={this.handleChange}
                  format={TIME_FORMAT}
                  use12Hours
                  inputReadOnly
                  open={openTimePicker}
                  onOpen={this.toggleOpen}
                  onClose={this.toggleOpen}
                />
              ) : null}
            </div>

            <AppointmentTimeDateSelect
              disabled={!shouldShowCalendar}
              date={dateDisplay}
              onClick={this.handleDateClick}
            />

            <AppointmentTimeErrorText errorText={appointmentErrorText} />

            {editRide ? (
              <div className="timeCustomFields">
                <AppointmentTimeCustomTimeFieldsExistingRide
                  timeCustomFields={timeCustomFields}
                  bookingType={bookingType}
                  isRoundTrip={roundTrip}
                  isBleg={isBleg}
                  className={extraTimeClass}
                  extraTime={extraTime}
                  error={extraTimeError}
                  onChange={this.changeCustomFields}
                />
              </div>
            ) : (
              <div className="timeCustomFields">
                {shouldShowCustomFields
                  ? timeCustomFields.map((field, key) => {
                    return (
                      <AppointmentTimeCustomTimeField
                        field={field}
                        fieldId={this.getTimeCustomFieldId(field.id)}
                        key={key}
                        isRoundTrip={roundTrip}
                        error={extraTimeError}
                        onChange={this.changeCustomFields}
                        value={this.state[this.getTimeCustomFieldId(field.id)] ?? ''}
                      />
                    );
                  })
                  : null}
              </div>
            )}
            <div className="buttonRow">
              {this.allowWillCall(bookingType) ? (
                <label htmlFor="willcall" className="willCallLabel">
                  <SimpleCheckbox checked={isWillCall === true} />
                  <p className="labelText">Will Call</p>
                  <input
                    type="checkbox"
                    name="willcall"
                    id="willcall"
                    onChange={this.handleWillCall}
                    value="Will Call"
                    checked={isWillCall}
                  />
                  <div className="info" data-tip={willCallTooltip} data-for="arrivalTip">
                    <SvgInfo className="infoSvg" />
                    <ReactTooltip
                      className="overrideTooltip"
                      effect="solid"
                      id="arrivalTip"
                      html={true}
                    />
                  </div>
                </label>
              ) : null}
              <button className="setAppointmentTime" onClick={this.submitTimeInfo}>
                Continue
              </button>
            </div>
          </div>
        </div>
        {shouldShowCalendar ? (
          <Calendar
            closeCalendar={this.closeModal}
            openCalendar={openCalendar}
            startDate={selectedDate}
            submitModal={this.submitModal}
            disabledDays={disabledDays}
            fromMonth={fromMonth}
            toMonth={toMonth}
          />
        ) : null}
      </div>
    );
  }
}

export const AppointmentTimeBookingTypeRadio = ({
  value,
  error,
  label,
  bookingType,
  tooltip = '',
  className = 'rideTypes',
  onChange = NOOP
}) => {
  const isChecked = value === bookingType;
  const labelClassName = error ? `${className} error` : className;

  return (
    <label className={labelClassName} htmlFor={value}>
      <Radio selected={isChecked} className="radioButton" />
      <input
        type="radio"
        id={value}
        name="bookingType"
        checked={isChecked}
        onChange={e => onChange(e)}
        value={value}
        required
      />
      <span className="labelText">{label}</span>
      <div className="info" data-tip={tooltip} data-for={`${value}Tip`}>
        <SvgInfo className="infoSvg" />
        <ReactTooltip className="overrideTooltip" effect="solid" id={`${value}Tip`} />
      </div>
    </label>
  );
};

export const AppointmentTimeErrorText = ({ errorText }) => {
  if (!errorText) {
    return null;
  }

  return <p className="errorText">{errorText}</p>;
};

export const AppointmentTimeDateSelect = ({ disabled, date, onClick = () => {} }) => {
  const className = disabled ? 'timeSelectors right disabled' : 'timeSelectors right';

  return (
    <div className={className}>
      <span className="dateSelect" onClick={onClick}>
        {date}
      </span>
      <SvgInsertInvitation className="whiteCalendar" />
    </div>
  );
};

export const AppointmentTimeCustomTimeFieldsExistingRide = ({
  timeCustomFields,
  bookingType,
  isRoundTrip,
  isBleg,
  className = '',
  extraTime,
  error = '',
  onChange = () => {}
}) => {
  if (!timeCustomFields || timeCustomFields.length < 1) {
    return null;
  }

  if (bookingType === 'ondemand') {
    return null;
  }

  if (isRoundTrip && isBleg) {
    return null;
  }

  const errorMsg = error ? (
    <p className="errorText">Please enter a valid number for extra time.</p>
  ) : null;

  return (
    <div className="timeCustomFields">
      <input
        type="text"
        className={className}
        name="extraTime"
        id="extraTime"
        placeholder="Extra Time"
        value={extraTime}
        onChange={onChange}
      />
      {errorMsg}
    </div>
  );
};

export const AppointmentTimeCustomTimeField = ({
  field,
  fieldId,
  value,
  error,
  isRoundTrip,
  onChange = () => {}
}) => {
  const label = isRoundTrip
    ? `Extra time applied to both legs of round trip.`
    : field.field_label_desc;

  return (
    <InputText
      type="text"
      value={value}
      fieldName={fieldId}
      placeholder={label}
      label={label}
      onChange={onChange}
      min={0}
      max={1000}
      errorText="Please enter a valid number for extra time."
      error={error}
    />
  );
};

/* set default prop values */
AppointmentTime.defaultProps = {
  updateBookingData: () => {},
  bookingData: {},
  updateAppointmentInfo: () => {},
  roundTrip: false,
  cancelRoundTrip: () => {},
  editForm: false,
  transportTypeValidate: () => {},
  editRide: false,
  timeCustomFields: [],
  user: {},
  updateDate: () => {},
  savedRideData: {},
  closeAppointmentForm: () => {},
  editBookedRide: false,
  isBleg: false,
  updateRideData: () => {},
  legIndex: -1,
  checkLocation: () => {},
  patientDetails: {},
  checkLocationData: {},
  clearAll: () => {},
  toggleLoading: () => {},
  pickupTimezone: ''
};

AppointmentTime.propTypes = {
  updateBookingData: PropTypes.func,
  bookingData: PropTypes.object,
  updateAppointmentInfo: PropTypes.func,
  roundTrip: PropTypes.bool,
  cancelRoundTrip: PropTypes.func,
  editForm: PropTypes.bool,
  transportTypeValidate: PropTypes.func,
  editRide: PropTypes.bool,
  timeCustomFields: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
  user: PropTypes.object,
  updateDate: PropTypes.func,
  savedRideData: PropTypes.object,
  closeAppointmentForm: PropTypes.func,
  editBookedRide: PropTypes.bool,
  isBleg: PropTypes.bool,
  updateRideData: PropTypes.func,
  legIndex: PropTypes.number,
  checkLocation: PropTypes.func,
  patientDetails: PropTypes.object,
  checkLocationData: PropTypes.object,
  pickupTimezone: PropTypes.string,
  clearAll: PropTypes.func,
  toggleLoading: PropTypes.func
};

const mapStateToProps = state => ({
  bookingData: state.bookingData,
  timeCustomFields: state.user.timeCustomFields,
  user: state.user,
  patientDetails: state.patients.patientDetails,
  checkLocationData: state.locationCheck.checkLocation,
  pickupTimezone: state.mapbox.pickupAddressTimezone,
  dropoffTimezone: state.mapbox.dropoffAddressTimezone
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      updateBookingData: bookRideData => updateBookingData(bookRideData),
      updateRideData: (rideData, index) => updateRideData(rideData, index),
      checkLocation: params => checkLocation(params)
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(AppointmentTime);
