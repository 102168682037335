import React, { useMemo } from 'react';
import { AWV_BANNER } from '~/constants';
import './AWVBanner.styl';
import { Button } from '@SRHealth/frontend-lib';
import type { UserStore } from '~/Modules/user';
const REVIEW_SOP_URL =
  'https://saferidehealth0-my.sharepoint.com/:w:/g/personal/brian_saferidehealth_com/EVhFkk5XUJNNsfym9Waa9PgBblX9HAw0RK2c_B0JAlSHOA?e=cpbOmG';

const REQUIRED_LABELS = ['AWV - Member interested', 'AWV - Member not interested'];

const COPY = {
  REVIEW_SOP: 'Review SOP',
  SEND_AWV_TEXT: 'Send AWV Text',
  BANNER_TITLE: 'Offer member to book an Annual Wellness Visit (AWV) appointment',
  BANNER_SUBTITLE: 'Remember to record final disposition of this effort in custom fields'
};

function AWVBanner({
  features,
  validation
}: {
  features: UserStore['features'];
  validation: Record<string, unknown>;
}) {
  const show = useMemo(() => {
    if (!features[AWV_BANNER]) return false;

    const requiredItems = Object.values(validation).filter((item: { label: string }) =>
      REQUIRED_LABELS.includes(item.label)
    );

    if (requiredItems.length !== REQUIRED_LABELS.length) return false;

    return requiredItems.every(
      (item: { value?: string; options?: { slug: string; label: string }[] }) =>
        !item.value ||
        !Array.isArray(item.options) ||
        !item.options.some(opt => opt.slug === item.value && opt.label !== '')
    );
  }, [features, validation]);

  if (!show) return null;

  return (
    <div className="member-awv-banner-wrapper">
      <div className="member-awv-banner-info">
        <p className="banner-info-title">{COPY.BANNER_TITLE}</p>
        <p className="banner-info-subtitle">{COPY.BANNER_SUBTITLE}</p>
      </div>
      <div className="member-awv-banner-buttons">
        <a
          href={REVIEW_SOP_URL}
          target="_blank"
          rel="noreferrer"
          className="member-awv-banner-button"
        >
          {COPY.REVIEW_SOP}
        </a>
        <Button id="default" label={COPY.SEND_AWV_TEXT} size="md" minWidth="100%" />
      </div>
    </div>
  );
}

export default AWVBanner;
