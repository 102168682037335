import type { App } from 'contracts/mod';
import type { RecurringRidePattern } from 'contracts/src/app-layer/rbf/v1/shared';
import type {
  DateProps,
  PassengerInfoProps,
  PublicCardModelProps,
  RideProps,
  TripType
} from '~/models';
import { baseRequestConfig } from '~/utilities/auth.helper';

/** Get ride booking flow date restrictions for a passenger. */
export const getDateRestrictions = (
  passengerId: number,
  tripType: TripType,
  transportType: string
) => {
  return fetch(
    `${process.env.APP_LAYER_URL}/rbf/v1/date-restrictions?passengerId=${passengerId}&tripType=${tripType}&transportType=${transportType}`,
    baseRequestConfig()
  ).then(res => res.json() as Promise<App.RBF.V1.GetDateRestrictionsResponse>);
};

/** Get the duplicate rides for a passenger on a given date. */
export const getDuplicateRides = (
  passengerInfo: PassengerInfoProps,
  date: DateProps,
  rides: RideProps[] = [],
  recurringRides: RecurringRidePattern | undefined = undefined
) => {
  return fetch(`${process.env.APP_LAYER_URL}/rbf/v1/duplicate-rides`, {
    ...baseRequestConfig(),
    method: 'POST',
    body: JSON.stringify({
      passengerInfo,
      date,
      rides,
      recurringRides
    })
  }).then(res => res.json() as Promise<App.RBF.V1.GetDuplicateRidesResponse>);
};

/** Retrieve the time restrictions for ride booking. */
export const getAvailableTimes = (
  transportType: string,
  tripType: TripType,
  currentDate: DateString
) => {
  return fetch(
    `${process.env.APP_LAYER_URL}/rbf/v1/available-times?transportType=${transportType}&tripType=${tripType}&currentDate=${currentDate}`,
    baseRequestConfig()
  ).then(res => res.json() as Promise<App.RBF.V1.GetAvailableTimesResponse>);
};

/** Retrieve the supply for the prospective ride. */
export const getSupply = (payload: App.RBF.V1.GetSupplyRequest) => {
  return fetch(`${process.env.APP_LAYER_URL}/rbf/v1/supply`, {
    method: 'POST',
    ...baseRequestConfig(),
    body: JSON.stringify(payload)
  })
    .then(res => res.json() as Promise<App.RBF.V1.GetSupplyResponse>)
    .then(res => res.data);
};

/** Books the ride(s) requested in the ride booking flow. */
export const book = (
  requestId: string | undefined,
  providerId: string | undefined,
  publicCards: PublicCardModelProps[] | undefined
) => {
  if (!requestId) throw Error('No ride request id found locally');
  const requestBody: App.RBF.V1.BookRideRequest = { requestId };

  if (providerId) {
    requestBody['providerId'] = providerId;
  }

  if (publicCards?.length) {
    requestBody.publicCards = publicCards;
  }

  return fetch(`${process.env.APP_LAYER_URL}/rbf/v1/book`, {
    method: 'POST',
    ...baseRequestConfig(),
    body: JSON.stringify(requestBody)
  })
    .then(res => res.json() as Promise<App.RBF.V1.BookRideResponse>)
    .then(res => res.data);
};
