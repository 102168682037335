import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import {
  Button,
  Card,
  Input,
  Section,
  useModal,
  Loader,
  TOKENS
} from '@SRHealth/frontend-lib';
import { useAppDispatch, useAppSelector } from '~/Modules';
import {
  completeTransportProviderThunk,
  RIDE_SECTION_ORDER,
  type PrivateProviders,
  type PublicProvider
} from '~/Modules/rideBooking';
import useModelSelector from '~/hooks/useModelSelector';
import PrivateTransports from './subcomponents/PrivateTransports';
import PublicTransports from './subcomponents/PublicTransports';
import { genericCancelBookingHandler } from '../../RideBooking.utils';
import TripScheduledModal from './subcomponents/TripScheduledModal';

const TransportProvider = () => {
  const history = useHistory();
  const dispatch = useAppDispatch();
  const [_, modalActions] = useModal();
  const tripType = useAppSelector(s => s.rideBooking.passengerInfo.tripType);
  const transportProviders = useAppSelector(s => s.rideBooking.meta.transportProviders);
  const confirmationMessage = useAppSelector(s => s.user.confirmationAgreement);
  const firstName = useAppSelector(s => s.memberProfile.formData.personalInfo.firstName);
  const lastName = useAppSelector(s => s.memberProfile.formData.personalInfo.lastName);
  const { model: selectedProvider } = useModelSelector(
    s => s.rideBooking.transportProvider
  );

  const [isLoading, setIsLoading] = useState(false);
  const [isComplianceError, setIsComplianceError] = useState(false);
  const [isComplianceConfirmed, setIsComplianceConfirmed] =
    useState(!confirmationMessage);

  // Does the user need to contact the transport provider via LiveChat?
  const mustContact =
    'isLivechatRequired' in selectedProvider && selectedProvider.isLivechatRequired;

  /** Verifies that the selected provider gets committed without issue and
   * that the compliance agreement has been confirmed by the user. */
  const preBookCommitAndCheck = async (): Promise<boolean> => {
    let commitError = null;

    // Attempt to commit the selected provider.
    await selectedProvider.commit().catch(error => (commitError = error));

    // Check compliance agreement.
    if (!isComplianceConfirmed) {
      // If the compliance agreement is not confirmed, surface red compliance text error to the user.
      setIsComplianceError(true);
    }

    // If there's a commit error or compliance is not confirmed, do not proceed.
    return !(commitError || !isComplianceConfirmed);
  };

  const handleScheduleTrip = async () => {
    try {
      setIsLoading(true);
      // Verify commit succeeded and compliance is confirmed before proceeding.
      if (!(await preBookCommitAndCheck())) return;

      const response = await dispatch(completeTransportProviderThunk()).unwrap();

      modalActions.set({
        isOpen: true,
        title: 'Trip Scheduled',
        content: (
          <TripScheduledModal
            firstName={firstName}
            lastName={lastName}
            selectedProvider={selectedProvider}
            modalActions={modalActions}
            initialRideId={response.rideIds[0]}
            returnRideId={response.rideIds[1]}
          />
        )
      });
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error('Failed to schedule trip:', error);
    } finally {
      // Will always run, even if early return.
      setIsLoading(false);
    }
  };

  const handleContactTransport = async () => {
    try {
      setIsLoading(true);
      // Commit and verify compliance before proceeding.
      if (!(await preBookCommitAndCheck())) {
        return setIsLoading(false);
      }

      await dispatch(completeTransportProviderThunk()).then(() => {
        history.push('/ride/scheduled');
      });
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error('Failed to contact transport:', error);
      // Only need to set isLoading to false if an error occurs. Otherwise, we'll be on a totally different page
      // by the time this function returns and the useState will not even exist.
      setIsLoading(false);
    }
  };

  // Display cancel booking section if is there are no transport providers available
  if (!transportProviders.length) {
    return (
      <Section.Footer>
        <div className="flex flex-row items-center justify-center">
          <Button
            label="Cancel Booking"
            onClick={genericCancelBookingHandler}
            minWidth="160px"
          />
        </div>
      </Section.Footer>
    );
  }

  return (
    <Section id="transport-provider" icon="CarTopView" label="Transport Provider">
      {tripType === 'public' ? (
        <PublicTransports selectedProvider={selectedProvider as PublicProvider} />
      ) : (
        <PrivateTransports selectedProvider={selectedProvider as PrivateProviders} />
      )}

      {confirmationMessage && (
        <Card label="Compliance Agreement" border={false}>
          <div className="reset-div flex flex-row" style={{ padding: '12px 0 0' }}>
            <Input
              inputId={0}
              name="confirmation"
              type="checkbox"
              label=""
              options={[{ value: true, label: '' }]}
              value={[isComplianceConfirmed]}
              onChange={() => {
                setIsComplianceConfirmed(!isComplianceConfirmed);
                // Reset the error on any change
                setIsComplianceError(false);
              }}
              error={isComplianceError}
            />
            <div
              className={`reset-div flex-1 ${
                isComplianceError ? `text-${TOKENS.FONT.ALERT}` : ''
              }`}
              dangerouslySetInnerHTML={{ __html: confirmationMessage }}
            />
          </div>
        </Card>
      )}

      {mustContact && (
        <p className="text-md" style={{ margin: '10px 0 -10px' }}>
          This trip needs to be confirmed by an NEMT to be scheduled.
        </p>
      )}

      <Section.Footer>
        <div className="flex justify-center">
          <div className="relative">
            {mustContact ? (
              <Button
                label="Contact Transport"
                size="lg"
                disabled={isLoading}
                onClick={handleContactTransport}
              />
            ) : (
              <Button
                label="Schedule Trip"
                size="lg"
                disabled={isLoading}
                onClick={handleScheduleTrip}
              />
            )}
            {isLoading && (
              <div
                className="absolute w-[36px] h-[36px]"
                style={{
                  left: '100%',
                  marginLeft: '4px',
                  top: '50%',
                  transform: 'translateY(-50%)'
                }}
              >
                <Loader background="app-white" color="primary-blue-md" />
              </div>
            )}
          </div>
        </div>
      </Section.Footer>
    </Section>
  );
};

TransportProvider.sectionIndex = RIDE_SECTION_ORDER['transport-provider'];

export { TransportProvider };
