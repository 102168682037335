import type { RideCardsRide } from '~/Modules/rideCards/rideCards.types';
import {
  LARAVEL_APP_URL,
  DATE_API_FORMAT,
  EMAIL_REGEX,
  ACTIVE_SCHEDULED_COMPLETED,
  CANCELLED_RIDES,
  EXPENSE_ID_MAX_LENGTH,
  USER_ROLES,
  RIDE_MODE,
  LYFT_VEHICLE_ID,
  UBER_VEHICLE_ID,
  REGEX_LAT_LNG,
  DAY_CHAR_TO_INT
} from '~/constants';
import moment from 'moment-timezone';
import type { Layout, Theme } from 'noty';
import Noty from 'noty';
import type { Address } from '~/types';
import { isArray, isString, isNumber, isObject } from './guards';
import { parsePhoneNumberFromString } from 'libphonenumber-js';
import { fixUTCDate } from './timesAndDates';

/** Simple common reference for function without logic. */
export const NOOP = () => {};

/**
 * Determine if the variable contains an empty value. Empty
 * values include:
 * * An array with length of 0
 * * A string with length of 0
 * * A number with a value of 0
 * * An object with no keys
 * * A value of undefined
 * * A value of null
 * @param value
 * @returns
 */
export function isEmpty(value: unknown) {
  if (!value) return true;

  if (isNumber(value)) {
    return value === 0;
  }

  if (isString(value) || isArray(value)) {
    return value.length === 0;
  }

  if (isObject(value)) {
    return Object.keys(value).length === 0;
  }

  return value === undefined || value === null;
}

/**
 * Deep clones an array or object. Tries with structuredClone first, then (if the browser doesn't support it)
 * falls back to JSON.parse(JSON.stringify())
 */
export function cloneDeep<T extends unknown[] | Record<string | number, unknown>>(
  value: T
): T {
  return 'structuredClone' in window
    ? window.structuredClone(value)
    : JSON.parse(JSON.stringify(value));
}

/**
 * modified a collection. A collection here is an array of objects, which is how we return rows of data from redux
 * @param {string} url - request uri
 * @return {string} - returns domain plus uri for url
 */
export const laravelUrl = url => {
  return `${LARAVEL_APP_URL}${url}`;
};

/**
 * Returns the ride start time
 * @returns {string} The start time
 */
export const getStartTime = ride => ride?.nemtRunPickup ?? ride?.rideStartTime;

/**
 * Formats the start date
 * @returns {string} MM/DD
 */
export const getStartDate = ride =>
  fixUTCDate(getStartTime(ride), ride.timezone_format, 'MM/DD');

/**
 * Formats pickup time to MM/DD h:mm A z
 * @returns {string} The formatted pickup time
 */
export const getPickupTime = ride =>
  fixUTCDate(getStartTime(ride), ride.timezone_format, 'MM/DD h:mm A z');

/**
 * Appends the three digit time zone abbreviation to a timestamp
 * @param {string} time The time
 * @param {string} timezone The timezone using standard moment.js format
 * @param {string} format The desired output format
 * @returns {string} MM/DD/YY hh:mm PST
 */
export const timeWithTimezone = (time, timezone, format) => {
  return `${moment(time).format(format)} ${moment.tz(timezone).zoneAbbr() || ''}`;
};

/**
 * returns ride card status
 * @param {object} myRide - ride object
 * @param {boolean} newUI - old layout or new layout
 * @param {string} timeFormat - format for delayed time
 * @return {object} status - status of ride card
 */
export const rideCardStatus = (myRide, newUI = false, timeFormat = 'HH:mm:ss') => {
  const ride = { ...myRide };
  const statusInfo = {
    status: ride.status,
    statusClassName: ride.status ? ride.status.toLowerCase() : '',
    cancelText: '',
    reorderText: 'REORDER',
    confirmationStatus: ''
  };

  const confirmationStatus = ride?.confirmation_status ?? null;
  const fullFillstatus = ['nemt_unable_to_fulfill', 'driver_unable_to_fullfill'];
  if (
    !newUI &&
    !fullFillstatus.includes(confirmationStatus) &&
    ACTIVE_SCHEDULED_COMPLETED.includes(ride.status)
  ) {
    return statusInfo;
  }

  switch (ride.status) {
    case 'Completed':
    case 'Incomplete':
      return statusInfo;

    case 'WillCall': {
      statusInfo.status = 'Will Call';

      if (confirmationStatus === 'nemt_unable_to_fulfill') {
        statusInfo.status = 'Unable to Fulfill';
        statusInfo.reorderText = 'Select Another NEMT';
        statusInfo.confirmationStatus = 'NEMT UNABLE TO FULFILL';
        statusInfo.statusClassName = 'cancelled';
      }
      return statusInfo;
    }

    case 'Processing': {
      statusInfo.status = 'Requesting Drivers';
      break;
    }

    case 'Scheduled': {
      if (confirmationStatus === 'unconfirmed') {
        statusInfo.confirmationStatus = 'SCHEDULED - CONFIRMING NEMT';
      }
      if (
        confirmationStatus === 'confirmed' ||
        confirmationStatus === 'driver_unable_to_fulfill'
      ) {
        statusInfo.confirmationStatus = 'CONFIRMED NEMT';
      }

      if (ride.lyftRideData && ride.lyftRideData.status === 'pending') {
        statusInfo.status = 'RIDE REQUESTED';
        statusInfo.confirmationStatus = '';
        statusInfo.statusClassName = 'requested';
      }

      break;
    }

    default:
  }

  const delayedStatuses = ['Scheduled', 'Processing', 'Inbound', 'Arrived', 'Started'];
  if (
    delayedStatuses.includes(ride.status) &&
    !fullFillstatus.includes(confirmationStatus)
  ) {
    let time = ride.rideStartTime;

    if (ride.status === 'Started') {
      time = moment
        .utc(ride.rideStartTime)
        .add(moment.duration(ride.estimatedFinishTime))
        .format(DATE_API_FORMAT);
    }

    statusInfo['delayedTime' as keyof typeof statusInfo] = getTimeDelayed(
      time,
      ride.passengerTimezone,
      DATE_API_FORMAT,
      timeFormat
    );

    return statusInfo;
  }

  if (
    CANCELLED_RIDES.includes(ride.status) ||
    confirmationStatus === 'nemt_unable_to_fulfill'
  ) {
    statusInfo.status = 'Cancelled';
    statusInfo.statusClassName = 'cancelled';

    if (ride.status === 'DriverCancelled') statusInfo.status = 'Driver Cancelled';

    if (ride.status === 'RequestMissed') statusInfo.status = 'Request Missed';

    if (confirmationStatus === 'nemt_unable_to_fulfill') {
      statusInfo.status = 'Unable to Fulfill';
      statusInfo.reorderText = 'Select Another NEMT';
      statusInfo.confirmationStatus = 'NEMT UNABLE TO FULFILL';
    }

    let cancelledBy = '';
    if (ride.cancelledBy === null) {
      if (ride.modelName === 'Lyft' || ride.cancelledByUserRole === 'lyft') {
        cancelledBy = `${cancelledBy} By Lyft`;
        // statusInfo.statusClassName = 'rejected';
      }
    } else {
      cancelledBy = ride?.cancelledByUserRole
        ? `${cancelledBy} by ${ride.cancelledByUserRole}`
        : `${cancelledBy} by`;
      cancelledBy = ride?.cancelledByUser
        ? `${cancelledBy} ${ride.cancelledByUser}`
        : ride?.cancelledByName
          ? `${cancelledBy} ${ride.cancelledByName}`
          : cancelledBy;
    }

    statusInfo['cancelledBy' as keyof typeof statusInfo] = cancelledBy;
    let cancelText = '';

    // Check for pusher update rideInfoUpdate
    if (ride?.reject_reason_txt) cancelText = ride.reject_reason_txt;

    // ridestatusupdate
    if (ride?.reject_reason_msg && !cancelText) cancelText = ride.reject_reason_msg;

    // check for api field on pageload, override above if it exists
    if (ride?.cancelText) cancelText = ride.cancelText;

    // request missed only
    if (ride.status === 'RequestMissed') cancelText = ride.reject_reason;

    statusInfo.cancelText = cancelText;
    return statusInfo;
  }

  if (ride.modelName === 'Lyft') return statusInfo;

  if (ride.rideType === 'ondemand') return statusInfo;

  statusInfo.status = ride.driverId ? 'Confirmed' : 'Confirming Driver';

  return statusInfo;
};

/**
 * Generates an ETA in the passenger's timezone
 * @param {string} passengerTimezone - the timezone abbreviation
 * @param {number} eta - the eta expressed in seconds
 * @returns {moment} Moment.js object
 */
export const generateETAString = (passengerTimezone, eta) =>
  moment().tz(passengerTimezone).add(eta, 'seconds').format('h:mm A z');

/**
 * Generates the duration of an active ride expressed as an ETA
 * @param {object} ride - ride card object
 * @returns {string} The ride duration
 */
export const generateRideDuration = (ride: RideCardsRide) => {
  try {
    const { status, passengerTimezone, timezone_format } = ride;

    if (!['Inbound', 'Started'].includes(status)) return '';

    const statusLocations = ride?.location?.[status];
    if (statusLocations?.length) {
      return generateETAString(
        timezone_format ?? passengerTimezone,
        statusLocations.at(-1)?.eta
      );
    }

    return '';
  } catch (e) {
    return '';
  }
};

/**
 * returns time intervals
 * @return {array} times - list of time intervals
 */
export const timeIntervals = () => {
  const timeIntervals = 24 * 4;
  const interval = 15;
  const times: string[] = [];
  let startTime = moment('12:00 am', 'hh:mm a');

  for (let i = 0; i < timeIntervals; i++) {
    times[i] = moment(startTime).format('hh:mm a');
    startTime = moment(startTime).add(interval, 'minutes');
  }
  return times;
};

/**
 * returns key value pairs for url parameters
 * @param {string} name - name of the request var you want the value of
 * @param {string} url - url, usually from window.location.href
 * @returns {string} return value for request var
 */
export const getParameterByName = (name, url) => {
  if (!url) url = window.location.href;

  // eslint-disable-next-line no-useless-escape
  name = name.replace(/[\[\]]/g, '\\$&');

  const results = new RegExp('[?&]' + name + '(=([^&#]*)|&|#|$)').exec(url);

  if (!results) return null;

  if (!results[2]) return '';

  return decodeURIComponent(results[2].replace(/\+/g, ' '));
};

/**
 * Returns offset value relative to the top of the page for any element
 * @param {object} el - DOM or ref element
 * @returns {object} {top: number, left: number}
 */
export const offset = el => {
  if (isEmpty(el)) return { top: 0, left: 0 };

  let { top, left } = el.getBoundingClientRect();
  top += window.pageXOffset || document.documentElement.scrollLeft;
  left += window.pageYOffset || document.documentElement.scrollTop;

  return { top, left };
};

/**
 * upper right status bar for status messaging, uses Noty notification library and animate.css library
 * @param {String} message - What do you want your notificatoin to say
 * @param {String} statusType - different notification status, such as notification, error, alert, warning, success, information
 * @param {integer} timeout - timeout for when animation triggers
 * @param {function} onClose - callback to call when closing notice
 * @returns {undefined} - returns nothing
 */
export const statusNotifications = (
  message,
  statusType,
  timeout = 600,
  onClose?: () => void
) => {
  const notyConfig: Noty.Options = {
    text: message,
    type: statusType,
    layout: 'topRight' as Layout,
    theme: 'mint' as Theme,
    timeout: timeout,
    closeWith: ['click'],
    animation: {
      open: 'animated bounceInRight', // Animate.css class names
      close: 'animated bounceOutRight' // Animate.css class names
    },
    callbacks: {
      onHover: function (this: { close: () => void }) {
        this.close();
      }
    }
  };

  if (onClose) {
    notyConfig.callbacks!['onClose'] = onClose;
  }
  new Noty(notyConfig).show();
};

/**
 * get time delayed, receives utc date, time zone and returns
 * @param {object} timeToCompare - date object
 * @param {string} timeZone - time zone
 * @param {string} delayedFormat - string format for the date
 * @param {string} timeFormat - formatting for the time delayed portion
 * @param {string} delayedText - text to return along with the time
 * @return {string} - returns delayed time
 */
export const getTimeDelayed = (
  timeToCompare,
  timeZone,
  delayedFormat,
  timeFormat,
  delayedText?
) => {
  const now = moment();

  delayedText ??= 'Delayed by ';

  const delayedTime = fixUTCDate(timeToCompare, timeZone, delayedFormat, '', true);

  if (!delayedTime || moment(now).isBefore(delayedTime)) {
    return '';
  }

  let delay = '';

  if (timeFormat === 'ago') {
    const delayHours = moment.utc(moment(now).diff(delayedTime)).hours();
    const delayMinutes = moment.utc(moment(now).diff(delayedTime)).minutes();
    if (delayHours > 0) {
      delay = `${delayHours} hours `;
    }
    delay = `${delay}${delayMinutes} minutes`;
  } else {
    delay = moment.utc(moment(now).diff(delayedTime)).format(timeFormat);
  }

  if (delay === 'Invalid date') return '';

  return `${delayedText}${delay}`;
};

/**
 * date of birth, or any date validation with MM/DD/YYYY
 * @param {string} date - date
 * @return {boolean} - returns whether its valid or not
 */
export const dateValidation = date => moment(date, 'MM/DD/YYYY', true).isValid();

/**
 * @param {object} errorObj - error object from api's
 * @param {string} customMessage - custom error message
 * @return {array} returns array embedded in error object
 */
export const errorParse = (errorObj, customMessage) => {
  const errorArray = errorObj?.data?.errors ?? [];

  // undefined error object
  if (!errorArray || !errorArray?.length) {
    return [errorObj?.message ?? customMessage];
  }

  // array
  if (Array.isArray(errorArray)) return errorArray;

  // object
  if (!errorArray?.length) {
    const errKey = Object.keys(errorArray)[0];

    const data = errorArray[errKey].data;

    // get fields for error
    const arrMsg = [errorArray[errKey].message];

    const fieldValidationMessage = Object.keys(data).map(
      key => `${key}: ${data[key].join('. ')}`
    );

    return arrMsg.concat(fieldValidationMessage);
  }
  return errorArray;
};

/**
 * Formats any given phone number to (###) ###-####. Handles
 * shortened or extended format lengths.
 *
 * Anything beyond the normal 13 character length will have the
 * extra numbers appended to the end on the return string
 * @param {string} phoneNumber - unformatted phone number
 * @return {string} Returns formatted phone number
 */
export const formatPhoneNumber = phoneNumber => {
  if (!phoneNumber) return '';

  if (typeof phoneNumber !== 'string') {
    if (phoneNumber?.toString && typeof phoneNumber.toString === 'function') {
      phoneNumber = phoneNumber.toString();
    } else {
      return '';
    }
  }

  phoneNumber = phoneNumber.replace(/[^\d]/g, '');
  if (phoneNumber.length > 10 && phoneNumber.charAt(0) === '1') {
    phoneNumber = phoneNumber.substring(1);
  }

  // eslint-disable-next-line no-empty-character-class
  const groups = phoneNumber.match(/^([]|)(\d{1,3})(\d{0,3})(\d{0,})$/) ?? [];

  let ret = '';

  if (groups[2]) {
    ret += `(${groups[2]})`;
  } else {
    return ret;
  }

  if (groups[3]) {
    ret += ` ${groups[3]}`;
  } else {
    return ret;
  }

  if (groups[4]) {
    ret += `-${groups[4]}`;
  }

  return ret;
};

/**
 * Gets the digits (without country code) of any number from any phone number format
 *
 * @param {string} phoneNumber - Phone number string passed in
 * @returns {string} - Returns a string of the digits of the national number (e.g. 2125591890)
 */
export const getNationalNumber = (phoneNumber: string): string => {
  if (!phoneNumber) {
    return ''; // First, if it's falsy just return an empty string
  }

  return (
    parsePhoneNumberFromString(phoneNumber || '')?.nationalNumber ?? // Then check if we can infer the country code
    parsePhoneNumberFromString(phoneNumber || '', 'US')?.nationalNumber ?? // if not, just assume it's a US number
    '' // If it's an invalid number for whatever reason, just return an empty string
  );
};

/**
 * validateEmail - determines if valid email syntax
 * @param {string} email - unformatted phone number
 * @return {boolean} - true if valid
 */
export const validateEmail = email => {
  EMAIL_REGEX.lastIndex = 0;

  return EMAIL_REGEX.test(email);
};

/**
 * @param {object} ride - ride
 * @return {object} returns object or false
 */
export const showExtraDetails = ride => {
  const objCustomFields: Record<string, unknown> = ride?.customFields ?? {};

  let customFields: string[] = [];

  if (!isEmpty(objCustomFields)) {
    customFields = Object.keys(objCustomFields);
  }

  const additionalNotes = ride?.additionalNotes ?? '';
  const shrinkCustomFields: unknown[] = [];

  for (let i = 0; i < customFields.length; i++) {
    const customField = customFields[i];
    const fieldValue = objCustomFields?.[customField]?.value?.field_value ?? '';

    if (fieldValue) {
      shrinkCustomFields.push(objCustomFields[customField]);
    }
  }

  if (!shrinkCustomFields.length && !additionalNotes) {
    return false;
  }

  const extraFields: { [x: string]: unknown } = {};

  if (shrinkCustomFields.length > 0) {
    extraFields.customFields = shrinkCustomFields;
  }

  if (!additionalNotes) extraFields.additionalNotes = additionalNotes;

  return extraFields;
};

/**
 * is it a network level role?
 * @param {string} role - user role
 * @return {boolean} is it a network level role or not
 */
export const isNetworkRole = role =>
  role === 'HospitalOwner' || role === 'HospitalNetworkManager';

/**
 * Check if user has a health plan role
 * @param   {Object}  user User object
 * @returns {boolean}      true/false
 */
export const isHealthPlanRole = user => {
  const role = user?.userData?.role ?? '';

  return role === 'HealthPlanOwner' || role === 'HealthPlanAdmin';
};

/**
 * Generates a 24 character unique
 *
 * Format: {hospitalGroupId}EXP{randomAlphaNumericString}
 * @param {int|string} hospitalGroupId the hospital group id
 * @returns {string} The expense id
 */
export const generateExpenseId = hospitalGroupId => {
  let expenseId = hospitalGroupId + 'EXP';
  const remainingLength = EXPENSE_ID_MAX_LENGTH - expenseId.length;
  const validChars = 'abcdefghijklmnopqrstuvwxyz1234567890ABCDEFGHIJKLMNOPQRSTUVWXYZ';

  for (let i = 0; i < remainingLength; i++) {
    expenseId += validChars[Math.floor(Math.random() * validChars.length)];
  }
  return expenseId;
};

/**
 * flip addresses for ride booking
 * @param {object} ride - ride data
 * @return {object} - flip ride data and return new object
 */
export const flipAddresses = ride => {
  const rideData = { ...ride };

  const flippedAddress = {
    pickupAddress: rideData.dropoffAddress,
    dropoffAddress: rideData.pickupAddress,
    pickupLatitude: rideData.dropoffLatitude,
    dropoffLatitude: rideData.pickupLatitude,
    pickupLongitude: rideData.dropoffLongitude,
    dropoffLongitude: rideData.pickupLongitude,
    pickupZipcode: rideData.dropoffZipcode,
    dropoffZipcode: rideData.pickupZipcode,
    pickupAddressName: rideData.dropoffAddressName,
    dropoffAddressName: rideData.pickupAddressName,
    pickupVenueName: rideData.dropoffVenueName,
    pickupEntranceName: rideData.dropoffEntranceName,
    pickupVenueId: rideData.dropoffVenueId,
    dropoffVenueName: rideData.pickupVenueName,
    dropoffEntranceName: rideData.pickupEntranceName,
    dropoffVenueId: rideData.pickupVenueId,
    showPickupVenue: rideData.showDropoffVenue,
    showPickupEntrance: rideData.showDropoffEntrance,
    showDropoffVenue: rideData.showPickupVenue,
    showDropoffEntrance: rideData.showPickupEntrance,
    showPickupApprovedProvider: rideData.showDropoffApprovedProvider ?? false,
    showDropoffApprovedProvider: rideData.showPickupApprovedProvider ?? false,
    pickupProviderId: rideData.dropoffProviderId || 0,
    dropoffProviderId: rideData.pickupProviderId || 0,
    pickupProviderName: rideData.dropoffProviderName || '',
    dropoffProviderName: rideData.pickupProviderName || '',
    pickupProviderNpi: rideData.dropoffProviderNpi || 0,
    dropoffProviderNpi: rideData.pickupProviderNpi || 0,
    pickupProviderPhone: rideData.dropoffProviderPhone || '',
    dropoffProviderPhone: rideData.pickupProviderPhone || '',
    pickupFacilityName: rideData.dropoffFacilityName,
    dropoffFacilityName: rideData.pickupFacilityName,
    pickupProviderAddr: rideData.dropoffProviderAddr || '',
    dropoffProviderAddr: rideData.pickupProviderAddr || '',
    pickupAdditionalNotes: rideData.dropoffAdditionalNotes,
    dropoffAdditionalNotes: rideData.pickupAdditionalNotes,
    pickupProviderNotFound: rideData.dropoffProviderNotFound ?? false,
    dropoffProviderNotFound: rideData.pickupProviderNotFound ?? false,
    toMemberSavedAddressId: rideData.fromMemberSavedAddressId,
    fromMemberSavedAddressId: rideData.toMemberSavedAddressId
  };

  return Object.assign(rideData, flippedAddress);
};

/**
 * takes array of messages and filters out messages that are empty
 * @param {array} message - messages as part of authorization
 * @return {array} returns array of messages minus the messages that are empty
 */
export const flattenMessage = message => {
  if (!Array.isArray(message)) return [];

  return message.filter(msg => typeof msg === 'string' && msg.trim());
};

/**
 * join array of messages helper function
 * @param {array} messages - list of messages
 * @return {string} - return message string
 */
export const joinMessage = messages => {
  let message = messages.join('. ').trim();
  if (message[message.length - 1] !== '.') {
    message = `${message}.`;
  }

  return message;
};

/**
 * which hospitals do we allow same day multi leg booking
 * @return {array} return list of hospitals same day is allowed for
 */
export const allowSameDayMultiLeg = () => {
  let sameDay: number[] = [];

  switch (process.env.REACT_APP_ENVIRONMENT) {
    case 'local':
      sameDay = [1];
      break;
    case 'sandbox':
      sameDay = [83];
      break;
    case 'staging':
      sameDay = [65];
      break;
    case 'production':
      sameDay = [123, 159];
      break;
    default:
      sameDay = [];
  }
  return sameDay;
};

/**
 * I got tired of null values not get caught with _.get
 * @param {object} myObject - object we'll use to run lodash get
 * @param {string} path - the search parameter
 * @param {any} defaultVal - default value for _.get
 * @return {any} result - result for _.get
 */
export const extendLodashGet = (myObject, path, defaultVal) =>
  myObject?.[path] ?? defaultVal;

/**
 * _.has will return true if property exists but is undefined
 * @param {object} myObject - object we'll use to run lodash get
 * @param {string} searchString - the search parameter
 * @param {bool} checkForNull - return false if value is there but its null
 * @return {boolean} - false if path is undefined or doesnt exist, true otherwise
 */
export const extendLodashHas = (myObject, searchString, checkForNull = false) => {
  if (!(searchString in myObject)) return false;

  if (myObject?.[searchString] === undefined) return false;

  return checkForNull ? !(myObject[searchString] === null) : true;
};

/**
 * takes a constant object with key values and assigns values based on data parameter
 * @param {object} map - map of properties
 * @param {object} data - data with the values that will be mapped
 * @return {object} returns mapped data
 */
export const merge = (map, data) => {
  const object = map;
  Object.keys(map).forEach(item => {
    if (item in data && data[item] !== null) {
      object[item] = data[item];
    }
  });

  return object;
};

/**
 * get lat/lng for hospital
 * @param {integer} hospitalId - hospital id
 * @param {object} hospitalData - hospital data from user api
 * @param {string} networkRole - network role
 * @return {object} lat/lng object
 */
export const getHospitalCoords = (hospitalId, hospitalData, networkRole = false) => {
  if (networkRole) {
    hospitalData = hospitalData.find(({ id }) => id === parseInt(hospitalId));

    hospitalData ??= {};
  }

  return 'latitude' in hospitalData
    ? {
      lat: parseFloat(hospitalData?.latitude ?? null),
      lng: parseFloat(hospitalData?.longitude ?? null)
    }
    : {};
};

/**
 * helper function for pluralizing words
 * @param {string} singular - singular word
 * @param {string} plural - plural word
 * @param {integer} count - count
 * @return {string} return singular or plural word depending on count
 */
export const pluralize = (singular, plural, count) => {
  return count === 1 ? singular : plural;
};

/**
 * handle possessive case for names for page or component headings
 * @param {string} firstName - passenger first name
 * @param {string} lastName - passenger last name
 * @return {string} - return string with possessive modifier
 */
export const possessive = (firstName, lastName) => {
  const patientName = `${firstName} ${lastName}`.trim();

  return patientName.endsWith('s') ? `${patientName}'` : `${patientName}'s`;
};

/**
 * handle possessive case for names for page or component headings
 * @param {string} date - passenger first name
 * @param {string} format - passenger last name
 * @param {integer} length - string length of date
 * @return {boolean} - return true if valid, false if invalid
 */
export const dateCheck = (date, format, length) => {
  if (date.length !== length) return false;

  return moment(date, format).isValid();
};

/**
 * Checks if a date is before the current date (time included)
 * @param {moment | date} date The date object to check
 * @returns {boolean} T/F
 */
export const isDatePast = date => {
  if (!date) return false;

  return moment.isMoment(date)
    ? date.isBefore(new Date())
    : moment(date).isBefore(new Date());
};

/**
 * Get moment from SMTWRFU character alias
 * @param {mixed} dayAlias - day character or int alias;
 * @returns {mixed} - day character or int alias;
 */
export const getStrOrIntDayAlias = (dayAlias: string | number) => {
  const keySearch = isString(dayAlias) ? 'char' : 'int';
  const keyReturn = isString(dayAlias) ? 'int' : 'char';

  const dayObject = DAY_CHAR_TO_INT.find(dayObject => dayObject[keySearch] === dayAlias);

  return dayObject?.[keyReturn] ?? null;
};

export const isHealthPlan = userRole =>
  [USER_ROLES.healthPlanOwner, USER_ROLES.healthPlanAdmin].includes(userRole);

export const getSafe = (fn, defaultValue = null) => {
  try {
    return fn();
  } catch (e) {
    return defaultValue;
  }
};

/**
 * Checks if ride is past public ride
 * @param {object} ride - ride object
 * @return {boolean} T/F
 */
export const isPastPublicRide = ({ ride }) =>
  ride.mode === RIDE_MODE.PUBLIC && ride.isPastRide && ride.status !== 'Cancelled';

/**
 * Overview - checks whether the vehicle id
 * belongs to a ride share program.
 * @param {number} id - transport id
 * @return {boolean} T/F
 */
export const isRideShareVehicleId = (id: number) =>
  [LYFT_VEHICLE_ID, UBER_VEHICLE_ID].includes(id);

/**
 * Check if field is a latitude or longitude value
 * @param {string} field  - lat or lng value
 * @return {boolean} returns true or false
 */
export const isLatLng = field => REGEX_LAT_LNG.test(field);

/**
 * Check if field is a latitude or longitude value
 * @param {string} field  - lat or lng value
 * @return {array} array of matches
 */
export const isLatLngMatch = field => REGEX_LAT_LNG.exec(field);

/**
 * find zipcode of the place object @TODO move to common utilities
 * @param {array} context - context array from autocomplete
 * @return {string} returns zipcode
 */
export const getZipcode = (context: Record<string, any>[] = []) => {
  const zipCodeObject = context.find((o: { [x: string]: any }) =>
    o.id.match(/postcode.*/)
  );

  return zipCodeObject && 'text' in zipCodeObject ? zipCodeObject.text : '';
};

/**
 * Takes an object and checks if the value for each key is true. All
 * true values are appended to the returned string
 * Replaces the unnecessary dependency for class names.
 * @param {object} classObj the classes
 * @returns {string} the class name
 */
export const getClassName = classObj => {
  let ret = '';

  Object.keys(classObj).forEach(key => {
    if (classObj[key]) ret += ` ${key}`;
  });

  return ret.trim();
};

/**
 * Converts the address data from the state BookingData into
 * address types
 * @param data
 * @returns
 */
export const standardizeAddresses = (data: BookingDataStore | RideData) => {
  /**
   * Converts the incoming address field to an alias.
   * _e.g._ the Zip field will be re-keyed as Zipcode
   * @param prefixes
   */
  function convertAliases(prefixes: string | string[]) {
    // Key: Convert To
    // Val: Convert From
    const aliasedFields = { Zipcode: 'Zip', VenueId: '_venue_id' };

    if (!Array.isArray(prefixes)) {
      prefixes = [prefixes];
    }

    prefixes.forEach(prefix => {
      for (const key in aliasedFields) {
        const fullKey = `${prefix}${key}`;
        const fullAlias = `${prefix}${aliasedFields[key]}`;

        if (data[fullAlias] && !data[fullKey]) {
          data[fullKey] = data[fullAlias];
          delete data[fullAlias];
        }
      }
    });
  }

  /**
   * Extracts the address fields depending on the prefixes
   */
  function extractAddress(prefixes: string | string[]): Address {
    const addressFields = [
      'AdditionalNotes',
      'Address',
      'EntranceName',
      'FacilityName',
      'Latitude',
      'Longitude',
      'ProviderId',
      'ProviderName',
      'ProviderPhone',
      'VenueName',
      'VenueId',
      'Zipcode',
      'AddressName',
      'ProviderNpi',
      'ProviderAddress',
      'MemberSavedAddressId'
    ];

    const address = {};

    if (!Array.isArray(prefixes)) {
      prefixes = [prefixes];
    }

    convertAliases(prefixes);

    addressFields.forEach(field => {
      for (const prefix of prefixes) {
        const key = `${prefix}${field}`;

        if (key in data) {
          const updatedKey = field[0].toLowerCase() + field.substring(1);

          address[updatedKey] = data[key];
          break;
        }
      }
    });

    return address as Address;
  }

  return {
    pickup: extractAddress(['pickup', 'from']),
    dropoff: extractAddress(['dropoff', 'to'])
  };
};

/**
 * Creates a callback function that can be used to alphabetically
 * sort an array of strings or array of objects using a specific key.
 *
 * e.g. `['c','b','a'].sort(sortStrings())`
 *
 * produces `['a', 'b', 'c'] `
 *
 * `[{key: 'c'}, {key: 'b'}, {key: 'a'}, {}].sort(sortStrings('key'))`
 *
 * produces `[{key: 'a'}, {key: 'b'}, {key: 'c'}, {}]`
 * @param objectKey
 * @returns
 */
export const sortStrings = (objectKey?: string) => {
  return (a, b) => {
    const _stringA = objectKey && objectKey in a ? a[objectKey] : a;
    const _stringB = objectKey && objectKey in b ? b[objectKey] : b;

    // Non-string values or objects missing key are
    // sorted lower
    if (!isString(_stringA)) {
      return isString(_stringB) ? 1 : 0;
    }

    const stringA = _stringA.toUpperCase();
    const stringB = _stringB.toUpperCase();

    if (stringA === stringB) {
      return 0;
    }

    return stringA < stringB ? -1 : 1;
  };
};

/**
 * Formats a phone number to E.164 format.
 *  (800)573-1231 => +18005731231
 * @param {string} phoneNumberString - The phone number to format.
 * @return {string|null} The formatted phone number, or null if the input is not a valid phone number.
 */
export function formatPhoneNumberToE164Standard(
  phoneNumberString: string | undefined
): string | null {
  const cleaned = ('' + phoneNumberString).replace(/\D/g, '');

  if (cleaned.length !== 10) {
    return null;
  }

  const match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);
  if (match) {
    return '+1' + match[1] + match[2] + match[3];
  }
  return null;
}
