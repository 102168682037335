import { Is, createModelFactory, type ModelType, isEmpty } from '@SRHealth/frontend-lib';
import { type PropertyRuleSignature } from '~/models';
import { type PublicCardModel, publicCardFactory } from './PublicCard.model';

export type ProviderPublicProps = {
  name: string;
  cards: PublicCardModel[];
  isLivechatRequired: boolean;
};

export type ProviderPublicModel = ModelType<ProviderPublicProps>;

export const PROVIDER_PUBLIC_DEFAULT = (): ProviderPublicProps => {
  return { name: 'public', cards: [publicCardFactory()], isLivechatRequired: false };
};

export const providerPublicFactory = createModelFactory<ProviderPublicModel>(
  PROVIDER_PUBLIC_DEFAULT,
  {
    properties: [
      ['is-array', 'cards', Is.JsonArray.strict],
      [
        'is-false',
        'isLivechatRequired',
        v => {
          if (v !== false) {
            throw Error(
              'isLivechatRequired must be false for public providers (they do not have livechat).'
            );
          }
          return true;
        }
      ]
    ],
    model: [
      [
        'valid-cards',
        async (model, isCommit) => {
          if (isCommit && !isEmpty(model.cards)) {
            const res = await Promise.all(
              model.cards.map(card => card.commit().catch(_ => false))
            );

            if (res.some(v => !v)) {
              throw new Error('Error in the card models');
            }
          }

          return true;
        }
      ]
    ]
  }
);

export type ProviderPublicPropertyRule = PropertyRuleSignature<ProviderPublicModel>;
