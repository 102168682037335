import { createModelFactory, Is, type ModelType } from '@SRHealth/frontend-lib';
import type { PropertyRuleSignature } from '.';

export type ProviderNemtProps = {
  id: string;
  name: string;
  ratings: number;
  rideCosts: number[];
  isPrimary: boolean;
  isSecondary: boolean;
  isLivechatRequired: boolean;
};

export type ProviderNemtModel = ModelType<ProviderNemtProps>;
export type ProviderNemtRule = PropertyRuleSignature<ProviderNemtProps>;

const PROVIDER_NEMT_DEFAULT = (): ProviderNemtProps => ({
  id: '',
  name: '',
  ratings: 0,
  rideCosts: [],
  isPrimary: false,
  isSecondary: false,
  isLivechatRequired: false
});

export const providerNemtFactory = createModelFactory<ProviderNemtModel>(
  PROVIDER_NEMT_DEFAULT(),
  {
    properties: [
      ['is-string', 'id', Is.String.strict],
      [
        'is-not-empty',
        'id',
        (v, _, isCommit) => {
          if (isCommit && Is.Empty(v)) throw Error('ID is required.');

          return true;
        }
      ],
      ['is-string', 'name', Is.String.strict],
      ['is-not-empty', 'name', v => Is.String.strict(v) && v.length > 0],
      ['is-number', 'ratings', Is.Number.strict],
      ['is-array', 'rideCosts', Is.JsonArray.strict],
      [
        'has-ride-cost',
        'rideCosts',
        (v: number[], _, isCommit) => {
          if (isCommit && v.length < 1) {
            throw Error('Ride must have cost for at least one leg.');
          }

          return true;
        }
      ],
      [
        'is-numeric-ride-costs',
        'rideCosts',
        (v: number[], _, isCommit) => {
          if (isCommit && v.some(e => !Is.Numeric(e))) {
            throw Error('Ride costs must be numeric.');
          }

          return true;
        }
      ],
      ['is-boolean', 'isPrimary', Is.Boolean.strict],
      ['is-boolean', 'isSecondary', Is.Boolean.strict],
      ['is-boolean', 'isLivechatRequired', Is.Boolean.strict]
    ],
    model: [
      [
        'is-primary-or-secondary',
        (m, isCommit) => {
          if (isCommit && m.isPrimary && m.isSecondary) {
            throw Error('Cannot be both primary and secondary.');
          }

          return true;
        }
      ]
    ]
  }
);
