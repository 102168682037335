import type { UserStore } from '~/Modules/user';
import type { ChatsStore } from '~/types';
import React from 'react';
import SvgLiveChatIcon from '~/Shared/Components/Svgs/SvgLiveChatIcon';

export type LiveChatLauncherProps = {
  user: UserStore;
  chats: ChatsStore;
  handleWindowToggle: () => void;
};

const LiveChatLauncher = ({ user, chats, handleWindowToggle }: LiveChatLauncherProps) => {
  const userId = user?.userData?.id ?? 0;

  /**
   * Get list of unread conversations (i.e. message threads) across all chat
   * requests
   */
  const unreadConversationsCount = Object.keys(chats).reduce((count, requestId) => {
    const messages = chats?.[requestId]?.messages ?? [];
    const requestStatus = chats?.[requestId]?.requestStatus ?? '';
    const unreadMessage = messages.find(message => {
      const reader = message.readers.find(reader => reader.id === userId);
      // if can't find the user as a reader AND the user wasn't the sender
      return !reader && message.senderId !== userId;
    });

    return unreadMessage && requestStatus !== 'cancelled' ? count + 1 : count;
  }, 0);

  return (
    <div className="liveChatLauncher" onClick={handleWindowToggle}>
      <SvgLiveChatIcon className="liveChatIcon" />
      {unreadConversationsCount > 0 ? <sup>{unreadConversationsCount}</sup> : null}
    </div>
  );
};

export default LiveChatLauncher;
