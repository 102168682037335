import { Is, createModelFactory, type ModelType } from '@SRHealth/frontend-lib';
import type { PropertyRuleSignature } from '~/models';
import { isExtendedPhoneNumberValid } from '~/utilities/phone';

export type AddressApprovedProviderNotFound = {
  type: 'provider-not-found';
  value: string;
  id: number;
  name: string;
  facility: string;
  phone: string;
  latitude: number;
  longitude: number;
  notes: string;
};

export type AddressApprovedProviderNotFoundModel =
  ModelType<AddressApprovedProviderNotFound>;

export type AddressApprovedProviderNotFoundPropertyRule =
  PropertyRuleSignature<AddressApprovedProviderNotFound>;

export const ADDRESS_APPROVED_PROVIDER_NOT_FOUND_DEFAULT =
  (): AddressApprovedProviderNotFound => ({
    type: 'provider-not-found',
    value: '',
    id: 0,
    name: '',
    facility: '',
    phone: '',
    latitude: 0,
    longitude: 0,
    notes: ''
  });

export const addressApprovedProviderNotFoundFactory =
  createModelFactory<AddressApprovedProviderNotFoundModel>(
    ADDRESS_APPROVED_PROVIDER_NOT_FOUND_DEFAULT(),
    {
      properties: [
        [
          'is-address-type',
          'type',
          v => {
            if (v !== 'provider-not-found') {
              throw Error('Type must be provider-not-found.');
            }

            return true;
          }
        ],
        ['is-string', 'value', Is.String.strict],
        [
          'is-not-empty',
          'value',
          (v, _, isCommit) => {
            if (isCommit && Is.Empty(v)) throw Error('Address cannot be empty.');

            return true;
          }
        ],
        ['is-number', 'id', Is.Number.strict],
        [
          'is-not-empty',
          'id',
          (v, _, isCommit) => {
            if (isCommit && Is.Empty(v)) throw Error('Id cannot be empty.');

            return true;
          }
        ],
        ['is-string', 'name', Is.String.strict],
        [
          'is-not-empty',
          'name',
          (v, _, isCommit) => {
            if (isCommit && Is.Empty(v)) throw Error('Name cannot be empty.');

            return true;
          }
        ],
        ['is-string', 'facility', Is.String.strict],
        [
          'is-not-empty',
          'facility',
          (v, _, isCommit) => {
            if (isCommit && Is.Empty(v)) throw Error('Facility cannot be empty.');

            return true;
          }
        ],
        ['is-string', 'phone', Is.String.strict],
        [
          'is-not-empty',
          'phone',
          (v: string, _, isCommit) => {
            if (isCommit) {
              if (Is.Empty(v)) throw Error('Phone cannot be empty.');
              if (!isExtendedPhoneNumberValid(v)) throw Error('Phone is invalid.');
            }

            return true;
          }
        ],
        ['is-number', 'latitude', Is.Number.strict],
        [
          'is-not-empty',
          'latitude',
          (v, _, isCommit) => {
            if (isCommit && Is.Empty(v)) throw Error('Latitude cannot be empty.');

            return true;
          }
        ],
        ['is-number', 'longitude', Is.Number.strict],
        [
          'is-not-empty',
          'longitude',
          (v, _, isCommit) => {
            if (isCommit && Is.Empty(v)) throw Error('Longitude cannot be empty.');

            return true;
          }
        ],
        ['is-string', 'notes', Is.String.strict]
      ],
      model: []
    }
  );
