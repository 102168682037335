import _ from 'lodash-es';
import React from 'react';
import moment from 'moment';
import SvgClose from '~/Shared/Components/Svgs/SvgClose';
import PropTypes from 'prop-types';
import ReactStars from 'react-stars';
import TripDetails from './components/TripDetails';
import PublicRoute from '~/Shared/Components/BookingConfirmation/Components/TransportOptions/PublicRoute';
import AddressDisplay from '~/Shared/Components/AddressDisplay/AddressDisplay';
import SvgMapPinEnd from '../svgs/SvgMapPinEnd';
import SvgMapPinStart from '../svgs/SvgMapPinStart';
import NemtTripSummary from './components/NemtTripSummary';
import LyftTripSummary from './components/LyftTripSummary';
import SvgPassengerIcon from '../svgs/SvgPassengerIcon';
import { formatPhoneNumber, standardizeAddresses } from '~/utilities/helperFunctions';
import AdditionalPassengersDetails from '~/Shared/Components/AdditionalPassengers/AdditionalPassengersDetails';
import PublicConfirmation from '~/Shared/Components/BookingConfirmation/Components/Confirmation/PublicConfirmation';
import { isEmpty } from '@SRHealth/frontend-lib';

import { LYFT_VEHICLE_ID, UBER_VEHICLE_ID, RIDE_MODE } from '~/constants';
import UberTripSummary from './components/UberTripSummary';

const RideInfo = ({
  rideData,
  canRebook,
  dataToggles,
  handleClose,
  vehicleTypes,
  rebookCallback,
  showColumnHeader
}) => {
  /**
   * Creates driver details object from the appropriate ride details
   * @returns {object} Driver object including name, avatar and mobileNo
   */
  const getDriverDetails = () => {
    const driver = {
      name: '',
      avatar: 'https://app.saferidehealth.com/images/avatar_default.jpg',
      mobileNo: ''
    };

    if (isLyft()) {
      const driverDetails = rideData?.lyftDetails?.ride_data?.driver ?? {};

      driver.name = driverDetails?.first_name ?? '';
      driver.avatar = driverDetails?.image_url ?? '';
      driver.mobileNo = driverDetails?.phone_number ?? '';
    } else if (isUber()) {
      const driverDetails = rideData?.uberDetails?.ride_data?.driver ?? {};

      driver.name = driverDetails?.name ?? '';
      driver.avatar = driverDetails?.picture_url ?? '';
      driver.mobileNo = driverDetails?.phone_number ?? '';

      // Uber passes a pin and special phone number to allow our CSRs
      // to contact drivers
      const pinBasedPhone = driverDetails?.pin_based_phone_number ?? null;

      if (pinBasedPhone) {
        driver.mobileNo = pinBasedPhone?.phone_number ?? driver.mobileNo;
        driver.pin = pinBasedPhone?.pin ?? null;
      }
    } else {
      const firstName = rideData?.driver?.firstName ?? '';
      const lastName = rideData?.driver?.lastName ?? '';

      driver.name = [firstName, lastName].filter(val => val).join(' ');
      driver.mobileNo = rideData?.driver?.mobileNo ?? '';
      driver.avatar = rideData?.driver?.avatar ?? '';
    }

    return driver;
  };

  const getAssistanceNeeds = () => {
    if (isEmpty(assistanceNeeds)) return '';

    let assistanceNeedsText = '';

    Object.keys(assistanceNeeds).map(key => {
      if (assistanceNeedsText === '') {
        assistanceNeedsText = assistanceNeeds[key];
      } else {
        assistanceNeedsText = `${assistanceNeedsText}, ${assistanceNeeds[key]}`;
      }
    });
    return assistanceNeedsText;
  };

  /**
   * Determines if the ride has a valid rebook status
   * @returns {bool} T/F
   */
  const getRebookStatus = () => {
    return (
      ['completed', 'cancelled', 'rejected', 'driverrejected'].indexOf(
        rideData.status.toLowerCase()
      ) !== -1
    );
  };

  /**
   * Retrieve the formatted string to indicate who cancelled the ride
   * @returns {string} The formatted text to display
   */
  const getCancelledBy = () => {
    let cancelledBy = '';

    if (rideData?.cancelledBy === null) {
      if (rideData.modelName === 'Lyft' || rideData.cancelledByUserRole === 'lyft') {
        cancelledBy = ' By Lyft';
      }
    } else {
      const user = rideData?.cancelledByUser ?? '';
      const userRole = rideData?.cancelledByUserRole ?? '';
      const name = rideData?.cancelledByName ?? '';

      cancelledBy = [' By', userRole, user || name].filter(x => x).join(' ');
    }

    return cancelledBy;
  };

  /**
   * Obtains the cancellation reason from the rideData
   * @returns {string} The cancellation reason
   */
  const getCancelText = () => {
    let text = '';

    const { reject_reason_txt, reject_reason_msg, cancelText } = rideData;

    // check for api field on pageload and prioritize that
    if (cancelText) {
      text = cancelText;
    } else if (reject_reason_txt) {
      // Check for pusher update rideInfoUpdate
      text = reject_reason_txt;
    } else if (reject_reason_msg) {
      // ridestatusupdate
      text = reject_reason_msg;
    }

    return text;
  };

  /**
   * Getter to access the ride additional compliance fields,
   * if applicable. Defaults to an empty object
   * @returns {object} The compliance fields
   */
  const getComplianceFields = () => {
    return _.get(rideData, 'additional_columns.0.additionalFields', {});
  };

  /**
   * Update state and navigate to scheduling page
   * @returns {undefined}
   */
  const handleBooking = () => {
    let healthPlanId = rideData?.passenger?.health_plan_id ?? null;
    healthPlanId = healthPlanId === 0 ? null : healthPlanId;
    let healthSubPlanId = rideData?.passenger?.health_sub_plan_id ?? null;
    healthSubPlanId = healthSubPlanId === 0 ? null : healthSubPlanId;

    let dob = rideData?.passenger?.dateOfBirth ?? '';
    if (dob) {
      dob = moment(dob, 'YYYY-MM-DD').format('MM/DD/YYYY');
    }

    rebookCallback(
      rideData.passenger.medicalId,
      rideData.hospitalId,
      rideData.id,
      healthPlanId,
      dob,
      false,
      healthSubPlanId
    );
  };

  /**
   * Getter to determine if a ride is a Lyft ride
   * @returns {bool} T/F if ride is Lyft
   */
  const isLyft = () => {
    return rideData.reqVehicleType === LYFT_VEHICLE_ID;
  };

  /**
   * Getter to determine if a ride is an Uber ride
   * @returns {bool} T/F if ride is Uber
   */
  const isUber = () => {
    return rideData.reqVehicleType === UBER_VEHICLE_ID;
  };

  /**
   * Getter to determine if ride is fulfillable by NEMT or Driver
   * @returns {bool} True if ride can be fulfilled
   */
  const isFulfillable = () => {
    const unableToFulfillStatuses = [
      'nemt_unable_to_fulfill',
      'driver_unable_to_fullfill'
    ];

    const confStatus = rideData?.confirmation_status ?? null;

    return confStatus && unableToFulfillStatuses.indexOf(confStatus) !== -1;
  };

  /**
   * Getter to determine if ride is confirmed or unable to fulfill
   * @returns {bool} True if confirmed or driver unable to fulfill
   */
  const isNEMTConfirmed = () => {
    const confStatus = rideData?.confirmation_status ?? null;
    const isScheduled = rideData.status === 'Scheduled';

    return (
      isScheduled &&
      (confStatus === 'confirmed' || confStatus === 'driver_unable_to_fulfill')
    );
  };

  /**
   * returns ride card status
   * @param {object} myRide - ride object
   * @param {boolean} newUI - old layout or new layout
   * @return {object} status - status of ride card
   */
  const rideCardStatus = (myRide, newUI = false) => {
    const ride = _.clone(myRide);

    const rideStatuses = [
      'Scheduled',
      'Inbound',
      'Arrived',
      'Started',
      'Completed',
      'Incomplete'
    ];
    const delayedStatuses = ['Scheduled', 'Processing', 'Inbound', 'Arrived', 'Started'];

    const statusInfo = {
      status: ride.status,
      cancelText: '',
      confirmationStatus: ''
    };

    const confirmationStatus = ride?.confirmation_status ?? null;
    const isValidStatus = rideStatuses.includes(ride.status);

    // Check to see if ride is not fulfillable and has valid status
    if (!newUI && !isFulfillable() && isValidStatus) {
      return statusInfo;
    }

    // Check to see if ride is delayed and fulfillable
    if (delayedStatuses.indexOf(ride.status) > -1 && isFulfillable()) {
      if (ride?.lyftDetails?.lyft_status === 'pending') {
        statusInfo.status = 'RIDE REQUESTED';
        statusInfo.confirmationStatus = 'RIDE REQUESTED';
        statusInfo.statusClassName = 'requested';
      }

      return statusInfo;
    }

    switch (ride.status) {
      // Falls through
      case 'Incomplete':
      case 'Completed':
        return statusInfo;

      case 'WillCall':
        statusInfo.status = 'Will Call';
        if (confirmationStatus === 'nemt_unable_to_fulfill') {
          statusInfo.status = 'Unable to Fulfill';
          statusInfo.reorderText = 'Select Another NEMT';
          statusInfo.confirmationStatus = 'NEMT UNABLE TO FULFILL';
          statusInfo.statusClassName = 'cancelled';
        }
        return statusInfo;

      case 'Processing':
        statusInfo.status = 'Requesting Drivers';
        break;

      case 'Scheduled':
        // Check if ride has been confirmed by NEMT yet
        if (confirmationStatus === 'unconfirmed') {
          statusInfo.confirmationStatus = 'SCHEDULED - CONFIRMING NEMT';
        } else if (isNEMTConfirmed()) {
          statusInfo.confirmationStatus = 'CONFIRMED NEMT';
        }
        break;

      case 'RequestMissed':
        statusInfo.status = 'Request Missed';
        statusInfo.cancelledBy = getCancelledBy();
        statusInfo.cancelText = ride.reject_reason;
        break;

      case 'DriverCancelled':
        statusInfo.status = 'Driver Cancelled';
        statusInfo.cancelledBy = getCancelledBy();
        statusInfo.cancelText = getCancelText();
        break;

      // falls through
      case 'Rejected':
      case 'Cancelled':
        statusInfo.status = 'Cancelled';
        statusInfo.cancelledBy = getCancelledBy();
        statusInfo.cancelText = getCancelText();
        break;
    }

    if (confirmationStatus === 'nemt_unable_to_fulfill') {
      statusInfo.status = 'Unable to Fulfill';
      statusInfo.confirmationStatus = 'NEMT UNABLE TO FULFILL';
      statusInfo.cancelledBy = getCancelledBy();
      statusInfo.cancelText = getCancelText();
    }

    // Early return for cancelled rides
    if (statusInfo.cancelledBy) {
      // Check if this is a cancelled ride that was later reordered
      if (!_.isNil(ride.reordered_ride_id)) {
        statusInfo.status = `Cancelled (Reordered as ${ride.reordered_ride_id})`;
      }

      return statusInfo;
    }

    if (ride.rideType !== 'ondemand' && ride.modelName !== 'Lyft') {
      if (ride.driverId === null) {
        statusInfo.status = 'Confirming Driver';
      } else {
        statusInfo.status = 'Confirmed';
      }
    }

    return statusInfo;
  };

  const passenger = rideData.passenger || {};
  const additionalNotes = rideData.additionalNotes || '';
  const treatmentName = rideData.treatment_name || '';
  const customFields = rideData.customFields || [];

  const phone2 = rideData?.ride_options?.phone2 ?? '';
  const assistanceNeeds = rideData?.ride_options?.assistance_needs ?? {};
  const memberIsRideAlone = rideData?.ride_options?.member_ride_alone ?? '';
  const memberIsManualScheduled = rideData?.ride_options?.member_manual_schedule ?? '';
  const rideInfoCSS = showColumnHeader ? 'innerRideInfo' : 'innerRideInfo hideHeader';
  const publicRouteData = rideData?.publicData?.[0]?.route_data;

  const driverDetails = getDriverDetails();
  const hasRebookableStatus = getRebookStatus();
  const complianceFields = getComplianceFields();
  const assistanceNeedsText = getAssistanceNeeds();
  const rideStatus = rideCardStatus(rideData, true);

  return (
    <div className="RideInfo">
      <RideInfoHeader
        display={showColumnHeader}
        rideId={rideData.id}
        onClose={handleClose}
      />

      <RideStatus
        status={rideStatus.status}
        confirmationStatus={rideStatus.confirmationStatus}
      />

      <RideRebookButton
        display={canRebook && hasRebookableStatus}
        onClick={handleBooking}
      />

      <div className={rideInfoCSS}>
        <RidePassengerInfo
          passenger={passenger}
          phone2={phone2}
          treatmentName={treatmentName}
          customFields={customFields}
          complianceFields={complianceFields}
        />

        <AdditionalPassengersDetails
          passengers={rideData.additionalPassengers}
          vehicleTypes={vehicleTypes}
        />

        <RideTripInfo rideData={rideData} />

        <AdditionalRideVehicleDetails
          {...rideData}
          assistanceNeedsText={assistanceNeedsText}
          comments={additionalNotes}
        />

        {publicRouteData && (
          <div
            className="TransportOptions"
            style={{
              borderTop: '1px solid #e0e0e0',
              borderBottom: '1px solid #e0e0e0',
              padding: '12px',
              cursor: 'unset'
            }}
          >
            <PublicRoute
              leg={0}
              key={`rd-0`}
              fare={_.get(publicRouteData, 'fare.text', '')}
              transit={null}
              selected={0}
              hideLeg={true}
              routeDataKey={0}
              defaultOpen={true}
              handler={() => {}}
              routeData={publicRouteData}
            />
          </div>
        )}

        {rideData.mode === 'Public' && (
          <PublicConfirmation
            confirmPublic={null}
            ride={rideData}
            trip={null}
            selectedLegs={null}
            disabled={true}
          />
        )}

        <DriverDetails rideData={rideData} driverDetails={driverDetails} />

        <div className="tripSummaryContainer">
          <h4>Trip Summary</h4>
          {isLyft() ? (
            <LyftTripSummary
              rideData={rideData}
              dataToggles={dataToggles}
              assistanceNeeds={assistanceNeedsText}
            />
          ) : isUber() ? (
            <UberTripSummary
              rideData={rideData}
              dataToggles={dataToggles}
              assistanceNeeds={assistanceNeedsText}
            />
          ) : (
            <NemtTripSummary
              rideData={rideData}
              dataToggles={dataToggles}
              assistanceNeeds={assistanceNeedsText}
              memberIsRideAlone={memberIsRideAlone}
              memberIsManualScheduled={memberIsManualScheduled}
            />
          )}
        </div>
        <div className="tripDetailsContainer">
          <h4>Trip Details</h4>
          <TripDetails rideData={rideData} />
        </div>
      </div>
    </div>
  );
};

/**
 * The Ride Info Header element. Conditionally displays if the display prop
 * is evaluated as truthy. Defaults to false
 * @param {bool} display Evaluated for truthiness to control if element should render
 * @param {number|string} rideId The ride id
 * @param {function} onClose The call back to execute when the info closes
 * @returns {JSX.Element} The element to render
 */
const RideInfoHeader = ({ display = false, rideId, onClose = () => {} }) => {
  if (!display) {
    return null;
  }

  return (
    <div className="rideInfoHeader">
      <h2>Ride Details</h2>
      <h3>Ride ID: {rideId}</h3>
      <div className="rideDetailClose" onClick={() => onClose()}>
        Close <SvgClose />
      </div>
    </div>
  );
};

/**
 * Creates the ride status element, displaying the status of the ride
 * @param {string} status The ride status
 * @param {string} confirmationStatus OPTIONAL the ride confirmation status
 * @returns {JSX.Element} The element to render
 */
const RideStatus = ({ status, confirmationStatus = '' }) => {
  const statusText = confirmationStatus ? confirmationStatus : status;

  return <div className="rideStatus">{statusText}</div>;
};

/**
 * Creates the rebook ride button and conditionally displays it
 * @param {boolean} display Evaluated for truthiness to determine if element should display
 * @param {function} onClick callback function for event
 * @returns {JSX.Element} The element to render
 */
const RideRebookButton = ({ display = false, onClick = () => {} }) => {
  if (!display) {
    return null;
  }

  return (
    <div className="rideBookingButton" onClick={onClick}>
      <span>+</span>
      <sub>REBOOK</sub>
    </div>
  );
};

/**
 * Renders the passenger information block, including
 * the passenger ID, phone number, date of birth as well as trip
 * purpose and type.
 * @param {object} passenger The passenger object
 * @param {string} phone2 The alternate phone number, if applicable
 * @param {string} treatmentName The treatment name listed for the ride
 * @param {string} notes Any notes
 * @param {array} customFields Any applicable custome fields
 * @param {object} complianceFields Any applicable compliance fields
 * @returns {JSX.Element} The element to render
 */
const RidePassengerInfo = ({
  passenger,
  phone2,
  treatmentName = '',
  _notes = '',
  customFields = [],
  complianceFields = {}
}) => {
  const { firstName, lastName, medicalId, mobileNo, dateOfBirth } = passenger;
  return (
    <div className="detailPassengerInfo">
      <div className="passengerName">
        <SvgPassengerIcon />
        {firstName} {lastName}
      </div>
      <div className="passengerId">ID: {medicalId}</div>
      <div className="passengerPhone">
        P: {formatPhoneNumber(mobileNo)} | DOB: {dateOfBirth || '--'}
      </div>

      {phone2 && <div className="passengerPhone">Alternate: {phone2}</div>}
      {treatmentName && <div className="passengerPhone">Treatment: {treatmentName}</div>}

      {Object.keys(customFields).map(key => {
        if (customFields[key].value) {
          return (
            <div
              key={key}
              className="passengerPhone"
            >{`${customFields[key].field.field_label}: ${customFields[key].value.field_value}`}</div>
          );
        }
      })}

      {Object.keys(complianceFields).map((key, i) => {
        return (
          <div
            key={i}
            className="passengerPhone"
          >{`${key}: ${complianceFields[key]}`}</div>
        );
      })}
    </div>
  );
};

/**
 * Renders pickup and dropoff address data blocks
 * @param {object} rideDate The data object for the ride
 * @returns {JSX.Element} The element to render
 */
const RideTripInfo = ({ rideData }) => {
  const { pickup, dropoff } = standardizeAddresses(rideData);

  return (
    <div className="tripInfo">
      <h4>Trip</h4>
      <div className="tripPickup">
        <SvgMapPinStart />
        <div>
          <AddressDisplay {...pickup} />
        </div>
      </div>
      <div className="tripSpacer">
        <div></div>
        <div></div>
        <div></div>
      </div>
      <div className="tripDropoff">
        <SvgMapPinEnd />
        <div>
          <AddressDisplay {...dropoff} />
        </div>
      </div>
    </div>
  );
};

/**
 * Renders the additional ride vehicle details including vehicle type
 * and assistance needs
 * @param {string} reqVehicleType The required vehicle type
 * @param {string} vehicleTypeName The vehicle type name
 * @param {string} originalReqVehicleType The original required vehicle type, if changed
 * @param {string} vehicleSubTypeName The vehicle sub-type, if applicable
 * @param {string} assistanceNeedsText Any assistance needs text
 * @param {string} comments Any additional comments, if applicable
 * @returns {JSX.Element} The element to render
 */
const AdditionalRideVehicleDetails = ({
  reqVehicleType = '',
  reqVehicleTypeName = '',
  originalReqVehicleType = '',
  vehicleSubTypeName = '',
  assistanceNeedsText,
  comments = ''
}) => {
  const isEdited = originalReqVehicleType && originalReqVehicleType !== reqVehicleType;

  const vehicleSubTypeTag = vehicleSubTypeName ? (
    <div className="vehicleType">{vehicleSubTypeName}</div>
  ) : null;

  const assistanceNeedsTag =
    assistanceNeedsText && reqVehicleType !== 8 ? (
      <div className="vehicleType">{assistanceNeedsText}</div>
    ) : null;

  const commentsTag =
    comments.length > 0 ? <div className="comments">{comments}</div> : null;

  return (
    <div className="addtlInfo">
      <div className="vehicleType">
        {reqVehicleTypeName}
        {isEdited ? <i> (Edited)</i> : null}
      </div>

      {vehicleSubTypeTag}
      {assistanceNeedsTag}
      {commentsTag}
    </div>
  );
};

/**
 * Renders the driver details including rating, name, phone number
 * and avatar
 * @param {object} props
 * @param {object} props.rideData
 * @param {object} props.driverDetails
 * @returns {JSX.Element}
 */
const DriverDetails = ({ rideData = {}, driverDetails = {} }) => {
  if (!rideData || rideData.mode === RIDE_MODE.PUBLIC) {
    return null;
  }

  const { cost = null, distance = null, reqVehicleType = null } = rideData;
  const { avatar = '', name, mobileNo, pin } = driverDetails;

  /**
   * Getter to determine the ride vehicle owner rating. Defaults
   * to a value of 0
   * @returns {number} The rating
   */
  const getVehicleOwnerRating = () => {
    let vehicleOwnerRating = rideData?.feedback?.rating ?? 0;

    if (reqVehicleType === LYFT_VEHICLE_ID) {
      vehicleOwnerRating = rideData?.lyftDetails?.ride_data?.driver?.rating ?? 0;
    } else if (reqVehicleType === UBER_VEHICLE_ID) {
      vehicleOwnerRating = rideData?.uberDetails?.ride_data?.driver?.rating ?? 0;
    }

    return vehicleOwnerRating;
  };

  return (
    <div className="driverInfo">
      <div className="driverDetails">
        <DriverAvatar avatar={avatar} />

        <DriverName name={name} mobileNo={mobileNo} pin={pin} />

        <DriverCost cost={cost} distance={distance} vehicleType={reqVehicleType} />
      </div>

      <DriverRating rating={getVehicleOwnerRating()} />
    </div>
  );
};

/**
 * Renders the avatar element. If an avatar URL is not
 * provided then a placeholder is rendered
 * @param {object} props
 * @param {string} props.avatar The URL to the avatar image
 * @returns {JSX.Element}
 */
const DriverAvatar = ({ avatar = '' }) => {
  return avatar ? (
    <div className="driverAvatar">
      <img src={avatar} />
    </div>
  ) : (
    <div className="driverAvatar empty" />
  );
};

/**
 * Renders the driver contact information including name,
 * phone and optional pin number
 * @param {object} props
 * @param {string} props.name
 * @param {string|number} props.mobileNo
 * @param {string|number} props.pin
 * @returns {JSX.Element}
 */
const DriverName = ({ name, mobileNo, pin }) => {
  let phoneString = formatPhoneNumber(mobileNo);

  if (pin) {
    phoneString += `| PIN: ${pin}`;
  }

  return (
    <div className="driverName">
      <div>{name}</div>
      <div>{phoneString}</div>
    </div>
  );
};

/**
 * Optionally renders the driver cost for non-Lyft rides. Cost
 * and distance must be provided.
 * @param {object} props
 * @param {number} props.cost
 * @param {number} props.distance
 * @param {number} props.vehicleType
 * @returns {JSX.Element}
 */
const DriverCost = ({ cost, distance, vehicleType }) => {
  if (vehicleType === LYFT_VEHICLE_ID || !cost || !distance) {
    return null;
  }

  return (
    <div className="driverCost">
      <div>${parseFloat(cost).toFixed(2)}</div>
      <div>{parseFloat(distance).toFixed(1)} miles</div>
    </div>
  );
};

/**
 * Renders the driver rating element
 * @param {object} props
 * @param {number} props.rating
 * @returns {JSX.Element}
 */
const DriverRating = ({ rating = 0 }) => {
  return (
    <div className="driverRating">
      <ReactStars
        count={5}
        color1={'gray'}
        color2={'#FF931E'}
        edit={false}
        className="stars"
        value={Number(rating)}
      />
    </div>
  );
};

RideInfo.propTypes = {
  rideData: PropTypes.object,
  canRebook: PropTypes.bool,
  handleClose: PropTypes.func,
  dataToggles: PropTypes.object,
  vehicleTypes: PropTypes.object,
  rebookCallback: PropTypes.func,
  showColumnHeader: PropTypes.bool
};

RideInfo.defaultProps = {
  rideData: {},
  canRebook: false,
  dataToggles: {},
  handleClose: () => {},
  vehicleTypes: {},
  showColumnHeader: false,
  rebookCallback: () => {}
};

export default RideInfo;
