import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { isEmpty, isEqual, cloneDeep, has, get, find, assign, isNil } from 'lodash-es';
import DropDown from '~/Shared/Components/DropDown';
import CheckBox from '~/Shared/Components/CheckBox';
import MemberNav from '../../MemberNav';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { putMemberProfile, clearData } from '~/Modules/memberProfile/actions';
import {
  getDropDownFormattedVehicleTypes,
  vehicleSubTypeRelationships
} from '~/utilities/memberProfile';
import LoadingModal from '~/Shared/Components/LoadingModal';
import MultiSelect from '~/Shared/Components/MultiSelect';
import { VEHICLE_SUB_TYPE } from '~/constants/features';
import { PERMISSIONS } from '~/Pages/MemberProfiles/MemberProfiles.constants';
import { Container } from '@material-ui/core';

class Mobility extends Component {
  constructor(props) {
    super(props);
    this.state = {
      member_default_assistance_needs: {},
      primaryPreferredNEMTList: [],
      secondaryPreferredNEMTList: [],
      flaggedNEMTList: [],
      primary_preferred_nemts: [],
      secondary_preferred_nemts: [],
      flagged_nemts: {},
      editable: false,
      isDirty: false,
      success: ''
    };
  }

  hasVehicleSubTypes() {
    return this.props.user.features[VEHICLE_SUB_TYPE];
  }

  componentDidMount() {
    const state = this.deriveFormFromRedux();

    this.setState(state, () => {
      this.props.setSelectionCallback(this.handleChildSelection);
      this.props.setEditButton(
        <MobilityEditButton
          permission={this.props.permission}
          editable={this.props.editable}
          handleEdit={this.handleEdit}
          handleCancel={this.handleCancel}
          handleSave={this.handleSave}
        />
      );
    });
  }

  componentDidUpdate(prevProps) {
    const state = {};

    if (!isEqual(this.props.mobility, prevProps.mobility)) {
      assign(state, this.deriveFormFromRedux());
    }

    if (!isEmpty(this.props.success) && isEmpty(prevProps.success)) {
      state.success =
        this.props.success.formType === 'mobility' ? this.props.success.message : '';
      state.showLoader = false;
      state.isDirty = false;
      this.props.cancel();
    }

    if (!isEmpty(this.props.error) && isEmpty(prevProps.error)) {
      state.error = this.props.error;
      state.showLoader = false;
    }

    if (this.props.editable !== prevProps.editable) {
      this.props.setEditButton(
        <MobilityEditButton
          permission={this.props.permission}
          editable={this.props.editable}
          handleEdit={this.handleEdit}
          handleCancel={this.handleCancel}
          handleSave={this.handleSave}
        />
      );
    }

    if (Object.keys(state).length) {
      this.setState(state, () => {
        if (this.state.error !== state.error) {
          this.props.setErrorMsg(state.error);
        }
        this.props.setIsChildDataLoading(state.showLoader);
      });
    }
  }

  /**
   * @returns {undefined} empty
   */
  componentWillUnmount() {
    this.props.cancel();
    this.props.clearData();
  }

  deriveFormFromRedux() {
    const vehicleCompanies = this.props.user;
    const preferredNEMTList = vehicleCompanies.map(v => ({
      id: v.id,
      name: v.companyName
    }));

    const memberData = cloneDeep(get(this.props.mobility, 'memberData', {}));
    if (
      has(memberData, 'member_default_assistance_needs') &&
      isEmpty(memberData.member_default_assistance_needs)
    ) {
      memberData.member_default_assistance_needs = {};
    }
    memberData.primary_preferred_nemts = memberData.primary_preferred_nemts || [];
    memberData.secondary_preferred_nemts = memberData.secondary_preferred_nemts || [];
    memberData.flagged_nemts = memberData.flagged_nemts || [];

    const flaggedNemts = {};
    for (const nemt of memberData.flagged_nemts) {
      flaggedNemts[nemt.id] = nemt.name;
    }
    memberData.flagged_nemts = flaggedNemts;

    return {
      ...memberData,
      preferredNEMTs: preferredNEMTList,
      primaryPreferredNEMTList: preferredNEMTList,
      secondaryPreferredNEMTList: preferredNEMTList,
      flaggedNEMTList: preferredNEMTList
    };
  }

  handleCheckBox = id => {
    this.setState(prevState => {
      const member_default_assistance_needs = prevState?.member_default_assistance_needs;

      assign(member_default_assistance_needs, {
        [id]: member_default_assistance_needs[id] ? 0 : 1
      });

      return { isDirty: true, member_default_assistance_needs };
    });
  };
  handleCancel = () => {
    this.setState({ isDirty: false });

    this.deriveFormFromRedux();
    this.props.cancel();
  };

  /**
   * edit member profile
   * @return {undefined}
   */
  handleEdit = () => {
    this.setState({ success: '' }, this.props.edit);
  };

  handleSave = () => {
    const passengerId = this.props.selectedId;

    const memberProfile = {};

    this.appendIfInState(memberProfile, [
      'member_default_assistance_needs',
      'member_default_mode',
      'member_ride_alone',
      'member_manual_schedule',
      'member_flag',
      'vehicle_type',
      'vehicle_sub_type',
      'primary_preferred_nemts',
      'secondary_preferred_nemts',
      'flagged_nemts'
    ]);

    const { flagged_nemts } = memberProfile;
    if (typeof flagged_nemts === 'object') {
      const flaggedNemts = [];
      for (const key in flagged_nemts) {
        flaggedNemts.push({
          id: key,
          name: flagged_nemts[key]
        });
      }
      memberProfile.flagged_nemts = flaggedNemts;
    }

    this.props.clearData();

    this.setState(
      {
        showLoader: true,
        isDirty: false
      },
      () => {
        this.props.putMemberProfile({
          passengerId,
          memberProfile,
          formType: 'mobility'
        });
        this.props.setIsChildDataLoading(true);
      }
    );
  };

  appendIfInState(requestPayload, stringKeys) {
    for (const stringKey of stringKeys) {
      if (has(this.state, stringKey)) {
        const value = get(this.state, stringKey);
        if (!isNil(value)) {
          requestPayload[stringKey] = value;
        }
      }
    }
    return requestPayload;
  }

  handleDDSelect(event, item, slug) {
    const { preferredNEMTs } = this.state;

    const newState = {
      [slug]: parseInt(event.target.id),
      isDirty: true
    };

    if (slug === 'primary_preferred_nemts') {
      const preferred_nemt = preferredNEMTs.find(d => d.id === Number(event.target.id));
      if (preferred_nemt) {
        newState.primary_preferred_nemts = [preferred_nemt];
      } else {
        newState.primary_preferred_nemts = [];
      }
      if (!preferred_nemt || preferred_nemt.id === 0) {
        newState.secondary_preferred_nemts = [];
      }
    }

    if (slug === 'secondary_preferred_nemts') {
      const preferred_nemt = preferredNEMTs.find(d => d.id === Number(event.target.id));
      if (preferred_nemt) {
        newState.secondary_preferred_nemts = [preferred_nemt];
      } else {
        newState.secondary_preferred_nemts = [];
      }
    }

    if (slug === 'flagged_nemts') {
      const preferred_nemt = preferredNEMTs.find(d => d.id === Number(event.target.id));
      if (preferred_nemt) {
        const { id, name } = preferred_nemt;
        newState.flagged_nemts[id] = name;
      }
    }

    if (slug === 'vehicle_type') {
      newState.vehicle_sub_type = 0;
    }

    if (slug === 'member_default_mode') {
      newState.vehicle_type = 0;
      newState.vehicle_sub_type = 0;
    }

    this.setState(newState);
  }

  generateSecondaryPreferredNEMTOptions = () => {
    const { primary_preferred_nemts, flagged_nemts, secondaryPreferredNEMTList } =
      this.state;

    const containedList = [];

    if (!isEmpty(primary_preferred_nemts)) {
      containedList.push(primary_preferred_nemts[0].id + '');
    }

    for (const key in flagged_nemts) {
      containedList.push(key + '');
    }

    return [{ id: 0, name: 'None' }].concat(
      secondaryPreferredNEMTList.filter(d => !containedList.includes(d.id + ''))
    );
  };

  getFlaggedNEMTOptions = () => {
    const { primary_preferred_nemts, secondary_preferred_nemts } = this.state;
    let preferredNEMTs = this.state.preferredNEMTs;

    const containedList = [];
    if (!isEmpty(primary_preferred_nemts) && primary_preferred_nemts[0].id !== 0) {
      containedList.push(primary_preferred_nemts[0]);
    }

    if (!isEmpty(secondary_preferred_nemts) && secondary_preferred_nemts[0].id !== 0) {
      containedList.push(secondary_preferred_nemts[0]);
    }

    if (!isEmpty(containedList)) {
      const containedListIds = containedList.map(({ id }) => id);
      preferredNEMTs = preferredNEMTs.filter(d => !containedListIds.includes(d.id));
    }

    if (!isEmpty(preferredNEMTs)) {
      preferredNEMTs = preferredNEMTs.map(item => {
        return { ...item, value: item.name };
      });
    }

    return preferredNEMTs;
  };

  getPrimaryPreferredNEMTList = () => {
    const { secondary_preferred_nemts, flagged_nemts, primaryPreferredNEMTList } =
      this.state;

    const containedList = [];

    if (!isEmpty(secondary_preferred_nemts) && secondary_preferred_nemts[0].id !== 0) {
      containedList.push(secondary_preferred_nemts[0].id + '');
    }

    for (const key in flagged_nemts) {
      containedList.push(key + '');
    }

    return [{ id: 0, name: 'None' }].concat(
      primaryPreferredNEMTList.filter(d => !containedList.includes(d.id + ''))
    );
  };

  getDropdownPlaceholder(slug) {
    const id = get(this.state, slug, 0);
    const selectedValue = find(this.props.mobility[slug].values, { id });
    return selectedValue ? selectedValue.value : '';
  }

  formatDropdownItems(options) {
    return options.map(value => ({
      id: value.id,
      name: value.value
    }));
  }

  //TODO: pass in slug and state
  enableMobilityTypeDDs() {
    const memberDefaultModeValues =
      this.props.mobility?.member_default_mode?.values ?? [];

    const memberDefaultModeValuesNormalized = memberDefaultModeValues.map(option => {
      option.value = option.value.toLowerCase().trim();
      return option;
    });

    const personalReimbursementOption = find(
      memberDefaultModeValuesNormalized,
      { value: 'personal reimbursement' },
      {}
    );
    const personalReimbursementOptionId = personalReimbursementOption?.id ?? 0;

    const selectedOptionId = this.state?.member_default_mode ?? 0;

    return selectedOptionId !== personalReimbursementOptionId;
  }

  getSubMobilityTypeDisabled() {
    // No feature? No sub mobility type!
    if (!this.hasVehicleSubTypes()) return true;

    if (!this.enableMobilityTypeDDs()) return true;

    return !this.props?.editable;
  }

  handleChildSelection = () => {
    return (
      !this.state.isDirty || confirm(`Are you sure you want to leave without saving?`)
    );
  };

  /**
   * render modal and calendar
   * @return {jsx} returns jsx.
   */
  render() {
    const {
      primary_preferred_nemts,
      showLoader,
      vehicle_type = 0,
      vehicle_sub_type = 0,
      success = '',
      error = ''
    } = this.state;

    const { permission, mobility, editable, navOptions } = this.props;
    const memberDefaultMode = mobility?.member_default_mode ?? null;
    const memberRideAlone = mobility?.member_ride_alone ?? null;
    const memberManualSchedule = mobility?.member_manual_schedule ?? null;
    const memberFlag = mobility?.member_flag ?? null;
    const memberDefaultAssistanceNeedsValues =
      mobility?.member_default_assistance_needs?.values ?? null;

    /** Vehicle Type/SubType validation and logic */
    const vehicleTypes = mobility?.vehicle_types ?? [];
    const selectedVehicleTypeId = vehicle_type;
    const selectedVehicleSubTypeId = vehicle_sub_type;

    const flaggedNEMTOptions = this.getFlaggedNEMTOptions();

    const {
      relevantVehicleSubTypes,
      selectedVehicleTypeName,
      selectedVehicleSubTypeName
    } = vehicleSubTypeRelationships(
      vehicleTypes,
      selectedVehicleTypeId,
      selectedVehicleSubTypeId
    );

    const subMobilityPlaceholder =
      this.hasVehicleSubTypes() && this.enableMobilityTypeDDs()
        ? selectedVehicleSubTypeName
        : '';

    const isSubMobilityDropdownDisabled =
      this.getSubMobilityTypeDisabled() || isEmpty(relevantVehicleSubTypes);

    const isSecondaryDropdownDisabled =
      editable === false ||
      isEmpty(primary_preferred_nemts) ||
      (primary_preferred_nemts[0] && primary_preferred_nemts[0].id === 0);

    return (
      <Container className="parentTab">
        {showLoader ? (
          <LoadingModal label="Saving Member Benefits..." isOpen={showLoader} />
        ) : null}
        <div className="childTab Mobility">
          <MemberNav
            navOptions={navOptions}
            handleChildSelection={this.handleChildSelection}
          >
            <MemberNav.ErrorMsg msg={error} />
            <MemberNav.SuccessMsg msg={success} />
            <MemberNav.EditButton>
              {permission === PERMISSIONS.EDIT ||
              permission === PERMISSIONS.CREATE_EDIT ? (
                  <>
                    {editable ? (
                      <>
                        <a onClick={this.handleCancel}>Cancel</a>
                        <a onClick={this.handleSave}>Save</a>
                      </>
                    ) : (
                      <a onClick={this.handleEdit}>Edit</a>
                    )}
                  </>
                ) : null}
            </MemberNav.EditButton>
          </MemberNav>

          <div className="memberRow">
            {memberDefaultMode && (
              <DropDown
                customClassName="oneColumn"
                placeholder={this.getDropdownPlaceholder(memberDefaultMode.slug)}
                items={this.formatDropdownItems(memberDefaultMode.values)}
                label={memberDefaultMode.name}
                showLabel={true}
                dropDownCallback={(e, item) =>
                  this.handleDDSelect(e, item, memberDefaultMode.slug)
                }
                disabled={!editable}
              />
            )}

            {!isEmpty(vehicleTypes) && (
              <DropDown
                customClassName="oneColumn defaultMobilityDropdown"
                placeholder={this.enableMobilityTypeDDs() ? selectedVehicleTypeName : ''}
                items={getDropDownFormattedVehicleTypes(vehicleTypes)}
                label={'Member Default Mobility Type'}
                showLabel={true}
                dropDownCallback={(e, item) =>
                  this.handleDDSelect(e, item, 'vehicle_type')
                }
                disabled={editable === false || !this.enableMobilityTypeDDs()}
                tooltips={true}
              />
            )}

            {!isEmpty(vehicleTypes) && (
              <DropDown
                customClassName="oneColumn"
                placeholder={subMobilityPlaceholder}
                items={relevantVehicleSubTypes}
                label={'Member Default Sub Mobility Type'}
                showLabel={true}
                dropDownCallback={(e, item) =>
                  this.handleDDSelect(e, item, 'vehicle_sub_type')
                }
                //todo add disabled for no vehicle_sub_types
                disabled={isSubMobilityDropdownDisabled}
              />
            )}
          </div>
          <div className="memberRow">
            {memberRideAlone && (
              <DropDown
                customClassName="oneColumn"
                items={this.formatDropdownItems(memberRideAlone.values)}
                placeholder={this.getDropdownPlaceholder(memberRideAlone.slug)}
                label={memberRideAlone.name}
                showLabel={true}
                dropDownCallback={(e, item) =>
                  this.handleDDSelect(e, item, memberRideAlone.slug)
                }
                disabled={!editable}
              />
            )}

            {memberManualSchedule && (
              <DropDown
                customClassName="oneColumn"
                items={this.formatDropdownItems(memberManualSchedule.values)}
                placeholder={this.getDropdownPlaceholder(memberManualSchedule.slug)}
                label={memberManualSchedule.name}
                showLabel={true}
                dropDownCallback={(e, item) =>
                  this.handleDDSelect(e, item, memberManualSchedule.slug)
                }
                disabled={!editable}
              />
            )}

            {memberFlag && (
              <DropDown
                customClassName="oneColumn"
                placeholder={this.getDropdownPlaceholder(memberFlag.slug)}
                items={this.formatDropdownItems(memberFlag.values)}
                label={memberFlag.name}
                showLabel={true}
                dropDownCallback={(e, item) =>
                  this.handleDDSelect(e, item, memberFlag.slug)
                }
                disabled={!editable}
              />
            )}
          </div>
          <div className="memberRow">
            <DropDown
              customClassName="oneColumn"
              placeholder={
                (this.state.primary_preferred_nemts[0] &&
                  this.state.primary_preferred_nemts[0].name) ||
                ''
              }
              showLabel={true}
              label="Primary Preferred NEMT"
              dropDownCallback={(e, item) =>
                this.handleDDSelect(e, item, 'primary_preferred_nemts')
              }
              items={this.getPrimaryPreferredNEMTList()}
              disabled={editable === false}
            />
            <DropDown
              customClassName="oneColumn"
              placeholder={
                (this.state.secondary_preferred_nemts[0] &&
                  this.state.secondary_preferred_nemts[0].name) ||
                ''
              }
              showLabel={true}
              label="Secondary Preferred NEMT"
              dropDownCallback={(e, item) =>
                this.handleDDSelect(e, item, 'secondary_preferred_nemts')
              }
              items={this.generateSecondaryPreferredNEMTOptions()}
              disabled={isSecondaryDropdownDisabled}
            />
            <MultiSelect
              dropDownCallback={flagged_nemts => this.setState({ flagged_nemts })}
              items={flaggedNEMTOptions}
              showLabel={true}
              label="Flagged NEMT"
              customClassName="oneColumn"
              clearAll="None"
              sendBackObject={true}
              disabled={editable === false}
              defaultChecked={
                Array.isArray(this.state.flagged_nemts) ? {} : this.state.flagged_nemts
              }
            />
          </div>
          <br />
          {memberDefaultAssistanceNeedsValues && (
            <>
              <p>Assistance Needs</p>
              <div className="memberRow checkBoxWrap">
                {memberDefaultAssistanceNeedsValues &&
                  memberDefaultAssistanceNeedsValues.map(assistanceNeed => {
                    return (
                      <CheckBox
                        key={assistanceNeed.id}
                        customClassName="oneColumn"
                        fieldName={assistanceNeed.value}
                        label={assistanceNeed.value}
                        blue={true}
                        disabled={!editable}
                        onChangeCallback={() => this.handleCheckBox(assistanceNeed.id)}
                        checked={
                          this.state.member_default_assistance_needs[
                            assistanceNeed.id
                          ] === 1
                        }
                      />
                    );
                  })}
              </div>
            </>
          )}
        </div>
      </Container>
    );
  }
}
const MobilityEditButton = ({
  editable,
  permission,
  handleEdit,
  handleSave,
  handleCancel
}) => {
  return (
    <>
      {permission === PERMISSIONS.EDIT || permission === PERMISSIONS.CREATE_EDIT ? (
        <>
          {editable ? (
            <>
              <a onClick={handleCancel}>Cancel</a>
              <a onClick={handleSave}>Save</a>
            </>
          ) : (
            <a onClick={handleEdit}>Edit</a>
          )}
        </>
      ) : null}
    </>
  );
};

Mobility.propTypes = {
  user: PropTypes.object,
  editable: PropTypes.bool,
  edit: PropTypes.func,
  cancel: PropTypes.func,
  selectedId: PropTypes.string,
  passengerId: PropTypes.number,
  permission: PropTypes.string,
  memberProfile: PropTypes.object,
  mobility: PropTypes.object,
  setIsChildDataLoading: PropTypes.func,
  putMemberProfile: PropTypes.func.isRequired,
  error: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
  success: PropTypes.object,
  clearData: PropTypes.func.isRequired,
  vehicleCompanies: PropTypes.array
};

Mobility.defaultProps = {
  user: {},
  editable: false,
  edit: () => {},
  cancel: () => {},
  selectedId: 0,
  passengerId: 0,
  permission: 'view',
  memberProfile: {},
  setIsChildDataLoading: () => {},
  mobility: {},
  vehicleCompanies: [],
  error: {},
  success: {}
};

const mapStateToProps = state => ({
  user: state.user,
  location: state.router.location,
  memberProfile: state.memberProfile,
  mobility: get(state, 'memberProfile.formData.mobility', {}),
  error: state.memberProfile.error,
  success: state.memberProfile.success
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      putMemberProfile: params => putMemberProfile(params),
      clearData: () => clearData()
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(Mobility);
