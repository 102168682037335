import React from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import {
  PREAUTHORIZE_ERROR,
  LYFT_ON_DEMAND_SEARCH_TIMEOUT,
  RIDESHARE_ON_DEMAND_SEARCH_TIMEOUT,
  AVAILABLE_CARDS_ERROR,
  PREAUTHORIZE_RIDES_VEHICLE_ERROR,
  CONFIRM_ERROR,
  SUBMIT_RIDE_ERROR,
  LYFT_RIDES_ON_DEMAND_OPTIONS,
  SUBMIT_RIDE_LYFT_ON_DEMAND_ERROR,
  alertTypes,
  availableCards,
  ridesPreAuthorizeVehicle,
  confirm,
  confirmPublic,
  submitRide,
  submitRideLyft,
  routeSelected,
  lyftRidesPreAuthorize,
  ridesPreAuthorize,
  ridesPreAuthorizeVehicleMulti,
  ridesPreAuthorizePublic,
  reset
} from '~/Modules/scheduleRides';
import { statusNotifications, isRideShareVehicleId } from '~/utilities/helperFunctions';
import {
  showBenefitsPage,
  showTransportationPage,
  showConfirmationPage,
  validateConfirmationData
} from '~/utilities/booking.helper';
import TransportOptions from './Components/TransportOptions';
import Authorization from './Components/Authorization';
import Confirmation from './Components/Confirmation';
import Retry from './Components/Retry/Retry';
import _, { cloneDeep } from 'lodash-es';
import { Redirect } from 'react-router-dom';
import URL from 'url';
import {
  LYFT_VEHICLE_ID,
  COMPONENT_STRINGS,
  PUBLIC_ROUTELESS_RIDES_MESSAGE,
  UBER_VEHICLE_ID
} from '~/constants';
import VehicleCompany from './Components/TransportOptions/VehicleCompany';
import SvgAddCircle from '~//Shared/Components/Svgs/SvgAddCircle.js';
import SvgRemoveCircle from '~/Shared/Components/Svgs/SvgRemoveCircle.js';

const ALLOWED_STATUS = ['allow', 'alert', 'block', 'stop'];

class BookingConfirmation extends React.Component {
  /**
   * constructor
   * @param {object} props, list of props passed down from RideReport.js
   * @return {undefined} returns nothing
   */
  constructor(props) {
    super(props);
    this.state = {
      showAuthorization: false,
      showTransportOptions: false,
      showConfirmation: false,
      showRetry: false,
      isLyft: false,
      hasChatData: false,
      showUsThemLegs: [],
      publicSelectedLegs: {}
    };
    this.modalParams = {};
    this.submittedData = {};
  }

  componentDidMount() {
    if (this.props.bookingData.id) {
      this.setState({
        showAuthorization: false,
        showTransportOptions: false,
        showConfirmation: true
      });
    }
  }

  componentDidUpdate(prevProps) {
    // confirmation data check, backwards compatibility
    const validConfirmationCardData = validateConfirmationData(
      this.props.confirmationCardData
    );
    const showTransportReducers = [
      PREAUTHORIZE_ERROR,
      LYFT_ON_DEMAND_SEARCH_TIMEOUT, // TODO:UBER — Remove After Uber Integration is verified
      RIDESHARE_ON_DEMAND_SEARCH_TIMEOUT,
      AVAILABLE_CARDS_ERROR,
      LYFT_RIDES_ON_DEMAND_OPTIONS
    ];
    const showConfirmationReducers = [
      CONFIRM_ERROR,
      SUBMIT_RIDE_ERROR,
      SUBMIT_RIDE_LYFT_ON_DEMAND_ERROR
    ];
    const onlyOneVehicleOption = this.isSingleNemtCard(prevProps);
    if (
      prevProps.displayTransportationOptions !== this.props.displayTransportationOptions
    ) {
      if (this.props.displayTransportationOptions) {
        this.setState({
          showAuthorization: false,
          showTransportOptions: !onlyOneVehicleOption,
          showConfirmation: false,
          showRetry: false
        });
      }
    }

    if (prevProps.auth !== this.props.auth) {
      if (this.props.auth.nemtAuthMessage === PUBLIC_ROUTELESS_RIDES_MESSAGE) {
        this.setState({
          showAuthorization: true,
          showTransportOptions: false,
          showConfirmation: false,
          showRetry: false
        });
      }
    }

    if (
      showTransportationPage(
        this.props,
        prevProps,
        showTransportReducers,
        validConfirmationCardData
      )
    ) {
      const state = {
        showAuthorization: false,
        showTransportOptions: !onlyOneVehicleOption,
        showConfirmation: false,
        showRetry: false
      };
      /**
       * TODO:UBER — change to isRideShare — Oliver
       */
      // if (_.get(this.props, 'type', '') === LYFT_RIDES_ON_DEMAND_OPTIONS) {
      const { bookingType, transportType } = this.props.bookingData;
      const isRideShare =
        transportType === LYFT_VEHICLE_ID || transportType === UBER_VEHICLE_ID;
      if (bookingType === 'ondemand' && isRideShare) {
        state.isLyft = true;
      }
      this.setState(state);
      this.props.toggleLoading(false);
    }

    if (this.showRetryPage({ prevProps, props: this.props })) {
      this.setState({
        showAuthorization: false,
        showTransportOptions: false,
        showConfirmation: false,
        showRetry: true
      });
    }

    if (showConfirmationPage(this.props, prevProps, showConfirmationReducers)) {
      this.setState({
        showAuthorization: false,
        showTransportOptions: false,
        showConfirmation: true,
        showRetry: false
      });
      this.props.toggleLoading(false);
    }

    if (
      showBenefitsPage(
        this.props,
        prevProps,
        ALLOWED_STATUS,
        PREAUTHORIZE_RIDES_VEHICLE_ERROR
      )
    ) {
      this.setState({
        showAuthorization: true,
        showTransportOptions: false,
        showConfirmation: false,
        showRetry: false
      });
    }

    if (onlyOneVehicleOption) {
      this.skipTransportSelect();
    }
    // handle redux response from ride submission
    if (
      !_.isEmpty(this.props.data) &&
      this.props.data.message !== prevProps.data.message
    ) {
      if (_.get(this.props, 'data.status', true) === false) {
        if (
          _.get(this.props, 'data.displayBookAlertPopup', false) === true &&
          _.has(this.props, 'data.dates')
        ) {
          this.submitData.confirm_booking = 1;
          this.submitRide(this.submitData, this.submitVehicleId, false);
        }
      } else {
        const redirect = _.get(this.props, 'data.redirectUrl', '');
        const { rideBookedConfirmation } = COMPONENT_STRINGS[this.props.strKey];
        if (redirect !== '') {
          statusNotifications(rideBookedConfirmation, 'success', 2000);
          this.props.toggleLoading(false);
          if (this.props.page === 'all' || this.props.page === 'scheduled') {
            if (this.props.query !== '') {
              this.props.reloadRides();
            }
            this.props.closeAndShowMap();
          } else {
            this.setState({
              redirect: true,
              redirectRideId: this.props.data.rideId
            });
          }
        }
      }
    }

    // listen to chats requestChat.
    // close bookingconfirmation if youre on /scheduled or /all
    const selectedRequestId = _.find(
      Object.keys(this.props.chats),
      requestId => this.props.chats[requestId].selected
    );
    const requestStatus = _.get(
      this.props,
      `chats.${selectedRequestId}.requestStatus`,
      ''
    );
    const prevRequestStatus = _.get(
      prevProps,
      `chats.${selectedRequestId}.requestStatus`,
      ''
    );

    if (requestStatus !== prevRequestStatus) {
      if (
        requestStatus === 'booked' ||
        (prevRequestStatus === 'open' && requestStatus === 'cancelled')
      ) {
        if (
          window.location.pathname.indexOf('/ride/scheduled') > -1 ||
          window.location.pathname.indexOf('/ride/all') > -1
        ) {
          this.props.closeAndShowMap();
        }
      }
      if (requestStatus === 'open') {
        this.setState({ hasChatData: true });
      }
    }
  }

  isSingleNemtCard = prevProps => {
    return (
      this.props.bookingData.bookingType !== 'multileg' &&
      this.props.bookingData.transportMode !== 'Public' &&
      !prevProps.confirmationCardData.length &&
      this.props.confirmationCardData.length > 0 &&
      this.props.confirmationCardData[0]?.availableCompanies?.length === 1 &&
      this.props.confirmationCardData[0]?.blockedCompanies?.length === 0
    );
  };

  skipTransportSelect = () => {
    const {
      bookingData,
      confirmationCardData,
      rideData,
      ridesPreAuthorizeVehicleMulti,
      toggleLoading
    } = this.props;
    const submittedData = _.cloneDeep(rideData.submittedData);

    // single ride, round trip or multileg with 1 ride no need to click confirm rides,
    // just go straight to the benefits and restrictions page
    if (bookingData.bookingType !== 'multileg') {
      confirmationCardData.forEach((card, index) => {
        submittedData.rides[index].companyId =
          confirmationCardData[index]?.availableCompanies[0].companyId;
        submittedData.rides[index].vehicleId =
          confirmationCardData[index]?.availableCompanies[0].id;
      });
      toggleLoading(true, 'Requesting Ride');
      ridesPreAuthorizeVehicleMulti(submittedData);
    }
  };

  showRetryPage = ({ prevProps, props }) => {
    const { bookingData } = props;
    const { transportType } = bookingData;

    if (!isRideShareVehicleId(transportType)) return false;
    return props.error !== prevProps.error && props.error.withRetry;
  };

  /**
   * if vehicle gets reassigned through livechat, we dont want to rerender component
   * @param {object} nextProps - nextProps
   * @param {object} nextState - nextState}
   * @return {undefined}
   */
  shouldComponentUpdate(nextProps, nextState) {
    const selectedRequestId = _.find(
      Object.keys(nextProps.chats),
      requestId => nextProps.chats[requestId].selected
    );
    const requestStatus = _.get(
      nextProps,
      `chats.${selectedRequestId}.requestStatus`,
      ''
    );
    if (nextState.hasChatData && requestStatus !== 'booked') {
      return false;
    }
    return true;
  }

  componentWillUnmount() {
    this.props.reset();
  }

  /**
   * handles confirmation card click, reset rideType val
   * @param {integer} companyId - ID for NEMT vehicle company
   * @param {integer} vehicleType - id for vehicle type (ambulatory, wheelchair)
   * @param {integer} vehicleId - id for vehicle
   * @param {integer} rideType - rideType
   * @returns {undefined} returns nothing
   */
  chooseVehicleId = (companyId, vehicleType, vehicleId, rideType) => {
    const submittedData = cloneDeep(this.props.rideData.submittedData);

    submittedData.vehicle_id = vehicleId;
    submittedData.rideType = rideType;

    this.props.ridesPreAuthorizeVehicle(companyId, vehicleType, submittedData);
  };

  /**
   * TODO:UBER
   */

  /**
   * click event for choosing lyft vehicle
   * @param {object} lyftVehicleInfo - vehicle info for selected lyft ride
   * @return {undefined}
   */
  chooseLyftVehicleId = lyftVehicleInfo => {
    const submittedData = _.cloneDeep(this.props.rideData.submittedData);
    _.assign(submittedData.rides[0], lyftVehicleInfo);

    this.props.ridesPreAuthorize(submittedData);
  };

  /**
   * confirmation handler
   * @return {undefined}
   */
  confirm = ({ isPublicRoutelessRides }) => {
    const { bookingData, rideData, confirm } = this.props;
    /**
     * TODO:UBER
     */
    const transportType = parseInt(bookingData.transportType, 10);
    const isLyft = transportType === LYFT_VEHICLE_ID || transportType === UBER_VEHICLE_ID;

    if (bookingData.bookingType === 'ondemand' && isLyft) {
      this.setState({
        showAuthorization: false,
        showTransportOptions: false,
        showConfirmation: true
      });
    } else if (isPublicRoutelessRides) {
      this.setState({
        showAuthorization: false,
        showTransportOptions: false,
        showConfirmation: true,
        isPublicRoutelessRides
      });
    } else if ('submittedData' in rideData) {
      confirm(rideData.submittedData); // This used to be the default state. Generated bugs when submittedDate was empty.
    }
  };

  /**
   * final ride submission
   * @param {object} fields - fields for submitting the ride
   * @param {boolean} isOnDemand - is it an on demand lyft ride
   * @return {undefined}
   */
  submitRide = (fields, isOnDemand) => {
    if (!isOnDemand) {
      this.submitData = fields;
    }

    this.props.submitRide(fields);
  };

  /**
   * closes book new ride component and reinitializes map
   * @return {undefined}
   */
  closeRequestNewRide = () => {
    this.props.closeRequestNewRide(true);
  };

  /**
   * @param {boolean} toggle - turn loading module on or off
   * @param {string} text - loading modal text
   * @return {undefined}
   */
  toggleLoading = (toggle, text) => {
    this.props.toggleLoading(toggle, text);
  };

  /**
   * vehicle selection for multiple leg booking
   * @param {object} submittedData - data object to submit
   * @return {undefined}
   */
  chooseVehiclesMultipleRides = submittedData => {
    this.props.ridesPreAuthorizeVehicleMulti(submittedData);
  };

  chooseVehicleIdPublic = submittedData => {
    this.props.routeSelected(submittedData);
    this.setState({
      publicSelectedLegs: submittedData,
      showAuthorization: false,
      showTransportOptions: false,
      showConfirmation: true
    });
  };

  /**
   * booking is complete so close booking confirmation form
   * @return {undefined}
   */
  bookingComplete() {
    this.setState(
      {
        showAuthorization: false,
        showTransportOptions: false,
        showConfirmation: false
      },
      this.props.closeRequestNewRide
    );
  }

  /**
   *
   * @returns {string} title of the component
   */
  generateHeaderTitle() {
    const { auth } = this.props;
    const { showTransportOptions } = this.state;
    const { headingTransportOption, headingRideConfirmation } =
      COMPONENT_STRINGS[this.props.strKey];
    if (auth?.error?.type === alertTypes.DUPLICATE_RIDE) {
      return 'Review Duplicates';
    }
    return showTransportOptions ? headingTransportOption : headingRideConfirmation;
  }

  render() {
    if (this.state.redirect) {
      const redirectURL = URL.parse(this.props.data.redirectUrl);

      return (
        <Redirect
          to={{
            pathname: redirectURL.pathname,
            state: { rideId: this.state.redirectRideId }
          }}
        />
      );
    }
    const { confirmationCardData } = this.props;

    //if the card confirmation data has blocked companies, and any leg
    const blockedCompaniesWereReturned =
      _.isArray(confirmationCardData) &&
      confirmationCardData.find(leg => {
        return !_.isEmpty(_.get(leg, 'blockedCompanies', []));
      });
    const heading = this.generateHeaderTitle();
    return (
      <div className="BookingConfirmation">
        <div className="heading">{heading}</div>
        <div className="bookingConfirmationCont">
          {this.state.showRetry ? <Retry {...this.props} /> : null}
          {this.state.showTransportOptions === true ? (
            <TransportOptions
              data={this.props.confirmationCardData}
              chooseVehicleId={this.chooseVehicleId}
              chooseVehicleIdPublic={this.chooseVehicleIdPublic}
              chooseLyftVehicleId={this.chooseLyftVehicleId}
              error={this.props.error}
              toggleLoading={this.toggleLoading}
              chooseVehiclesMultipleRides={this.chooseVehiclesMultipleRides}
              handleTransitSelect={this.props.handleTransitSelect}
              transit={this.props.transit}
              bookingData={this.props.bookingData}
              lyft={this.state.isLyft}
              changeTransport={this.props.changeTransport}
            />
          ) : null}
          {this.state.showAuthorization === true && !blockedCompaniesWereReturned ? (
            <Authorization
              confirm={this.confirm}
              ride={this.props.rideData}
              auth={this.props.auth}
              error={this.props.error}
              closeRequestNewRide={this.closeRequestNewRide}
              toggleLoading={this.toggleLoading}
              bookingData={this.props.bookingData}
              availableCards={this.props.availableCards}
              user={this.props.user}
            />
          ) : null}
          {this.state.showConfirmation === true ? (
            <Confirmation
              ride={this.props.rideData}
              trip={this.props.trip}
              submitRide={this.submitRide}
              error={this.props.error}
              toggleLoading={this.toggleLoading}
              bookingData={this.props.bookingData}
              publicSelectedLegs={this.state.publicSelectedLegs}
              data={this.props.data}
              confirmPublic={this.props.confirmPublic}
              closeRequestNewRide={this.closeRequestNewRide}
              isPublicRoutelessRides={this.state.isPublicRoutelessRides}
            />
          ) : null}
          {!this.state.showTransportOptions && blockedCompaniesWereReturned && (
            <div className={'TransportOptions'}>
              <div className={'transportLegOptions'}>
                {this.props.bookingData.bookingType === 'roundtrip' &&
                  _.isArray(confirmationCardData) &&
                  confirmationCardData.map((result, thisLeg) => {
                    return (
                      <>
                        {thisLeg === confirmationCardData.length - 1 ? (
                          <p className="legText">Round Trip</p>
                        ) : null}
                        {thisLeg === confirmationCardData.length - 1 &&
                          result.blockedCompanies.map((vehicleCompany, index) => {
                            return (
                              <VehicleCompany
                                bookRideCallback={() => {}}
                                vehicleInfo={vehicleCompany}
                                key={index}
                                index={thisLeg}
                                multileg={this.state.multileg}
                                selected={false}
                                makeClickable={false}
                                isBlocked={true}
                                bookingData={this.props.bookingData}
                                relevantLeg={{}}
                              ></VehicleCompany>
                            );
                          })}
                      </>
                    );
                  })}
                {this.props.bookingData.bookingType !== 'roundtrip' &&
                  _.isArray(confirmationCardData) &&
                  confirmationCardData.map((result, thisLeg) => {
                    return (
                      <>
                        <div
                          className="accordSelect"
                          onClick={() => {
                            const showUsThemFancyNewLegs = this.state.showUsThemLegs;

                            this.state.showUsThemLegs.indexOf(thisLeg) !== -1
                              ? showUsThemFancyNewLegs.pop(thisLeg)
                              : showUsThemFancyNewLegs.push(thisLeg);

                            this.setState({ showUsThemLegs: showUsThemFancyNewLegs });
                          }}
                        >
                          <span className="vehicleLegText">{`Leg ${thisLeg + 1}`}</span>
                          <span className={'selectedText unselected'}>Unselected</span>
                          {this.state.showUsThemLegs.indexOf(thisLeg) !== -1 ? (
                            <SvgRemoveCircle />
                          ) : (
                            <SvgAddCircle />
                          )}
                        </div>
                        {this.state.showUsThemLegs.indexOf(thisLeg) !== -1 &&
                          _.has(result, 'blockedCompanies') &&
                          result.blockedCompanies.map((vehicleCompany, index) => {
                            return (
                              <VehicleCompany
                                bookRideCallback={() => {}}
                                vehicleInfo={vehicleCompany}
                                key={index}
                                index={thisLeg}
                                multileg={this.state.multileg}
                                selected={false}
                                makeClickable={false}
                                isBlocked={true}
                                bookingData={this.props.bookingData}
                                relevantLeg={{}}
                              ></VehicleCompany>
                            );
                          })}
                      </>
                    );
                  })}
              </div>
            </div>
          )}
        </div>
      </div>
    );
  }
}

BookingConfirmation.defaultProps = {
  strKey: 'BookingConfirmation',
  page: '',
  ridesPreAuthorizeVehicle: () => {},
  confirmationCardData: {},
  submittedData: {},
  rideData: {},
  confirm: () => {},
  auth: {},
  submitRide: () => {},
  closeRequestNewRide: () => {},
  data: {},
  error: {},
  closeAndShowMap: () => {},
  chats: {},
  query: '',
  reloadRides: () => {},
  bookingData: {},
  trip: {},
  toggleLoading: () => {},
  type: '',
  lyftRidesPreAuthorize: () => {},
  submitRideLyft: () => {},
  changeTransport: () => {},
  reset: () => {},
  ridesPreAuthorizeVehicleMulti: () => {},
  ridesPreAuthorizePublic: () => {},
  availableCards: () => {},
  transit: null,
  handleTransitSelect: () => {}
};

BookingConfirmation.propTypes = {
  strKey: PropTypes.string,
  page: PropTypes.string,
  ridesPreAuthorizeVehicle: PropTypes.func,
  // below confirmationCardData - oneOfType is for backwards compatibility (lumen endpoint vs laravel endpoint). eventually they will always be arrays.
  // laravel endpoint the data is an object.
  // lumen it's an array.
  confirmationCardData: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
  rideData: PropTypes.object,
  auth: PropTypes.object,
  confirm: PropTypes.func,
  submitRide: PropTypes.func,
  closeRequestNewRide: PropTypes.func,
  data: PropTypes.object,
  error: PropTypes.object,
  closeAndShowMap: PropTypes.func,
  chats: PropTypes.object,
  query: PropTypes.string,
  reloadRides: PropTypes.func,
  bookingData: PropTypes.object,
  trip: PropTypes.object,
  toggleLoading: PropTypes.func,
  type: PropTypes.string,
  lyftRidesPreAuthorize: PropTypes.func,
  submitRideLyft: PropTypes.func,
  changeTransport: PropTypes.func,
  reset: PropTypes.func,
  ridesPreAuthorizeVehicleMulti: PropTypes.func,
  ridesPreAuthorizePublic: PropTypes.func,
  availableCards: PropTypes.func,
  transit: PropTypes.object,
  handleTransitSelect: PropTypes.func
};

const mapStateToProps = state => ({
  user: state.user,
  hospitalData: state.user.hospitalData,
  hospitalUsers: state.user.hospitalUsers,
  hospitalNames: state.user.hospitalNames,
  nodeUserType: state.user.nodeUserType,
  availableVehicleTypes: state.user.availableVehicleTypes,
  bookingData: state.bookingData,

  // return from request booking
  auth: state.scheduleRides.auth,
  confirmationCardData: state.scheduleRides.confirmationCardData,
  rideData: state.scheduleRides.ride,
  trip: state.scheduleRides.trip,
  //response for final submission
  data: state.scheduleRides.data,
  //loading modal
  error: state.scheduleRides.errorObj,
  type: state.scheduleRides.type,
  chats: state.chats,
  timestamp: state.scheduleRides.timestamp // unused value, but needed to tell the component to re-render when this updates
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      availableCards: data => availableCards(data),
      ridesPreAuthorizeVehicle: (vehicleId, vehicleType, data) =>
        ridesPreAuthorizeVehicle(vehicleId, vehicleType, data),
      lyftRidesPreAuthorize: (data, onDemand) => lyftRidesPreAuthorize(data, onDemand),
      confirm: data => confirm(data),
      submitRide: data => submitRide(data),
      submitRideLyft: data => submitRideLyft(data),
      confirmPublic: data => confirmPublic(data),
      reset: () => reset(),
      routeSelected: route => routeSelected(route),
      ridesPreAuthorize: data => ridesPreAuthorize(data),
      ridesPreAuthorizeVehicleMulti: params => ridesPreAuthorizeVehicleMulti(params),
      ridesPreAuthorizePublic: params => ridesPreAuthorizePublic(params)
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(BookingConfirmation);
