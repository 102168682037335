import type { MemberProfileStore, MemberPhoneNumber } from '~/Modules/memberProfile';
import { additionalPassengerFactory, isPassengerType } from '~/models';
import type { PassengerInfoModel, PassengerInfoProps, TripType } from '~/models';
import type { VehicleSubType } from '~/types';
import { TRANSPORT_TYPE_CLASSIFICATIONS } from '~/constants/vehicles';
import { formatPhoneNumberToE164Standard } from '~/utilities/helperFunctions';

/**
 * Sets to the default assistance fields based on the member profile store and trip type.
 */
export function setToAssistanceDefaults(
  model: PassengerInfoModel | PassengerInfoProps,
  memberProfile: MemberProfileStore,
  tripType: TripType
) {
  const assistanceDefaults = getAssistanceDefaults(memberProfile, tripType);
  model.serviceLevel = assistanceDefaults.serviceLevel;
  model.mobilityDevice = assistanceDefaults.mobilityDevice;
  model.specialCircumstances = assistanceDefaults.specialCircumstances;
  model.driverProvisions = assistanceDefaults.driverProvisions;
}

/**
 * Returns the default assistance fields based on the member profile store and trip type.
 */
function getAssistanceDefaults(
  memberProfile: MemberProfileStore,
  tripType: TripType
): {
  serviceLevel?: string;
  mobilityDevice?: string;
  specialCircumstances: string[];
  driverProvisions: string[];
} {
  return {
    serviceLevel: getMemberServiceLevel(memberProfile, tripType),
    mobilityDevice: getMemberMobilityDevice(memberProfile, tripType),
    specialCircumstances: getMemberSpecialCircumstances(memberProfile, tripType),
    driverProvisions: getMemberDriverProvisions(memberProfile, tripType)
  };
}

/**
 * Returns the default service level based on the member profile store and trip type.
 */
function getMemberServiceLevel(
  memberProfile: MemberProfileStore,
  tripType: TripType
): string | undefined {
  // Public trips do not have a service level.
  if (tripType === 'public') {
    return undefined;
  }
  const memberDefaultAssistanceNeeds =
    memberProfile.formData.mobility.memberData?.member_default_assistance_needs ?? {};
  for (const level of memberProfile.serviceLevels) {
    if (
      level.id in memberDefaultAssistanceNeeds &&
      memberDefaultAssistanceNeeds[level.id] === 1
    ) {
      return level.value as string;
    }
  }
  return undefined;
}

/**
 * Returns the default mobility device based on the member profile store and trip type.
 */
function getMemberMobilityDevice(
  memberProfile: MemberProfileStore,
  tripType: TripType
): string | undefined {
  // Public trips do not have a mobility device.
  if (tripType === 'public') {
    return undefined;
  }
  const memberDefaultAssistanceNeeds =
    memberProfile.formData.mobility.memberData?.member_default_assistance_needs ?? {};
  for (const device of memberProfile.mobilityDevices) {
    if (
      device.id in memberDefaultAssistanceNeeds &&
      memberDefaultAssistanceNeeds[device.id] === 1
    ) {
      return device.value as string;
    }
  }
  // If the member has completed a mobility assessment but no device was selected, return 'None'
  if (memberProfile.mobilityAssessment?.submission.length) {
    return 'None';
  }
  return undefined;
}

/**
 * Returns the default special circumstances based on the member profile store and trip type.
 */
function getMemberSpecialCircumstances(
  memberProfile: MemberProfileStore,
  tripType: TripType
): string[] {
  // Public trips do not have special circumstances.
  if (tripType === 'public') {
    return [];
  }
  const memberDefaultAssistanceNeeds =
    memberProfile.formData.mobility.memberData?.member_default_assistance_needs ?? {};
  const specialCircumstances: string[] = [];
  for (const need of memberProfile.assistanceNeeds) {
    if (
      need.id in memberDefaultAssistanceNeeds &&
      memberDefaultAssistanceNeeds[need.id] === 1
    ) {
      specialCircumstances.push(need.value as string);
    }
  }
  return specialCircumstances;
}

/**
 * Returns the default driver provisions based on the member profile and trip type.
 */
function getMemberDriverProvisions(
  memberProfile: MemberProfileStore,
  tripType: TripType
): string[] {
  // Public trips do not have driver provisions.
  if (tripType === 'public') {
    return [];
  }
  const memberDefaultAssistanceNeeds =
    memberProfile.formData.mobility.memberData?.member_default_assistance_needs ?? {};
  const driverProvisions: string[] = [];
  for (const prov of memberProfile.driverProvisions) {
    if (
      prov.id in memberDefaultAssistanceNeeds &&
      memberDefaultAssistanceNeeds[prov.id] === 1
    ) {
      driverProvisions.push(prov.value as string);
    }
  }
  return driverProvisions;
}

/** Check criteria to verify that a phone number is textable. */
function phoneNumberIsTextable(phoneNumber: MemberPhoneNumber | undefined) {
  return (
    phoneNumber &&
    phoneNumber.textable === 1 &&
    phoneNumber.phone_type === 'mobile' &&
    !phoneNumber.opt_out
  );
}

/** Retrieves the ride's primary phone number from the member profile. Prioritizes
 * the primary number unless it is not textable. In that case it takes the secondary
 * number if it is mobile, textable and has not opted out. */
export function getMemberPhoneNumbers(memberProfile: MemberProfileStore): {
  primary: Partial<MemberPhoneNumber> | undefined;
  secondary?: Partial<MemberPhoneNumber> | undefined;
} {
  const memberNumbers = memberProfile.formData.personalInfo.memberPhoneNumbers;
  const primary = memberNumbers.find(({ is_primary }) => is_primary);
  const secondary = memberNumbers.find(
    p =>
      p.phone_number ===
      formatPhoneNumberToE164Standard(memberProfile.formData.personalInfo.phone2)
  );

  if (phoneNumberIsTextable(primary)) {
    return { primary, secondary };
  }

  if (memberProfile.formData.personalInfo.phone2) {
    if (phoneNumberIsTextable(secondary)) {
      return { primary: secondary };
    }
  }

  return { primary, secondary };
}

/** Extract the default TransportType from the member's default transport type. If
 * not present, returns undefined. */
export function getMemberTransportType(memberProfile: MemberProfileStore) {
  const mobilityData = memberProfile.formData.mobility;

  const vehicleRecord = mobilityData.vehicle_types.find(
    ({ id }) => id === mobilityData.memberData?.vehicle_type
  );

  return vehicleRecord?.nickName ?? undefined;
}

/** Pull the transport type sub types from a transport type. */
export function getTransportTypeSubTypes(
  memberProfile: MemberProfileStore,
  tripType: TripType,
  transportType: string | undefined
) {
  if (!transportType) return [];

  return memberProfile.transportTypes[tripType]![transportType]?.subTypes ?? [];
}

/**
 * Get the member's sub transport type.
 */
export function getMemberSubTransportType(
  memberProfile: MemberProfileStore,
  transportSubTypes: VehicleSubType[]
) {
  const mobilityData = memberProfile.formData.mobility;
  const subTypeId = mobilityData.memberData.vehicle_sub_type;

  return transportSubTypes.find(({ id }) => id === subTypeId)?.['value'] ?? undefined;
}

/** Extract a TripType from the member's selected default mode and/or TransportType. If not
 * present, defaults to first available key in TransportTypes. */
export function getMemberTripType(
  memberProfile: MemberProfileStore,
  transportType: string | undefined
): TripType {
  const mobilityData = memberProfile.formData.mobility;

  const defaultVal = mobilityData.member_default_mode.values.find(
    ({ id }) => id === mobilityData.memberData?.member_default_mode
  );

  /**
   * If the member has public set as the default use that.
   * The hard-coded values in TRANSPORT_TYPE_CLASSIFICATIONS are
   * for both public and private but with that if before this then
   * it was never defaulting to public when the member has that selected
   * as the preferred mode.
   */
  if (defaultVal?.value.toLowerCase() === 'public') {
    return defaultVal?.value.toLowerCase() as TripType;
  }

  if (transportType && transportType in TRANSPORT_TYPE_CLASSIFICATIONS) {
    return TRANSPORT_TYPE_CLASSIFICATIONS[transportType];
  }

  return Object.keys(memberProfile.transportTypes)[0] as TripType;
}

/** Extract the additional passenger data from a member profile's attendantInfo
 * section. */
export function getMemberAdditionalPassengers(
  memberProfile: MemberProfileStore,
  primaryPassengerTransportType: string | undefined
) {
  const additionalPassengers =
    memberProfile.formData.attendantInfo.memberData?.additionalPassengers ?? [];

  return additionalPassengers.map(passenger => {
    const type = isPassengerType(passenger.ageGroup) ? passenger.ageGroup : 'adult';

    let transportType;
    let subTransportType;

    // If the primary passenger is a Lyft or Uber ride, we restrict the additional
    // passengers to the same transport type.
    if (
      primaryPassengerTransportType === 'LFT' ||
      primaryPassengerTransportType === 'UBR'
    ) {
      transportType = primaryPassengerTransportType;
      subTransportType = undefined;
    } else {
      transportType = memberProfile.formData.mobility.vehicle_types.find(
        ({ id }) => id === passenger.mobilityType
      )?.nickName;

      subTransportType = passenger.subMobilityType ?? undefined;
    }

    return additionalPassengerFactory({
      type: type,
      isAttendant: passenger.isAttendant,
      firstName: passenger.firstName,
      lastName: passenger.lastName,
      transportType: transportType ?? undefined,
      subTransportType
    });
  });
}
