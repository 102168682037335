import React from 'react';
import { Link } from '@SRHealth/frontend-lib';
import { format, addDays, endOfDay } from 'date-fns';
import type { DuplicateRide } from '~/Modules/rideBooking';
import { getFormattedDuplicateRideTime } from '~/Pages/RideBooking/RideBooking.utils';

interface DuplicateRideDetailsProps {
  duplicateRide: DuplicateRide;
  selectedDate: DateString;
}

const DuplicateRideDetails = ({
  duplicateRide,
  selectedDate
}: DuplicateRideDetailsProps) => {
  const pickupTime = getFormattedDuplicateRideTime(duplicateRide);
  // Convert selectedDate to the last millisecond of the next day for the ride history table range
  const toDate = format(
    addDays(endOfDay(new Date(selectedDate)), 2),
    'yyyy-MM-dd\'T\'HH:mm:ss.SSS\'Z\''
  );

  return (
    <div className="text-base" style={{ paddingTop: '10px' }}>
      <div
        style={{
          display: 'flex',
          marginLeft: '-6px',
          marginBottom: '-6px'
        }}
      >
        <Link
          href={`/ride/reports?id=${duplicateRide.id}&toDate=${toDate}`}
          target="_blank"
          label={`Ride ID: ${duplicateRide.id}`}
          onClick={() => {}}
        />
      </div>
      <div className="text-base">{`Pick up time: ${pickupTime}`}</div>
      <div className="text-base">{`Location: ${duplicateRide.pickupAddress}`}</div>
    </div>
  );
};

export default DuplicateRideDetails;
