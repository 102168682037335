import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import {
  ridesPreAuthorize,
  confirm,
  submitRide,
  reset,
  ridesPreAuthorizeVehicleMulti,
  PREAUTHORIZE_ERROR,
  AVAILABLE_CARDS_ERROR,
  PREAUTHORIZE_RIDES_VEHICLE_ERROR,
  CONFIRM_ERROR
} from '~/Modules/scheduleRides';
import { updateBookingData } from '~/Modules/bookingData';
import _ from 'lodash-es';
import { CHAT_CANCELLATION_REASON } from '~/constants';
import { getRideDetailsPayloadMulti } from '~/utilities/chats.helper';
import { possessive } from '~/utilities/helperFunctions';
import { isEmpty } from '@SRHealth/frontend-lib';
import {
  showTransportationPage,
  showBenefitsPage,
  showConfirmationPage,
  validateConfirmationData
} from '~/utilities/booking.helper';
import Authorization from '~/Shared/Components/BookingConfirmation/Components/Authorization';
import Confirmation from '~/Shared/Components/BookingConfirmation/Components/Confirmation';
import TransportOptions from '~/Shared/Components/BookingConfirmation/Components/TransportOptions';
import SvgClose from '~/Shared/Components/Svgs/SvgClose';
import ReactLoading from 'react-loading';

const ALLOWED_STATUS = ['allow', 'alert', 'block', 'stop'];

class LiveChatReassign extends Component {
  constructor(props) {
    super(props);
    this.state = {
      currentStepIndex: 98,
      errorMessage: ''
    };
  }

  /**
   * lifecyle function triggers preauthorize call and sets booking data for booking confirmation components
   * @return {undefined}
   */
  componentDidMount() {
    const submittedData = getRideDetailsPayloadMulti(this.props.selectedChat);
    const bookingData = _.cloneDeep(submittedData);
    bookingData.currentRideIndex = bookingData.rides.length - 1;
    this.props.updateBookingData(bookingData);
    this.props.ridesPreAuthorize(submittedData);
  }

  componentDidUpdate(prevProps) {
    const confirmationCardData = this.props?.confirmationCardData ?? {};
    const benefitsData =
      this.props?.selectedChat?.availableNemts?.[this.props.selectedVehicleOwnerId]
        ?.benefitsData ?? {};

    const requestStatus = this.props?.selectedChat?.requestStatus ?? '';
    const prevRequestStatus = prevProps?.selectedChat?.requestStatus ?? '';

    // skip to final step if there are no benefits available
    if (
      !isEmpty(benefitsData) &&
      isEmpty(confirmationCardData) &&
      !benefitsData.benefits.length &&
      !benefitsData.messages.length
    ) {
      this.confirm();
    }

    // check if status changed to somether other than 'open' or 'active' and cancel with error
    if (
      requestStatus !== prevRequestStatus &&
      !['open', 'active'].includes(requestStatus)
    ) {
      const cancellationReason = this.props?.selectedChat?.cancellationReason ?? 0;
      const isNemtCancelled =
        requestStatus === 'cancelled' &&
        cancellationReason === CHAT_CANCELLATION_REASON.BY_NEMT_RESPONDER;

      if (!isNemtCancelled) {
        this.setState({
          currentStepIndex: 99,
          errorMessage: `This ride request has been ${requestStatus} and can no longer be reassigned.`
        });
      }
    }

    if (
      showTransportationPage(
        this.props,
        prevProps,
        [PREAUTHORIZE_ERROR, AVAILABLE_CARDS_ERROR],
        validateConfirmationData(this.props.confirmationCardData)
      )
    ) {
      this.setState({ currentStepIndex: 0 });
    }

    if (
      showBenefitsPage(
        this.props,
        prevProps,
        ALLOWED_STATUS,
        PREAUTHORIZE_RIDES_VEHICLE_ERROR
      )
    ) {
      this.setState({ currentStepIndex: 1 });
    }

    if (showConfirmationPage(this.props, prevProps, [CONFIRM_ERROR])) {
      this.setState({ currentStepIndex: 2 });
    }
  }

  /**
   * for handling vehicle selection
   * @param {object} submittedData - data to be submitted
   * @return {undefined}
   */
  chooseVehiclesMultipleRides = submittedData => {
    this.setState({ currentStepIndex: 98 }, () => {
      this.props.ridesPreAuthorizeVehicleMulti(submittedData);
    });
  };

  /**
   * confirmation handler
   * @return {undefined}
   */
  confirm = () => {
    const { rideData, confirm } = this.props;

    this.setState({ currentStepIndex: 98 }, () => confirm(rideData.submittedData));
  };

  /**
   * close modal
   * @return {undefined}
   */
  close = () => this.props.hideReassignWindow();

  /**
   * adding parameter for sending vehicle owner id
   * @return {undefined}
   */
  confirmReassignment = () => {
    this.setState({ currentStepIndex: 98 }, () => {
      this.props.confirmReassignment(this.props.trip.rides[0].vehicleOwnerId);
    });
  };

  /**
   * heading
   * @param {integer} step - step
   * @return {string} returns title
   */
  getHeading(step) {
    switch (step) {
      case 0:
        return 'Available NEMTs';

      case 1: {
        const patientNamePossessive = possessive(
          this.props.selectedChat?.rideDetails?.patientName ?? '',
          this.props.selectedChat?.rideDetails?.patientLastName ?? ''
        );
        return `${patientNamePossessive} Benefits`;
      }

      case 2:
        return 'Ride Confirmation';

      case 98:
        return 'Loading';

      case 99:
        return 'Error';

      default:
        return 'Available NEMTs';
    }
  }

  render() {
    const heading = this.getHeading(this.state.currentStepIndex);

    return (
      <div className="LiveChatReassign">
        <div className="reassignStep">
          <h3>{heading}</h3>
          <div className="closeReassignWindow" onClick={this.close}>
            <SvgClose />
          </div>
          <div className="reassignBlock">
            {
              {
                0: (
                  <TransportOptions
                    error={this.props.error}
                    lyft={false}
                    data={this.props.confirmationCardData}
                    bookingData={this.props.bookingData}
                    chooseVehiclesMultipleRides={this.chooseVehiclesMultipleRides}
                  />
                ),
                1: (
                  <Authorization
                    confirm={this.confirm}
                    ride={this.props.rideData}
                    auth={this.props.auth}
                    error={this.props.error}
                    closeRequestNewRide={this.close}
                    toggleLoading={this.toggleLoading}
                    bookingData={this.props.bookingData}
                    user={this.props.user}
                  />
                ),
                2: (
                  <Confirmation
                    ride={this.props.rideData}
                    trip={this.props.trip}
                    submitRide={this.confirmReassignment}
                    error={this.props.error}
                    bookingData={this.props.bookingData}
                    data={this.props.data}
                    closeRequestNewRide={this.close}
                  />
                ),
                97: (
                  <div className="reassignStep">
                    <h3>Success</h3>
                    <div className="reassignError">
                      <p>Your ride was reassigned.</p>
                      <a onClick={this.props.hideReassignWindow}>Close this window</a>
                    </div>
                  </div>
                ),
                98: <ReactLoading type="spin" className="reassignLoader" />,
                99: (
                  <div className="reassignStep">
                    <h3>Error</h3>
                    <div className="reassignError">
                      {this.state.errorMessage}
                      <a onClick={this.props.hideReassignWindow}>Close this window</a>
                    </div>
                  </div>
                )
              }[this.state.currentStepIndex]
            }
          </div>
        </div>
      </div>
    );
  }
}

LiveChatReassign.propTypes = {
  selectedChat: PropTypes.object,
  selectedVehicleOwnerId: PropTypes.number,
  hideReassignWindow: PropTypes.func,
  confirmReassignment: PropTypes.func,
  ridesPreAuthorize: PropTypes.func,
  ridesPreAuthorizeVehicleMulti: PropTypes.func,
  confirm: PropTypes.func,
  submitRide: PropTypes.func,
  reset: PropTypes.func,
  bookingData: PropTypes.object,
  chats: PropTypes.object,
  confirmationCardData: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
  updateBookingData: PropTypes.func,
  rideData: PropTypes.object,
  error: PropTypes.object,
  auth: PropTypes.object,
  trip: PropTypes.object,
  data: PropTypes.object,
  user: PropTypes.object
};

LiveChatReassign.defaultProps = {
  selectedChat: {},
  selectedVehicleOwnerId: 0,
  hideReassignWindow: () => {},
  confirmReassignment: () => {},
  ridesPreAuthorize: () => {},
  ridesPreAuthorizeVehicleMulti: () => {},
  confirm: () => {},
  submitRide: () => {},
  reset: () => {},
  bookingData: {},
  chats: {},
  confirmationCardData: [],
  updateBookingData: () => {},
  rideData: {},
  error: {},
  auth: {},
  trip: {},
  data: {},
  user: {}
};

const mapStateToProps = state => ({
  auth: state.scheduleRides.auth,
  confirmationCardData: state.scheduleRides.confirmationCardData,
  rideData: state.scheduleRides.ride,
  trip: state.scheduleRides.trip,
  data: state.scheduleRides.data,
  error: state.scheduleRides.errorObj,
  type: state.scheduleRides.type,
  timestamp: state.scheduleRides.timestamp // unused value, but needed to tell the component to re-render when this updates
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      ridesPreAuthorize: (data, reassign) => ridesPreAuthorize(data, reassign),
      confirm: data => confirm(data),
      submitRide: data => submitRide(data),
      reset: () => reset(),
      updateBookingData: data => updateBookingData(data),
      ridesPreAuthorizeVehicleMulti: params => ridesPreAuthorizeVehicleMulti(params)
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(LiveChatReassign);
