import { createSelector } from '@reduxjs/toolkit';
import { selectEligibility, selectEligibilityDates } from '~/Modules/memberProfile';
import type { AppStore } from '~/types';
import { minutesUntilDate } from '~/utilities/timesAndDates';
import type { BlockType } from '../RideBooking.types';
import { selectIgnoreBenefitLimits } from './selectIgnoreBenefitLimits';
import {
  findMostRelevantBenefitRemaining,
  getBenefitsUnderRemainingThreshold,
  getBookableYears
} from '../RideBooking.utils';
import { selectBenefitsUsageAndLimits } from './selectBenefitsUsageAndLimits';
import { selectTreatAllBlocksAsSoft } from './selectTreatAllBlocksAsSoft';
import { selectActiveTreatment } from './selectActiveTreatment';
import { selectUsedUpBenefitPeriods } from './selectUsedUpBenefitPeriods';

const selectDateRestrictions = (s: AppStore) => s.rideBooking.meta.dateRestrictions;
const selectPastBookingDays = (s: AppStore) => s.user?.pastBookingDays ?? 0;

/**
 * Given the selected treatment and the information we have so far on the funding source section,
 * determines if the passenger is blocked from booking a ride based on their current benefits
 * and if the sub health plan has treatments enabled. This only affects passengers with health
 * sub plans that have a rides per year benefit AND have treatments enabled.
 *
 * If a passenger is no longer eligible but WAS eligible then we have to check
 * the date of their latest eligibility. If it's within the past booking days
 * range then they should be allowed to book a past ride. The date lookup accounts
 * for member eligibility.
 */
export const selectIsFundingSourceBlocked = createSelector(
  [
    selectEligibility,
    selectPastBookingDays,
    selectDateRestrictions,
    selectBenefitsUsageAndLimits,
    selectIgnoreBenefitLimits,
    selectTreatAllBlocksAsSoft,
    selectActiveTreatment,
    selectEligibilityDates,
    selectUsedUpBenefitPeriods
  ],
  (
    isEligible,
    pastBookingDays,
    dateRestrictions,
    benefitsUsageAndLimits,
    ignoreBenefitLimits,
    treatAllBlocksAsSoftBlocks,
    activeTreatment,
    eligibilityDates,
    { usedUpBenefitPeriods }
  ): [boolean, BlockType, string[]] | [boolean, BlockType] => {
    // If the user is no longer eligible but WAS eligible then we have to check
    // the date of their latest eligibility. If it's within the past booking days
    // range then they should be allowed to book a past ride. The date lookup accounts
    // for member eligibility.
    if (!isEligible) {
      if (!pastBookingDays || !dateRestrictions) return [true, 'Hard'];

      if (minutesUntilDate(dateRestrictions.latestDate) <= pastBookingDays * 24 * 60) {
        return [true, 'Past'];
      }
    }

    // Ignore benefit limits only applies for eligible passengers
    if (ignoreBenefitLimits) return [false, undefined];

    // If treatments is enabled and benefit deduction is 0, allow ride booking
    if (activeTreatment?.benefit_deduction === 0) return [false, undefined];

    const currentYear = new Date().getFullYear().toString();

    const benefitsUnderThreshold = getBenefitsUnderRemainingThreshold(
      1,
      benefitsUsageAndLimits,
      treatAllBlocksAsSoftBlocks
    ).filter(
      // Filter out monthly benefits, only obey the rides per year limit (and for only the
      // current year) on the Funding Source section. We'll check more in later sections.
      b => !b.month && b.year === currentYear
    );

    const mostRelevantBenefit = findMostRelevantBenefitRemaining(benefitsUnderThreshold);

    if (mostRelevantBenefit) {
      let blockType: BlockType;
      // We return and display a special block type if the benefit is a benefit category
      if (mostRelevantBenefit.benefitCategoryId) {
        blockType = mostRelevantBenefit.isHardBlock
          ? 'Treatments-Hard'
          : 'Treatments-Soft';
      } else {
        blockType = mostRelevantBenefit.isHardBlock ? 'Hard' : 'Soft';
      }

      // Compute bookable years based on eligibility dates and used up benefit periods.
      const bookableYears = getBookableYears(
        usedUpBenefitPeriods,
        eligibilityDates.startDate,
        eligibilityDates.endDate,
        dateRestrictions?.earliestDate,
        dateRestrictions?.latestDate
      );

      // For hard block limits where there are bookable years, turn the blockType into a LimitedYears block type (which is a warning/soft block, not an error/hard block).
      if (bookableYears.length > 0) {
        if (blockType === 'Hard') {
          blockType = 'LimitedYears';
        } else if (blockType === 'Treatments-Hard') {
          blockType = 'Treatments-LimitedYears';
        }
      }
      return [true, blockType, bookableYears];
    }

    // If no limits hit or anything else, then no block
    return [false, undefined];
  }
);
