import React, { useState, useEffect } from 'react';
import { Container } from '@material-ui/core';
import InputText from '~/Shared/Components/InputText';
import { isEmpty } from '@SRHealth/frontend-lib';
import moment from 'moment';
import type { BenefitAddonMetadata, BenefitsForm } from '~/Modules/memberProfile';

interface BenefitAddonFieldProps {
  heading: string;
  children: React.ReactNode;
  className?: string;
}

const BenefitAddonField: React.FC<BenefitAddonFieldProps> = ({ heading, children }) => {
  return (
    <div className="CustomInputText halfColumn">
      <p className="benefitsHeading">{heading}</p>
      {children}
    </div>
  );
};

interface BenefitAddonInput {
  err: boolean;
  errorText: string;
  value: string;
}

interface BenefitAddonProps {
  benefits: BenefitsForm;
  editBenefits: boolean;
  onAddOnChange: (id: string, value: string, isError: boolean, errorText: string) => void;
  currentFormState: Record<string, BenefitAddonInput>;
}

const BenefitAddons: React.FC<BenefitAddonProps> = ({
  benefits,
  editBenefits,
  onAddOnChange,
  currentFormState
}) => {
  const [addonFormState, setAddonFormState] = useState({});
  const metadata = benefits?.addonsMetadata;
  const addons = benefits?.memberAddons;

  useEffect(() => {
    if (metadata && isEmpty(addonFormState)) {
      const initialFormState = metadata.reduce((acc, addon) => {
        const inputState = {
          ...acc,
          [`addon-${addon.id}`]: {
            value: '',
            error: false,
            errorText: ''
          }
        };

        if (!isEmpty(addons[addon.id]) && addons[addon.id].start_date) {
          const startDate = formatDate(addons[addon.id].start_date);
          inputState[`addon-${addon.id}`].value = startDate;
        }

        return inputState;
      }, {});

      setAddonFormState(initialFormState);
    }
  }, [metadata]);

  const handleOnChange = ({ target }, id) => {
    setAddonFormState(prev => ({
      ...addonFormState,
      [id]: {
        ...prev[id],
        value: target.value
      }
    }));

    onAddOnChange(id, target.value, false, '');
  };

  const formatDate = (date: string) => {
    return moment(date, 'YYYY-MM-DD').format('MM/DD/YYYY');
  };

  const getFormState = (
    addonId: number,
    formState: Record<string, BenefitAddonInput>
  ) => {
    const formKey = `addon-${addonId}`;
    return !isEmpty(formState) ? formState[formKey] : null;
  };

  const getBalance = (addon: BenefitAddonMetadata) => {
    const usage = benefits?.addonsUsage[addon.id] ?? 0;
    const balance = addon.limit - usage;

    return `${balance} rides`;
  };

  const setStartDate = (addon: BenefitAddonMetadata) => {
    const memberAddon = benefits?.memberAddons[addon.id];
    const formState = getFormState(addon.id, currentFormState);

    if (memberAddon?.start_date && !editBenefits) {
      return <p className="benefitsText">{formatDate(memberAddon?.start_date)}</p>;
    }

    return (
      <InputText
        customClassName="fullColumn"
        placeholder="##/##/####"
        mask="_"
        fieldName="startDate"
        error={formState?.err ?? false}
        errorText={formState?.errorText ?? ''}
        onChange={e => handleOnChange(e, `addon-${addon.id}`)}
        value={
          !isEmpty(addonFormState)
            ? (addonFormState[`addon-${addon.id}`]?.value ?? '##/##/####')
            : memberAddon?.start_date
        }
        disabled={!editBenefits}
        format="##/##/####"
      />
    );
  };

  return (
    <>
      {benefits?.addonsMetadata &&
        benefits?.addonsMetadata.map(addon => (
          <div key={addon.id}>
            <Container className="parentTab benefits">
              <div className="memberRow">
                <div className="CustomInputText twoColumn">
                  <p className="benefitsHeading">Health Plan</p>
                  <p className="benefitsText">{benefits.healthPlanName || '--'}</p>
                </div>
                <div className="CustomInputText twoColumn">
                  <p className="benefitsHeading">Benefit Add-On</p>
                  <p className="benefitsText">{addon.name || '--'}</p>
                </div>
              </div>
            </Container>

            <Container className="parentTab benefits">
              <div className="memberRow">
                <BenefitAddonField heading="Member Ride Benefit">
                  <p className="benefitsText">
                    {addon.limit} Rides / {addon.duration} {addon.duration_unit}
                  </p>
                </BenefitAddonField>

                <BenefitAddonField heading="Start Date">
                  {setStartDate(addon)}
                </BenefitAddonField>

                <BenefitAddonField heading="Usage (YTD)">
                  <p className="benefitsText">
                    {benefits?.addonsUsage[addon.id] ?? 0} rides
                  </p>
                </BenefitAddonField>

                <BenefitAddonField heading="End Date">
                  <p className="benefitsText">
                    {benefits?.memberAddons[addon.id]?.end_date
                      ? formatDate(benefits?.memberAddons[addon.id]?.end_date)
                      : ''}
                  </p>
                </BenefitAddonField>

                <BenefitAddonField heading="Balance (YTD)">
                  <p className="benefitsText">{getBalance(addon)} </p>
                </BenefitAddonField>
              </div>
            </Container>
          </div>
        ))}
    </>
  );
};

export default BenefitAddons;
