import type { MemberProfileStore } from '~/Modules/memberProfile';
import React, { useState, useEffect } from 'react';
import type { FormEventHandler, FormState } from '~/Shared/Components/Form';
import Form from '~/Shared/Components/Form';
import { Divider, Typography, Container, Box, Grid, Button } from '@material-ui/core';
import type { FormInputProps } from '~/Shared/Components/Form/FormInput';
import useStyles from './formsTheme';
import useAnalytics from '~/hooks/useAnalytics';
import { autofillFirstAssessment } from './MobilityAssessment.helper';
import type { MobilityAssessmentEventProperties } from '~/analytics/analytics.events';

type TimeRecord = {
  start: number | undefined;
  questionOne: MobilityAssessmentEventProperties['Question One'];
  questionTwo: MobilityAssessmentEventProperties['Question Two'];
  questionThree: MobilityAssessmentEventProperties['Question Three'];
};

const INITIAL_TIME_RECORD = {
  start: undefined,
  questionOne: undefined,
  questionTwo: undefined,
  questionThree: undefined
};

export type MobilityAssessmentProps = {
  isDisabled: boolean;
  member: MemberProfileStore;
  updateMember: (submission: FormState) => void;
  setIsChildBeingEdited: (isEditing: boolean) => void;
  formId?: string;
  formTemplate?: FormState;
};

const MobilityAssessment = ({
  isDisabled,
  member,
  updateMember,
  setIsChildBeingEdited,
  formId,
  formTemplate
}: MobilityAssessmentProps) => {
  const classes = useStyles();
  const analytics = useAnalytics();
  const lastAssessment = member['mobilityAssessment'];

  const [isEditing, setIsEditing] = useState(false);
  const [template, setTemplate] = useState<FormState>();
  const [submitHook, setSubmitHook] = useState<FormEventHandler>();
  const [cancelHook, setCancelHook] = useState<FormEventHandler>();

  /** Used to track how long it takes users to answer the first
   * three questions in the assessment. This isn't used if member
   * has previously completed an assessment.
   *
   * Timestamps are stored in Unix epoch ms */
  const [timeRecord, setTimeRecord] = useState<TimeRecord>(INITIAL_TIME_RECORD);

  /**
   * Load either the assessment template or the most recent
   * member submission for the mobility assessment.
   */
  useEffect(() => {
    if (lastAssessment) {
      setTemplate(lastAssessment.submission);
    } else if (formTemplate && !template) {
      const firstAssessment = autofillFirstAssessment(formTemplate as FormState, member);

      setTemplate(firstAssessment);
    }
  }, [lastAssessment, formTemplate]);

  /**
   * changes the parent isChildBeingEdited state in MemberProfiles, whenever the local state changes
   */
  useEffect(() => {
    setIsChildBeingEdited(isEditing);
  }, [isEditing, setIsChildBeingEdited]);

  /** Edit event handler */
  function handleEdit() {
    if (isDisabled) return;

    setIsEditing(true);

    if (!lastAssessment) {
      setTimeRecord({ ...timeRecord, start: Date.now() });

      /** If the most recent member submission id does
       * not match the form id then assume form template is
       * a newer version. */
    } else if (lastAssessment.formId !== formId) {
      const firstAssessment = autofillFirstAssessment(formTemplate as FormState, member);

      setTemplate(firstAssessment);
    }
  }

  /** Cancel event handler */
  function handleCancel() {
    setIsEditing(false);
    if (cancelHook) cancelHook();
    if (lastAssessment) setTimeRecord(INITIAL_TIME_RECORD);
  }

  /** Submit event handler */
  function handleSubmit() {
    if (isDisabled) return;

    // Submit answer times to Mixpanel
    analytics.trackEvent('Mobility Assessment Time', {
      ['Question One']: timeRecord.questionOne,
      ['Question Two']: timeRecord.questionTwo,
      ['Question Three']: timeRecord.questionThree
    });

    if (submitHook) {
      const newTemplate = submitHook() as FormState | undefined;

      // Short-circuit in case validation fails
      if (!newTemplate) return;

      updateMember(newTemplate);
      setTemplate(newTemplate as FormState);
    }

    setIsEditing(false);
  }

  /**
   * Track the question answer times to submit to mixpanel
   * @param {FormInputProps} input
   * @returns {void}
   */
  function handleChange(input: FormInputProps, index: number) {
    let key: keyof MobilityAssessmentEventProperties | undefined;

    switch (index) {
      case 0:
        key = 'questionOne';
        break;
      case 1:
        key = 'questionTwo';
        break;
      case 2:
        key = 'questionThree';
        break;
      default:
    }

    const start = timeRecord.start;
    if (key && start) {
      let newTime: MobilityAssessmentEventProperties[keyof MobilityAssessmentEventProperties];

      if (!input.selection && input.selection !== 0) {
        newTime = undefined;
      } else {
        newTime = (Date.now() - start) / 1000;
      }

      setTimeRecord({ ...timeRecord, [key]: newTime });
    }
  }

  return (
    <Container
      id="member-mobilty-assessment-form"
      className={`parentTab mma ${classes.parentTab}`}
    >
      <Grid container direction="row" justifyContent="space-between" alignItems="center">
        <FormHeader className={classes.requiredFields} />

        {!isDisabled && (
          <>
            <CancelButton
              isEditing={isEditing}
              className={classes.cancelButton}
              onClick={handleCancel}
            />
            <SubmitButton
              isEditing={isEditing}
              isNew={!lastAssessment}
              className={classes.submitButton}
              onEdit={handleEdit}
              onSubmit={handleSubmit}
            />
          </>
        )}
      </Grid>

      <Divider />

      <Grid container direction="row" justifyContent="center" alignItems="center">
        <Box>
          <CompletionMessage isEditing={isEditing} isNew={!lastAssessment} />
        </Box>
      </Grid>

      {template && (
        <Form
          name="mobilityAssessment"
          template={template}
          disabled={!isEditing}
          onChange={handleChange}
          cancelEventHook={handler => setCancelHook(() => handler)}
          submitEventHook={handler => setSubmitHook(() => handler)}
        />
      )}
    </Container>
  );
};

type FormHeaderProps = {
  className?: string;
};

function FormHeader({ className }: FormHeaderProps) {
  return (
    <Box flex={'1'}>
      <Box display="inline-flex" flexDirection="row" style={{ gap: '6px' }}>
        <Typography variant="h6">Member Mobility Assessment</Typography>
        <Typography variant="subtitle2" className={className}>
          * Required Questions
        </Typography>
      </Box>
    </Box>
  );
}

type CompletionMessageProps = {
  isNew: boolean;
  isEditing: boolean;
};

function CompletionMessage({ isNew, isEditing }: CompletionMessageProps) {
  if (!isEditing || !isNew) return null;

  return (
    <Typography>Please complete the following member mobility assessment</Typography>
  );
}

type SubmitButtonProps = {
  isEditing: boolean;
  isNew: boolean;
  className?: string;
  onSubmit: () => void;
  onEdit: () => void;
};

function SubmitButton({
  isEditing,
  isNew,
  className,
  onSubmit,
  onEdit
}: SubmitButtonProps) {
  let label = isNew ? 'Assess' : 'Edit';
  let onClick = onEdit;

  if (isEditing) {
    label = 'Submit';
    onClick = onSubmit;
  }

  return (
    <Button className={className} onClick={onClick}>
      {label}
    </Button>
  );
}

type CancelButtonProps = {
  isEditing: boolean;
  className?: string;
  onClick?: () => void;
};

function CancelButton({ isEditing, className, onClick }: CancelButtonProps) {
  return isEditing ? (
    <Button className={className} onClick={onClick}>
      Cancel
    </Button>
  ) : null;
}

export default MobilityAssessment;
