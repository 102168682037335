import type { DevFeatureFlag } from "./types.d.ts";

// Define the feature flags as a constant
const FLAGS: DevFeatureFlag[] = [
  {
    name: "BILLING_CONFIG_PARAMS",
    description: "Enables customer to get appropriate billing params",
    value: process.env.BILLING_CONFIG_PARAMS,
  },
];

export const getFlag = (
  flags: DevFeatureFlag[],
  name: string,
): DevFeatureFlag | undefined => {
  return flags.find((flag) => flag.name === name);
};

export const isEnvFeatureEnabled = (flags: DevFeatureFlag[], name: string): boolean => {
  const flag = getFlag(flags, name);
  const environmentValue = flag?.value;

  if (!environmentValue) {
    console.warn(
      `Missing environment variable for Feature Flag ${name}. Defaulting to false.`,
    );
    return false;
  }

  return ["1", "true", "yes"].includes(environmentValue.toLowerCase());
};

export const defaultFlags = FLAGS;
