import { createSelector } from '@reduxjs/toolkit';
import type { AppStore } from '~/types';

const selectAllowBenefitOverride = (state: AppStore) =>
  state.memberProfile.formData.benefits.allowBenefitOverride;

/**
 * Determines if we should treat all benefit limits/blocks as soft blocks.
 * @todo: Remove this completely. It's just renaming a value being pulled
 * from the memberProfile store. It's unnecessary complexity.
 */
export const selectTreatAllBlocksAsSoft = createSelector(
  [selectAllowBenefitOverride],
  (allowBenefitOverride): boolean => {
    // If the user's role allows them to override benefit limits,
    // then we should treat any blocks as soft blocks
    return allowBenefitOverride;
  }
);
