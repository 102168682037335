import { createAppAsyncThunk } from '~/Modules';
import { resetRecurringRides } from '../RideBooking.slice';
import { getProviderSupplyThunk } from './getProviderSupply.thunk';
import { processNewPrimaryRide } from '../RideBooking.utils';
import { selectTreatAllBlocksAsSoft } from '../selectors/selectTreatAllBlocksAsSoft';

export const completeRidesThunk = createAppAsyncThunk(
  'rideBooking/completeRides',
  async (_, { getState, dispatch, rejectWithValue }) => {
    const state = getState();
    const lastRide = state.rideBooking.rides.at(-1);

    if (!lastRide) throw Error('No rides found');

    const shouldClearRecurringRides = lastRide.isDirty;

    try {
      await processNewPrimaryRide({
        getState,
        rideIndex: state.rideBooking.rides.length - 1,
        treatAllBlocksAsSoftBlocks: selectTreatAllBlocksAsSoft(state)
      });
    } catch (hardBlockDuplicateRidesError) {
      return rejectWithValue(hardBlockDuplicateRidesError);
    }

    // Clear recurring rides if needed.
    if (shouldClearRecurringRides) {
      dispatch(resetRecurringRides());
    }

    // Dispatch the provider supply thunk.
    return dispatch(getProviderSupplyThunk());
  }
);
