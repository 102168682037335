import { createSelector } from '@reduxjs/toolkit';
import { selectHealthSubPlanTreatments } from '~/Modules/patients';
import type { AppStore } from '~/types';

const selectHealthSubPlanTreatmentId = (s: AppStore) =>
  s.rideBooking.fundingSource.healthSubPlanTreatmentId;

export const selectActiveTreatment = createSelector(
  [selectHealthSubPlanTreatments, selectHealthSubPlanTreatmentId],
  (healthSubPlanTreatments, healthSubPlanTreatmentId) =>
    healthSubPlanTreatmentId
      ? healthSubPlanTreatments?.find(t => t.id === healthSubPlanTreatmentId)
      : undefined
);
