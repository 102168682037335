import type { RideBookingSection } from './RideBooking.types';

/** Controls the ordering of the RBF sections. */
export const RIDE_SECTION_ORDER: Readonly<Record<RideBookingSection, number>> = {
  ['passenger-info']: 0,
  ['funding-source']: 1,
  ['date']: 2,
  ['rides']: 3,
  ['recurring-rides']: 4,
  ['transport-provider']: 5
} as const;

/** Contains all standardized messaging used across the RideBooking domain. */
export const TXT = Object.freeze({
  /** The displayed label if the passenger has a rides/year benefit. This
   * value is used in a comparison check for which benefit we are displaying
   * in the UI. */
  RIDES_PER_YEAR_LABEL: 'Rides/Year',
  /** The displayed label if the passenger has a rides/month benefit. */
  RIDES_PER_MONTH_LABEL: 'Rides/Month',
  /** Message displayed when member has hit their benefit limit for a specific period */
  BENEFIT_PERIOD_LIMIT_HIT: 'Member has hit their benefit limit for'
} as const);

/** The available cadence options for recurring rides. */
export const RECURRING_RIDES_CADENCE = [
  'weekly',
  'everyTwoWeeks',
  'everyThreeWeeks',
  'everyFourWeeks'
] as const;
